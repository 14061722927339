import React, { useState, useEffect } from 'react';
import { Menu, MenuItem, Fade, Grid } from '@material-ui/core';
import { capitalize } from 'lodash';
import ConfirmationDialog from './ConfirmationDialog';
import ExpandMoreOutlinedIcon from '@material-ui/icons/ExpandMoreOutlined';
import { remapTCRStatus } from '../utils';
import { convertTimeWithTimezone } from '../../../../utils/time';
import apiInstance from '../../../../utils/apiInterceptor';
import { MIGRATION_STATUS_LABEL_MAP } from '../constants';

export default function PartnerCampaignDetailsBasic(props) {
  const { campaign, renewalData } = props;
  const [openModal, setOpenModal] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);
  const [open, setOpen] = useState(false);
  const [migrationInfo, setMigrationInfo] = useState();

  useEffect(() => {
    fetchMigrationInfo(campaign.uid);
  }, [campaign?.uid]);

  const fetchMigrationInfo = async (id) => {
    // call the api silently, due to no migration record would returns 400 error
    const response = await apiInstance(`csp/campaigns/${id}/migrateCnp`, {
      validateStatus: null,
    });
    if (response.ok) {
      setMigrationInfo(response.data);
    }
  };

  const getMigrationStatus = () => {
    const { status = 'No' } = migrationInfo ?? {};
    return MIGRATION_STATUS_LABEL_MAP[status] ?? status;
  };

  const handleClick = (e) => {
    setOpen(true);
    setAnchorEl(e.currentTarget);
  };
  const handleRequestClose = (e) => {
    setOpen(false);
  };

  return (
    <>
      <Grid
        container
        className="basic-info"
        spacing={2}
        data-testid="partnerCampaignDetailBasic"
      >
        <Grid item xs={6}>
          <div className="column">
            {campaign.subUsecases ? (
              <div className="campaign-usecase-wrapper">
                <h3
                  data-testid="partnerCampaignDetailBasicUseCase"
                  className="heading2"
                  aria-owns={open ? 'simple-menu' : null}
                  aria-haspopup="true"
                  // onMouseOver={handleClick}
                >
                  <span>Use-Case:</span>
                  <span onClick={handleClick} className="val">
                    {campaign.usecase}
                    <ExpandMoreOutlinedIcon
                      className={`dropdown-arrow ${open ? 'open' : ''}`}
                    />
                  </span>
                </h3>
                <Menu
                  id="simple-menu"
                  data-testid="partnerCampaignDetailBasicMenu"
                  anchorEl={anchorEl}
                  open={open}
                  onClose={handleRequestClose}
                  className="campaign-subusecases-menu"
                  MenuListProps={
                    {
                      // onMouseLeave: handleRequestClose
                    }
                  }
                  TransitionComponent={Fade}
                >
                  {campaign.subUsecases.split(',').map((sub, index) => (
                    <MenuItem
                      key={index}
                      onClick={handleRequestClose}
                      data-testid={`partnerCampaignDetailBasicMenuItem-${index}`}
                    >
                      {sub}
                    </MenuItem>
                  ))}
                </Menu>
              </div>
            ) : (
              <h3
                className="heading2"
                data-testid="partnerCampaignDetailBasicUseCase"
              >
                <span>Use-Case:</span>
                {campaign.usecase}
              </h3>
            )}
            <h3
              className="heading2"
              data-testid="partnerCampaignDetailBasicTcrStatus"
            >
              <span className="title">TCR Status:</span>
              <span
                className={`
                            val
                            ${
                              remapTCRStatus(campaign.status) === 'ACTIVE'
                                ? 'primary-color'
                                : remapTCRStatus(campaign.status) ===
                                  'DEACTIVATED'
                                ? 'error-color'
                                : ''
                            }
                            `}
              >
                {capitalize(remapTCRStatus(campaign.status))}
              </span>
            </h3>
          </div>
        </Grid>
        <Grid item xs={6} className="border-left">
          <div className="column">
            <h3
              className="heading2"
              data-testid="partnerCampaignDetailBasicRegisteredOn"
            >
              <span>Registered on:</span>
              {campaign.createDate
                ? convertTimeWithTimezone(
                    campaign.createDate,
                    undefined,
                    'MM/DD/YYYY'
                  )
                : 'N/A'}
            </h3>
            <h3
              className="heading2"
              data-testid="partnerCampaignDetailBasicMigration"
            >
              <span className="title">Migration:</span>
              <span className="val primary-color">{getMigrationStatus()}</span>
            </h3>
          </div>
        </Grid>
      </Grid>
      <ConfirmationDialog
        open={openModal}
        type="renewal"
        handleCancel={() => setOpenModal(false)}
        handleSuccess={() => {
          setOpenModal(false);
          props.handleSubmitEdit({ autoRenewal: false });
        }}
        text={renewalData ? renewalData.text : ''}
      />
    </>
  );
}
