import React from 'react';
import { Grid, Dialog } from '@material-ui/core';
import { S3_ASSETS_PATH } from '../../../../../constants';

import { ModalType, VettingClassType, VettingProvider } from '../../types';
import { EmptyString } from '../../../../../utils/types';

interface Props {
  vettingProviders: Array<VettingProvider>;
  extVettingProviderId: string;
  vettingId: string;
  vettingToken: string;
  brandName: string;
  applyErrorMsg: string;
  vettingClass: VettingClassType | EmptyString;
  vettingConfirmed: boolean;
  applyError: boolean;
  vettingScore: undefined | number;
  importVettingError: boolean;
  modalType: ModalType;
  handleClose: () => void;
}
const VettingStatusModal: React.FC<Props> = ({
  vettingProviders,
  extVettingProviderId,
  vettingId,
  vettingToken,
  brandName,
  applyErrorMsg,
  vettingClass,
  vettingConfirmed,
  applyError,
  vettingScore,
  importVettingError,
  modalType,
  handleClose,
}) => {
  const vettingProvider = extVettingProviderId
    ? vettingProviders.find((item) => item.uid === extVettingProviderId)
    : '';
  return (
    <Dialog
      open={true}
      onClose={(_e, reason) => {
        !(reason === 'backdropClick') && handleClose();
      }}
      className="brand-vetting-modal"
      data-testid="vettingStatusModal"
    >
      <div className="modal-wrapper">
        <div className="modal-content">
          <Grid
            container
            alignItems="center"
            className="vetting-applied-wrapper modal-info"
          >
            {vettingConfirmed ? (
              <>
                <Grid item xs={12}>
                  <img
                    className="done"
                    src={`${S3_ASSETS_PATH}/images/vetting-success.svg`}
                    alt=""
                    data-testid="vettingStatusModalSuccessImage"
                  />
                </Grid>
                <Grid item xs={12}>
                  {modalType === ModalType.vettingStatus ? (
                    <h3 className="paragraph">
                      Vetting information received correctly
                    </h3>
                  ) : (
                    <h3 className="paragraph">External vetting completed.</h3>
                  )}
                </Grid>
                <Grid item xs={12} className="vetting-partner">
                  <p className="paragraph">
                    Vetting Partner:
                    <span>
                      {vettingProvider ? vettingProvider.displayName : ''}
                    </span>
                  </p>
                  <p className="paragraph">
                    Vetting Type:<span>{vettingClass}</span>
                  </p>
                  <p className="paragraph">
                    Vetting Outcome:<span>{vettingScore}</span>
                  </p>
                </Grid>
                <Grid item xs={12} style={{ textAlign: 'center' }}>
                  <div
                    className="form-group-field"
                    style={{ marginBottom: '0' }}
                  >
                    <input
                      data-testid="vettingStatusModalCloseButton"
                      type="submit"
                      className="primary-btn"
                      value={'Close'}
                      onClick={() => handleClose()}
                    />
                  </div>
                </Grid>
              </>
            ) : importVettingError ? (
              <>
                <Grid item xs={12}>
                  <img
                    className="done"
                    src={`${S3_ASSETS_PATH}/images/vetting-error.svg`}
                    alt=""
                    data-testid="vettingStatusModalErrorImage"
                  />
                </Grid>
                <Grid item xs={12}>
                  <h3 className="paragraph">
                    We are unable to confirm your vetting information
                  </h3>
                </Grid>
                <Grid item xs={12} className="vetting-partner">
                  <p className="paragraph">
                    Vetting Partner:
                    <span>
                      {vettingProvider ? vettingProvider.displayName : ''}
                    </span>
                  </p>
                  {/* <p className="paragraph">Vetting Type:<span>{vettingClass}</span></p> */}
                  <p className="paragraph">
                    Vetting ID:<span>{vettingId}</span>
                  </p>
                  {extVettingProviderId === 'AEGIS' &&
                  modalType === ModalType.vettingStatus ? (
                    <p className="paragraph">
                      Vetting Token:<span>{vettingToken}</span>
                    </p>
                  ) : null}
                </Grid>
                <Grid item xs={12} style={{ textAlign: 'center' }}>
                  <div
                    className="form-group-field"
                    style={{ marginBottom: '0' }}
                  >
                    <input
                      data-testid="vettingStatusModalCloseButton"
                      type="submit"
                      className="primary-btn"
                      value={'Close'}
                      onClick={() => handleClose()}
                    />
                  </div>
                </Grid>
              </>
            ) : (
              <>
                <Grid item xs={12}>
                  {applyError ? (
                    <img
                      src={`${S3_ASSETS_PATH}/images/vettingError.svg`}
                      alt=""
                    />
                  ) : (
                    <img
                      src={`${S3_ASSETS_PATH}/images/vetting-pending-status.svg`}
                      alt=""
                    />
                  )}
                </Grid>
                <Grid item xs={12}>
                  {/* {props.modalType == ModalType.vettingResult ? applyError ? <h3 className="paragraph confirm">Your <span>vetting request for "{brandName}" is unable to process with </span>{vettingProvider.displayName ? vettingProvider.displayName.toUpperCase() : ''}.Please try again later!</h3> : <h3 className="paragraph confirm pending">Your <span>vetting request for "{brandName}" is pending with </span>{vettingProvider.displayName}.You will be notified once results are received.</h3> : <h3 className="paragraph confirm">Your <span>vetting request is pending </span>with {vettingProvider.displayName}.You will be notified once results are received.</h3>} */}
                  {modalType === ModalType.vettingResult ? (
                    applyError ? (
                      applyErrorMsg ? (
                        <h3 className="paragraph confirm pending">
                          {applyErrorMsg}
                        </h3>
                      ) : (
                        <h3 className="paragraph confirm pending">
                          Your{' '}
                          <span>
                            vetting request for "{brandName}" is unable to
                            process with{' '}
                          </span>
                          {vettingProvider && vettingProvider.displayName
                            ? vettingProvider.displayName.toUpperCase()
                            : ''}
                          .Please try again later!
                        </h3>
                      )
                    ) : (
                      <h3 className="paragraph confirm pending">
                        We are confirming your data with the Vetting Partner.{' '}
                        <br />
                        Please come back later to check on the status of the
                        request.
                      </h3>
                    )
                  ) : (
                    <h3 className="paragraph confirm">
                      We are confirming your data with the Vetting Partner.{' '}
                      <br />
                      Please come back later to check on the status of the
                      request.
                    </h3>
                  )}
                </Grid>
                <Grid item xs={12} style={{ textAlign: 'center' }}>
                  <div
                    className="form-group-field"
                    style={{ marginBottom: '0' }}
                  >
                    <input
                      data-testid="vettingStatusModalCloseButton"
                      type="submit"
                      className="primary-btn"
                      value={'Close'}
                      onClick={() => handleClose()}
                    />
                  </div>
                </Grid>
              </>
            )}
          </Grid>
        </div>
      </div>
    </Dialog>
  );
};
export default VettingStatusModal;
