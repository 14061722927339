import React from 'react';
import { Link } from 'react-router-dom';
import { S3_ASSETS_PATH } from '../../../../constants';
import { Grid } from '@material-ui/core';

export default function RegisterBrand(props) {
  return (
    <Grid
      container
      className="no-brands-found-container"
      justify="center"
      alignItems="center"
    >
      <Grid item className="text-center">
        <div className="no-brands-found text-center">
          <img src={`${S3_ASSETS_PATH}/images/register_a_brand.svg`} alt="" />
          <h3 className="heading1">Please register a Brand</h3>
          <p className="heading1 light">
            You will need to Register a Brand to Register Campaign
          </p>
        </div>
        <Link to="/brand/create" className="primary-btn">
          Register a Brand
        </Link>
      </Grid>
    </Grid>
  );
}
