import {
  globalGetService,
  globalPostService,
} from '../../../utils/globalApiServices';
import * as actions from './actions';

export const getStripeKeyAc = () => {
  return (dispatch) => {
    // globalGetService('csp/payment/publishableKey')
    // .then(response =>{
    //   dispatch({
    //     type: actions.STRIPE_KEY,
    //     payload: response.data.publishableKey
    //   })
    // })
  };
};

export const getActivationFeeAc = () => {
  return (dispatch) => {
    // globalGetService('csp/payment/activationFee')
    // .then(response =>{
    //   dispatch({
    //     type: actions.ACTIVATION_FEE,
    //     payload: response.data
    //   })
    // })
  };
};
