import React from 'react';
import { Dialog, DialogContent } from '@material-ui/core';
import parse from 'html-react-parser';

export default function ConfirmationDialog(props) {
  const { open, type } = props;
  return (
    <Dialog
      open={open}
      //   onClose={() => this.setState({signInModal: false})}
      aria-labelledby="responsive-dialog-title"
      maxWidth="xs"
      fullWidth={true}
      id="successModal"
      className={`edit-modal auto-renewal ${
        type == 'deactivate' ? `deactivate` : ``
      }`}
      data-testid="campaignConfirmationDialog"
    >
      <DialogContent className={`edit-content text-center`}>
        {<div className="paragraph">{parse(props.text)}</div>}
        <ul className="list-inline footer">
          <li>
            <p>
              <a
                data-testid="campaignConfirmationDialogCancelButton"
                onClick={props.handleCancel}
                className={props.loader ? 'secondary-btn' : 'secondary-btn'}
              >
                Cancel
              </a>
            </p>
          </li>
          <li>
            <p>
              <a
                data-testid="campaignConfirmationDialogContinueButton"
                onClick={props.handleSuccess}
                className={
                  props.loader ? 'primary-btn disabled' : 'primary-btn'
                }
              >
                Continue
              </a>
            </p>
          </li>
        </ul>
      </DialogContent>
    </Dialog>
  );
}
