import { FC, ComponentProps } from 'react';
import { BoxV2 as Box } from 'portal-commons';

export const TwoColumnGrid: FC<ComponentProps<typeof Box>> = ({
  children,
  sx,
  ...rest
}) => {
  return (
    <Box
      sx={{
        display: 'grid',
        gridTemplateColumns: '1fr 1fr',
        columnGap: 'xxl',
        rowGap: 'l',
        ...sx,
      }}
      {...rest}
    >
      {children}
    </Box>
  );
};

export default TwoColumnGrid;
