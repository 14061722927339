import React from 'react';
import PropTypes from 'prop-types';
import {
  Button,
  Dialog,
  DialogContent,
  Grid,
  makeStyles,
} from '@material-ui/core';

const useStyles = makeStyles({
  container: {},
  content: {
    width: 387,
    padding: '30px 20px !important',
    textAlign: 'center',
    boxSizing: 'border-box',
  },
});

const DownloadLimitedModal = ({ open, onClose, onSubmit }) => {
  const classes = useStyles();

  return (
    <Dialog
      classes={{ container: classes.container }}
      open={open}
      onClose={onClose}
      data-testid="downloadLimiteModal"
    >
      <DialogContent classes={{ root: classes.content }}>
        <Grid container>
          <Grid className="title" item xs={12}>
            <p className="paragraph" style={{ lineHeight: '20px' }}>
              Download limited to 10,000 records
            </p>
          </Grid>
          <Grid
            style={{ marginTop: 25 }}
            container
            direction="row"
            alignItems="center"
            justifyContent="center"
            xs={12}
          >
            <a
              data-testid="downloadLimiteModalCancelButton"
              className="secondary-btn"
              style={{ width: 80 }}
              onClick={onClose}
            >
              Cancel
            </a>
            <a
              data-testid="downloadLimiteModalProceedButton"
              className="primary-btn"
              style={{ width: 80, marginLeft: 20 }}
              onClick={onSubmit}
            >
              Proceed
            </a>
          </Grid>
        </Grid>
      </DialogContent>
    </Dialog>
  );
};

DownloadLimitedModal.propTypes = {
  open: PropTypes.bool,
  onClose: PropTypes.func,
  onSubmit: PropTypes.func,
};

export default DownloadLimitedModal;
