import React, { Component } from 'react';
import { withRouter, Redirect } from 'react-router-dom';
import { withOktaAuth } from '@okta/okta-react';
import { Header, SideNavigation, MobileHeader } from '../shared_elements';
import { isMobile } from 'react-device-detect';
import { APP_ROLES } from '../constants';
import { toastFlashMessage, verifyUserStatus } from '../utils';
import NoAccess from '../NoAccess';
import datadog from '../utils/datadog';
import { AppLayout } from 'portal-commons';

async function checkUser() {
  if (this.props.authState.isAuthenticated && !this.state.userInfo) {
    const userInfo = await this.props.oktaAuth.getUser();
    !datadog.hasUser() && datadog.setUser(userInfo);
    localStorage.setItem('userInfo', JSON.stringify(userInfo));
    this.setState({
      userInfo,
      role: userInfo && userInfo.groups ? userInfo.groups[0] : 'ROLE_USER',
    });
  }
}

function HeaderHoc(ComposedComponent, extraInfo) {
  class HeaderHoc extends Component {
    constructor(props) {
      super(props);
      this.state = {
        appRoles: APP_ROLES,
        userInfo: '',
        role: localStorage.getItem('userInfo')
          ? JSON.parse(localStorage.getItem('userInfo')).groups
            ? JSON.parse(localStorage.getItem('userInfo')).groups[0]
            : 'ROLE_USER'
          : 'ROLE_USER',
        userDetail: localStorage.getItem('okta-token-storage')
          ? JSON.parse(localStorage.getItem('okta-token-storage'))
          : {},
      };
      this.checkUser = checkUser.bind(this);
    }

    componentDidMount() {
      if (
        (localStorage.getItem('cspStatus') &&
          localStorage.getItem('cspStatus') != 'active' &&
          !this.props.location.pathname.includes('/user/csp')) ||
        (!localStorage.getItem('cspStatus') &&
          !this.props.location.pathname.includes('/user/csp'))
      ) {
        this.props.history.push('/');
      }
      this.checkUser();
      const userDetail = localStorage.getItem('okta-token-storage')
        ? JSON.parse(localStorage.getItem('okta-token-storage'))
        : {};
      if (typeof userDetail === 'object' && userDetail !== null) {
        if (!(userDetail.idToken && userDetail.accessToken)) {
          // toastFlashMessage('Please login again', 'success')
          setTimeout(() => {
            localStorage.clear();
            this.props.history.push('/login');
          }, 2000);
        } else if (
          verifyUserStatus() &&
          !this.props.location.pathname.includes('/user/csp')
        ) {
          toastFlashMessage(
            'Re-login required due to user role update',
            'success'
          );
          setTimeout(() => {
            localStorage.clear();
            this.props.history.push('/login');
          }, 2000);
        }
      } else {
        // toastFlashMessage('Please login again', 'success')
        setTimeout(() => {
          localStorage.clear();
          this.props.history.push('/login');
        }, 2000);
      }
    }

    render() {
      const pathName = this.props.location ? this.props.location.pathname : '';
      const cspFlag = pathName && pathName == '/user/csp' ? true : false;
      const { role, appRoles, userDetail } = this.state;

      if (!(userDetail.idToken && userDetail.accessToken)) {
        toastFlashMessage('Please login again', 'success');
        localStorage.clear();
        return <Redirect to="/login" />;
      } else if (
        verifyUserStatus() &&
        !this.props.location.pathname.includes('/user/csp')
      ) {
        // calling the verify fun only if status is active not initiate csp routes
        toastFlashMessage(
          'Re-login required due to user role update',
          'success'
        );
        localStorage.clear();
        return <Redirect to="/login" />;
      } else {
        const authenticated =
          appRoles[role].includes(extraInfo.id) ||
          appRoles.GENERAL.includes(extraInfo.id);
        return (
          <AppLayout
            authenticated={authenticated}
            sideNavigation={
              isMobile ? (
                <MobileHeader />
              ) : (
                <SideNavigation path={pathName} role={role} />
              )
            }
            topBar={
              <Header
                testId="hocHeader"
                title={extraInfo ? extraInfo.title : ''}
                path={pathName}
                role={role}
              />
            }
            footer={
              <div className="footer-hoc-block">
                <div className="text-center">
                  <p>
                    Confidentiality Notice: The information accessed through The
                    Campaign Registry CSP Portal is intended for use by
                    authorized individuals only. Any unauthorized access or use
                    of this information is prohibited. By using The Campaign
                    Registry CSP Portal, you agree to keep all accessed
                    confidential in accordance with the{' '}
                    <a
                      href="https://www.campaignregistry.com/TCR-T&Cs.pdf"
                      rel="noopener noreferrer"
                      target="_blank"
                    >
                      Terms of Use
                    </a>
                    .
                  </p>
                </div>
              </div>
            }
          >
            <div
              className={`flex-1 header-hoc-block ${cspFlag ? 'csp' : ''}`}
              style={
                isMobile
                  ? { padding: 20 }
                  : pathName.includes('/404')
                  ? { padding: 0 }
                  : {}
              }
            >
              {appRoles[role].includes(extraInfo.id) ||
              appRoles.GENERAL.includes(extraInfo.id) ? (
                <ComposedComponent
                  {...this.props}
                  access={appRoles[role]}
                  role={role}
                />
              ) : (
                <NoAccess />
              )}
            </div>
          </AppLayout>
        );
      }
    }
  }
  return withOktaAuth(withRouter(HeaderHoc));
}
export default HeaderHoc;
