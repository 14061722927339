import { FC, ComponentProps } from 'react';
import { IconProp } from '@fortawesome/fontawesome-svg-core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Flex, BoxV2 as Box } from 'portal-commons';

interface ISection extends ComponentProps<typeof Flex> {
  title: string;
  icon: IconProp;
}

const Section: FC<ISection> = ({ title, icon, children, sx, ...props }) => {
  return (
    <Flex
      as="section"
      sx={{
        padding: '28px 35px 30px 35px',
        flexDirection: 'column',
        alignItems: 'flexStart',
        alignSelf: 'stretch',
        border: '1px solid #C0CCD4',
        background: '#FFF',
        flex: 1,
        ...sx,
      }}
      {...props}
    >
      <Flex
        sx={{
          flexDirection: 'row',
          alignItems: 'center',
          gap: 'xs',
          paddingBottom: 'm',
          marginBottom: 'm',
          borderBottom: '1px solid',
          borderColor: 't.black20',
        }}
      >
        <FontAwesomeIcon icon={icon} fontSize="24px" color="#4D5659" />
        <Box
          as="h3"
          sx={{ fontSize: 'H500', color: 't.black70', fontWeight: 'semiBold' }}
        >
          {title}
        </Box>
      </Flex>
      {children}
    </Flex>
  );
};

export default Section;
