/* eslint-disable no-console */
export const generateAndDownloadFile = (file: File, fileName?: string) => {
  if (!file) {
    console.error('No file provided');
    return;
  }
  const downloadFileName = fileName || file.name || 'download';
  let url;

  try {
    url = URL.createObjectURL(file);
    const element = document.createElement('a');
    element.href = url;
    element.setAttribute('download', downloadFileName);
    document.body.appendChild(element);
    element.click();
    document.body.removeChild(element);
  } catch (error) {
    console.error('Error creating or using object URL:', error);
  } finally {
    url && URL.revokeObjectURL(url);
  }
};
