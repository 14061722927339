import { globalGetService } from '../../../utils/globalApiServices';
import { getTimezone } from '../../../utils/time';
import { Event, EventType } from './types';
import { Pagination } from '../../../utils/types';

export const fetchEventHistory = async (
  query = {}
): Promise<Pagination<Event> | undefined> => {
  const response = await globalGetService('csp/event', {
    ...query,
    timezone: getTimezone(),
  });
  if (response?.ok) {
    return response.data;
  }
  return undefined;
};

export const downloadEventHistoryAsCsv = async (
  query = {}
): Promise<ArrayBuffer | undefined> => {
  const response = await globalGetService(
    'csp/event/csv',
    { ...query, timezone: getTimezone() },
    'arraybuffer'
  );
  if (response?.ok) {
    return response.data;
  }
  return undefined;
};

export const getAllEventTypes = async (): Promise<EventType[]> => {
  const response = await globalGetService('enums/eventTypes');
  if (response?.ok) {
    return response.data;
  }
  return [];
};
