import React from 'react';
import { Button } from 'portal-commons';
import {
  Dialog,
  DialogContent,
  DialogContentText,
  DialogActions,
  makeStyles,
} from '@material-ui/core';

const useStyles = makeStyles({
  content: {
    padding: '40px 30px !important',
    fontSize: '16px',
    lineHeight: 1.15,
    textAlign: 'center',
    width: '525px',
    boxSizing: 'border-box',
  },
  paragraph: {
    fontSize: '16px',
    lineHeight: 1.2,
    marginBottom: '30px',
  },
});

interface Props {
  open: boolean;
  handleClose: () => void;
}

const CanadianBrandNoticeModal: React.FC<Props> = ({ open, handleClose }) => {
  const classes = useStyles();

  return (
    <Dialog
      open={open}
      aria-labelledby="Canadian Brand Notice Modal"
      data-testid="canadianBrandNoticeModal"
    >
      <DialogContent className={classes.content}>
        <DialogContentText className={classes.paragraph}>
          In the future, TCR will only accept business number (BN) for Canadian
          Brand registrations. BN is the 9 digit account number issued by the
          Canada Revenue Agency (CRA). Until that time we will accept either the
          BN or the Corporation / Incorporation / Registry ID numbers.
        </DialogContentText>
        <DialogActions style={{ justifyContent: 'center', padding: 0 }}>
          <Button
            color="primary"
            onClick={handleClose}
            data-testid="canadianBrandNoticeModalConfirmButton"
          >
            Confirm
          </Button>
        </DialogActions>
      </DialogContent>
    </Dialog>
  );
};

export default CanadianBrandNoticeModal;
