import React, { Component } from 'react';
import Grid from '@material-ui/core/Grid';
import { ResetPasswordForm } from '../components';
import { Header } from '../../../shared_elements';
import { withRouter } from 'react-router-dom';
import '../../../assets/styles/user-authentication-module.scss';

class ResetPassword extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  componentDidMount() {
    document.title = 'The Campaign Registry - Welcome to the Campaign Registry';
  }

  render() {
    const { pathname } = this.props.location;
    return (
      <section className="user-authentication-section">
        <Header
          title="WELCOME TO THE CAMPAIGN REGISTRY"
          testId="resetPasswordHeader"
        ></Header>
        <Grid
          container
          spacing={0}
          alignItems="center"
          justify="center"
          className="user-form-wrapper"
          style={{ height: window.innerHeight - 60 }}
        >
          <ResetPasswordForm />
        </Grid>
      </section>
    );
  }
}
export default withRouter(ResetPassword);
