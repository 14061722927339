import { Table } from 'portal-commons';
import { FunctionComponent } from 'react';

import { User } from '../types';
import UserListingRow from './UserListingRow';

interface UserTableProps {
  role?: string;
  loading: boolean;
  users: User[];
  onEdit?: (user: User) => void;
  onDelete?: (user: User) => void;
}

const headRows = [
  { id: 'fullName', label: 'NAME', sortable: false },
  { id: 'email', label: 'E-MAIL', sortable: false },
  { id: 'uid', label: 'USER ID', sortable: false },
  { id: 'role', label: 'ROLE', sortable: false },
  { id: 'created', label: 'CREATED ON', sortable: false },
  { id: 'lastLogin', label: 'LAST LOGIN', sortable: false },
  { id: 'action', label: '', sortable: false },
];

const UserTable: FunctionComponent<UserTableProps> = ({
  role,
  loading,
  users,
  onEdit,
  onDelete,
}) => {
  return (
    <Table
      disableHover
      emptyState="no users to view"
      loading={loading}
      records={{ total: users.length }}
      headRows={headRows}
      tableData={users.map((user) => (
        <UserListingRow
          key={user.id}
          role={role}
          user={user}
          onEdit={() => onEdit?.(user)}
          onDelete={() => onDelete?.(user)}
        />
      ))}
      testId="userTable"
    />
  );
};

export default UserTable;
