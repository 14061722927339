import axiosInstance from './apiInterceptor';

export const globalGetService = (url, params, responseType = 'json') => {
  return new Promise(function (resolve, reject) {
    axiosInstance({
      method: 'GET',
      url,
      params,
      responseType,
    }).then((response) => {
      resolve(response);
    });
  });
};
export const globalPostService = (url, data) => {
  return new Promise(function (resolve, reject) {
    axiosInstance({
      method: 'POST',
      url,
      data,
    }).then((response) => {
      resolve(response);
    });
  });
};
export const globalPutService = (url, data) => {
  return new Promise(function (resolve, reject) {
    axiosInstance({
      method: 'PUT',
      url,
      data,
    }).then((response) => {
      resolve(response);
    });
  });
};
export const globalDeleteService = (url, data) => {
  return new Promise(function (resolve, reject) {
    axiosInstance({
      method: 'DELETE',
      url,
      data,
    }).then((response) => {
      resolve(response);
    });
  });
};
