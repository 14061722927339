import PropTypes from 'prop-types';
import React, { useState } from 'react';
import { Button, Dialog, DialogContent, makeStyles } from '@material-ui/core';

import { isValidInput } from '../../../../utils';
import MnoTermsComparisonTable from './MnoTermsComparisonTable';

const useStyles = makeStyles({
  backdrop: {
    backdropFilter: 'blur(1px)',
  },
  container: {
    width: '525px',
    maxWidth: '525px',
    marginLeft: '232px',
  },
  root: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    padding: '28px 20px 41px 20px !important',
  },
  title: {
    color: '#071822',
    fontFamily: 'Roboto',
    fontWeight: 500,
    fontSize: '20px',
    lineHeight: '23px',
  },
  description: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    marginTop: '26px',
    '& > *': {
      fontFamily: 'Roboto',
      fontWeight: 400,
      fontSize: '16px',
      lineHeight: '19px',
      color: '#49535D',
    },
  },
  buttons: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    gap: '14px',
    marginTop: '38px',
  },
  close: {
    height: '40px',
    padding: '0 20px',
    borderRadius: '100px',
    color: '#D41C54',
    background: '#FFFFFF',
    border: '1px solid #D41C54',
    boxShadow: 'none !important',
    textTransform: 'none',
    fontFamily: 'Roboto',
    fontWeight: 500,
    fontSize: '14px',
    lineHeight: '16px',
    '&:hover': {
      color: '#D41C54',
      background: '#FFFFFF',
    },
  },
  submit: {
    height: '40px',
    padding: '0 20px',
    borderRadius: '100px',
    color: '#FFFFFF',
    background: '#D41C54',
    boxShadow: 'none !important',
    textTransform: 'none',
    fontFamily: 'Roboto',
    fontWeight: 500,
    fontSize: '14px',
    lineHeight: '16px',
    '&:hover': {
      color: '#FFFFFF',
      background: '#D41C54',
    },
    '&:disabled': {
      color: '#FFFFFF',
      background: '#D41C54',
      opacity: 0.4,
    },
  },
});

const ResubmissionConfirmationModal = ({
  open,
  disabled,
  onClose,
  onSubmit,
}) => {
  const classes = useStyles();

  return (
    <Dialog
      classes={{
        root: classes.backdrop,
        paper: classes.container,
      }}
      disableBackdropClick
      fullWidth
      open={open}
      onClose={onClose}
      maxWidth="xs"
      data-testid="resubmissionConfirmationModal"
    >
      <DialogContent
        classes={{
          root: classes.root,
        }}
      >
        <div className={classes.title}>Resubmission Confirmation</div>
        <p className={classes.description}>
          <div>
            The campaign will adopt all the BEST vetting terms not the NEWEST
          </div>
          <div>vetting terms.</div>
          <br />
          <div>Are you sure want to submit with the newest terms?</div>
        </p>
        <div className={classes.buttons}>
          <Button
            variant="contained"
            className={classes.close}
            onClick={onClose}
            data-testid="resubmissionConfirmationModalCancelButton"
          >
            Cancel
          </Button>
          <Button
            variant="contained"
            className={classes.submit}
            disabled={disabled}
            onClick={onSubmit}
            data-testid="resubmissionConfirmationModalConfirmButton"
          >
            Confirm
          </Button>
        </div>
      </DialogContent>
    </Dialog>
  );
};

ResubmissionConfirmationModal.propTypes = {
  open: PropTypes.bool,
  disabled: PropTypes.bool,
  onClose: PropTypes.func,
  onSubmit: PropTypes.func,
};

export default ResubmissionConfirmationModal;
