import React from 'react';
import { Icon, Checkbox } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import { S3_ASSETS_PATH } from '../constants';

const useStyles = makeStyles({
  imageIcon: {
    height: '100%',
    width: '100%',
    objectFit: 'contain',
  },
  iconRoot: {
    textAlign: 'center',
    width: '15.47px',
    height: '15px',
  },
  mnoIconRoot: {
    textAlign: 'center',
    width: '18px',
    height: '18px',
  },
});
export default function CheckBox({
  checked,
  handleCheckbox,
  id,
  negative,
  disabled,
  type,
  testId = 'checkBox',
}) {
  const classes = useStyles();
  const isMnoPreview = type === 'mno-preview';

  return (
    <Checkbox
      data-testid={testId}
      disableRipple
      disabled={disabled}
      checked={checked}
      icon={
        <Icon
          classes={{
            root: isMnoPreview ? classes.mnoIconRoot : classes.iconRoot,
          }}
        >
          {disabled ? (
            <img
              className={classes.imageIcon}
              src={`${S3_ASSETS_PATH}/images/icon-checkbox-disabled.svg`}
            />
          ) : isMnoPreview ? (
            <img
              className={classes.imageIcon}
              src={`${S3_ASSETS_PATH}/images/mno-no-select.svg`}
            />
          ) : (
            <img
              className={classes.imageIcon}
              src={`${S3_ASSETS_PATH}/images/unchecked.svg`}
            />
          )}
        </Icon>
      }
      checkedIcon={
        <Icon
          classes={{
            root: isMnoPreview ? classes.mnoIconRoot : classes.iconRoot,
          }}
        >
          {disabled ? (
            <img
              className={classes.imageIcon}
              src={`${S3_ASSETS_PATH}/images/icon-checkbox-disabled.svg`}
            />
          ) : negative ? (
            <img
              className={classes.imageIcon}
              src={`${S3_ASSETS_PATH}/images/negative-checked.svg`}
            />
          ) : isMnoPreview ? (
            <img
              className={classes.imageIcon}
              src={`${S3_ASSETS_PATH}/images/mno-select.svg`}
            />
          ) : (
            <img
              className={classes.imageIcon}
              src={`${S3_ASSETS_PATH}/images/checked-new.svg`}
            />
          )}
        </Icon>
      }
      id={id}
      onChange={(e) => handleCheckbox(e)}
      className="payment-check-box"
    />
  );
}
