import React from 'react';
import { Grid } from '@material-ui/core';
import { S3_ASSETS_PATH } from '../../../../../constants';

import { Box } from 'portal-commons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAngleLeft } from '@fortawesome/pro-regular-svg-icons';

interface Props {
  backLink: string;
  handleGoBack: () => void;
  hasEditPermission?: () => void;
}

const BrandDetailTopBlock: React.FC<Props> = ({ backLink, handleGoBack }) => {
  return (
    <Grid container className="top-blk" data-testid="brandDetailTopBlock">
      <Grid item xs={6} className="back-btn">
        <a
          onClick={handleGoBack}
          className="paragraph"
          data-testid="brandDetailTopBlockBackButton"
        >
          <Box flexDirection="row" alignItems="center">
            <Box margin={{ right: 's' }}>
              <FontAwesomeIcon icon={faAngleLeft} size="lg" />
            </Box>
            {backLink === '/brands' ? 'back to all brands' : 'back'}
          </Box>
        </a>
      </Grid>
      <Grid item xs={6} className="button-wrapper">
        {/* {this.hasEditPermission(this.props.role) && (
          <Link
            to={{
              pathname: '/campaign/create',
              state: { brandUid: id, brandName: brand.displayName },
            }}
            className="primary-btn"
          >
            + Add new Campaign
          </Link>
          )} */}
      </Grid>
    </Grid>
  );
};

export default BrandDetailTopBlock;
