import PropTypes from 'prop-types';
import React, { useState } from 'react';
import {
  Button,
  Dialog,
  DialogContent,
  makeStyles,
  TextField,
} from '@material-ui/core';

import { isValidInput } from '../../../../utils';

const useStyles = makeStyles({
  root: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    padding: '30px !important',
  },
  message: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  input: {
    marginTop: '30px',
    '& input::placeholder': {
      fontSize: '14px',
      color: '#68737A',
    },
    '& .MuiFilledInput-underline:after': {
      borderBottomColor: '#0091B3',
    },
    '&.error .MuiFilledInput-underline:after': {
      borderBottomColor: '#F44336',
    },
  },
  error: {
    alignSelf: 'flex-start',
    color: '#F44336',
  },
  buttons: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    marginTop: '30px',
    '& > *:not(:first-child)': {
      marginLeft: '25px',
    },
  },
  cancel: {
    height: '40px',
    padding: '0 20px',
    borderRadius: '200px',
    color: '#00797C',
    background: '#FFFFFF',
    border: '1px solid #00797C',
    boxShadow: 'none !important',
    '&:hover': {
      background: 'rgba(0, 0, 0, 0.1)',
    },
  },
  send: {
    height: '40px',
    padding: '0 20px',
    borderRadius: '200px',
    color: '#FFFFFF',
    background: '#00797C',
    boxShadow: 'none !important',
    '&:hover': {
      background: 'rgba(0, 121, 124, 0.9)',
    },
  },
});

const RequestReviewModal = ({ open, onClose, onSend }) => {
  const [message, setMessage] = useState('');
  const [error, setError] = useState('');
  const classes = useStyles();

  const handleChange = (event) => {
    setMessage(event.target.value);
    setError('');
  };

  const handleClose = () => {
    onClose && onClose();
    setMessage('');
    setError('');
  };

  const handleSend = () => {
    if (isValidInput(message)) {
      onSend && onSend(message);
      handleClose();
    } else {
      setError('Invalid input');
    }
  };

  return (
    <Dialog
      disableBackdropClick
      fullWidth
      open={open}
      onClose={handleClose}
      maxWidth="xs"
      data-testid="requestReviewModal"
    >
      <DialogContent
        classes={{
          root: classes.root,
        }}
      >
        <p className={classes.message}>
          <span>
            Are you sure you would like to <b>nudge the CNP to Review</b>?
          </span>
          <span>This will remind the upstream CNP to review the campaign.</span>
          <br />
          <span>If yes, you may add an optional message below</span>
          <span>to send with your action.</span>
        </p>
        <TextField
          data-testid="requestReviewModalMessageInput"
          inputProps={{ maxLength: 1024 }}
          className={`${classes.input}${error ? ' error' : ''}`}
          error={!!error}
          fullWidth
          variant="filled"
          placeholder="Type optional message here"
          value={message}
          onChange={handleChange}
        />
        {error && <h6 className={classes.error}>{error}</h6>}
        <div className={classes.buttons}>
          <Button
            data-testid="requestReviewModalCancelButton"
            variant="contained"
            className={classes.cancel}
            onClick={handleClose}
          >
            Cancel
          </Button>
          <Button
            variant="contained"
            className={classes.send}
            disabled={error}
            onClick={handleSend}
            data-testid="requestReviewModalSendButton"
          >
            Send
          </Button>
        </div>
      </DialogContent>
    </Dialog>
  );
};

RequestReviewModal.propTypes = {
  open: PropTypes.bool,
  onClose: PropTypes.func,
  onSend: PropTypes.func,
};

export default RequestReviewModal;
