import React, { ChangeEvent, useState } from 'react';
import {
  Dialog,
  FormControl,
  Grid,
  InputLabel,
  makeStyles,
  MenuItem,
  Select,
  TextField,
} from '@material-ui/core';
import { CSPErrorCode } from '../../../index';
import { fieldValidation } from '../../../../../utils/validator';

type TemplateType = 'otp_pin' | 'otp_yes' | 'otp_link';
export const TEMPLATES = {
  otp_pin:
    'This is your messaging campaign registration confirmation pin: @OTP_PIN@',
  otp_yes:
    'Please confirm your messaging campaign registration by replying @OTP_YES@',
  otp_link:
    'Please click on https://acme.com/z4q7/@OTP_PIN@ to complete your messaging campaign registration',
};

const useStyles = makeStyles(() => ({
  root: {},
  container: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    gap: 20,
    padding: 30,
    background: '#FFFFFF',
  },
  title: {
    fontSize: 16,
    lineHeight: '19px',
    fontWeight: 500,
    color: '#49535D',
  },
  description: {
    fontSize: 14,
    lineHeight: '16px',
    fontWeight: 400,
    color: '#68737A',
    textAlign: 'center',
  },
  prompt: {
    fontSize: 14,
    lineHeight: '16px',
    fontWeight: 500,
    color: '#68737A',
    textAlign: 'center',
  },
  column: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  row: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
  },
}));

interface Props {
  open: boolean;
  onClose: () => void;
  onSubmit: (_value: string) => void;
}

const TriggerOtpModal: React.FC<Props> = ({ open, onClose, onSubmit }) => {
  const classes = useStyles();
  const [option, setOption] = useState('');
  const [template, setTemplate] = useState('');
  const [error, setError] = useState<string>('');

  const validateInput = () => {
    const validNum = fieldValidation({
      ...CSPErrorCode.pinSmsObj,
      fieldval: template,
    });
    return CSPErrorCode.pinSms[validNum];
  };

  const handleOptionChange = (event: React.ChangeEvent<{ value: unknown }>) => {
    const newOption = event.target.value as TemplateType;
    setOption(newOption);
    setTemplate(TEMPLATES[newOption] || '');
    setError('');
  };

  const handleTemplateChange = (event: ChangeEvent<HTMLInputElement>) => {
    setTemplate(event.target.value);
    setError('');
  };

  const handleTemplateBlur = () => {
    const checkError = validateInput();
    setError(checkError);
  };

  const handleClose = () => {
    onClose && onClose();
    setOption('');
    setTemplate('');
  };

  const handleSubmit = () => {
    const checkError = validateInput();
    if (checkError) {
      setError(checkError);
    } else {
      onSubmit && onSubmit(template);
      handleClose();
    }
  };

  return (
    <Dialog
      classes={{
        root: classes.root,
        paper: classes.container,
      }}
      open={open}
      data-testid="triggerOtpModal"
    >
      <h3 className={classes.title}>Trigger OTP</h3>

      <div className={classes.description}>
        After selecting a template you will have full editing abilities of the
        <br />
        content aside from the @otp_pin@ or @otp_yes@ elements which
        <br />
        TCR will generate when sending the message to the end user.
      </div>

      <div className="form-group-field" style={{ width: '100%' }}>
        <FormControl
          style={{ width: '100%' }}
          data-testid="triggerOtpModalSelectForm"
        >
          <InputLabel>Please choose an OTP template</InputLabel>
          <Select
            fullWidth
            value={option}
            onChange={handleOptionChange}
            inputProps={{
              'data-testid': 'triggerOtpModalSelect',
            }}
          >
            <MenuItem value="otp_pin">Send OTP Pin</MenuItem>
            <MenuItem value="otp_yes">Send OTP Yes</MenuItem>
            <MenuItem value="otp_link">Send OTP Link</MenuItem>
          </Select>
        </FormControl>
      </div>

      <div className={classes.column} style={{ width: '100%' }}>
        <div className={classes.prompt}>
          Please edit your chosen OTP Template below:
        </div>
        <div
          className="form-group-field"
          style={{ width: '100%', marginTop: 10 }}
          data-testid="triggerOtpModalTemplate"
        >
          <TextField
            multiline
            minRows={5}
            value={template}
            error={!!error}
            onChange={handleTemplateChange}
            onBlur={handleTemplateBlur}
            inputProps={{
              'data-testid': 'triggerOtpModalTemplateTextarea',
            }}
          />
          {error && (
            <h6
              className="error-msg"
              data-testid="triggerOtpModalTemplateErrorMessage"
            >
              {error}
            </h6>
          )}
        </div>
      </div>

      <div className={`otp ${classes.row}`}>
        <Grid item className="modal-footer">
          <ul className="list-inline text-center">
            <li>
              <a
                className="secondary-btn"
                onClick={handleClose}
                data-testid="triggerOtpModalCancelButton"
              >
                Cancel
              </a>
            </li>
            <li style={{ marginLeft: 20 }}>
              <div className="form-group-field">
                <input
                  type="submit"
                  disabled={!(option && template) || Boolean(error)}
                  className="primary-btn"
                  value="Submit"
                  onClick={handleSubmit}
                  data-testid="triggerOtpModalSubmitButton"
                />
              </div>
            </li>
          </ul>
        </Grid>
      </div>
    </Dialog>
  );
};

export default TriggerOtpModal;
