import React from 'react';
import { TextField } from '@material-ui/core';
import Grid from '@material-ui/core/Grid';
import Modal from '@material-ui/core/Modal';
import { S3_ASSETS_PATH } from '../../../../constants';
import PropTypes from 'prop-types';

const EscalateRequestModal = (props) => {
  const { open, handleClose, handleChange, text, handleSubmit } = props;
  return (
    <Grid container data-testid="escalateRequestModal">
      <Modal open={open} onClose={handleClose}>
        <div
          className="modal-wrapper"
          data-testid="escalateRequestModalWrapper"
        >
          <Grid
            item
            className="close-icon"
            data-testid="escalateRequestModalCloseButton"
          >
            <img
              src={`${S3_ASSETS_PATH}/images/close.svg`}
              alt="close"
              onClick={handleClose}
            />
          </Grid>
          <div className="modal-content text-center">
            <img
              src={`${S3_ASSETS_PATH}/images/raise-a-dispute.svg`}
              alt="icon"
            />
            <h3>Open a Ticket</h3>
            <h4>
              Your original CSP Verification application will be attached to the
              ticket once it is sent.
            </h4>
            <div className="form-group-field">
              <TextField
                data-testid="escalateRequestModalTextInput"
                value={text}
                onChange={(e) => handleChange('text', e.target.value)}
                multiline={true}
                rows={2}
                fullWidth={true}
                placeholder="Type something..."
                id="text"
              />
            </div>
          </div>
          <Grid item className="modal-footer">
            <ul className="list-unstyled flex">
              <li>
                <a
                  data-testid="escalateRequestModalCancelButton"
                  href="javascript:void(0)"
                  onClick={handleClose}
                  className="secondary-btn"
                >
                  CANCEL
                </a>
              </li>
              <li>
                <a
                  href="javascript:void(0)"
                  className="primary-btn"
                  onClick={handleSubmit}
                  data-testid="escalateRequestModalSendButton"
                >
                  SEND
                </a>
              </li>
            </ul>
          </Grid>
        </div>
      </Modal>
    </Grid>
  );
};
EscalateRequestModal.propTypes = {
  open: PropTypes.bool.isRequired,
  handleClose: PropTypes.func.isRequired,
  handleChange: PropTypes.func.isRequired,
  text: PropTypes.string,
  handleSubmit: PropTypes.func.isRequired,
};
export default EscalateRequestModal;
