import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Grid, Dialog, DialogContent, Container } from '@material-ui/core';
import { fieldValidation } from '../../../../utils/validator';
import {
  RegisterBrand,
  SelectBrand,
  MnoPreviewNew,
  CampaignStatusModal,
  CampaignDetailsForm,
} from '../components';
import {
  getBrandsList,
  getListOfCampaignUsecases,
  buildCampaignRequest,
  findAllVerticalTypes,
  submitCampaignRequest,
  getCnpListApi,
  getAllResellers,
  getListOfBrandUsecases,
  uploadAttachmentApi,
  deleteAttachmentApi,
  getCampaignTermsAndConditions,
  downloadAttachmentToDesktop,
} from '../apiServices';
import { AddResellerModal } from '../../Resellers/components';
import Loader from '../../../../shared_elements/containers/Loader';
import { toastFlashMessage, isValidInput } from '../../../../utils';
import { HelpSection } from '../../../../shared_elements';
import { campaignErrorCode } from '../../index';
import '../../../../assets/styles/campaign-register-module.scss';
import parse from 'html-react-parser';
import DeleteSampleMultimediaModal from '../components/DeleteSampleMultimediaModal';
import {
  deleteAttachment,
  getSupportedFileFormats,
  uploadAttachment,
} from '../apis';
import { AttachmentFolder } from '../enums';
import {
  CAMPAIGN_REGISTRATION_PRE_FILL_VALUES,
  DEFAULT_CAMPAIGN_FOR_CREATION_FORM,
} from '../constants';
import { isFieldGreyOut } from '../utils';

class CampaignRegisterForm extends Component {
  constructor(props) {
    super(props);
    this.state = {
      brandUid: '',
      usecase: {},
      loader: true,
      brands: [],
      error: {},
      usecases: [],
      loadFlag: false,
      step: 1,
      mnoInfo: {},
      mnoStatusFlag: false,
      campaignInfo: DEFAULT_CAMPAIGN_FOR_CREATION_FORM,
      verticalTypes: [],
      errorCode: campaignErrorCode,
      sampleMessage: {
        sample1: '',
      },
      sampleMultimedia: [],
      sampleMultimediaDeleteIndex: -1,
      modalOpen: false,
      cnpOptions: [],
      resellers: [],
      openDisclaimer: false,
      openEmergencyAlert: false,
      openCarrierExemptionAlert: false,
      emergencyAlertData: '',
      mnoDisclaimerData: '',
      carrierExempationData:
        '<p>The Use Case you selected is only intended for those CSPs that have an <strong>existing exemption agreement</strong> with MNOs. Proceeding without having an existing agreement may result in the Campaign being <strong>rejected.</strong> </p>',
      brandUsecases: [],
      termsAndConditions: [],
      optinPopup: false,
      optoutPopup: false,
      helpPopup: false,
      supportedFileFormatsForSupportingDocument: {},
      supportedFileFormatsForSampleMultimedia: {},
      supportingDocument: [],
      supportingDocumentDeleteIndex: -1,
      embeddedLinkPopup: false,
      greyoutFields: [],
    };
    this.getBrandsList = getBrandsList.bind(this);
    this.getListOfCampaignUsecases = getListOfCampaignUsecases.bind(this);
    this.buildCampaignRequest = buildCampaignRequest.bind(this);
    this.findAllVerticalTypes = findAllVerticalTypes.bind(this);
    this.getCnpListApi = getCnpListApi.bind(this);
    this.getAllResellers = getAllResellers.bind(this);
    this.getListOfBrandUsecases = getListOfBrandUsecases.bind(this);
  }

  componentDidMount() {
    document.title = 'The Campaign Registry - Register a Campaign';
    if (this.props.location.state) {
      const { brandUid, brandName } = this.props.location.state;
      this.setState({
        brandUid,
        brandName,
      });
      this.getListOfBrandUsecases(brandUid);
    }
    this.getBrandsList();
    this.getListOfCampaignUsecases();
    this.findAllVerticalTypes();

    this.getAllResellers();
    this.initialize();
  }

  componentDidUpdate(prevProps, prevState) {
    if (this.props.cspData.length && this.state.mnoDisclaimerData == '') {
      const mnoDisclaimerData = this.props.cspData.find(
        (item) => item.lookup_key == 'csp-campaign-register-form#mno-disclaimer'
      );
      this.setState({
        mnoDisclaimerData,
      });
    }
    if (this.props.cspData.length && this.state.emergencyAlertData == '') {
      const emergencyAlertData = this.props.cspData.find(
        (item) =>
          item.lookup_key == 'csp-campaign-register-form#emergency-alert'
      );
      this.setState({
        emergencyAlertData,
      });
    }
    if (
      this.state.brandUsecases !== prevState.brandUsecases ||
      this.state.usecase !== prevState.usecase
    ) {
      if (this.state.usecase?.id && Array.isArray(this.state.brandUsecases)) {
        const currentUseCase = this.state.brandUsecases.find(
          ({ usecase }) => usecase === this.state.usecase.id
        );
        this.setState({
          greyoutFields: currentUseCase?.greyoutFields || [],
        });
      }
    }
  }

  initialize = async () => {
    const supportedFileFormatsForSupportingDocument =
      await getSupportedFileFormats(AttachmentFolder.SupportingDocument);
    const supportedFileFormatsForSampleMultimedia =
      await getSupportedFileFormats(AttachmentFolder.SampleMultimedia);
    const termsAndConditions = await getCampaignTermsAndConditions();
    this.setState({
      termsAndConditions,
      supportedFileFormatsForSampleMultimedia,
      supportedFileFormatsForSupportingDocument,
    });
  };

  handleError = (value, key) => {
    const { errorCode } = this.state;
    this.setState((prevState) => ({
      ...prevState,
      error: {
        ...prevState.error,
        [key]:
          errorCode[key][
            fieldValidation({ ...errorCode[`${key}Obj`], fieldval: value })
          ],
      },
    }));
  };

  handleChange = (value, key, type = '') => {
    if (type === 'campaign') {
      if (key === 'subUsecases') {
        const { subUsecases } = this.state.campaignInfo;
        const index = subUsecases.indexOf(value);
        if (index === -1) {
          subUsecases.push(value);
        } else {
          subUsecases.splice(index, 1);
        }
        this.setState((prevState) => ({
          ...prevState,
          campaignInfo: {
            ...prevState.campaignInfo,
            [key]: subUsecases,
          },
          error: {
            ...prevState.error,
            usecase: '',
          },
        }));
      } else {
        this.setState((prevState) => ({
          ...prevState,
          campaignInfo: {
            ...prevState.campaignInfo,
            [key]: value,
          },
          error: {
            ...prevState.error,
            [key]: '',
          },
        }));
      }
    } else {
      const isUseCaseChanged = key === 'usecase';
      const { id: useCaseId } = value;
      this.setState((prevState) => ({
        ...prevState,
        [key]: value,
        error: {
          ...prevState.error,
          [key]: '',
        },
      }));
      if (isUseCaseChanged) {
        // reset form
        this.setState({
          campaignInfo: {
            ...DEFAULT_CAMPAIGN_FOR_CREATION_FORM,
            ...CAMPAIGN_REGISTRATION_PRE_FILL_VALUES[useCaseId],
          },
          sampleMessage: {
            sample1: '',
          },
          sampleMultimedia: [],
          supportingDocument: [],
        });
      }
    }
  };

  handleSubmit = (type = '') => {
    const { step, brandUid, usecase, brands, campaignInfo, brandName } =
      this.state;
    this.setState({ loadFlag: true });
    if (step === 1) {
      const validateNewInput = {
        brandUid: brandUid ? '' : 'Please select a Brand',
        usecase: usecase.id
          ? usecase.maxSubUsecases > 0 &&
            campaignInfo.subUsecases.length < usecase.minSubUsecases
            ? `At least ${usecase.minSubUsecases} sub ${
                usecase.minSubUsecases > 1 ? `use-cases` : `use-case`
              } to be selected`
            : ''
          : 'Please select a campaign use-case',
      };
      if (
        Object.keys(validateNewInput).every((k) => {
          return validateNewInput[k] === '';
        })
      ) {
        if (!brandName) {
          const brandName = brands.find(
            (brand) => brand.uid === brandUid
          ).displayName;
          this.setState({ brandName });
        }
        this.setState({
          openEmergencyAlert: usecase.id === 'EMERGENCY',
          openCarrierExemptionAlert: usecase.id === 'CARRIER_EXEMPT',
        });
        if (usecase.id !== 'EMERGENCY' && usecase.id !== 'CARRIER_EXEMPT') {
          this.buildCampaignRequest(brandUid, usecase.id);
        } else {
          this.setState({ loadFlag: false });
        }
      } else {
        if (validateNewInput.usecase) {
          toastFlashMessage(`${validateNewInput.usecase}`, 'error');
        }
        this.setState({
          error: validateNewInput,
          loadFlag: false,
        });
      }

      if (usecase.id === 'SOLE_PROPRIETOR') {
        this.getCnpListApi({
          soleProprietorEnabled: true,
        });
      } else {
        this.getCnpListApi();
      }
    } else if (step === 2) {
      const { campaignInfo } = this.state;
      if (campaignInfo.mnoIds && campaignInfo.mnoIds.length) {
        this.setState({ step: 3, loadFlag: false });
      }
    } else if (step === 3) {
      const { errorCode, campaignInfo, sampleMessage } = this.state;
      const validateNewInput = {
        description:
          errorCode.description[
            fieldValidation({
              ...errorCode.descriptionObj,
              fieldval: campaignInfo.description,
            })
          ],
        upstreamCnpUid:
          errorCode.upstreamCnpUid[
            fieldValidation({
              ...errorCode.upstreamCnpUidObj,
              fieldval: campaignInfo.upstreamCnpUid,
            })
          ],
        // embeddedLink: matchingRules.embeddedLinkAllowed ? checked ? errorCode['embeddedLink'][fieldValidation({...errorCode['embeddedLinkObj'], fieldval: campaignInfo.embeddedLink})] : '' : '',
        // sampleMessage: mnoMetadata.minMsgSamples <= sampleMessageCount ? ('') : `Please add minimum required ${mnoMetadata.minMsgSamples} Sample Messages`,
        embeddedPhone:
          campaignInfo.embeddedPhone !== '' ? '' : 'Please select an option',
        numberPool:
          campaignInfo.numberPool !== '' ? '' : 'Please select an option',
        ageGated: campaignInfo.ageGated !== '' ? '' : 'Please select an option',
        directLending:
          campaignInfo.directLending !== '' ? '' : 'Please select an option',
        termsAndConditions:
          campaignInfo.termsAndConditions !== ''
            ? ''
            : 'Please select an option',
        messageFlow: isValidInput(campaignInfo.messageFlow)
          ? errorCode.messageFlow[
              fieldValidation({
                ...errorCode.messageFlowObj,
                fieldval: campaignInfo.messageFlow,
              })
            ]
          : 'Invalid input',
        optinMessage:
          campaignInfo.subscriberOptin !== true
            ? ''
            : campaignInfo.optinMessage !== ''
            ? isValidInput(campaignInfo.optinMessage)
              ? errorCode.optinMessage[
                  fieldValidation({
                    ...errorCode.optinMessageObj,
                    fieldval: campaignInfo.optinMessage,
                  })
                ]
              : 'Invalid input'
            : '',
        subscriberOptin:
          campaignInfo.subscriberOptin === false
            ? ''
            : campaignInfo.subscriberOptin !== ''
            ? campaignInfo.optinMessage !== ''
              ? isValidInput(campaignInfo.optinMessage)
                ? errorCode.optinMessage[
                    fieldValidation({
                      ...errorCode.optinMessageObj,
                      fieldval: campaignInfo.optinMessage,
                    })
                  ]
                : 'Invalid input'
              : ''
            : 'Please select an option',
        optoutMessage:
          campaignInfo.subscriberOptout !== true
            ? ''
            : campaignInfo.optoutMessage !== ''
            ? isValidInput(campaignInfo.optoutMessage)
              ? errorCode.optoutMessage[
                  fieldValidation({
                    ...errorCode.optoutMessageObj,
                    fieldval: campaignInfo.optoutMessage,
                  })
                ]
              : 'Invalid input'
            : 'Please input message',
        subscriberOptout:
          campaignInfo.subscriberOptout === false
            ? ''
            : campaignInfo.subscriberOptout !== ''
            ? campaignInfo.optoutMessage !== ''
              ? isValidInput(campaignInfo.optoutMessage)
                ? errorCode.optoutMessage[
                    fieldValidation({
                      ...errorCode.optoutMessageObj,
                      fieldval: campaignInfo.optoutMessage,
                    })
                  ]
                : 'Invalid input'
              : 'Please input message'
            : 'Please select an option',
        helpMessage:
          campaignInfo.subscriberHelp !== true
            ? ''
            : campaignInfo.helpMessage !== ''
            ? isValidInput(campaignInfo.helpMessage)
              ? errorCode.helpMessage[
                  fieldValidation({
                    ...errorCode.helpMessageObj,
                    fieldval: campaignInfo.helpMessage,
                  })
                ]
              : 'Invalid input'
            : 'Please input message',
        subscriberHelp:
          campaignInfo.subscriberHelp === false
            ? ''
            : campaignInfo.subscriberHelp !== ''
            ? campaignInfo.helpMessage !== ''
              ? isValidInput(campaignInfo.helpMessage)
                ? errorCode.helpMessage[
                    fieldValidation({
                      ...errorCode.helpMessageObj,
                      fieldval: campaignInfo.helpMessage,
                    })
                  ]
                : 'Invalid input'
              : 'Please input message'
            : 'Please select an option',
        embeddedLinkSample:
          campaignInfo.embeddedLink !== true
            ? ''
            : campaignInfo.embeddedLinkSample !== ''
            ? errorCode.embeddedLinkSample[
                fieldValidation({
                  ...errorCode.embeddedLinkSampleObj,
                  fieldval: campaignInfo.embeddedLinkSample,
                })
              ]
            : '',
        embeddedLink:
          campaignInfo.embeddedLink === false
            ? ''
            : campaignInfo.embeddedLink !== ''
            ? campaignInfo.embeddedLinkSample !== ''
              ? errorCode.embeddedLinkSample[
                  fieldValidation({
                    ...errorCode.embeddedLinkSampleObj,
                    fieldval: campaignInfo.optinMessage,
                  })
                ]
              : ''
            : 'Please select an option',
      };
      Object.keys(sampleMessage).forEach((k) => {
        if (!this.isGreyOut('sampleMessage') && sampleMessage[k] === '') {
          validateNewInput[k] = 'Please type any Sample Message';
        }
        if (!isValidInput(sampleMessage[k])) {
          validateNewInput[k] = 'Invalid input';
        }
      });
      if (
        Object.keys(validateNewInput).every((k) => {
          return validateNewInput[k] === '';
        })
      ) {
        if (
          Object.keys(validateNewInput).every((k) =>
            typeof campaignInfo[k] === 'boolean'
              ? true
              : isValidInput(campaignInfo[k])
          )
        ) {
          this.setState({
            modalOpen: true,
            loadFlag: false,
          });
        } else {
          Object.keys(validateNewInput).forEach((k) => {
            if (
              !isValidInput(campaignInfo[k]) &&
              typeof campaignInfo[k] !== 'boolean'
            ) {
              this.setState((prevState) => ({
                ...prevState,
                error: {
                  ...prevState.error,
                  [k]: 'Invalid input',
                },
                loadFlag: false,
              }));
            }
          });
        }
      } else {
        this.setState({
          loadFlag: false,
          error: validateNewInput,
        });
      }
    }
  };

  handleSampleMessageClick = () => {
    const { sampleMessage } = this.state;
    const sampleMessageCount = Object.keys(sampleMessage).length;
    if (sampleMessageCount >= 0 && sampleMessageCount < 5) {
      sampleMessage[`sample${sampleMessageCount + 1}`] = '';
    }
    this.setState((prevState) => ({
      ...prevState,
      sampleMessage,
      error: {
        ...prevState.error,
        sampleMessage: '',
      },
    }));
  };

  handleSampleMessageDelete = (index) => {
    const { sampleMessage, error, errorCode } = this.state;
    if (sampleMessage.hasOwnProperty(index)) {
      error[index] = '';
      delete sampleMessage[index];
    }
    Object.keys(sampleMessage).forEach((key, index) => {
      if (key != `sample${index + 1}`) {
        sampleMessage[`sample${index + 1}`] = sampleMessage[key];
        error[`sample${index + 1}`] =
          errorCode[`sample${index + 1}`][
            fieldValidation({
              ...errorCode[`sample${index + 1}Obj`],
              fieldval: sampleMessage[key],
            })
          ];
        error[key] = '';
        delete sampleMessage[key];
      } else {
        error[key] =
          errorCode[`sample${index + 1}`][
            fieldValidation({
              ...errorCode[`sample${index + 1}Obj`],
              fieldval: sampleMessage[key],
            })
          ];
      }
    });
    this.setState((prevState) => ({
      ...prevState,
      sampleMessage,
      error,
    }));
  };

  handleMessageTextChange = (value, key) => {
    this.setState((prevState) => ({
      ...prevState,
      sampleMessage: { ...prevState.sampleMessage, [key]: value },
      error: {
        ...prevState.error,
        [key]: '',
      },
    }));
  };

  handleSampleMultimediaAdd = async (file) => {
    const { sampleMultimedia } = this.state;
    if (sampleMultimedia.length < 5) {
      try {
        const metadata = await uploadAttachmentApi(file);
        this.setState((prevState) => ({
          ...prevState,
          sampleMultimedia: [
            ...sampleMultimedia,
            {
              ...metadata,
              file,
            },
          ],
        }));
      } catch (e) {
        // TODO: error handling
      }
    }
  };

  handleSampleMultimediaDelete = async (index) => {
    this.setState({
      sampleMultimediaDeleteIndex: index,
    });
  };

  handleSampleMultimediaDeleteSubmit = async (index) => {
    const { sampleMultimedia } = this.state;
    if (sampleMultimedia.length > index) {
      const newSampleMultimedia = [...sampleMultimedia];
      newSampleMultimedia.splice(index, 1);
      this.setState((prevState) => ({
        ...prevState,
        sampleMultimedia: newSampleMultimedia,
      }));
      try {
        const media = sampleMultimedia[index];
        await deleteAttachmentApi(media.uuid);
      } catch (e) {
        // TODO: error handling
        this.setState((prevState) => ({
          ...prevState,
          sampleMultimedia,
        }));
      }
    }
  };

  handleModalClick = async (flag) => {
    if (flag) {
      const {
        usecase,
        brandUid,
        campaignInfo,
        sampleMessage,
        sampleMultimedia,
        supportingDocument,
      } = this.state;
      const {
        messageFlow,
        optinKeywords,
        optinMessage,
        optoutKeywords,
        optoutMessage,
        helpKeywords,
        helpMessage,
      } = campaignInfo;
      const formData = {
        ...campaignInfo,
        ...sampleMessage,
        mmsMediaUuid: sampleMultimedia.map((media) => media.uuid),
        messageFlow,
        optinKeywords: optinKeywords.length ? optinKeywords.join(',') : null,
        optinMessage,
        optoutKeywords: optoutKeywords.length ? optoutKeywords.join(',') : null,
        optoutMessage,
        helpKeywords: helpKeywords.length ? helpKeywords.join(',') : null,
        helpMessage,
        supportingDocumentUuid: supportingDocument.map(
          (document) => document.uuid
        ),
      };
      // formData.vertical = formData.vertical.value
      // formData.resellerUid = formData.resellerUid ? formData.resellerUid.value: null
      formData.resellerUid = formData.resellerUid ? formData.resellerUid : null;
      this.setState({ modalLoadFlag: true });
      try {
        const response = await submitCampaignRequest(
          brandUid,
          usecase.id,
          formData
        );
        if (response) {
          this.props.history.push({
            pathname: '/campaign/create/successfully',
            state: {
              campaignUid: response.campaignUid,
              mnoMetadata: response.mnoMetadata,
              cspData: this.props.cspData,
            },
          });
        }
      } finally {
        this.setState({
          modalLoadFlag: false,
          modalOpen: false,
        });
      }
    } else {
      this.setState({
        modalOpen: false,
        loadFlag: false,
        modalLoadFlag: false,
      });
    }
  };

  setCampaignInfoFieldState = (fieldKey, value) => {
    this.setState((prevState) => ({
      campaignInfo: {
        ...prevState.campaignInfo,
        [fieldKey]: value,
      },
    }));
  };

  updatePartialErrorState = (object) => {
    this.setState((prevState) => ({
      error: {
        ...prevState.error,
        ...object,
      },
    }));
  };

  handleAddReseller = (flag, newReseller = '') => {
    if (newReseller) {
      this.setState((prevState) => ({
        ...prevState,
        resellers: [...prevState.resellers, newReseller],
        campaignInfo: {
          ...prevState.campaignInfo,
          resellerUid: newReseller.uid,
        },
        step: 3,
      }));
    }
    this.setState({ addReseller: flag });
  };

  handleWarningClick = () => {
    if (this.state.openDisclaimer) {
      this.setState({ openDisclaimer: false });
    } else {
      this.buildCampaignRequest(this.state.brandUid, this.state.usecase.id);
      this.setState({
        loadFlag: true,
        openEmergencyAlert: false,
        openCarrierExemptionAlert: false,
      });
    }
  };

  handleMessageFlowChange = (messageFlow) => {
    this.setCampaignInfoFieldState('messageFlow', messageFlow);
    this.updatePartialErrorState({
      messageFlow: '',
    });
  };

  handleOptinPopupChange = (active) => {
    this.setState(
      {
        optinPopup: active,
        optoutPopup: false,
        helpPopup: false,
        embeddedLinkPopup: false,
      },
      () => {
        this.handleOptinMessageBlur();
      }
    );
  };

  handleOptinKeywordsChange = (keywords) => {
    this.setCampaignInfoFieldState('optinKeywords', keywords);
  };

  handleOptinMessageChange = (message) => {
    this.setCampaignInfoFieldState('optinMessage', message);
    this.updatePartialErrorState({
      optinMessage: '',
    });
  };

  handleOptinMessageBlur = (e) => {
    const message = this.state.campaignInfo.optinMessage;
    const errorMessage =
      campaignErrorCode.optinMessage[
        fieldValidation({
          ...campaignErrorCode.optinMessageObj,
          fieldval: message,
        })
      ];
    this.updatePartialErrorState({
      optinMessage: this.state.campaignInfo.subscriberOptin ? errorMessage : '',
      subscriberOptin: this.state.campaignInfo.subscriberOptin
        ? errorMessage
        : '',
    });
  };

  handleOptoutPopupChange = (active) => {
    this.setState(
      {
        optinPopup: false,
        optoutPopup: active,
        helpPopup: false,
        embeddedLinkPopup: false,
      },
      () => {
        this.handleOptoutMessageBlur();
      }
    );
  };

  handleOptoutKeywordsChange = (keywords) => {
    this.setCampaignInfoFieldState('optoutKeywords', keywords);
  };

  handleOptoutMessageChange = (message) => {
    this.setCampaignInfoFieldState('optoutMessage', message);
    this.updatePartialErrorState({ optoutMessage: '' });
  };

  handleOptoutMessageBlur = (e) => {
    const message = this.state.campaignInfo.optoutMessage;
    const errorMessage =
      campaignErrorCode.optoutMessage[
        fieldValidation({
          ...campaignErrorCode.optoutMessageObj,
          fieldval: message,
        })
      ];
    this.updatePartialErrorState({
      optoutMessage: this.state.campaignInfo.subscriberOptout
        ? errorMessage
        : '',
      subscriberOptout: this.state.campaignInfo.subscriberOptout
        ? errorMessage
        : '',
    });
  };

  handleHelpPopupChange = (active) => {
    this.setState(
      {
        optinPopup: false,
        optoutPopup: false,
        helpPopup: active,
        embeddedLinkPopup: false,
      },
      () => {
        this.handleHelpMessageBlur();
      }
    );
  };

  handleHelpKeywordsChange = (keywords) => {
    this.setCampaignInfoFieldState('helpKeywords', keywords);
  };

  handleHelpMessageChange = (message) => {
    this.setCampaignInfoFieldState('helpMessage', message);
    this.updatePartialErrorState({ helpMessage: '' });
  };

  handleHelpMessageBlur = (e) => {
    const message = this.state.campaignInfo.helpMessage;
    const errorMessage =
      campaignErrorCode.helpMessage[
        fieldValidation({
          ...campaignErrorCode.helpMessageObj,
          fieldval: message,
        })
      ];
    this.updatePartialErrorState({
      helpMessage: this.state.campaignInfo.subscriberHelp ? errorMessage : '',
      subscriberHelp: this.state.campaignInfo.subscriberHelp
        ? errorMessage
        : '',
    });
  };

  handleSampleMultimediaDownload = async (index) => {
    await downloadAttachmentToDesktop(
      this.state.sampleMultimedia[index].uuid,
      this.state.sampleMultimedia[index].fileName
    );
  };

  handleSupportingDocumentAdd = async (file) => {
    const { supportingDocument } = this.state;
    if (supportingDocument.length < 5) {
      const metadata = await uploadAttachment(
        AttachmentFolder.SupportingDocument,
        file
      );
      if (metadata) {
        this.setState((prevState) => ({
          ...prevState,
          supportingDocument: [
            ...supportingDocument,
            {
              ...metadata,
              file,
            },
          ],
        }));
      }
    }
  };

  handleSupportingDocumentDownload = async (index) => {
    await downloadAttachmentToDesktop(
      this.state.supportingDocument[index].uuid,
      this.state.supportingDocument[index].fileName
    );
  };

  handleSupportingDocumentDelete = (index) => {
    this.setState({
      supportingDocumentDeleteIndex: index,
    });
  };

  applySupportingDocumentDelete = async () => {
    const { supportingDocument, supportingDocumentDeleteIndex } = this.state;
    if (supportingDocument.length > supportingDocumentDeleteIndex) {
      const newSupportingDocument = [...supportingDocument];
      newSupportingDocument.splice(supportingDocumentDeleteIndex, 1);
      this.setState((prevState) => ({
        ...prevState,
        supportingDocument: newSupportingDocument,
      }));

      const response = await deleteAttachment(
        supportingDocument[supportingDocumentDeleteIndex].uuid
      );
      if (!response) {
        // revert supporting document due to API failure
        this.setState((prevState) => ({
          ...prevState,
          supportingDocument,
        }));
      }
    }
  };

  handleEmbeddedLinkPopupChange = (active) => {
    this.setState(
      {
        optinPopup: false,
        optoutPopup: false,
        helpPopup: false,
        embeddedLinkPopup: active,
      },
      () => {
        this.handleEmbeddedLinkSampleBlur();
      }
    );
  };

  handleEmbeddedLinkSampleBlur = () => {
    const value = this.state.campaignInfo.embeddedLinkSample;
    const error =
      campaignErrorCode.embeddedLinkSample[
        fieldValidation({
          ...campaignErrorCode.embeddedLinkSampleObj,
          fieldval: value,
        })
      ];
    this.setState({
      error: {
        ...this.state.error,
        embeddedLink: this.state.campaignInfo.embeddedLink ? error : '',
        embeddedLinkSample: this.state.campaignInfo.embeddedLink ? error : '',
      },
    });
  };

  isGreyOut = (field) => isFieldGreyOut(this.state.greyoutFields, field);

  render() {
    const {
      loader,
      error,
      brands,
      loadFlag,
      usecases,
      usecase,
      brandUid,
      step,
      mnoStatusFlag,
      mnoInfo,
      modalOpen,
      modalLoadFlag,
      brandName,
      campaignInfo,
      sampleMessage,
      sampleMultimedia,
      sampleMultimediaDeleteIndex,
      cnpOptions,
      openDisclaimer,
      resellers,
      addReseller,
      openEmergencyAlert,
      mnoDisclaimerData,
      emergencyAlertData,
      openCarrierExemptionAlert,
      carrierExempationData,
      brandUsecases,
      supportedFileFormatsForSampleMultimedia,
      supportedFileFormatsForSupportingDocument,
      supportingDocument,
      supportingDocumentDeleteIndex,
    } = this.state;
    return (
      <section
        className="campaign-register-section"
        data-testid="campaignRegisterForm"
      >
        <Container
          maxWidth={false}
          className="campaign-register-container selectBrand"
        >
          {loader ? (
            <Loader />
          ) : brands.length == 0 ? (
            <RegisterBrand />
          ) : (
            <>
              <div className="steps-wrapper text-center">
                <ul className="steps-listing list-unstyled">
                  <li className={step == 1 ? 'active' : ''}>
                    <div className="step">
                      <span>1</span>
                    </div>
                    <h3 className="secondaryText">Use Case Selection</h3>
                  </li>
                  <li className={step == 2 ? 'active mno' : 'mno'}>
                    <div className="step">
                      <span>2</span>
                    </div>
                    <h3 className="secondaryText">Carrier Terms Preview</h3>
                  </li>
                  <li className={step == 3 ? 'active' : ''}>
                    <div className="step">
                      <span>3</span>
                    </div>
                    <h3 className="secondaryText">Campaign Details</h3>
                  </li>
                </ul>
              </div>
              <Grid container spacing={0} alignItems="center">
                {step == 1 ? (
                  <SelectBrand
                    handleChange={this.handleChange}
                    error={error}
                    brandUid={brandUid}
                    usecases={usecases}
                    usecase={usecase}
                    campaignInfo={campaignInfo}
                    handleError={this.handleError}
                    displayName={brandName}
                    brandUsecases={brandUsecases}
                    getListOfBrandUsecases={this.getListOfBrandUsecases}
                    resetBrandUsecases={() => {
                      this.setState((prevState) => ({
                        ...prevState,
                        brandUsecases: [],
                      }));
                    }}
                  />
                ) : step == 2 ? (
                  <MnoPreviewNew
                    type="preview"
                    mnoInfo={mnoInfo}
                    campaignInfo={campaignInfo}
                    handleChange={this.handleChange}
                    error={error}
                  />
                ) : step == 3 ? (
                  <CampaignDetailsForm
                    brandName={brandName}
                    brandUid={brandUid}
                    mnoInfo={mnoInfo}
                    campaignInfo={campaignInfo}
                    error={error}
                    handleChange={(value, key) =>
                      this.handleChange(value, key, 'campaign')
                    }
                    handleError={this.handleError}
                    usecase={usecase}
                    handleSampleMessageClick={this.handleSampleMessageClick}
                    handleSampleMessageDelete={this.handleSampleMessageDelete}
                    sampleMessage={sampleMessage}
                    handleMessageTextChange={this.handleMessageTextChange}
                    sampleMultimedia={sampleMultimedia}
                    handleSampleMultimediaAdd={this.handleSampleMultimediaAdd}
                    handleSampleMultimediaDelete={
                      this.handleSampleMultimediaDelete
                    }
                    cnpOptions={cnpOptions}
                    resellers={resellers}
                    handleAddReseller={this.handleAddReseller}
                    termsAndConditions={this.state.termsAndConditions}
                    messageFlow={campaignInfo.messageFlow}
                    handleMessageFlowChange={this.handleMessageFlowChange}
                    optinPopup={this.state.optinPopup}
                    handleOptinPopupChange={this.handleOptinPopupChange}
                    optinKeywords={campaignInfo.optinKeywords}
                    handleOptinKeywordsChange={this.handleOptinKeywordsChange}
                    optinMessage={campaignInfo.optinMessage}
                    handleOptinMessageChange={this.handleOptinMessageChange}
                    handleOptinMessageBlur={this.handleOptinMessageBlur}
                    optoutPopup={this.state.optoutPopup}
                    handleOptoutPopupChange={this.handleOptoutPopupChange}
                    optoutKeywords={campaignInfo.optoutKeywords}
                    handleOptoutKeywordsChange={this.handleOptoutKeywordsChange}
                    handleOptoutMessageBlur={this.handleOptoutMessageBlur}
                    optoutMessage={campaignInfo.optoutMessage}
                    handleOptoutMessageChange={this.handleOptoutMessageChange}
                    helpPopup={this.state.helpPopup}
                    handleHelpPopupChange={this.handleHelpPopupChange}
                    helpKeywords={campaignInfo.helpKeywords}
                    handleHelpKeywordsChange={this.handleHelpKeywordsChange}
                    helpMessage={campaignInfo.helpMessage}
                    handleHelpMessageChange={this.handleHelpMessageChange}
                    handleHelpMessageBlur={this.handleHelpMessageBlur}
                    handleSampleMultimediaDownload={
                      this.handleSampleMultimediaDownload
                    }
                    supportedFileFormatsForSampleMultimedia={
                      supportedFileFormatsForSampleMultimedia
                    }
                    supportedFileFormatsForSupportingDocument={
                      supportedFileFormatsForSupportingDocument
                    }
                    supportingDocument={supportingDocument}
                    handleSupportingDocumentAdd={
                      this.handleSupportingDocumentAdd
                    }
                    handleSupportingDocumentDownload={
                      this.handleSupportingDocumentDownload
                    }
                    handleSupportingDocumentDelete={
                      this.handleSupportingDocumentDelete
                    }
                    embeddedLinkPopup={this.state.embeddedLinkPopup}
                    handleEmbeddedLinkPopupChange={
                      this.handleEmbeddedLinkPopupChange
                    }
                    handleEmbeddedLinkSampleBlur={
                      this.handleEmbeddedLinkSampleBlur
                    }
                    greyOutFields={this.state.greyoutFields ?? []}
                  />
                ) : null}
              </Grid>
              <Grid item xs={12} className="bottom-blk">
                <ul className="list-inline">
                  {step == 1 && (
                    <li className="form-group-field">
                      <input
                        data-testid="campaignRegisterFormNextButton"
                        type="submit"
                        disabled={
                          loadFlag ||
                          Object.keys(error).find((k) => error[k] != '')
                            ? true
                            : false
                        }
                        onClick={(e) => {
                          e.preventDefault();
                          this.handleSubmit(1);
                        }}
                        value={loadFlag ? 'Processing..' : 'Next'}
                      />
                    </li>
                  )}
                  {step == 2 && (
                    <>
                      <li className="">
                        <p>
                          <a
                            data-testid="campaignRegisterFormPreviousButton"
                            onClick={(e) => {
                              e.preventDefault();
                              this.setState({ step: 1 });
                            }}
                            className={'secondary-btn'}
                          >
                            Previous
                          </a>
                        </p>
                      </li>
                      <li className="form-group-field">
                        <input
                          data-testid="campaignRegisterFormNextButton"
                          type="submit"
                          disabled={
                            !mnoStatusFlag || !campaignInfo.mnoIds.length
                              ? true
                              : false
                          }
                          onClick={(e) => {
                            e.preventDefault();
                            this.handleSubmit(2);
                          }}
                          value={loadFlag ? 'Processing..' : 'Next'}
                        />
                      </li>
                    </>
                  )}
                  {step == 3 && (
                    <>
                      <li className="">
                        <p>
                          <a
                            data-testid="campaignRegisterFormPreviousButton"
                            onClick={(e) => {
                              e.preventDefault();
                              this.setState({ step: 2 });
                            }}
                            className={
                              loadFlag ||
                              Object.keys(error).find((k) => error[k] != '')
                                ? 'secondary-btn disabled'
                                : 'secondary-btn'
                            }
                          >
                            Previous
                          </a>
                        </p>
                      </li>
                      <li className="form-group-field">
                        <input
                          data-testid="campaignRegisterFormNextButton"
                          type="submit"
                          disabled={
                            loadFlag ||
                            Object.keys(error).find((k) => error[k] != '')
                              ? true
                              : false
                          }
                          onClick={(e) => {
                            e.preventDefault();
                            this.handleSubmit(3);
                          }}
                          value={loadFlag ? 'Processing..' : 'Next'}
                        />
                      </li>
                    </>
                  )}
                </ul>
              </Grid>
            </>
          )}
          <CampaignStatusModal
            loadFlag={modalLoadFlag}
            handleClick={this.handleModalClick}
            open={modalOpen}
          />
          <AddResellerModal
            open={addReseller}
            handleModalClick={this.handleAddReseller}
            modalType="add"
          />
          {
            <HelpSection
              path={
                step == 1
                  ? '/campaigns/create#step1'
                  : step == 2
                  ? '/campaigns/create#step2'
                  : step == 3
                  ? '/campaigns/create#step3'
                  : ''
              }
              imgStyle={step === 3 ? { zIndex: 100 } : undefined}
            />
          }
          <DeleteSampleMultimediaModal
            open={!!sampleMultimedia[sampleMultimediaDeleteIndex]}
            fileName={
              sampleMultimedia[sampleMultimediaDeleteIndex]
                ? sampleMultimedia[sampleMultimediaDeleteIndex].fileName
                : ''
            }
            onCancel={() => {
              this.setState({
                sampleMultimediaDeleteIndex: -1,
              });
            }}
            onSubmit={async () => {
              this.setState({
                sampleMultimediaDeleteIndex: -1,
              });
              this.handleSampleMultimediaDeleteSubmit(
                sampleMultimediaDeleteIndex
              );
            }}
          />
          <DeleteSampleMultimediaModal
            open={!!supportingDocument[supportingDocumentDeleteIndex]}
            fileName={
              supportingDocument[supportingDocumentDeleteIndex]
                ? supportingDocument[supportingDocumentDeleteIndex].fileName
                : ''
            }
            onCancel={() => {
              this.setState({
                supportingDocumentDeleteIndex: -1,
              });
            }}
            onSubmit={async () => {
              await this.applySupportingDocumentDelete();
              this.setState({
                supportingDocumentDeleteIndex: -1,
              });
            }}
          />
          {openDisclaimer || openEmergencyAlert || openCarrierExemptionAlert ? (
            <Dialog
              open={
                openDisclaimer ||
                openEmergencyAlert ||
                openCarrierExemptionAlert
              }
              data-testid="campaignRegisterFormDialog"
              aria-labelledby="responsive-dialog-title"
              maxWidth="xs"
              fullWidth={true}
              id="successModal"
              className={`success-modal ${
                openCarrierExemptionAlert
                  ? 'carrier-exemption-alert'
                  : openEmergencyAlert
                  ? 'emergency-alert'
                  : 'mno-disclaimer'
              }`}
            >
              <DialogContent className={`success-content-blk text-center`}>
                <div className="paragraph">
                  {parse(
                    openDisclaimer
                      ? mnoDisclaimerData
                        ? mnoDisclaimerData.text
                        : ''
                      : openEmergencyAlert
                      ? emergencyAlertData.text
                        ? emergencyAlertData.text
                        : ''
                      : openCarrierExemptionAlert
                      ? carrierExempationData
                      : ''
                  )}
                </div>
                <ul className="list-inline footer">
                  {openCarrierExemptionAlert ? (
                    <li>
                      <a
                        data-testid="campaignRegisterFormDialogCancelButton"
                        onClick={() =>
                          this.setState({ openCarrierExemptionAlert: false })
                        }
                        className="secondary-btn"
                      >
                        Cancel
                      </a>
                    </li>
                  ) : null}
                  <li>
                    <a
                      data-testid="campaignRegisterFormDialogOkButton"
                      onClick={this.handleWarningClick}
                      className="primary-btn"
                    >
                      {openCarrierExemptionAlert ? 'Proceed' : 'OK'}
                    </a>
                  </li>
                </ul>
              </DialogContent>
            </Dialog>
          ) : null}
        </Container>
      </section>
    );
  }
}
const mapStateToProps = (state) => ({
  cspData: state.shareReducer.cspData,
});
export default connect(mapStateToProps)(CampaignRegisterForm);
