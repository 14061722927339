import { BoxV2 as Box, Flex } from 'portal-commons';
import { FunctionComponent, useState } from 'react';
import styled from 'styled-components';
import { faPen, faXmark } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import MessageInput from './MessageInput';
import { ToolTip } from '../../../../ui_elements';

interface EmbeddedLinkPopupProps {
  disabled?: boolean;
  editable?: boolean;
  defaultReadonly?: boolean;
  hideCloseButton?: boolean;
  error?: string;
  value?: string;
  onChange?: (value: string) => void;
  onBlur?: () => void;
  onClose?: () => void;
}

const Button = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 80px;
  height: 25px;
  font-size: 12px;
  line-height: 14px;
  color: #0091b3;
  background: transparent;
  border: 1px solid #0091b3;
  border-radius: 200px;
  cursor: pointer;
`;

const EmbeddedLinkPopup: FunctionComponent<EmbeddedLinkPopupProps> = ({
  disabled,
  editable,
  defaultReadonly,
  hideCloseButton,
  error,
  value,
  onChange,
  onBlur,
  onClose,
}) => {
  const [readonly, setReadonly] = useState(!!defaultReadonly);

  const handleEdit = () => {
    setReadonly(false);
  };

  const handleClose = () => {
    setReadonly(disabled || !error);
    onClose?.();
  };

  return (
    <Box
      sx={{
        position: 'absolute',
        right: 0,
        left: 0,
        background: '#FFFFFF',
        boxSizing: 'border-box',
        boxShadow: '0 2px 4px rgba(104, 115, 122, 0.2)',
        zIndex: 5,
      }}
    >
      <Flex
        sx={{
          position: 'relative',
          width: '100%',
          flexDirection: 'column',
          alignItems: 'center',
          rowGap: 18,
          padding: 20,
          background: 'rgba(0, 145, 179, 0.05)',
          borderTop: '1px solid rgba(0, 145, 179, 0.2)',
        }}
      >
        <Flex
          sx={{
            position: 'absolute',
            top: 4,
            right: 4,
            flexDirection: 'row',
            alignItems: 'center',
            columnGap: 8,
          }}
        >
          {editable !== false && readonly && (
            <FontAwesomeIcon
              style={
                disabled
                  ? {
                      opacity: 0.5,
                      cursor: 'not-allowed',
                      pointerEvents: 'none',
                    }
                  : {
                      objectFit: 'cover',
                      cursor: 'pointer',
                    }
              }
              color="#0091B3"
              size="xs"
              icon={faPen}
              onClick={handleEdit}
              data-testid="embeddedLinkPopupEditButton"
            />
          )}
          {!hideCloseButton && (
            <FontAwesomeIcon
              style={{
                objectFit: 'cover',
                cursor: 'pointer',
              }}
              color="#0091B3"
              size="xs"
              icon={faXmark}
              onClick={handleClose}
              data-testid="embeddedLinkPopupCloseButton"
            />
          )}
        </Flex>

        {readonly ? (
          <Box
            sx={{
              position: 'relative',
              width: '100%',
              minHeight: '32px',
              padding: '8px 8px 8px 10px',
              background: '#FFFFFF',
              border: error ? '1px solid red' : 'none',
              '> svg': {
                top: '9px !important',
              },
            }}
            data-testid="embeddedLinkPopupView"
          >
            <Box>{value}</Box>
            <ToolTip title="A field for an embedded link sample" />
          </Box>
        ) : (
          <Box
            sx={{
              position: 'relative',
              width: '100%',
              '> svg': {
                top: '14px !important',
              },
            }}
            data-testid="embeddedLinkPopupInput"
          >
            <MessageInput
              showCharCounter
              placeholder="Type Embedded Link here"
              maxLength={255}
              error={error}
              value={value}
              onValueChange={onChange}
              onBlur={onBlur}
            />
            <ToolTip title="A field for an embedded link sample" />
          </Box>
        )}

        {!readonly && (
          <Button
            onClick={handleClose}
            data-testid="embeddedLinkPopupDoneButton"
          >
            Done
          </Button>
        )}
      </Flex>
    </Box>
  );
};

export default EmbeddedLinkPopup;
