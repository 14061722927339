import React from 'react';
import { Grid } from '@material-ui/core';
import { Link } from 'react-router-dom';
import { S3_ASSETS_PATH } from '../../../constants';

const ResetEmailSuccess = (props) => {
  return (
    <div className="campaign-registry">
      <Grid item className="content-blk">
        <img src={`${S3_ASSETS_PATH}/images/thankyou.svg`} alt="logo"></img>
        <h4 className="heading1">Thank You!</h4>
        <p className="heading1 text ">
          A reset password link has been sent to your registered email address.
        </p>
        <br />
        <p className="heading1 text">
          {' '}
          Please click on the link in the email to reset your password.
        </p>
        <br />
        <p className="supportText">
          If you are unable to reset your password, please contact Customer
          Support.
        </p>
      </Grid>
      <Grid item className="link">
        <Link
          to="/login"
          className="primary-btn"
          data-testid="registerFormBackToLoginButton"
        >
          Back to Login
        </Link>
      </Grid>
    </div>
  );
};

export default ResetEmailSuccess;
