import { BoxV2 as Box, Button, Flex } from 'portal-commons';
import { FunctionComponent, useEffect } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import {
  faDollar,
  faEnvelope,
  faGearCode,
  faIndustryWindows,
  faMessageDots,
} from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Dialog, makeStyles } from '@material-ui/core';

import EmailsEditBlock from './EmailsEditBlock';
import { MAX_EMAIL_COUNT, MAX_EMAIL_LENGTH } from '../constants';
import { EmailPreferencesUpdatePayload } from '../types';

interface ModalProps {
  open: boolean;
  disabled: boolean;
  emails: string[];
  escalateEmails: string[];
  financeEmails: string[];
  techEmails: string[];
  onClose: () => void;
  onSubmit: (data: EmailPreferencesUpdatePayload) => void;
}

const useStyles = makeStyles({
  container: {
    width: '960px',
    padding: '24px 32px 9px',
  },
});

const EmailPreferencesEditModal: FunctionComponent<ModalProps> = ({
  open,
  disabled,
  emails,
  escalateEmails,
  financeEmails,
  techEmails,
  onClose,
  onSubmit,
}) => {
  const classes = useStyles();
  const form = useForm<EmailPreferencesUpdatePayload>({
    defaultValues: {
      emails: emails.map((email) => ({ email })),
      escalateEmails: escalateEmails.map((email) => ({ email })),
      financeEmails: financeEmails.map((email) => ({ email })),
      techEmails: techEmails.map((email) => ({ email })),
    },
  });

  useEffect(() => {
    if (open) {
      form.reset({
        emails: emails.map((email) => ({ email })),
        escalateEmails: escalateEmails.map((email) => ({ email })),
        financeEmails: financeEmails.map((email) => ({ email })),
        techEmails: techEmails.map((email) => ({ email })),
      });
    }
  }, [open]);

  return (
    <Dialog open={open} classes={{ paper: classes.container }}>
      <FormProvider {...form}>
        <form noValidate onSubmit={form.handleSubmit(onSubmit)}>
          <Flex
            sx={{ flexDirection: 'column' }}
            data-testid="emailPreferencesEditModal"
          >
            <Flex
              sx={{
                flexDirection: 'row',
                alignItems: 'center',
                columnGap: '12px',
                marginBottom: '32px',
                color: '#49535D',
                fontFamily: 'Roboto',
                fontWeight: 500,
                fontSize: '18px',
                lineHeight: '21px',
              }}
            >
              <FontAwesomeIcon icon={faEnvelope} />
              <Box>Email & Notification Preferences</Box>
            </Flex>
            <Flex
              sx={{
                flexDirection: 'column',
                rowGap: '32px',
              }}
            >
              <EmailsEditBlock
                disabled={disabled}
                icon={faIndustryWindows}
                title="Commercial Contact Details"
                name="emails"
                maxCount={MAX_EMAIL_COUNT}
                maxLength={MAX_EMAIL_LENGTH}
                testId="emailsEditBlock"
              />
              <EmailsEditBlock
                disabled={disabled}
                icon={faMessageDots}
                title="System Notification Email"
                name="escalateEmails"
                maxCount={MAX_EMAIL_COUNT}
                maxLength={MAX_EMAIL_LENGTH}
                testId="escalateEmailsEditBlock"
              />
              <EmailsEditBlock
                disabled={disabled}
                icon={faDollar}
                title="Financial Contact Details"
                name="financeEmails"
                maxCount={MAX_EMAIL_COUNT}
                maxLength={MAX_EMAIL_LENGTH}
                testId="financeEmailsEditBlock"
              />
              <EmailsEditBlock
                disabled={disabled}
                icon={faGearCode}
                title="Technical Contact Details"
                name="techEmails"
                maxCount={MAX_EMAIL_COUNT}
                maxLength={MAX_EMAIL_LENGTH}
                testId="techEmailsEditBlock"
              />
            </Flex>
            <Flex
              sx={{
                flexDirection: 'row',
                alignItems: 'center',
                justifyContent: 'center',
                columnGap: '13px',
                marginTop: '33px',
                marginBottom: '37px',
              }}
            >
              <Button
                variant="outline"
                onClick={onClose}
                data-testid="cancelButton"
              >
                Cancel
              </Button>
              <Button
                type="submit"
                disabled={disabled}
                data-testid="saveButton"
              >
                Save
              </Button>
            </Flex>
          </Flex>
        </form>
      </FormProvider>
    </Dialog>
  );
};

export default EmailPreferencesEditModal;
