import React from 'react';
import { Dialog, DialogContent } from '@material-ui/core';

const getModifier = (noun: string) =>
  noun.trim().match(/^[AEIOUaeiou]/) ? 'an' : 'a';

interface Props {
  open: boolean;
  scoreName: string;
  companyName: string;
  contactEmail: string;
  handleClose: () => void;
}

const AppealModal: React.FC<Props> = (props: Props) => {
  const isAgeis = props.companyName.toLocaleLowerCase().startsWith('aegis');
  return (
    <Dialog
      open={props.open}
      onClose={props.handleClose}
      aria-labelledby="responsive-dialog-title"
      maxWidth="xs"
      fullWidth={true}
      id="successModal"
      className={`success-modal appeal-vetting`}
      data-testid="appealModal"
    >
      <DialogContent className={`success-content-blk text-center`}>
        <p
          className="paragraph"
          style={{ color: '#68737A' }}
          data-testid="appealModalContent"
        >
          {isAgeis ? (
            <>
              To request an appeal or get more information please send an email{' '}
              to <span>support@campaignregistry.com</span>.<br />
              <br />
              Make sure to include your Brand ID and Vet ID or Vet Certificate{' '}
              to assist in the process.
            </>
          ) : (
            <>
              To request an appeal or get more information regarding{' '}
              {getModifier(props.scoreName)} <span>{props.scoreName}</span>{' '}
              provided by <span>{props.companyName}</span>, please send an email{' '}
              to <span>{props.contactEmail}</span>.<br />
              <br />
              Make sure to include your Vet ID or your Vet Certificate to{' '}
              positively identify your records.
            </>
          )}
        </p>
        <a
          onClick={props.handleClose}
          style={{ width: 80, boxSizing: 'border-box' }}
          className="primary-btn"
          data-testid="appealModalConfirmButton"
        >
          OK
        </a>
      </DialogContent>
    </Dialog>
  );
};

export default AppealModal;
