import { getCountryCallingCode } from 'react-phone-number-input';

import { CSPErrorCode } from '../../index';
import { fieldValidation } from '../../../../utils/validator';

// returns true => valid
// returns error message => invalid
export const fieldValidator = {
  ein: (value, { country, label, validationRules = [] }) => {
    if (!value) {
      return `Please enter ${label}`;
    }
    if (country === 'US') {
      // validationRules was fetched through API
      const validationObject =
        validationRules &&
        validationRules.find((item) => item.attributeName === 'ein');
      const newRegex =
        validationObject && validationObject.regex
          ? new RegExp(validationObject.regex, 'gmi')
          : /^[0-9]{2}[-.\s]?[0-9]{7}$/gim;
      if (newRegex.test(value)) {
        return true;
      } else {
        return `Invalid ${label} ${
          validationObject && validationObject.structure
            ? `- ${validationObject.structure}`
            : ''
        }`;
      }
    } else {
      return value.length > 21 ? `Maximum 21 chars allowed for ${label}` : true;
    }
  },
  postalCode: (value, { country }) => {
    if (country === 'US') {
      const msg =
        CSPErrorCode.postalCode[
          fieldValidation({
            ...CSPErrorCode.postalCodeObj,
            fieldval: value,
          })
        ];
      return msg ? `${msg} ZIP code` : true;
    }

    return value.length
      ? value.length > 20
        ? 'Maximum 20 chars allowed for Postal Code'
        : true
      : 'Please enter Postal Code';
  },
  phone: (value, { countryIsoCode }) => {
    if (!value) {
      return 'Please enter Phone Number';
    }
    const dialCode = value
      ? countryIsoCode
        ? getCountryCallingCode(countryIsoCode)
        : true
      : true;

    return value === `+${dialCode}`
      ? 'Please enter Phone Number'
      : countryIsoCode === 'IN'
      ? value.length > 13
        ? 'Maximum 10 numbers allowed'
        : true
      : true;
  },
  refDcaContactPhone: (value, { countryIsoCode }) => {
    return value
      ? countryIsoCode === 'IN'
        ? value.length > 13
          ? 'Maximum 10 numbers allowed'
          : true
        : true
      : 'Please enter Phone Number';
  },
  companyName: (value) => {
    return (
      CSPErrorCode.companyName[
        fieldValidation({ ...CSPErrorCode.companyNameObj, fieldval: value })
      ] || true
    );
  },
  displayName: (value) => {
    return (
      CSPErrorCode.displayName[
        fieldValidation({ ...CSPErrorCode.displayNameObj, fieldval: value })
      ] || true
    );
  },
  country: (value) => {
    return value ? true : 'Please select country';
  },
  entityType: (value) => {
    return value !== '' ? true : 'Please select your entity type';
  },
  street: (value) => {
    return (
      CSPErrorCode.street[
        fieldValidation({ ...CSPErrorCode.streetObj, fieldval: value })
      ] || true
    );
  },
  city: (value) => {
    return (
      CSPErrorCode.city[
        fieldValidation({ ...CSPErrorCode.cityObj, fieldval: value })
      ] || true
    );
  },
  state: (value, { country }) => {
    if (country === 'US') {
      return value ? true : 'Please select state';
    }
    return (
      CSPErrorCode.state[
        fieldValidation({ ...CSPErrorCode.stateObj, fieldval: value })
      ] || true
    );
  },
  website: (value) => {
    return (
      CSPErrorCode.website[
        fieldValidation({ ...CSPErrorCode.websiteObj, fieldval: value })
      ] || true
    );
  },
  stockSymbol: (value, { entityType }) => {
    if (entityType === 'PUBLIC_PROFIT') {
      return (
        CSPErrorCode.stockSymbol[
          fieldValidation({ ...CSPErrorCode.stockSymbolObj, fieldval: value })
        ] || true
      );
    }
    return true;
  },
  stockExchange: (value, { entityType }) => {
    if (entityType === 'PUBLIC_PROFIT') {
      return (
        CSPErrorCode.stockExchange[
          fieldValidation({ ...CSPErrorCode.stockExchangeObj, fieldval: value })
        ] || true
      );
    }
    return true;
  },
  email: (value) => {
    return (
      CSPErrorCode.email[
        fieldValidation({
          ...CSPErrorCode.emailObj,
          fieldval: value,
        })
      ] || true
    );
  },
  techEmail: (value) => {
    return (
      CSPErrorCode.techEmail[
        fieldValidation({
          ...CSPErrorCode.techEmailObj,
          fieldval: value,
        })
      ] || true
    );
  },
  financeEmail: (value) => {
    return (
      CSPErrorCode.financeEmail[
        fieldValidation({
          ...CSPErrorCode.financeEmailObj,
          fieldval: value,
        })
      ] || true
    );
  },
  escalateEmail: (value) => {
    return (
      CSPErrorCode.escalateEmail[
        fieldValidation({
          ...CSPErrorCode.escalateEmailObj,
          fieldval: value,
        })
      ] || true
    );
  },
};
