import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core';

import { S3_ASSETS_PATH } from '../../../../constants';

import { Box } from 'portal-commons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAward } from '@fortawesome/pro-regular-svg-icons';

const useStyles = makeStyles({
  container: {
    display: 'flex',
    flexDirection: 'column',
    gap: '7px',
  },
  row: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    width: '100%',
    height: '61px',
  },
  unqualified: {
    width: '23px',
    height: '23px',
    marginRight: '5px',
    objectFit: 'cover',
    cursor: 'not-allowed',
  },
  checked: {
    width: '23px',
    height: '23px',
    marginRight: '5px',
    objectFit: 'cover',
    cursor: 'pointer',
  },
  unchecked: {
    width: '23px',
    height: '23px',
    marginRight: '5px',
    background: '#FFFFFF',
    border: '2px solid #A0A8AC',
    borderRadius: '50%',
    boxSizing: 'border-box',
    cursor: 'pointer',
  },
  medal: {
    width: '15px',
    height: '18px',
    marginRight: '5px',
    boxSizing: 'border-box',
  },
  carrier: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    width: '104px',
    height: '100%',
    padding: '0 17px',
    color: '#FFFFFF',
    background: '#0091B3',
    boxSizing: 'border-box',
    textAlign: 'center',
    fontWeight: 700,
    fontSize: '12px',
    lineHeight: '14px',
    '&.disabled': {
      background: '#C0CCD4',
    },
  },
  terms: {
    flex: 1,
    display: 'flex',
    flexDirection: 'row',
    height: '100%',
  },
  term: {
    flex: 2,
    display: 'flex',
    flexDirection: 'column',
    height: '100%',
  },
  label: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    width: '100%',
    height: '22px',
    fontFamily: 'Roboto',
    fontWeight: 600,
    fontSize: '12px',
    lineHeight: '14px',
    color: '#00698F',
    background: '#EBF4F7',
    '&.disabled': {
      color: 'rgba(0, 105, 143, 0.4)',
      background: '#EBF4F7',
    },
  },
  comparison: {
    flex: 1,
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
    gap: '9px',
    width: '100%',
    background: '#F6F7F7;',
  },
  currentValue: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-end',
    justifyContent: 'center',
    gap: '2px',
    width: '48px',
    height: '33px',
    padding: '0px 5px',
    border: '1px solid #EBEBEB',
    boxSizing: 'border-box',
    '& > span': {
      maxWidth: '100%',
      fontFamily: 'Roboto',
      fontWeight: 500,
      fontSize: '12px',
      lineHeight: '14px',
      color: '#68737A',
      overflow: 'hidden',
      textOverflow: 'ellipsis',
    },
    '& > span:first-child': {
      maxWidth: '100%',
      fontWeight: 500,
      fontSize: '20px',
      lineHeight: '24px',
      color: '#68737A',
      zoom: 0.5,
      overflow: 'hidden',
      textOverflow: 'ellipsis',
    },
    '&.disabled > span': {
      color: 'rgba(104, 115, 122, 0.4)',
    },
  },
  newValue: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    justifyContent: 'center',
    gap: '2px',
    width: '48px',
    height: '33px',
    padding: '0px 5px',
    background: '#ECECEC',
    border: '1px solid #EBEBEB',
    boxSizing: 'border-box',
    '& > span': {
      maxWidth: '100%',
      fontFamily: 'Roboto',
      fontWeight: 500,
      fontSize: '12px',
      lineHeight: '14px',
      color: '#68737A',
      overflow: 'hidden',
      textOverflow: 'ellipsis',
    },
    '& > span:first-child': {
      maxWidth: '100%',
      fontWeight: 500,
      fontSize: '20px',
      lineHeight: '24px',
      color: '#68737A',
      zoom: 0.5,
      overflow: 'hidden',
      textOverflow: 'ellipsis',
    },
    '&.disabled > span': {
      background: 'rgba(236, 236, 236, 0.4)',
      color: 'rgba(104, 115, 122, 0.4)',
    },
  },
});

const getAttribute = (object, key) => {
  return object ? object[key] : null;
};

const MnoTermsComparisonTable = ({
  mnoStatus,
  newMnoTerms,
  selectedMnos,
  onSelectedMnosChange,
  ...props
}) => {
  const classes = useStyles();

  const handleMnoToggle = (id) => {
    const index = selectedMnos.indexOf(id);
    if (index === -1) {
      onSelectedMnosChange([...selectedMnos, id]);
    } else {
      const newSelectedMnos = [...selectedMnos];
      newSelectedMnos.splice(index, 1);
      onSelectedMnosChange(newSelectedMnos);
    }
  };

  return (
    <div className={classes.container} {...props}>
      {Object.entries(newMnoTerms).map(([key, terms]) => {
        const currentStatus = mnoStatus.find(
          (status) => String(status.networkId) === key
        );
        return (
          <div
            key={key}
            className={`${classes.row}${terms.qualify ? '' : ' disabled'}`}
          >
            {terms.qualify ? (
              selectedMnos.includes(key) ? (
                <img
                  className={classes.checked}
                  src={`${S3_ASSETS_PATH}/images/selected-mno-icon.svg`}
                  onClick={() => handleMnoToggle(key)}
                  data-testid="mnoTermsComparisonTableCheckedMnoToggle"
                />
              ) : (
                <div
                  className={classes.unchecked}
                  onClick={() => handleMnoToggle(key)}
                  data-testid="mnoTermsComparisonTableUnchckedMnoToggle"
                />
              )
            ) : (
              <span style={{ width: '23px', marginRight: '5px' }}></span>
            )}
            <div
              className={`${classes.carrier}${
                terms.qualify ? '' : ' disabled'
              }`}
            >
              {terms.mno}
            </div>
            <div className={classes.terms}>
              <div
                className={classes.term}
                data-testid="mnoTermsComparisonTableQualifyColumn"
              >
                <div className={classes.label}>
                  <Box
                    flexDirection="row"
                    justifyContent="center"
                    alignItems="center"
                  >
                    <Box margin={{ right: 'xxs' }}>
                      <FontAwesomeIcon icon={faAward} size="xl" />
                    </Box>
                    <span>QUALIFY</span>
                  </Box>
                </div>
                <div className={classes.comparison}>
                  {terms.qualify ? (
                    <span className="paragraph bold">Yes</span>
                  ) : (
                    <img
                      style={{ width: '26px' }}
                      src={`${S3_ASSETS_PATH}/images/not-qualify.svg`}
                      alt="No"
                    />
                  )}
                </div>
              </div>
              <div
                className={classes.term}
                style={{ flex: 3 }}
                data-testid="mnoTermsComparisonTableBrandTierTpmScopeColumn"
              >
                <div
                  className={`${classes.label}${
                    terms.qualify ? '' : ' disabled'
                  }`}
                >
                  {terms.mno === 'T-Mobile' ? 'BRAND TIER' : 'TPM SCOPE'}
                </div>
                <div className={classes.comparison}>
                  <div
                    className={`${classes.currentValue}${
                      terms.qualify ? '' : ' disabled'
                    }`}
                    style={{ width: 80 }}
                    data-testid="mnoTermsComparisonTableCurrentMno"
                  >
                    <span>Current</span>
                    <span>
                      {(currentStatus
                        ? terms.mno === 'T-Mobile'
                          ? getAttribute(
                              currentStatus.rulesEngineResultsJson,
                              'brandTier'
                            )
                          : getAttribute(
                              currentStatus.rulesEngineResultsJson,
                              'tpmScope'
                            )
                        : null) || 'N/A'}
                    </span>
                  </div>
                  <div
                    className={`${classes.newValue}${
                      terms.qualify ? '' : ' disabled'
                    }`}
                    style={{ width: 80 }}
                    data-testid="mnoTermsComparisonTableNewMno"
                  >
                    <span>New</span>
                    <span>
                      {(terms.mno === 'T-Mobile'
                        ? terms.brandTier
                        : terms.tpmScope) || 'N/A'}
                    </span>
                  </div>
                </div>
              </div>
              <div
                className={classes.term}
                data-testid="mnoTermsComparisonTableBrandDailyCapSmsTpmColumn"
              >
                <div
                  className={`${classes.label}${
                    terms.qualify ? '' : ' disabled'
                  }`}
                >
                  {terms.mno === 'T-Mobile' ? 'BRAND DAILY CAP' : 'SMS TPM'}
                </div>
                <div className={classes.comparison}>
                  <div
                    data-testid="mnoTermsComparisonTableCurrentStatus"
                    className={`${classes.currentValue}${
                      terms.qualify ? '' : ' disabled'
                    }`}
                  >
                    <span>Current</span>
                    <span>
                      {(currentStatus
                        ? terms.mno === 'T-Mobile'
                          ? getAttribute(
                              currentStatus.rulesEngineResultsJson,
                              'brandDailyCap'
                            )
                          : getAttribute(
                              currentStatus.rulesEngineResultsJson,
                              'tpm'
                            )
                        : null) || 'N/A'}
                    </span>
                  </div>
                  <div
                    className={`${classes.newValue}${
                      terms.qualify ? '' : ' disabled'
                    }`}
                    data-testid="mnoTermsComparisonTableNew"
                  >
                    <span>New</span>
                    <span>
                      {(terms.mno === 'T-Mobile'
                        ? terms.brandDailyCap
                        : terms.tpm) || 'N/A'}
                    </span>
                  </div>
                </div>
              </div>
              <div
                className={classes.term}
                data-testid="mnoTermsComparisonTableMmsTpmColumn"
              >
                <div
                  className={`${classes.label}${
                    terms.qualify ? '' : ' disabled'
                  }`}
                >
                  MMS TPM
                </div>
                <div className={classes.comparison}>
                  <div
                    className={`${classes.currentValue}${
                      terms.qualify ? '' : ' disabled'
                    }`}
                  >
                    <span>Current</span>
                    <span>
                      {(currentStatus
                        ? getAttribute(
                            currentStatus.rulesEngineResultsJson,
                            'mmsTpm'
                          )
                        : null) || 'N/A'}
                    </span>
                  </div>
                  <div
                    className={`${classes.newValue}${
                      terms.qualify ? '' : ' disabled'
                    }`}
                  >
                    <span>New</span>
                    <span>{terms.mmsTpm || 'N/A'}</span>
                  </div>
                </div>
              </div>
              <div
                className={classes.term}
                data-testid="mnoTermsComparisonTableMessageClassColumn"
              >
                <div
                  className={`${classes.label}${
                    terms.qualify ? '' : ' disabled'
                  }`}
                >
                  MESSAGE CLASS
                </div>
                <div className={classes.comparison}>
                  <div
                    className={`${classes.currentValue}${
                      terms.qualify ? '' : ' disabled'
                    }`}
                  >
                    <span>Current</span>
                    <span>
                      {(currentStatus
                        ? getAttribute(
                            currentStatus.rulesEngineResultsJson,
                            'msgClass'
                          )
                        : null) || 'N/A'}
                    </span>
                  </div>
                  <div
                    className={`${classes.newValue}${
                      terms.qualify ? '' : ' disabled'
                    }`}
                  >
                    <span>New</span>
                    <span>{terms.msgClass || 'N/A'}</span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        );
      })}
    </div>
  );
};

MnoTermsComparisonTable.propTypes = {
  mnoStatus: PropTypes.arrayOf(
    PropTypes.shape({
      networkId: PropTypes.number.isRequired,
      rulesEngineResultsJson: PropTypes.shape({
        mno: PropTypes.string.isRequired,
        tpm: PropTypes.number,
        tpmScope: PropTypes.string,
        mmsTpm: PropTypes.number,
        msgClass: PropTypes.string,
        brandTier: PropTypes.string,
        brandDailyCap: PropTypes.number,
      }).isRequired,
    })
  ).isRequired,
  newMnoTerms: PropTypes.objectOf(
    PropTypes.shape({
      mno: PropTypes.string.isRequired,
      qualify: PropTypes.bool.isRequired,
      tpm: PropTypes.number,
      tpmScope: PropTypes.string,
      mmsTpm: PropTypes.number,
      msgClass: PropTypes.string,
      brandTier: PropTypes.string,
      brandDailyCap: PropTypes.number,
    })
  ),
  selectedMnos: PropTypes.arrayOf(PropTypes.string),
  onSelectedMnosChange: PropTypes.func,
};

export default MnoTermsComparisonTable;
