import {
  useImperativeHandle,
  forwardRef,
  Ref,
  useState,
  useEffect,
  Dispatch,
  SetStateAction,
} from 'react';
import { faChartNetwork } from '@fortawesome/pro-regular-svg-icons';
import { Controller, useFormContext } from 'react-hook-form';
import { Flex, BoxV2 as Box, Button, TextInput } from 'portal-commons';

import Section from './Section';
import BodyText from './BodyText';
import RadioButton from './RadioButton';
import { SINGLE_COL_INPUT_WIDTH, REQUIRED_ERR_MSG } from './constants';

interface ICnpPartnerInfo {
  backwards?: boolean;
  csp: Record<string, any>;
  has2ndCnp: string;
  setActBtnVisibility: Dispatch<SetStateAction<boolean>>;
  setHas2ndCnp: Dispatch<SetStateAction<string>>;
  onTerminate: () => void;
}

interface RefProps {
  next: () => boolean;
  prev: () => boolean;
}

enum Scene {
  CspAcknowledgement,
  CnpDetails,
  SecondaryCnp,
  CnpRelation,
}

const CnpPartnerInfo = forwardRef(
  (
    {
      backwards,
      onTerminate,
      setActBtnVisibility,
      has2ndCnp,
      setHas2ndCnp,
    }: ICnpPartnerInfo,
    ref: Ref<RefProps>
  ) => {
    const { control } = useFormContext();
    const [scene, setScene] = useState<Scene>(
      backwards ? Scene.CnpRelation : Scene.CspAcknowledgement
    );
    const next = (scene: Scene): boolean => {
      if (scene < Scene.CnpRelation) {
        setScene((prev) => prev + 1);
        return true;
      }
      return false;
    };

    const prev = (scene: Scene): boolean => {
      if (scene >= Scene.CspAcknowledgement) {
        setScene((prev) => prev - 1);
        return true;
      }
      return false;
    };

    useImperativeHandle(
      ref,
      () => ({
        next: () => next(scene),
        prev: () => prev(scene),
      }),
      [scene]
    );

    useEffect(() => {
      setActBtnVisibility(scene !== Scene.CspAcknowledgement);
    }, [scene]);

    if (scene === Scene.CspAcknowledgement) {
      return (
        <Flex sx={{ flexDirection: 'column', alignItems: 'center' }}>
          <Flex
            sx={{
              flexDirection: 'column',
              gap: 'l',
              border: '1px solid',
              borderColor: '#C0CCD4',
              background: 't.white',
              width: '540px',
              py: 'xl',
              px: 'xxl',
            }}
          >
            <Box
              as="p"
              sx={{
                fontSize: 'H500',
                textAlign: 'center',
                color: 't.black80',
                lineHeight: 'normal',
              }}
            >
              Are you considered a Campaign Service Provider (CSP) that offers a
              Communications Platform as a Service (CPaaS), A2P Services or SMS
              Campaign services?
            </Box>
            <Flex sx={{ flexDirection: 'row', justifyContent: 'center' }}>
              <Button
                type="button"
                variant="outline"
                style={{ marginRight: '16px' }}
                onClick={onTerminate}
              >
                No
              </Button>
              <Button
                variant="standard"
                type="button"
                onClick={() => next(scene)}
              >
                Yes
              </Button>
            </Flex>
          </Flex>
        </Flex>
      );
    }

    if (scene === Scene.CnpDetails) {
      return (
        <Section title="Connectivity Partner Details" icon={faChartNetwork}>
          <BodyText>
            Who is your Connectivity Partner (CNP) or Direct Connect Aggregator
            (DCA) for SMS delivery?
          </BodyText>
          <Controller
            control={control}
            defaultValue=""
            name="questionnairePrimaryCnpName"
            rules={{
              required: REQUIRED_ERR_MSG,
              maxLength: 100,
            }}
            render={({ field, fieldState: { invalid, error } }) => (
              <TextInput
                label="CNP/DCA"
                required
                error={invalid}
                note={error?.message}
                style={{
                  width: SINGLE_COL_INPUT_WIDTH,
                }}
                maxLength={100}
                {...field}
              />
            )}
          />
        </Section>
      );
    }

    if (scene === Scene.SecondaryCnp) {
      return (
        <Section
          title="Secondary Connectivity Partner Details"
          icon={faChartNetwork}
        >
          <BodyText>
            Do you have a secondary Connectivity Partner (CNP) or Direct Connect
            Aggregator (DCA)?
          </BodyText>
          <Flex sx={{ flexDirection: 'row', gap: 'xxl' }}>
            <RadioButton
              label="Yes"
              value="yes"
              onChange={setHas2ndCnp}
              checked={has2ndCnp === 'yes'}
            />
            <RadioButton
              label="No"
              value="no"
              onChange={setHas2ndCnp}
              checked={has2ndCnp === 'no'}
            />
          </Flex>
          {has2ndCnp === 'yes' && (
            <>
              <BodyText mt="14px">
                Please list your secondary Connectivity Partner (CNP) or Direct
                Connect Aggregator (DCA) below.
              </BodyText>
              <Controller
                control={control}
                defaultValue=""
                name="questionnaireSecondaryCnpName"
                rules={{
                  required: REQUIRED_ERR_MSG,
                  maxLength: 100,
                }}
                render={({ field, fieldState: { invalid, error } }) => (
                  <TextInput
                    label="Secondary CNP/DCA"
                    required
                    error={invalid}
                    note={error?.message}
                    style={{
                      width: SINGLE_COL_INPUT_WIDTH,
                    }}
                    maxLength={100}
                    {...field}
                  />
                )}
              />
            </>
          )}
        </Section>
      );
    }

    if (scene === Scene.CnpRelation) {
      return (
        <Section
          title="Connectivity Partner Relationship"
          icon={faChartNetwork}
        >
          <BodyText>
            What is the length of the relationship with your existing
            connectivity partners?
          </BodyText>
          <Controller
            control={control}
            defaultValue=""
            name="questionnaireRelationshipLength"
            rules={{
              required:
                'Enter a relationship length to continue (e.g. 6 months, 1 year, 2 years)',
              maxLength: 1000,
            }}
            render={({ field, fieldState: { invalid, error } }) => (
              <TextInput
                label="Relationship Length"
                required
                maxLength={1000}
                error={invalid}
                note={error?.message}
                style={{
                  width: SINGLE_COL_INPUT_WIDTH,
                }}
                {...field}
              />
            )}
          />
        </Section>
      );
    }

    return null;
  }
);

CnpPartnerInfo.displayName = 'CnpPartnerInfo';

export default CnpPartnerInfo;
