import React, { useEffect, useState } from 'react';
import {
  Backdrop,
  Grid,
  makeStyles,
  Typography,
  Container,
} from '@material-ui/core';
import {
  getExtVettingReport,
  downloadAttachmentToDesktop,
  downloadAttachmentApi,
  requestStandardVettingAppeal,
} from '../../apiServices';
import { ATTACHMENT_FOLDER_MMS } from '../../../Campaigns/constants';
import Spinner from '../../../Campaigns/components/Spinner';
import AppealModal from '../AppealModal';
import AppealRequestModal from '../AppealRequestModal';
import AppealHistoryModal from '../AppealHistoryModal';
import AppealRequestConfirmationModal from '../AppealRequestConfirmationModal/AppealRequestConfirmationModal';
import { toastFlashMessage } from '../../../../../utils';
import {
  AppealCategoryData,
  AppealRequestData,
  AttachmentInfoExtras,
  BrandDetail,
  BrandFeedback,
  ExtVettingRecord,
  ModalType,
  VettingAppeal,
} from '../../types';
import VettingDetailsRow from './VettingDetailsRow';
import { VettingRecordAction, TABLE_COLUMNS } from './constants';
import { Box, Table, AttachmentPreview } from 'portal-commons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFileMagnifyingGlass } from '@fortawesome/pro-regular-svg-icons';

const useStyles = makeStyles(() => ({
  root: {},
  modal: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    width: 430,
    height: 226,
    background: '#FFFFFF',
  },
}));

type stateObject = {
  [key: string]: boolean | number;
};

type AppealsByVettingId = {
  [key: string]: VettingAppeal;
};

interface Props {
  role: string;
  brand: BrandDetail;
  records: ExtVettingRecord[];
  appeals: VettingAppeal[];
  appealCategories: AppealCategoryData[];
  validAppealCategoryIds: string[];
  disabledAppealCategoryIds: string[];
  feedbackInfo: BrandFeedback[];
  onAppealCreate: (_appeal: VettingAppeal) => void;
  hasEditPermission: (_role: string) => boolean;
  handleState: (_obj: stateObject) => void;
}

const BrandVettingDetailsBlock: React.FC<Props> = ({
  role,
  brand,
  records = [],
  appeals = [],
  appealCategories = [],
  validAppealCategoryIds,
  disabledAppealCategoryIds,
  onAppealCreate,
  hasEditPermission,
  handleState,
}) => {
  const classes = useStyles();

  const [appealOpen, setAppealOpen] = useState(false);
  const [appealScoreName, setAppealScoreName] = useState('');
  const [appealCompanyName, setAppealCompanyName] = useState('');
  const [appealContactEmail, setAppealContactEmail] = useState('');
  const [appealRequestOpen, setAppealRequestOpen] = useState(false);
  const [appealRequestRecord, setAppealRequestRecord] =
    useState<ExtVettingRecord | null>(null);
  const [appealRequestData, setAppealRequestData] =
    useState<AppealRequestData | null>(null);
  const [appealRequestConfirmationOpen, setAppealRequestConfirmationOpen] =
    useState(false);
  const [
    appealRequestConfirmationDisabled,
    setAppealRequestConfirmationDisabled,
  ] = useState(false);
  const [appealHistoryOpen, setAppealHistoryOpen] = useState(false);
  const [appealHistoryIndex, setAppealHistoryIndex] = useState<string>('');
  const [viewReport, setViewReport] = useState<AttachmentInfoExtras>();
  const [loadingReport, setLoadingReport] = useState(false);
  const [latestAppeals, setLatestAppeals] = useState<AppealsByVettingId>({});

  const handleAppealClick = (item: ExtVettingRecord) => {
    if (item.evpUid === 'AEGIS') {
      if (item.vettingClass === 'STANDARD') {
        setAppealRequestOpen(true);
        setAppealRequestRecord(item);
      } else {
        setAppealOpen(true);
        setAppealScoreName('AI3MS Score™');
        setAppealCompanyName('Aegis Mobile LLC');
        setAppealContactEmail('appeals@aegismobile.com');
      }
    } else if (item.evpUid === 'WMC') {
      setAppealOpen(true);
      setAppealScoreName('RISQ Score');
      setAppealCompanyName('WMC Global');
      setAppealContactEmail('support@risqscore.zendesk.com');
    }
  };

  const handleAppealHistoryClick = (item: ExtVettingRecord) => {
    setAppealHistoryOpen(true);
    setAppealHistoryIndex(item.vettingId);
  };

  useEffect(() => {
    const tmpLatestAppeals: AppealsByVettingId = {};
    appeals.forEach((appeal: VettingAppeal) => {
      if (!tmpLatestAppeals[appeal.vettingId]) {
        tmpLatestAppeals[appeal.vettingId] = appeal;
      }
    });
    setLatestAppeals(tmpLatestAppeals);
  }, [appeals]);

  const handleViewReport = async (item: Record<string, any>) => {
    try {
      setViewReport(undefined);
      setLoadingReport(true);
      const result = await getExtVettingReport(
        item.brandUid,
        ATTACHMENT_FOLDER_MMS
      );
      const response = await downloadAttachmentApi(result[0].uuid);
      result[0] = {
        ...result[0],
        file: new File([response], result[0].fileName, {
          type: 'application/pdf',
        }),
        mimeType: 'application/pdf',
      };
      setViewReport(result[0]);
      setLoadingReport(false);
    } catch (error) {
      // console.log(error);
    }
  };

  const handleDownloadReport = async (item: Record<string, any>) => {
    try {
      const result = await getExtVettingReport(item.brandUid);
      await downloadAttachmentToDesktop(result[0].uuid, result[0].fileName);
    } catch (error) {
      // console.log(error);
    }
  };

  const handleVettingActionClick = (
    action: VettingRecordAction,
    record: ExtVettingRecord
  ) => {
    switch (action) {
      case VettingRecordAction.Appeal:
        handleAppealClick(record);
        break;
      case VettingRecordAction.ViewAppealHistory:
        handleAppealHistoryClick(record);
        break;
      case VettingRecordAction.ViewReport:
        handleViewReport(record);
        break;
      case VettingRecordAction.DownloadReport:
        handleDownloadReport(record);
        break;
    }
  };

  return (
    <Grid item xs={12} data-testid="brandVettingDetailsBlock">
      <div className="vetting details">
        <div className="details-heading">
          <div className="title">
            <Box flexDirection="row" alignItems="center">
              <Box margin={{ right: 'xs' }}>
                <FontAwesomeIcon icon={faFileMagnifyingGlass} size="xl" />
              </Box>
              <h3 className="heading1">Vetting Details</h3>
            </Box>
          </div>
          {hasEditPermission(role) && (
            <ul className="list-inline vetting">
              <li
                onClick={() => {
                  handleState({
                    modalOpenFlag: true,
                    modalType: ModalType.newVetting,
                  });
                }}
                data-testid="brandVettingDetailsBlockApplyVettingButton"
              >
                <p className="paragraph">
                  <a>Apply for Vetting</a>
                </p>
              </li>
              <li
                onClick={() =>
                  handleState({
                    modalOpenFlag: true,
                    modalType: ModalType.importVetting,
                  })
                }
                data-testid="brandVettingDetailsBlockImportVettingButton"
              >
                <p className="paragraph">
                  <a>Import Vetting</a>
                </p>
              </li>
            </ul>
          )}
        </div>
        <Grid
          container
          className="brand-vetting-details"
          data-testid="brandVettingDetailsBlockContainer"
        >
          <Table
            className="vetting-list"
            loading={false}
            headRows={TABLE_COLUMNS}
            disableHover
            records={{ total: records.length }}
            tableData={records.map((r, idx) => (
              <VettingDetailsRow
                key={idx}
                record={r}
                appeals={appeals}
                role={role}
                onActionClick={handleVettingActionClick}
              />
            ))}
            emptyState="no vettings to view"
          />
          <AppealModal
            open={appealOpen}
            scoreName={appealScoreName}
            companyName={appealCompanyName}
            contactEmail={appealContactEmail}
            handleClose={() => setAppealOpen(false)}
          />
          <AppealRequestModal
            open={appealRequestOpen}
            brand={brand}
            title="Standard Vet Appeal Request"
            message="You can submit an Appeal up to 45 days after the completion of a vet. Appeals are charged $10 regardless of the outcome. Do not use this for Identity Status appeals."
            categories={appealCategories.filter((category) =>
              validAppealCategoryIds.includes(category.appealCategoryId)
            )}
            disabledCategoryIds={disabledAppealCategoryIds}
            onClose={() => setAppealRequestOpen(false)}
            onSubmit={(data) => {
              setAppealRequestOpen(false);
              setAppealRequestData(data);
              setAppealRequestConfirmationOpen(true);
            }}
          />
          <AppealRequestConfirmationModal
            open={appealRequestConfirmationOpen}
            disabled={appealRequestConfirmationDisabled}
            onClose={() => setAppealRequestConfirmationOpen(false)}
            onSubmit={async () => {
              setAppealRequestConfirmationDisabled(true);
              try {
                if (appealRequestRecord && appealRequestData) {
                  const appeal = await requestStandardVettingAppeal(
                    appealRequestRecord.brandUid,
                    appealRequestRecord.vettingId,
                    appealRequestData.categories,
                    appealRequestData.explanation,
                    appealRequestData.attachments
                  );
                  toastFlashMessage('Vetting Appeal Requested', 'success');
                  onAppealCreate && onAppealCreate(appeal);
                }
              } catch (e) {
                // Ignore
              }
              setAppealRequestConfirmationOpen(false);
              setAppealRequestConfirmationDisabled(false);
            }}
          />
          <AppealHistoryModal
            open={appealHistoryOpen && !!latestAppeals[appealHistoryIndex]}
            title="Standard Vet"
            appeal={latestAppeals[appealHistoryIndex]}
            appealCategories={appealCategories}
            onClose={() => setAppealHistoryOpen(false)}
          />

          {viewReport?.file && (
            <AttachmentPreview
              file={viewReport.file}
              mimeType={viewReport.mimeType}
              onClose={() => setViewReport(undefined)}
            />
          )}
          <Backdrop
            style={{ zIndex: 1005 }}
            open={loadingReport}
            onClick={() => setViewReport(undefined)}
          >
            <Container classes={{ root: classes.modal }}>
              <Spinner />
              <Typography
                variant="body1"
                align="center"
                style={{
                  marginTop: 40,
                  color: '#49535D',
                }}
              >
                Please wait while we load
                <br />
                your preview
              </Typography>
            </Container>
          </Backdrop>
        </Grid>
      </div>
    </Grid>
  );
};

export default BrandVettingDetailsBlock;
