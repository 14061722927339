import React from 'react';
import ToolTip from '../../../../ui_elements/ToolTip';
import PaymentContainer from '../../../../payment';
import PropTypes from 'prop-types';
import { S3_ASSETS_PATH } from '../../../../constants';

const CspPayment = (props) => {
  return (
    <div className="csp-payment-outer-wrapper" data-testid="cspPayment">
      <div className="csp-payment">
        <h4 className="heading1">ONE TIME PAYMENT</h4>
        <div className="csp-payment-info">
          <h4 className="heading2">Payment Information</h4>
          <p className="heading2 light">
            One time Non Refundable Payment
            <ToolTip
              title="A one-time, non-refundable payment is required to begin the verification process.  Once verification has been successfully completed, CSPs can immediately begin registering campaigns on behalf of brands.  Payment will not be refunded to CSPs who are not approved."
              heading
            />
          </p>
          <img src={`${S3_ASSETS_PATH}/images/payment.svg`} />
        </div>
        {props.stripeKey != '' ? (
          <PaymentContainer
            togglePaymentLoader={props.togglePaymentLoader}
            payActivationFee={props.payActivationFee}
            stripeKey={props.stripeKey}
          />
        ) : null}
      </div>
    </div>
  );
};
CspPayment.propTypes = {
  checked: PropTypes.bool.isRequired,
  handleChecked: PropTypes.func.isRequired,
  activationFee: PropTypes.object.isRequired,
  stripeKey: PropTypes.string.isRequired,
  payActivationFee: PropTypes.func.isRequired,
  togglePaymentLoader: PropTypes.func.isRequired,
};
export default CspPayment;
