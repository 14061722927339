import React, { useEffect, useState } from 'react';
import { Box, Grid, Modal, makeStyles } from '@material-ui/core';
import { S3_ASSETS_PATH } from '../../../../constants';
import { fetchAllResellers } from '../apiServices';
import PropTypes from 'prop-types';
import { Button } from 'portal-commons';
import IntegrationReactSelect from '../../../../ui_elements/IntegrationReactSelect';

import { Box as BoxCommon } from 'portal-commons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faTag,
  faBullhorn,
  faMessageDots,
  faFileImage,
  faSquarePollHorizontal,
  faArrowProgress,
  faTrash,
} from '@fortawesome/pro-regular-svg-icons';

const useStyles = makeStyles(() => ({
  container: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: '525px',
    padding: '28px',
    marginLeft: '100px',
    backgroundColor: '#FFFFFF',
    borderRadius: '5px',
    boxSizing: 'border-box',
  },
}));

const ResellerSelectModal = (props) => {
  const classes = useStyles();
  const [resellersOptions, setResellersOptions] = useState([]);
  const [resellerId, setResellerId] = useState(null);
  const { open, handleClose, handleSubmit } = props;

  const handleResellerChange = (selectedOption, _keyName) => {
    setResellerId(selectedOption.value);
  };

  const handleSaveResellerId = () => {
    if (resellerId) {
      handleSubmit(resellerId);
    }
    handleClose();
  };

  const handleDialogClose = () => {
    setResellerId(null);
    handleClose();
  };

  useEffect(async () => {
    const response = await fetchAllResellers();
    const options = response.data.records.map((item) => ({
      label: `${item.companyName} – ${item.uid}`,
      value: item.uid,
    }));
    setResellersOptions(options);
  }, []);

  return (
    <Modal
      open={open}
      onClose={handleDialogClose}
      aria-labelledby="other-responsible-parties"
      aria-describedby="select-reseller-id"
      data-testid="resellerSelectModal"
    >
      <Box classes={{ root: classes.container }}>
        <Grid container spacing={2} className="reseller-select-modal">
          <Grid item xs={12}>
            <div className="details">
              <div className="details-heading">
                <BoxCommon
                  flexDirection="row"
                  justifyContent="center"
                  alignItems="center"
                >
                  <BoxCommon margin={{ right: 'xs' }}>
                    <FontAwesomeIcon icon={faArrowProgress} />
                  </BoxCommon>
                  Add a Reseller
                </BoxCommon>
              </div>
            </div>
          </Grid>
          <Grid item xs={12}>
            <p className="paragraph">
              Please note: once a Reseller has been added to a campaign, it
              cannot be changed or removed later.
            </p>
          </Grid>
          <Grid
            item
            xs={12}
            className="reseller-selector"
            data-testid="resellerSelectModalResellerSelect"
          >
            <IntegrationReactSelect
              options={resellersOptions}
              label="Select Reseller"
              value={resellersOptions.find(
                (option) => option.value === resellerId
              )}
              handleChange={handleResellerChange}
              keyName="resellerUid"
            />
          </Grid>
          <Grid
            container
            xs={12}
            justifyContent="center"
            alignItems="center"
            style={{ marginTop: '20px', columnGap: '20px' }}
          >
            <Button
              data-testid="resellerSelectModalCancelButton"
              onClick={handleDialogClose}
              shape="rounded"
              variant="outline"
            >
              Cancel
            </Button>
            <Button
              onClick={handleSaveResellerId}
              disabled={resellerId === null}
              data-testid="resellerSelectModalSaveButton"
            >
              Save
            </Button>
          </Grid>
        </Grid>
      </Box>
    </Modal>
  );
};
ResellerSelectModal.prototype = {
  open: PropTypes.bool.isRequired,
  handleClose: PropTypes.func.isRequired,
  handleSubmit: PropTypes.func.isRequired,
};
export default ResellerSelectModal;
