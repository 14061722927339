import { BoxV2 as Box, Flex, TextInput } from 'portal-commons';
import { FunctionComponent } from 'react';
import { Controller, useFieldArray, useFormContext } from 'react-hook-form';
import styled from 'styled-components';
import { IconDefinition } from '@fortawesome/pro-regular-svg-icons';
import { faTrashCan } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { createMaxLengthValidator, isValidEmail } from '../validators';

interface EmailsEditBlockProps {
  disabled: boolean;
  icon: IconDefinition;
  title: string;
  name: string;
  maxCount?: number;
  maxLength?: number;
  testId?: string;
}

const Content = styled.div`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  column-gap: 24px;
  row-gap: 10px;
  padding-top: 8px;
  margin-top: 8px;
  box-sizing: border-box;
`;

const EmailsEditBlock: FunctionComponent<EmailsEditBlockProps> = ({
  disabled,
  icon,
  title,
  name,
  maxCount,
  maxLength,
  testId,
}) => {
  const { control } = useFormContext();
  const { fields, append, remove } = useFieldArray({
    control,
    name,
  });

  const handleAdd = () => {
    append({ email: '' });
  };

  const handleDelete = (index: number) => {
    remove(index);
  };

  return (
    <Flex sx={{ flexDirection: 'column', width: '100%' }} data-testid={testId}>
      <Flex
        sx={{
          flexDirection: 'row',
          alignItems: 'center',
          columnGap: '8px',
          paddingBottom: '10px',
          borderBottom: '1px solid #C0CCD4',
          color: '#49535D',
          fontFamily: 'Roboto',
          fontWeight: 600,
          fontSize: '16px',
          lineHeight: '19px',
        }}
      >
        <FontAwesomeIcon icon={icon} />
        <Box>{title}</Box>
        {(!maxCount || fields.length < maxCount) && (
          <Box
            sx={{
              marginLeft: '8px',
              color: '#0091B3',
              fontSize: '12px',
              fontWeight: '500',
              lineHeight: '14px',
              cursor: 'pointer',
            }}
            onClick={handleAdd}
            data-testid="addEmailButton"
          >
            + Add a New Email
          </Box>
        )}
      </Flex>
      <Content>
        {fields.map((field, index) => (
          <Flex
            key={field.id}
            sx={{
              flexDirection: 'row',
              alignItems: 'flex-start',
              columnGap: '5px',
            }}
            data-testid="emailContainer"
          >
            <Box sx={{ flex: 1 }}>
              <Controller
                name={`${name}.${index}`}
                rules={{
                  validate: {
                    ...(maxLength !== undefined
                      ? {
                          isValidLength: (value) =>
                            createMaxLengthValidator(maxLength)(value.email),
                        }
                      : {}),
                    isValidEmail: (value) => isValidEmail(value.email),
                  },
                }}
                render={({
                  field: { value, onChange, onBlur },
                  fieldState: { error = {} },
                }) => (
                  <TextInput
                    disabled={disabled}
                    error={!!error.message}
                    label="Email Address"
                    note={error.message}
                    maxLength={maxLength}
                    value={value.email}
                    onChange={(value) => onChange({ email: value })}
                    onBlur={onBlur}
                    data-testid="email"
                  />
                )}
              />
            </Box>
            {fields.length > 1 && !disabled && (
              <Flex
                sx={{
                  alignItems: 'center',
                  justifyContent: 'center',
                  height: '40px',
                }}
              >
                <FontAwesomeIcon
                  style={{ cursor: 'pointer' }}
                  icon={faTrashCan}
                  onClick={() => handleDelete(index)}
                  data-testid="deleteEmailButton"
                />
              </Flex>
            )}
          </Flex>
        ))}
      </Content>
    </Flex>
  );
};

export default EmailsEditBlock;
