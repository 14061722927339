// src/App.js

import React from 'react';
import { Provider } from 'react-redux';
import { BrowserRouter as Router } from 'react-router-dom';
import { Toast, ThemeProvider, cspTheme } from 'portal-commons';
import configureStore from './store/configureStore';
import Favicon from 'react-favicon';
import './assets/styles/app.scss';
import { S3_ASSETS_PATH, DIRECTUS_TOKEN } from './constants';
import AppWithRouterAccess from './AppWithRouterAccess';
import datadog from './utils/datadog';
import LoadingModal from './shared_elements/containers/LoadingModal';

const store = configureStore();

datadog.init();
const userInfoJsonString = localStorage.getItem('userInfo');
if (userInfoJsonString) {
  const userInfo = JSON.parse(userInfoJsonString);
  !datadog.hasUser() && datadog.setUser(userInfo);
}

const App = () => {
  return (
    <div className="App">
      <ThemeProvider theme={cspTheme}>
        <Favicon url={`${S3_ASSETS_PATH}/images/favicon-48x48.ico`} />
        <Provider store={store}>
          <LoadingModal />
          <Toast />
          <Router>
            <AppWithRouterAccess />
          </Router>
        </Provider>
      </ThemeProvider>
    </div>
  );
};
export default App;
