export type EmptyString = '';

export interface Pagination<T> {
  totalRecords: number;
  records: Array<T>;
  page: number;
  recordsPerPage: number;
  metaData?: Record<string, string | number> | null;
}

export interface SelectItem {
  label: string;
  value: string;
}

export interface AdditionalFilters {
  sortField: string;
  ascendingOrder: boolean;
  page?: string | number;
  [key: string]: string | boolean | number | undefined;
}

export const DEFAULT_ADDITIONAL_FILTERS: AdditionalFilters = {
  sortField: 'createDate',
  ascendingOrder: false,
};
