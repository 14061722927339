import React, { Component } from 'react';
import Grid from '@material-ui/core/Grid';
import { ForgotPasswordForm } from '../components';
import { Header } from '../../../shared_elements';
import '../../../assets/styles/user-authentication-module.scss';

export default class ForgotPassword extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  componentDidMount() {
    document.title = 'The Campaign Registry - Welcome to the Campaign Registry';
  }

  render() {
    return (
      <section className="user-authentication-section">
        <Header
          title="WELCOME TO THE CAMPAIGN REGISTRY"
          testId="forgetPasswordHeader"
        />
        <Grid
          container
          spacing={0}
          alignItems="center"
          justify="center"
          className="user-form-wrapper"
        >
          <ForgotPasswordForm />
        </Grid>
      </section>
    );
  }
}
