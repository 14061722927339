import React, { Component } from 'react';
import EscalateRequestModal from './EscalateRequestModal';
import PropTypes from 'prop-types';
import parse from 'html-react-parser';

class CspVerificationMessage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      modalFlag: false,
      text: '',
    };
  }

  handleModalTextChange = (key, value) => {
    this.setState((prevState) => ({
      ...prevState,
      [key]: value,
    }));
  };

  handleModalTextSubmit = () => {
    // console.log('Text inside the modal', this.state.text)
    this.setState({
      modalFlag: false,
      text: '',
    });
  };

  render() {
    const { type, icon, title, content } = this.props;
    const { text, modalFlag } = this.state;
    return (
      <>
        <div
          className={`verification-status text-center ${type}`}
          data-type={type}
          data-testid="cspVerificationMessage"
        >
          <img src={icon} alt="icon" />
          <div className="content-blk">
            <h4 className="heading1 text-center">{title}</h4>
            <div className="heading1 text-center">{parse(content)}</div>

            {/* { type == 'rejected' ?
              <div className="rejected-button-wrapper">
                <a
                  href="javascript:void(0)"
                  onClick={() =>
                    this.setState({
                      modalFlag: true
                    })
                  }
                  style={{marginRight: 20}}
                  className="primary-btn"
                  >OPEN A TICKET
                </a>
                <Link to= "/user/edit/csp" className = "primary-btn">MODIFY & RE-SUBMIT</Link>
              </div>:null
            } */}
          </div>
        </div>
        {modalFlag ? (
          <EscalateRequestModal
            text={text}
            open={modalFlag}
            handleClose={this.handleModalTextSubmit}
            handleChange={this.handleModalTextChange}
            handleSubmit={this.handleModalTextSubmit}
          />
        ) : null}
      </>
    );
  }
}
CspVerificationMessage.propTypes = {
  type: PropTypes.string.isRequired,
  icon: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  content: PropTypes.string.isRequired,
};
export default CspVerificationMessage;
