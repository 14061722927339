export const parseDate = (timestamp: number | Date): string => {
  const date = new Date(timestamp);
  return `${`0${date.getMonth() + 1}`.slice(-2)}/${`0${date.getDate()}`.slice(
    -2
  )}/${date.getFullYear()}`;
};
export const capitalizeFirstLetter = (str: string): string => {
  return str.slice(0, 1).toUpperCase() + str.slice(1).toLowerCase();
};
export const isStatusPending = (status: string): boolean => {
  return status.toLowerCase() === 'pending';
};
