import React from 'react';
import { Dialog, DialogContent, Grid } from '@material-ui/core';

const EinErrorModal = (props) => {
  const { einBrandInfo } = props;
  return (
    <Dialog
      open={props.open}
      onClose={() => props.handleClick(false)}
      aria-labelledby="responsive-dialog-title"
      maxWidth="xs"
      fullWidth={true}
      id="successModal"
      data-testid="einErrorModalDialog"
      className={`create-brand-modal ein-error`}
      disableBackdropClick
    >
      <DialogContent className={`create-brand-content text-center`}>
        <Grid container>
          <Grid item xs={12} className="title">
            <p className="paragraph">
              The Brand <span>{einBrandInfo.displayName}</span>{' '}
              {`${
                props.type == 'brandList'
                  ? `has registered with `
                  : `has already registered with`
              } ${
                einBrandInfo.country == 'US' ? 'EIN' : 'Tax ID'
              } number `}{' '}
              <span>{einBrandInfo.ein}</span>
            </p>
          </Grid>
          <Grid item xs={12} className="footer">
            <li>
              <p>
                <a
                  data-testid="einErrorModalDialogCloseButton"
                  className={'secondary-btn'}
                  onClick={() => props.handleClick(false)}
                >
                  Close
                </a>
              </p>
            </li>
            <li>
              <p>
                <a
                  data-testid="einErrorModalDialogShowButton"
                  className={'primary-btn'}
                  onClick={() => props.handleClick(true)}
                >
                  Show
                </a>
              </p>
            </li>
          </Grid>
        </Grid>
      </DialogContent>
    </Dialog>
  );
};
export default EinErrorModal;
