import React from 'react';
import { Slide, Snackbar } from '@material-ui/core';
import NotificationsNoneIcon from '@material-ui/icons/NotificationsNone';
import CloseIcon from '@material-ui/icons/Close';

function SlideTransition(props) {
  return <Slide {...props} direction="left" />;
}

export default function ElectCNPAlert(props) {
  const { open } = props;
  return (
    <div className="elect-cnp-alert-wrapper" data-testid="electCnpAlert">
      <Snackbar
        anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
        open={open}
        onClose={props.handleClose}
        message={
          <div
            className="alert-content-wrapper"
            data-testid="electCnpAlertNotification"
          >
            <NotificationsNoneIcon className="alert-icon" />
            <div className="alert">
              <h3 className="heading1">Reminder!</h3>
              <p className="paragraph">
                Please elect a new Connectivity Partner.
              </p>
            </div>
            <CloseIcon
              className="close-icon"
              onClick={props.handleClose}
              data-testid="electCnpAlertNotificationCloseButton"
            />
          </div>
        }
        // autoHideDuration={6000}
        TransitionComponent={SlideTransition}
      />
    </div>
  );
}
