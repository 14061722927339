import React from 'react';
import { makeStyles } from '@material-ui/core';
import { Table } from 'portal-commons';
import { VettingAppeal } from '../../types';
import AppealHistoryTableRow from './AppealHistoryTableRow';

const useStyles = makeStyles({
  container: {
    '& table': {
      '& tr td': {
        padding: '0 8px 0 16px',
        height: 40,
      },
      '& .empty-row': {
        height: 150,
      },
    },
  },
  header: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    width: '100%',
    height: '50px',
    background: 'rgba(0, 145, 179, 0.1)',
    fontFamily: 'Roboto',
    fontWeight: 500,
    fontSize: '14px',
    lineHeight: '16px',
    color: '#0091B3',
  },
});

interface Props {
  appeals: VettingAppeal[];
  onAppealView: (_idx: number) => Promise<void>;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  [key: string]: any;
}

const TABLE_COLUMNS = [
  { id: 'status', label: 'VETTING STATUS', sortable: false },
  { id: 'reqData', label: 'REQUEST DATE', sortable: false },
  { id: 'updateDate', label: 'STATUS UPDATE DATE', sortable: false },
  { id: 'details', label: 'DETAILS', sortable: false },
];

const AppealHistoryTable: React.FC<Props> = ({
  appeals = [],
  onAppealView,
  ...props
}) => {
  const classes = useStyles();

  const handleViewClick = (
    event: React.MouseEvent<HTMLAnchorElement>,
    index: number
  ) => {
    event.preventDefault();
    event.stopPropagation();
    onAppealView && onAppealView(index);
  };

  return (
    <div
      className={classes.container}
      {...props}
      data-testid="appealHistoryTable"
    >
      <Table
        loading={false}
        headRows={TABLE_COLUMNS}
        disableHover
        records={{ total: appeals.length }}
        tableData={appeals.map((appeal, idx) => (
          <AppealHistoryTableRow
            key={appeal.vettingId}
            data={appeal}
            onViewDetailsClick={(evt) => handleViewClick(evt, idx)}
          />
        ))}
        emptyState="no appeal history to view"
      />
    </div>
  );
};

export default AppealHistoryTable;
