export enum VettingRecordAction {
  Appeal = 'appeal',
  ViewAppealHistory = 'view_appeal_history',
  ViewReport = 'view_report',
  DownloadReport = 'download_report',
}

export const TABLE_COLUMNS = [
  {
    id: 'type',
    label: 'VETTING TYPE',
    sortable: false,
  },
  {
    id: 'partner',
    label: 'VETTING PARTNER',
    sortable: false,
  },
  {
    id: 'status',
    label: 'VETTING STATUS',
    sortable: false,
  },
  {
    id: 'date',
    label: 'DATE',
    sortable: false,
  },
  {
    id: 'outcome',
    label: 'OUTCOME',
    sortable: false,
  },
  {
    id: 'action',
    label: 'ACTION',
    sortable: false,
  },
];
