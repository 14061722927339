import React, { useState } from 'react';
import { Dialog, DialogContent, Grid, TextField } from '@material-ui/core';
import { S3_ASSETS_PATH } from '../../../../constants';
import PhoneInput, {
  formatPhoneNumberIntl,
  getCountryCallingCode,
} from 'react-phone-number-input';
import { fieldValidation } from '../../../../utils/validator';
import { toastFlashMessage, isValidInput } from '../../../../utils';
import regexExpressions from '../../../../constants/regexExpressions';
import { addNewResellerApi, updateResellerApi } from '../apiServices';
import ToolTip from '../../../../ui_elements/ToolTip';
import flags from 'react-phone-number-input/flags';

import { Box } from 'portal-commons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowsTurnRight } from '@fortawesome/pro-regular-svg-icons';

const AddResellerModal = (props) => {
  const [reseller, setReseller] = useState(
    props.reseller
      ? props.reseller
      : {
          companyName: '',
          email: '',
          phone: '',
        }
  );
  const [countryIsoCode, setCountryIsoCode] = useState('US');
  const [error, setError] = useState({});
  const [errorCode] = useState({
    companyName: {
      0: '',
      1: 'Please enter Legal Company Name',
      4: 'Invalid input',
    },
    companyNameObj: {
      requiredFlag: true,
      regexPattern: regexExpressions.alphaNumeric,
    },
    email: {
      0: '',
      1: 'Please enter Email Address',
      4: 'Invalid Email Address',
    },
    emailObj: {
      requiredFlag: true,
      regexPattern: regexExpressions.email,
    },
  });
  const [loader, setLoader] = useState(false);

  const handleChange = (value, key) => {
    setReseller({
      ...reseller,
      [key]: value,
    });
    setError({
      ...error,
      [key]: '',
    });
  };
  const handleError = (value, key) => {
    if (key == 'phone') {
      if (value) {
        setError({
          ...error,
          phone:
            countryIsoCode == 'IN'
              ? value.length > 13
                ? 'Maximum 10 numbers allowed'
                : ''
              : '',
        });
      } else {
        setError({
          ...error,
          phone: 'Please enter Phone Number',
        });
      }
    } else {
      setError({
        ...error,
        [key]:
          errorCode[key][
            fieldValidation({ ...errorCode[`${key}Obj`], fieldval: value })
          ],
      });
    }
  };
  const handleSubmit = (e) => {
    setLoader(true);
    e.preventDefault();
    const dialCode = getCountryCallingCode(countryIsoCode.toUpperCase());
    const validateNewInput = {
      companyName:
        errorCode.companyName[
          fieldValidation({
            ...errorCode.companyNameObj,
            fieldval: reseller.companyName,
          })
        ],
      email:
        errorCode.email[
          fieldValidation({
            ...errorCode.emailObj,
            fieldval: reseller.email,
          })
        ],
      phone: reseller.phone
        ? reseller.phone == `+${dialCode}`
          ? 'Please enter Mobile Number'
          : countryIsoCode == 'IN'
          ? reseller.phone.length > 13
            ? 'Maximum 10 numbers allowed'
            : ''
          : ''
        : 'Please enter Mobile Number',
    };
    if (
      Object.keys(validateNewInput).every((k) => {
        return validateNewInput[k] === '';
      })
    ) {
      if (
        Object.keys(reseller).every((k) =>
          reseller[k] ? isValidInput(reseller[k]) : true
        )
      ) {
        const formData = { ...reseller };
        formData.phone = formatPhoneNumberIntl(formData.phone);
        if (props.modalType == 'add') {
          addNewResellerApi(formData).then((response) => {
            setLoader(false);
            if (response.status >= 200 && response.status < 300) {
              const newReseller = response.data;
              toastFlashMessage('Reseller added successfully', 'success');
              props.handleModalClick(false, newReseller);
            }
          });
        } else {
          updateResellerApi(props.updateId, formData).then((response) => {
            setLoader(false);
            if (response && response.status >= 200 && response.status < 300) {
              toastFlashMessage('Reseller updated successfully', 'success');
              props.handleModalClick(false, null, true);
            }
          });
        }
      } else {
        Object.keys(reseller).forEach((k) => {
          if (!isValidInput(reseller[k])) {
            setLoader(false);
            setError({ ...error, [k]: 'Invalid input' });
          }
        });
      }
    } else {
      setLoader(false);
      setError(validateNewInput);
    }
  };
  return (
    <Dialog
      open={props.open}
      onClose={() => props.handleModalClick(false)}
      aria-labelledby="responsive-dialog-title"
      maxWidth="xs"
      fullWidth={true}
      id="successModal"
      className={`add-reseller-modal`}
      data-testid="addResellerModal"
    >
      <DialogContent className={`add-reseller-content text-center`}>
        <Grid container>
          <Grid item xs={12}>
            <div className="title text-center">
              <Box
                flexDirection="row"
                justifyContent="center"
                alignItems="center"
              >
                <Box margin={{ right: 's' }}>
                  <FontAwesomeIcon icon={faArrowsTurnRight} size="lg" />
                </Box>
                <h3 className="heading1">
                  {props.modalType == 'add'
                    ? 'Reseller Details'
                    : 'Edit Reseller Details'}
                </h3>
              </Box>
            </div>
          </Grid>
          <Grid item xs={12}>
            <div className="form-group-field">
              <TextField
                data-testid="addResellerModalCompanyNameInput"
                error={error.companyName ? true : false}
                value={reseller.companyName}
                id="companyName"
                required={true}
                fullWidth={true}
                label="Legal Company Name"
                autoComplete="off"
                // inputProps={{ 'aria-label': 'companyName', autocomplete: "new-password",
                // form: {
                //     autocomplete: "off",
                // } }}
                onChange={(event) =>
                  handleChange(event.target.value, 'companyName')
                }
                onBlur={(event) =>
                  handleError(event.target.value, 'companyName')
                }
              />
              <ToolTip title={'Type the legally registered company name'} />
              {error.companyName ? (
                <h6 className="error-msg">{error.companyName}</h6>
              ) : null}
            </div>
          </Grid>
          <Grid item xs={12}>
            <div className="form-group-field">
              <TextField
                data-testid="addResellerModalEmailInput"
                error={error.email ? true : false}
                value={reseller.email}
                id="email"
                required={true}
                fullWidth={true}
                label="Email Address"
                autoComplete="off"
                // inputProps={{ 'aria-label': 'companyName', autocomplete: "new-password",
                // form: {
                //     autocomplete: "off",
                // } }}
                onChange={(event) => handleChange(event.target.value, 'email')}
                onBlur={(event) => handleError(event.target.value, 'email')}
              />
              {error.email ? (
                <h6 className="error-msg">{error.email}</h6>
              ) : null}
            </div>
          </Grid>
          <Grid item xs={12}>
            <div className="form-group-field">
              <PhoneInput
                data-testid="addResellerModalPhoneInput"
                flags={flags}
                value={reseller.phone}
                international={true}
                country="US"
                countryOptions={['US', 'IT', 'IN', '|']}
                // defaultcountry="US"
                onChange={(telNumber) => handleChange(telNumber, 'phone')}
                limitMaxLength
                placeholder="Phone Number *"
                onCountryChange={(countryIsoCode) =>
                  setCountryIsoCode(countryIsoCode)
                }
                onBlur={(e) => handleError(e.target.value, 'phone')}
                inputClassName={error.phone ? 'error' : ''}
              />
              {error.phone ? (
                <h6 className="error-msg">{error.phone}</h6>
              ) : null}
            </div>
          </Grid>
          <Grid item xs={12} className="text-center">
            <ul className="list-inline">
              <li>
                <p>
                  <a
                    onClick={() => props.handleModalClick(false)}
                    className="secondary-btn"
                    data-testid="addResellerModalCancelButton"
                  >
                    Cancel
                  </a>
                </p>
              </li>
              <li className="form-group-field" style={{ margin: 0 }}>
                <input
                  type="submit"
                  disabled={
                    loader || Object.keys(error).find((k) => error[k] != '')
                      ? true
                      : false
                  }
                  onClick={handleSubmit}
                  data-testid="addResellerModalSaveButton"
                  value={loader ? 'Saving...' : 'Save'}
                />
              </li>
            </ul>
          </Grid>
        </Grid>
      </DialogContent>
    </Dialog>
  );
};
export default AddResellerModal;
