import React from 'react';
import Grid from '@material-ui/core/Grid';
import FilterRowItem from '../components/FilterRowItem';
import PropTypes from 'prop-types';

export default function FilterRow(props) {
  const {
    appliedFilterObj,
    filterObject,
    handleRemoveFilterClick,
    handleClearFilter,
    testId,
  } = props;
  return (
    <div className="filter-applied" data-testid={testId}>
      <ul className="list-unstyled flex-centered">
        {appliedFilterObj &&
          Object.keys(appliedFilterObj).map((key) => {
            return (
              <FilterRowItem
                key={key}
                filterLabel={filterObject[key].label}
                filterValue={
                  filterObject[key].options && filterObject[key].options.length
                    ? filterObject[key].options.find((item) => {
                        return item.value === appliedFilterObj[key];
                      })?.label
                    : appliedFilterObj[key]
                }
                handleRemoveFilterClick={handleRemoveFilterClick}
                name={key}
              />
            );
          })}
        {Object.keys(appliedFilterObj).length && (
          <li
            className="clear-filter"
            onClick={handleClearFilter}
            data-testid="filterRowClearAllButton"
          >
            Clear all filters
          </li>
        )}
      </ul>
    </div>
  );
}
FilterRow.propTypes = {
  appliedFilterObj: PropTypes.object.isRequired,
  filterObject: PropTypes.object.isRequired,
  handleRemoveFilterClick: PropTypes.func,
  handleClearFilter: PropTypes.func,
  testId: PropTypes.string,
};
