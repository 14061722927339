import { Flex } from 'portal-commons';
import { FunctionComponent, useEffect, useState } from 'react';

import {
  createUser,
  deleteUser,
  getBrandCount,
  getProfile,
  getRoles,
  getUsers,
  updateEmailPreferences,
  updateMfaEnabled,
  updateUser,
} from '../apis';
import AccountUsageSection from '../components/AccountUsageSection';
import CspDetailsSection from '../components/CspDetailsSection';
import EmailPreferencesEditModal from '../components/EmailPreferencesEditModal';
import EmailPreferencesSection from '../components/EmailPreferencesSection';
import EnablementsSection from '../components/EnablementsSection';
import MfaToggleModal from '../components/MfaToggleModal';
import ProfileBanner from '../components/ProfileBanner';
import UserCreateModal from '../components/UserCreateModal';
import UserDeleteModal from '../components/UserDeleteModal';
import UserEditModal from '../components/UserEditModal';
import UserManagementSection from '../components/UserManagementSection';
import {
  EmailPreferencesUpdatePayload,
  Profile,
  User,
  UserCreatePayload,
  UserUpdatePayload,
} from '../types';
import { Loader } from '../../../../shared_elements';
import { toastFlashMessage } from '../../../../utils';

interface ProfileManagementProps {
  role: string;
}

const ProfileManagement: FunctionComponent<ProfileManagementProps> = ({
  role,
}) => {
  const [loading, setLoading] = useState(true);
  const [profile, setProfile] = useState<Profile | undefined>();
  const [usersLoading, setUsersLoading] = useState(false);
  const [users, setUsers] = useState<User[] | undefined>();
  const [roles, setRoles] = useState<string[]>([]);
  const [brandCount, setBrandCount] = useState<number | undefined>();

  const [emailPreferencesEditing, setEmailPreferencesEditing] = useState(false);
  const [emailPreferencesUpdating, setEmailPreferencesUpdating] =
    useState(false);

  const [mfaToggling, setMfaToggling] = useState(false);
  const [mfaUpdating, setMfaUpdating] = useState(false);

  const [userCreating, setUserCreating] = useState(false);
  const [userEditing, setUserEditing] = useState(false);
  const [userDeleting, setUserDeleting] = useState(false);
  const [userUpdating, setUserUpdating] = useState(false);
  const [targetUser, setTargetUser] = useState<User | undefined>();

  useEffect(() => {
    (async () => {
      const profile = await getProfile();
      const users = await getUsers();
      const roles = await getRoles();
      const brandCount = await getBrandCount();
      setLoading(false);
      setProfile(profile);
      setUsers(users);
      setRoles(roles || []);
      setBrandCount(brandCount);
    })();
  }, []);

  const reloadProfile = async () => {
    setLoading(true);
    const profile = await getProfile();
    setProfile(profile);
    setLoading(false);
  };

  const reloadUsers = async () => {
    setUsersLoading(true);
    const users = await getUsers();
    setUsers(users);
    setUsersLoading(false);
  };

  const handleEmailPreferencesEdit = () => {
    setEmailPreferencesEditing(true);
  };

  const handleEmailPreferencesSubmit = async (
    data: EmailPreferencesUpdatePayload
  ) => {
    setEmailPreferencesUpdating(true);
    const response = await updateEmailPreferences({
      email: data.emails
        .map((value) => value.email)
        .filter((value) => !!value)
        .join(','),
      escalateEmail: data.escalateEmails
        .map((value) => value.email)
        .filter((value) => !!value)
        .join(','),
      financeEmail: data.financeEmails
        .map((value) => value.email)
        .filter((value) => !!value)
        .join(','),
      techEmail: data.techEmails
        .map((value) => value.email)
        .filter((value) => !!value)
        .join(','),
    });
    if (response) {
      toastFlashMessage('Email Address Successfully Added', 'success');
      setEmailPreferencesEditing(false);
      reloadProfile();
    }
    setEmailPreferencesUpdating(false);
  };

  const handleMfaToggle = () => {
    setMfaToggling(true);
  };

  const applyMfaToggle = async () => {
    setMfaUpdating(true);
    const response = await updateMfaEnabled(!profile?.mfaEnabled);
    if (response) {
      toastFlashMessage(
        `MFA ${
          profile?.mfaEnabled ? 'deactivated' : 'activated'
        } for all portal users`,
        'success'
      );
      setMfaToggling(false);
      reloadProfile();
    }
    setMfaUpdating(false);
  };

  const handleUserCreate = () => {
    setUserCreating(true);
  };

  const applyUserCreation = async (data: UserCreatePayload) => {
    setUserUpdating(true);
    const response = await createUser(data);
    if (response) {
      toastFlashMessage(
        `New User ${data.firstName} ${data.lastName} Created`,
        'success'
      );
      setUserCreating(false);
      reloadUsers();
    }
    setUserUpdating(false);
  };

  const handleUserEdit = (user: User) => {
    setUserEditing(true);
    setTargetUser(user);
  };

  const applyUserUpdate = async (data: UserUpdatePayload) => {
    setUserUpdating(true);
    const response = await updateUser(targetUser!.id, data);
    if (response) {
      toastFlashMessage(
        `User ${targetUser!.profile.fullName} Edit Successful`,
        'success'
      );
      setUserEditing(false);
      // TCR-12068: Fix delay update for user data on Okta side
      setUsersLoading(true);
      await new Promise((resolve) => {
        setTimeout(resolve, 2000);
      });
      reloadUsers();
    }
    setUserUpdating(false);
  };

  const handleUserDelete = (user: User) => {
    setUserDeleting(true);
    setTargetUser(user);
  };

  const applyUserDelete = async () => {
    setUserUpdating(true);
    const response = await deleteUser(targetUser!.id);
    if (response) {
      toastFlashMessage(
        `User ${targetUser!.profile.fullName} Deleted`,
        'success'
      );
      setUserDeleting(false);
      reloadUsers();
    }
    setUserUpdating(false);
  };

  return loading ? (
    <Loader />
  ) : (
    <Flex sx={{ flexDirection: 'column', rowGap: '32px' }}>
      <ProfileBanner
        companyName={profile?.companyName}
        displayName={profile?.displayName}
        uid={profile?.uid}
        entityType={profile?.entityType}
      />
      <CspDetailsSection
        street={profile?.street}
        ein={profile?.ein}
        city={profile?.city}
        website={profile?.website}
        state={profile?.state}
        stockSymbol={profile?.stockSymbol}
        postalCode={profile?.postalCode}
        stockExchange={profile?.stockExchange}
        country={profile?.country}
        createDate={profile?.createDate}
      />
      <EmailPreferencesSection
        role={role}
        emails={profile?.emails}
        escalateEmails={profile?.escalateEmails}
        financeEmails={profile?.financeEmails}
        techEmails={profile?.techEmails}
        onEdit={handleEmailPreferencesEdit}
      />
      <EnablementsSection
        platformFreeTrialEnabled={profile?.platformFreeTrialEnabled}
        ucaasEnabled={profile?.ucaasEnabled}
        soleProprietorEnabled={profile?.soleProprietorEnabled}
        dpaSigned={profile?.dpaSigned}
      />
      <AccountUsageSection
        currentPortalUsers={users?.length}
        maxPortalUsers={profile?.maxPortalUsers}
        maxCampaignsPerBrand={profile?.maxCampaignsPerBrand}
        currentBrands={brandCount}
        maxBrands={profile?.maxBrands}
      />
      <UserManagementSection
        role={role}
        loading={usersLoading}
        mfaEnabled={profile?.mfaEnabled}
        users={users}
        maxPortalUsers={profile?.maxPortalUsers}
        onMfaToggle={handleMfaToggle}
        onCreate={handleUserCreate}
        onEdit={handleUserEdit}
        onDelete={handleUserDelete}
      />
      <EmailPreferencesEditModal
        open={emailPreferencesEditing}
        disabled={emailPreferencesUpdating}
        emails={profile?.emails ?? []}
        escalateEmails={profile?.escalateEmails ?? []}
        financeEmails={profile?.financeEmails ?? []}
        techEmails={profile?.techEmails ?? []}
        onClose={() => setEmailPreferencesEditing(false)}
        onSubmit={handleEmailPreferencesSubmit}
      />
      <MfaToggleModal
        open={mfaToggling}
        disabled={mfaUpdating}
        on={!profile?.mfaEnabled}
        onClose={() => setMfaToggling(false)}
        onSubmit={applyMfaToggle}
      />
      <UserCreateModal
        open={userCreating}
        disabled={userUpdating}
        roles={roles}
        onClose={() => setUserCreating(false)}
        onSubmit={applyUserCreation}
      />
      {targetUser && (
        <>
          <UserEditModal
            open={userEditing}
            disabled={userUpdating}
            user={targetUser}
            roles={roles}
            onClose={() => setUserEditing(false)}
            onSubmit={applyUserUpdate}
          />
          <UserDeleteModal
            open={userDeleting}
            disabled={userUpdating}
            user={targetUser}
            onClose={() => setUserDeleting(false)}
            onSubmit={applyUserDelete}
          />
        </>
      )}
    </Flex>
  );
};

export default ProfileManagement;
