import React from 'react';
import BrandDetailItem from '../BrandDetailItem';
import { Grid } from '@material-ui/core';
import { BrandDetail } from '../../types';

interface Props {
  brand: BrandDetail;
}
const BrandContactDetails: React.FC<Props> = (props: Props) => {
  const { brand } = props;
  return (
    <Grid container data-testid="brandContactDetails">
      <Grid item xs={6}>
        <BrandDetailItem
          xs={5}
          title="Support Email Address"
          value={brand.email}
        />
      </Grid>
      <Grid item xs={6}>
        <BrandDetailItem
          xs={5}
          title="Support Phone Number"
          value={brand.phone}
        />
      </Grid>
    </Grid>
  );
};

export default BrandContactDetails;
