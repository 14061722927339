import { Fragment, FunctionComponent } from 'react';
import styled from 'styled-components';
import { IconDefinition } from '@fortawesome/pro-regular-svg-icons';

import Subsection from './Subsection';

interface EmailPreferencesSubsectionProps {
  icon: IconDefinition;
  title: string;
  description: string;
  emails: string[];
  testId?: string;
}

const Content = styled.div`
  display: grid;
  grid-template-columns: 164px 1fr 164px 1fr;
  row-gap: 12px;
  padding-top: 12px;
  border-top: 1px solid #cccfd0;
  font-family: Roboto, sans-serif;
`;

const Field = styled.span`
  font-weight: 400;
  font-size: 14px;
  line-height: 16px;
  color: #4d5659;
`;

const Value = styled.span`
  font-weight: 800;
  font-size: 14px;
  line-height: 16px;
  color: #4d5659;
  overflow: hidden;
  text-overflow: ellipsis;
`;

const EmailPreferencesSubsection: FunctionComponent<
  EmailPreferencesSubsectionProps
> = ({ icon, title, description, emails, testId }) => {
  return (
    <Subsection
      icon={icon}
      title={title}
      description={description}
      testId={testId}
    >
      <Content>
        {emails.map((email, index) => (
          <Fragment key={index}>
            <Field>Email Address:</Field>
            <Value data-testid="email">{email}</Value>
          </Fragment>
        ))}
        {emails.length < 1 && (
          <>
            <Field>Email Address:</Field>
            <Value data-testid="email">N/A</Value>
          </>
        )}
      </Content>
    </Subsection>
  );
};

export default EmailPreferencesSubsection;
