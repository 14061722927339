import { isValidInput as validateInput } from '../../../utils';

export const isRequired = (value?: string) => !!value?.length || 'Required';
export const isValidInput = (value?: string) =>
  validateInput(value?.trim() || '') || 'Invalid input';

export const createMinLengthValidator =
  (minLength: number) => (value?: string) =>
    (value?.length ?? 0) >= minLength || `Minimum ${minLength} chars`;
export const createMaxLengthValidator =
  (maxLength: number) => (value?: string) =>
    (value?.length ?? 0) <= maxLength ||
    `Maximum ${maxLength} chars are allowed`;
