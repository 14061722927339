import React, { Component } from 'react';
import OktaAuth from '@okta/okta-auth-js';
import { withOktaAuth } from '@okta/okta-react';
import { OKTA_ISSUER_URL } from './constants';
import { Link } from 'react-router-dom';
import { Loader } from './shared_elements';

export default withOktaAuth(
  class Protected extends Component {
    constructor(props) {
      super(props);
      this.state = {
        sessionToken: null,
      };
      this.oktaAuth = new OktaAuth({ issuer: OKTA_ISSUER_URL });
    }

    signOut = () => {
      localStorage.clear();
      this.props.history.push('/login');
    };

    render() {
      return (
        <section>
          {/* <h1 className="text-center" style={{marginTop: '80px', marginBottom: '30px'}}>Welcome to Campaing Registry</h1>
        <p className="text-center"> <Link><span onClick={this.signOut}>Sign out</span></Link></p> */}
          <Loader hocFlag />
        </section>
      );
    }
  }
);
