import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Button, Grid } from '@material-ui/core';
import SampleMultimediaList from './SampleMultimediaList';
import AttachmentPreview from './AttachmentPreview';
import { ATTACHMENT_MIME_TYPES } from '../constants';

const EditSampleMultimedia = ({
  sampleMultimedia,
  onAdd,
  onDelete,
  onSelect,
  onDownload,
}) => {
  const [selectedIndex, setSelectedIndex] = useState(-1);

  const handleSelect = (index) => {
    setSelectedIndex(index);
    onSelect && onSelect(index);
  };

  return (
    <Grid container spacing={4} data-testid="editSampleMultimedia">
      <Grid item xs={6}>
        <Button
          variant="contained"
          component="label"
          data-testid="editSampleMultimediaUploadButton"
        >
          Upload File
          <input
            hidden
            type="file"
            accept={ATTACHMENT_MIME_TYPES.join(',')}
            onChange={(e) => {
              onAdd && onAdd(e.target.files[0]);
            }}
          />
        </Button>
        {sampleMultimedia[selectedIndex] &&
          sampleMultimedia[selectedIndex].file && (
            <AttachmentPreview
              file={sampleMultimedia[selectedIndex].file}
              mimeType={sampleMultimedia[selectedIndex].mimeType}
            />
          )}
      </Grid>
      <Grid item xs={6}>
        <SampleMultimediaList
          editable
          attachments={sampleMultimedia}
          selectedIndex={selectedIndex}
          onSelect={handleSelect}
          onDelete={onDelete}
          onDownload={onDownload}
        />
      </Grid>
    </Grid>
  );
};

EditSampleMultimedia.propTypes = {
  sampleMultimedia: PropTypes.arrayOf(
    PropTypes.shape({
      uuid: PropTypes.string.isRequired,
      fileName: PropTypes.string.isRequired,
      mimeType: PropTypes.string.isRequired,
    })
  ).isRequired,
  onAdd: PropTypes.func,
  onDelete: PropTypes.func,
  onSelect: PropTypes.func,
};

export default EditSampleMultimedia;
