import {
  Dialog,
  DialogContent,
  DialogTitle,
  makeStyles,
} from '@material-ui/core';
import { Button } from 'portal-commons';

const useStyles = makeStyles({
  container: {
    width: '525px',
    padding: '28px 20px 36px 20px',
    marginLeft: '200px',
  },
  title: {
    padding: '0',
    '& > *': {
      textAlign: 'center',
      fontWeight: 500,
      fontSize: '18px',
      lineHeight: '21px',
      color: '#071822',
    },
  },
  content: {
    display: 'flex',
    flexDirection: 'column',
    padding: '0',
    marginTop: '26px',
  },
  message: {
    fontFamily: 'Roboto',
    fontWeight: 400,
    fontSize: '16px',
    lineHeight: '19px',
    textAlign: 'center',
    color: '#49535D',
  },
  bold: {
    fontWeight: 700,
  },
  actions: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
    gap: '18px',
    marginTop: '28px',
  },
});

interface Props {
  open: boolean;
  disabled: boolean;
  onClose: () => void;
  onSubmit: () => void;
}

const AppealRequestConfirmationModal: React.FC<Props> = ({
  open,
  disabled,
  onClose,
  onSubmit,
}) => {
  const classes = useStyles();

  return (
    <Dialog
      open={open}
      classes={{
        paper: classes.container,
      }}
      data-testid="appealRequestConfirmationModal"
    >
      <DialogTitle
        classes={{
          root: classes.title,
        }}
        data-testid="appealRequestConfirmationModalTitle"
      >
        Appeal Request Confirmation
      </DialogTitle>

      <DialogContent
        classes={{
          root: classes.content,
        }}
      >
        <div className={classes.message}>
          This appeal will <span className={classes.bold}>cost $10</span>{' '}
          regardless of the outcome.
          <br />
          <br />
          Do you still want to continue?
        </div>

        <div className={classes.actions}>
          <Button
            portal="csp"
            variant="outline"
            onClick={onClose}
            data-testid="appealRequestConfirmationModalCancelButton"
          >
            Cancel
          </Button>
          <Button
            portal="csp"
            disabled={disabled}
            onClick={onSubmit}
            data-testid="appealRequestConfirmationModalConfirmButton"
          >
            Confirm Appeal
          </Button>
        </div>
      </DialogContent>
    </Dialog>
  );
};

export default AppealRequestConfirmationModal;
