import React, { useCallback, Fragment, useState, useMemo } from 'react';
import { Container, Grid, Menu, MenuItem, Fade } from '@material-ui/core';
import { S3_ASSETS_PATH } from '../../constants';
import { Link, withRouter } from 'react-router-dom';
import { withOktaAuth } from '@okta/okta-react';
import { isMobile } from 'react-device-detect';
import { Box, TopBar } from 'portal-commons';

const Header = ({ role, history, title, note, testId, oktaAuth }) => {
  const [flag, setFlag] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);
  const active = localStorage.getItem('cspStatus')
    ? localStorage.getItem('cspStatus') === 'active'
      ? true
      : false
    : false;

  const handleLogout = useCallback(() => {
    localStorage.clear();
    sessionStorage.setItem('logoutSuccess', true);
    oktaAuth.signOut('/login');
  }, [oktaAuth]);

  const menus = useMemo(() => {
    const menuItems = [
      active && role !== 'ROLE_REVIEWER' ? (
        <TopBar.MenuItem onClick={() => history.push('/profile')}>
          View CSP Profile
        </TopBar.MenuItem>
      ) : null,
      <TopBar.MenuItem onClick={handleLogout}>Logout</TopBar.MenuItem>,
    ].filter(Boolean);
    return menuItems;
  }, [role, history, active, handleLogout]);

  const userName = localStorage.getItem('okta-token-storage')
    ? JSON.parse(localStorage.getItem('okta-token-storage')).idToken
      ? JSON.parse(localStorage.getItem('okta-token-storage')).idToken.claims
          .name
      : localStorage.getItem('userInfo')
      ? JSON.parse(localStorage.getItem('userInfo')).name
      : ''
    : '';

  const handleClick = (event) => {
    const anchorEl = event.currentTarget;
    setFlag((prevState) => !prevState);
    setAnchorEl(anchorEl);
  };

  const handleClose = () => {
    setFlag((prevState) => !prevState);
    setAnchorEl(null);
  };

  if (!isMobile) {
    return (
      <TopBar
        authenticated={!!userName}
        portal="csp"
        username={userName}
        title={title}
        subText={note}
        menus={menus}
      />
    );
  }

  if (isMobile) {
    return (
      <Fragment>
        <header data-testid={testId}>
          <Container maxWidth={false} className="flex-centered">
            <Grid
              container
              spacing={0}
              alignItems="center"
              className="flex-centered"
            >
              {isMobile && (
                <Grid
                  item
                  lg={2}
                  xs={isMobile ? 12 : 2}
                  style={isMobile ? { height: '100%' } : {}}
                >
                  {isMobile && (
                    <div className="header-logo">
                      <Link to="/" data-testid="headerLogoLink">
                        <img
                          src={`${S3_ASSETS_PATH}/images/logo/sideNavLogo.svg`}
                          alt="logo"
                        />
                      </Link>
                    </div>
                  )}
                </Grid>
              )}
              {userName ? (
                <Grid item lg={4} className="user-dropdown">
                  <Box
                    aria-controls="fade-menu"
                    onClick={handleClick}
                    data-testid="headerNavMenu"
                    justifyContent="center"
                    alignItems="center"
                    className={`header-nav-menu ${anchorEl ? 'active' : ''}`}
                  >
                    <Box
                      margin={{ right: 'xxs' }}
                      justifyContent="center"
                      alignItems="center"
                      flexDirection="row"
                    >
                      <img src={`${S3_ASSETS_PATH}/images/wing.svg`} />
                    </Box>
                    <span>{userName}</span>
                  </Box>
                  <Menu
                    id="fade-menu"
                    anchorEl={anchorEl}
                    keepMounted
                    open={flag}
                    onClose={handleClose}
                    TransitionComponent={Fade}
                    className="header-drop-down"
                    // classes={{root : styles.root}}
                  >
                    {role !== 'ROLE_REVIEWER' ? (
                      active ? (
                        <MenuItem
                          className="menu-item"
                          onClick={() => history.push('/profile')}
                          data-testid="headerNavMenuProfileLink"
                        >
                          View CSP Profile
                        </MenuItem>
                      ) : null
                    ) : null}
                    <MenuItem
                      onClick={handleLogout}
                      data-testid="headerNavMenuLogoutLink"
                    >
                      Logout
                    </MenuItem>
                  </Menu>
                </Grid>
              ) : (
                ''
              )}
            </Grid>
          </Container>
        </header>
      </Fragment>
    );
  }
};

export default withRouter(withOktaAuth(Header));
