import * as actions from './actions';

export default function (
  state = {
    stripeKey: '',
    activationFee: {},
    paymentLoader: false,
    appealCategories: [],
    vettingSettings: [],
  },
  action
) {
  switch (action.type) {
    case actions.STRIPE_KEY:
      return {
        ...state,
        stripeKey: action.payload,
      };
    case actions.ACTIVATION_FEE:
      return {
        ...state,
        activationFee: action.payload,
      };
    case actions.PAYMENT_LOADER:
      return {
        ...state,
        paymentLoader: action.payload,
      };
    case actions.SET_APPEAL_CATEGORIES:
      return {
        ...state,
        appealCategories: action.payload.map((item) => ({
          ...item,
          appealCategoryId: item.id,
        })),
      };
    case actions.SET_VETTING_SETTINGS:
      return {
        ...state,
        vettingSettings: action.payload,
      };
    default:
      return state;
  }
}
