import { globalGetService } from '../../../utils/globalApiServices';

export function getBrandStatistics() {
  globalGetService('csp/statistics/brand?excludeSoleProprietor=false').then(
    (response) => {
      // debugger
      // console.log('campaignStats', response)
      if (response && response.status >= 200 && response.status < 300) {
        this.setState((prevState) => ({
          ...prevState,
          brandInfo: response.data,
        }));
      }
    }
  );
}
export function getCampaignStatistics() {
  globalGetService('csp/statistics/campaign').then((response) => {
    // console.log('campaignStats', response)
    if (response && response.status >= 200 && response.status < 300) {
      this.setState((prevState) => ({
        ...prevState,
        campaignInfo: response.data,
      }));
    }
  });
}
export function getPartnerCampaignStatistics() {
  globalGetService(`csp/campaigns/sharedWithMe`, {
    actionableOnly: true,
    status: 'ACTIVE',
  }).then((response) => {
    if (response.status >= 200 && response.status < 300) {
      this.setState({
        partnerCampaignInfo: { total: response.data.totalRecords },
      });
    }
  });
}
export function getCampaignRequestStatistics(
  data = { numUnits: '10', unit: 'year' }
) {
  globalGetService('csp/statistics/campaignRequest', data).then((response) => {
    // console.log('campaignRequestStats', response)
    if (response && response.status >= 200 && response.status < 300) {
      this.setState((prevState) => ({
        ...prevState,
        campaignRequestInfo: response.data,
      }));
    }
  });
}
export function getCampaignTopUseCase() {
  globalGetService('csp/statistics/campaign/topUseCase').then((response) => {
    if (response && response.status >= 200 && response.status < 300) {
      const useCaseInfo = response.data;
      this.setState((prevState) => ({
        ...prevState,
        useCaseInfo,
        tableLoader: {
          ...prevState.tableLoader,
          usecase: false,
        },
      }));
    }
  });
}
export function getEventHistory(query = {}) {
  globalGetService('csp/event', { ...query, recordsPerPage: 20 }).then(
    (response) => {
      // console.log('eventHistory', response)
      if (response && response.status >= 200 && response.status < 300) {
        this.setState((prevState) => ({
          ...prevState,
          eventInfo: response.data,
          tableLoader: {
            ...prevState.tableLoader,
            event: false,
          },
        }));
      }
    }
  );
}

export function getCampaignTopVertical() {
  globalGetService('csp/statistics/campaign/topVertical').then((response) => {
    if (response && response.status >= 200 && response.status < 300) {
      const verticalInfo = response.data;
      this.setState((prevState) => ({
        ...prevState,
        verticalInfo,
        tableLoader: {
          ...prevState.tableLoader,
          vertical: false,
        },
      }));
    }
  });
}

export async function getCampaignDetail(campaignUid) {
  const response = await globalGetService(`csp/campaigns/${campaignUid}`);
  if (response.status >= 200 && response.status < 300) {
    return response.data;
  }
  return null;
}

export async function getCampaignByPhone(phoneNumber) {
  const response = await globalGetService(
    `csp/campaigns/byPhone/${phoneNumber}`
  );
  if (response.status >= 200 && response.status < 300) {
    return response.data;
  }
  return null;
}
