import React from 'react';
import { Grid, makeStyles, TextField } from '@material-ui/core';
import { S3_ASSETS_PATH } from '../../../../constants';
import { charCounter } from '../utils';

import { Box } from 'portal-commons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTrash } from '@fortawesome/pro-regular-svg-icons';

const useStyles = makeStyles({
  input: {
    '& .MuiFormHelperText-root': {
      textAlign: 'right',
    },
  },
});

export default function EditSampleMessages(props) {
  const { sampleMessage = {}, error, sampleMessageCount } = props;
  const messageCount = Object.keys(sampleMessage).length;
  const classes = useStyles();

  return (
    <div className="sample-message-wrapper" data-testid="editSampleMessages">
      {Object.keys(sampleMessage).map((key, index) => (
        <div className="message-item" key={key}>
          <div className="form-group-field">
            <label>
              <Box flexDirection="row" alignItems="center">
                <span>{`Sample message ${index + 1}`}</span>
                {sampleMessageCount <= index && (
                  <Box margin={{ left: 'xs' }}>
                    <FontAwesomeIcon
                      icon={faTrash}
                      data-testid="editSampleMessagesDeleteButton"
                      onClick={(e) => {
                        e.stopPropagation();
                        props.handleDeleteSampleMessage(`sample${index + 1}`);
                      }}
                      color="#0091B3"
                      style={{ cursor: 'pointer' }}
                    />
                  </Box>
                )}
              </Box>
            </label>
            <TextField
              classes={{ root: classes.input }}
              data-testid="editSampleMessagesDescriptionInput"
              inputProps={{
                maxLength: 1024,
                minLength: 20,
                'aria-label': 'description',
              }}
              fullWidth
              multiline
              error={!!error[key]}
              rows={2}
              rowsMax={3}
              id="description"
              value={sampleMessage[key]}
              helperText={charCounter({
                value: sampleMessage[key],
                maxLength: 1024,
              })}
              onChange={(event) => props.handleChange(event.target.value, key)}
              onBlur={(e) => props.handleError(e.target.value, key)}
            />
            {error[key] ? <h6 className="error-msg">{error[key]}</h6> : null}
          </div>
        </div>
      ))}
      {messageCount < 5 && (
        <div className="add-message-btn">
          <p>
            <a
              className=""
              onClick={props.handleAddSampleMessage}
              data-testid="editSampleMessagesAddSampleMessageButton"
            >
              + Add Sample Message
            </a>
          </p>
        </div>
      )}
    </div>
  );
}
