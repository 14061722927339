import React, { Component } from 'react';
import Grid from '@material-ui/core/Grid';
import { Header } from '../../../shared_elements';
import { ResetEmailSuccess, RegisterSuccess } from '../components';
import { withRouter } from 'react-router-dom';
import '../../../assets/styles/user-authentication-module.scss';

class UserAuthThanks extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  componentDidMount() {
    document.title = 'The Campaign Registry - Welcome to the Campaign Registry';
  }

  render() {
    return (
      <section
        className="user-authentication-section"
        style={{ background: '#004f5f' }}
      >
        <Header
          title="WELCOME TO THE CAMPAIGN REGISTRY"
          path={this.props.location.pathname}
          testId="userAuthThanksHeader"
        ></Header>
        <Grid
          container
          spacing={0}
          alignItems="center"
          justify="center"
          className="user-form-wrapper auth-thanks"
          style={{ height: window.innerHeight - 60 }}
        >
          {this.props.match.params.type === 'create' ? (
            <RegisterSuccess />
          ) : (
            <ResetEmailSuccess />
          )}
        </Grid>
      </section>
    );
  }
}
export default withRouter(UserAuthThanks);
