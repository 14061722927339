import React, { useState } from 'react';
import { Dialog, Grid, makeStyles, TextField } from '@material-ui/core';
import { CSPErrorCode } from '../../../index';
import { fieldValidation } from '../../../../../utils/validator';

const useStyles = makeStyles(() => ({
  root: {},
  container: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    gap: 20,
    padding: '50px 30px 30px 30px',
    background: '#FFFFFF',
  },
  prompt: {
    fontSize: 14,
    lineHeight: '16px',
    fontWeight: 500,
    color: '#68737A',
    textAlign: 'center',
  },
  column: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  row: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
  },
}));
interface Props {
  open: boolean;
  onClose: () => void;
  onSubmit: (_value: string) => void;
}
const EnterOtpModal: React.FC<Props> = ({ open, onClose, onSubmit }) => {
  const classes = useStyles();
  const [otp, setOtp] = useState('');
  const [error, setError] = useState('');

  const validateInput = () => {
    const validNum = fieldValidation({
      ...CSPErrorCode.otpPinObj,
      fieldval: otp,
    });
    return CSPErrorCode.otpPin[validNum];
  };

  const handleOtpChange = (event: React.ChangeEvent<{ value: unknown }>) => {
    setOtp(event.target.value as string);
    setError('');
  };

  const handleOtpBlur = () => {
    const checkError = validateInput();
    setError(checkError);
  };

  const handleClose = () => {
    onClose && onClose();
    setOtp('');
    setError('');
  };

  const handleSubmit = () => {
    const checkError = validateInput();
    if (checkError) {
      setError(checkError);
    } else {
      onSubmit && onSubmit(otp);
      handleClose();
    }
  };

  return (
    <Dialog
      classes={{
        root: classes.root,
        paper: classes.container,
      }}
      open={open}
      data-testid="enterOtpModal"
    >
      <div className={classes.column} style={{ width: '100%' }}>
        <div className={classes.prompt}>
          Please enter the OTP Pin you received from the end user
        </div>
        <div
          className="form-group-field"
          style={{ width: '100%', marginTop: 20 }}
        >
          <TextField
            label="Type OTP Pin here"
            value={otp}
            error={!!error}
            onChange={handleOtpChange}
            onBlur={handleOtpBlur}
            inputProps={{
              'data-testid': 'enterOtpModalInput',
            }}
          />
          {error && (
            <h6 className="error-msg" data-testid="enterOtpModalErrorMessage">
              {error}
            </h6>
          )}
        </div>
      </div>

      <div className={`otp ${classes.row}`}>
        <Grid item className="modal-footer">
          <ul className="list-inline text-center">
            <li>
              <a
                className="secondary-btn"
                onClick={handleClose}
                data-testid="enterOtpModalCancelButton"
              >
                Cancel
              </a>
            </li>
            <li style={{ marginLeft: 20 }}>
              <div className="form-group-field">
                <input
                  type="submit"
                  disabled={!otp || Boolean(error)}
                  className="primary-btn"
                  value="Submit"
                  onClick={handleSubmit}
                  data-testid="enterOtpModalSubmitButton"
                />
              </div>
            </li>
          </ul>
        </Grid>
      </div>
    </Dialog>
  );
};

export default EnterOtpModal;
