import React from 'react';
import { Grid } from '@material-ui/core';
import { ENTITY_TYPES } from '../../../../../constants';
import BrandDetailItem from '../BrandDetailItem';
import { BrandDetail } from '../../types';
import { convertTimeWithTimezone } from '../../../../../utils/time';

interface Props {
  brand: BrandDetail;
}

const BrandCompanyDetails: React.FC<Props> = (props: Props) => {
  const { brand } = props;
  const brandEntityTypeLabel =
    brand.entityType &&
    ENTITY_TYPES.find((entity) => entity.value === brand.entityType)?.label;

  return (
    <Grid container data-testid="brandCompanyDetails">
      <Grid item xs={6}>
        <BrandDetailItem
          xs={5}
          title="Brand Name or DBA"
          value={brand.displayName}
        />
        <BrandDetailItem
          xs={5}
          title="Entity Type"
          value={
            brand.entityType ? brandEntityTypeLabel || brand.entityType : ''
          }
        />
        <BrandDetailItem
          xs={5}
          title={`${brand.country === 'US' ? 'EIN' : 'Tax Number/ID'}`}
          value={brand.ein}
        />
        <BrandDetailItem
          xs={5}
          title={
            brand.country === 'US'
              ? 'EIN Issuing Country'
              : brand.country
              ? 'Tax Number/ID Issuing Country'
              : 'Tax Number/ID/EIN Issuing Country'
          }
          value={brand.einIssuingCountry}
        />
        <BrandDetailItem
          xs={5}
          title={`${brand.altBusinessIdType || 'DUNS or GIIN or LEI'} Number`}
          value={brand.altBusinessId}
        />
        <BrandDetailItem xs={5} title="Vertical" value={brand.vertical} />
        <BrandDetailItem
          xs={5}
          title="Stock Symbol"
          value={brand.stockSymbol}
        />
        <BrandDetailItem
          xs={5}
          title="Stock Exchange"
          value={brand.stockExchange}
        />
        <BrandDetailItem xs={5} title="First Name" value={brand.firstName} />
        <BrandDetailItem
          xs={5}
          title="Registered on"
          value={convertTimeWithTimezone(
            brand.createDate,
            undefined,
            'MM/DD/YYYY'
          )}
        />
      </Grid>
      <Grid item xs={6}>
        <BrandDetailItem xs={5} title="Address/Street" value={brand.street} />
        <BrandDetailItem xs={5} title="City" value={brand.city} />
        <BrandDetailItem xs={5} title="State" value={brand.state} />
        <BrandDetailItem xs={5} title="Postal Code" value={brand.postalCode} />
        <BrandDetailItem xs={5} title="Country" value={brand.country} />
        <BrandDetailItem
          xs={5}
          title="Website/Online Presence"
          value={brand.website}
        />
        <BrandDetailItem
          xs={5}
          title="Reference ID"
          value={brand.referenceId}
        />
        <BrandDetailItem
          xs={5}
          title="Mobile Phone"
          value={brand.mobilePhone}
        />
        <BrandDetailItem xs={5} title="Last Name" value={brand.lastName} />
        <BrandDetailItem
          xs={5}
          title="OTP Confirmation Date"
          value={
            brand.mobilePhoneVerifiedDate &&
            convertTimeWithTimezone(
              brand.mobilePhoneVerifiedDate,
              undefined,
              'MM/DD/YYYY'
            )
          }
        />
      </Grid>
    </Grid>
  );
};

export default BrandCompanyDetails;
