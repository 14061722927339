import React from 'react';
import PropTypes from 'prop-types';
import {
  Button,
  Dialog,
  DialogContent,
  Grid,
  makeStyles,
} from '@material-ui/core';

const useStyles = makeStyles({
  container: {},
  content: {
    width: 387,
    padding: '30px 20px !important',
    textAlign: 'center',
    boxSizing: 'border-box',
  },
});

const UploadSizeExceededModal = ({ open, onClose }) => {
  const classes = useStyles();

  return (
    <Dialog
      classes={{ container: classes.container }}
      open={open}
      onClose={onClose}
      data-testid="uploadSizeExceededModal"
    >
      <DialogContent classes={{ root: classes.content }}>
        <Grid container>
          <Grid className="title" item xs={12}>
            <p className="paragraph" style={{ lineHeight: '20px' }}>
              The uploaded file
              <br />
              <b>exceeds</b> the upload file size directive
            </p>
          </Grid>
          <Grid
            style={{ marginTop: 20 }}
            container
            direction="row"
            justifyContent="center"
            xs={12}
            data-testid="uploadSizeExceededModalCloseButton"
          >
            <a className="primary-btn" style={{ width: 80 }} onClick={onClose}>
              OK
            </a>
          </Grid>
        </Grid>
      </DialogContent>
    </Dialog>
  );
};

UploadSizeExceededModal.propTypes = {
  open: PropTypes.bool,
  onClose: PropTypes.func,
};

export default UploadSizeExceededModal;
