import React from 'react';

export const ROLES: { [key: string]: string } = {
  REVIEWER: 'ROLE_REVIEWER',
  USER: 'ROLE_USER',
  MANAGER: 'ROLE_MANAGER',
};

export const userCreateRoletooltipTemplate = (
  <>
    <p>Reviewer: View only access and cannot view Integrations information</p>
    <p>User: View and edit access for all components</p>
    <p>Manager: View and edit access for all components and users</p>
  </>
);

export const MAX_EMAIL_COUNT = 5;
export const MAX_EMAIL_LENGTH = 100;
