import React from 'react';
import { S3_ASSETS_PATH } from '../../../constants';
import { Grid } from '@material-ui/core';

const RegisterSuccess = (props) => {
  return (
    <Grid item className="campaign-registry">
      <div className="content-blk">
        <img src={`${S3_ASSETS_PATH}/images/thankyou.svg`} alt="logo"></img>
        <h4 className="heading1">Account Creation Email Sent</h4>
        <p className="heading1 text">
          An account confirmation email has been sent to the registered email
          address.
          <br />
          <br /> Please follow the instructions to set your Campaign Registry
          account password.
        </p>
        <p className="spam text-center">
          Please check your spam folder if you do not see the email in your
          inbox.
        </p>
      </div>
    </Grid>
  );
};

export default RegisterSuccess;
