import { Grid } from '@material-ui/core';
import { S3_ASSETS_PATH } from '../../../../../constants';
import BrandSuspendedStatus from '../BrandSuspendedStatus';
import { MNOSuspendState } from '../../types';

import { Box } from 'portal-commons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSquareMinus } from '@fortawesome/pro-regular-svg-icons';

interface Props {
  mnos: MNOSuspendState[];
}

const BrandSuspendedStatusBlock: React.FC<Props> = ({ mnos }) => {
  return (
    <Grid item xs={12} data-testid="brandSuspendedStatusBlock">
      <div className="vetting details">
        <div
          className="details-heading"
          style={{
            paddingBottom: 18,
            borderBottom: '1px solid #C0CCD4',
          }}
        >
          <div
            style={{
              display: 'flex',
              flexDirection: 'row',
              alignItems: 'center',
              gap: 6,
            }}
          >
            <Box flexDirection="row" alignItems="center">
              <Box margin={{ right: 'xs' }}>
                <FontAwesomeIcon icon={faSquareMinus} size="xl" />
              </Box>
              <h3 className="heading1">Brand Suspended</h3>
            </Box>
          </div>
        </div>
        <BrandSuspendedStatus mnos={mnos} />
      </div>
    </Grid>
  );
};

export default BrandSuspendedStatusBlock;
