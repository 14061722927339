import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import {
  Box,
  Container,
  Slide,
  Button,
  Backdrop,
  Typography,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';

const useStyles = makeStyles({
  container: {
    padding: 10,
    border: '1px dashed gray',
  },
  previewImg: {
    color: 'white',
  },
  hidden: {
    display: 'none',
  },
  modal: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    width: 430,
    height: 110,
    background: '#FFFFFF',
  },
});

const AttachmentPreview = ({ file, mimeType }) => {
  const classes = useStyles();
  const [content, setContent] = useState('');
  const [hasError, setHasError] = useState(false);
  const isDocxFile =
    mimeType ===
    'application/vnd.openxmlformats-officedocument.wordprocessingml.document';

  useEffect(() => {
    if (isDocxFile) {
      setHasError(true);
    }
    if (mimeType.startsWith('text/')) {
      const reader = new FileReader();
      reader.onload = (e) => {
        if (e.target.readyState !== 2) {
          return;
        }
        if (e.target.error) {
          return;
        }
        setContent(String(e.target.result));
      };
      reader.readAsText(file);
    }
  }, [file, mimeType]);

  return (
    <Box
      display="flex"
      alignItems="center"
      justifyContent="center"
      p={2}
      data-testid="attachmentPreview"
    >
      {hasError && (
        <Container classes={{ root: classes.modal }}>
          <Typography
            variant="body1"
            align="center"
            style={{
              color: '#49535D',
            }}
          >
            This type of file cannot be loaded.
            <br />
            Download your file to preview
          </Typography>
        </Container>
      )}

      {!hasError && mimeType.startsWith('text/') && (
        <textarea
          style={{ width: 300, height: 300, resize: 'none' }}
          readOnly
          value={content}
        />
      )}
      {!hasError && mimeType.startsWith('image/') && (
        <img
          style={{ width: 300, height: 300, objectFit: 'contain' }}
          onError={(error) => {
            setHasError(true);
          }}
          src={URL.createObjectURL(file)}
        />
      )}
      {!hasError && mimeType.startsWith('audio/') && (
        <audio
          style={{ width: 300 }}
          controls
          src={URL.createObjectURL(file)}
          onError={(error) => {
            setHasError(true);
          }}
        />
      )}
      {!hasError && mimeType.startsWith('video/') && (
        <video
          style={{ width: 300, height: 300 }}
          controls
          src={URL.createObjectURL(file)}
          onError={(error) => {
            setHasError(true);
          }}
        />
      )}
      {!hasError && mimeType.startsWith('application/') && (
        <embed
          src={URL.createObjectURL(file)}
          width={`${window.innerWidth - 100}px`}
          height={`${window.innerHeight - 100}px`}
          onError={(error) => {
            setHasError(true);
          }}
        />
      )}
      {!mimeType.startsWith('text/') &&
        !mimeType.startsWith('image/') &&
        !mimeType.startsWith('audio/') &&
        !mimeType.startsWith('video/') &&
        !mimeType.startsWith('application/') && (
          <Container classes={{ root: classes.modal }}>
            <Typography
              variant="body1"
              align="center"
              style={{
                color: '#49535D',
              }}
            >
              This type of file cannot be loaded.
              <br />
              Download your file to preview
            </Typography>
          </Container>
        )}
    </Box>
  );
};

AttachmentPreview.propTypes = {
  file: PropTypes.object.isRequired,
  mimeType: PropTypes.string.isRequired,
};

export default AttachmentPreview;
