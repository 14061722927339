import { makeStyles } from '@material-ui/core';
import { MNOSuspendState } from '../../types';
import { sortMnoList } from '../../../../../utils';

const useStyles = makeStyles({
  container: {
    display: 'grid',
    gridTemplateColumns: 'repeat(3, 1fr)',
    rowGap: '9px',
  },
  item: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    gap: '7px',
    boxSizing: 'border-box',
    fontFamily: 'Roboto',
    fontWeight: 700,
    fontSize: '12px',
    lineHeight: '14px',
    color: '#68737A',
  },
  padded: {
    paddingLeft: '20%',
    borderLeft: '1px solid #C0CCD4',
  },
  name: {
    fontWeight: 500,
    color: '#D41C54',
    '&.inactive': {
      color: '#68737A',
    },
  },
});

interface Props {
  mnos: MNOSuspendState[];
}

const BrandSuspendedStatus: React.FC<Props> = ({ mnos }) => {
  const classes = useStyles();

  const newMonsList = sortMnoList(mnos, ['displayName']);
  const rows = newMonsList.length / 3;
  const remaining = newMonsList.length % 3;

  return (
    <div className={classes.container} data-testid="brandSuspendedStatus">
      {new Array(rows + (remaining > 0 ? 1 : 0)).fill(0).map((row, rowIndex) =>
        new Array(3).fill(0).map((column, columnIndex) => {
          const mno = newMonsList[rowIndex + columnIndex * 2];
          return mno ? (
            <div
              data-testid={`brandSuspendedStatusSuspendedBrand-${mno?.displayName?.replace(
                /\s/g,
                ''
              )}`}
              key={mno.networkId}
              className={`${classes.item} ${
                columnIndex % 3 > 0 ? classes.padded : ''
              }`}
            >
              <span
                className={`${classes.name} ${
                  typeof mno.suspended !== 'boolean' ? 'inactive' : ''
                }`}
              >
                {mno.displayName}
              </span>
              <span>
                {mno.suspended === true
                  ? 'YES'
                  : mno.suspended === false
                  ? 'NO'
                  : 'N/A'}
              </span>
            </div>
          ) : null;
        })
      )}
    </div>
  );
};

export default BrandSuspendedStatus;
