import React, { useState } from 'react';
import { Button, makeStyles, Menu, MenuItem } from '@material-ui/core';
import PropTypes from 'prop-types';

const useStyles = makeStyles({
  button: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    height: '40px',
    padding: '0 20px',
    borderRadius: '200px',
    background: '#D41C54',
    boxShadow: 'none',
    '&:hover': {
      background: '#D41C54',
      boxShadow: 'none',
    },
    '&.enabled': {
      background: '#00797C',
    },
  },
  dots: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    '& > *:not(:first-child)': {
      marginLeft: '5px',
    },
  },
  dot: {
    width: '5px',
    height: '5px',
    borderRadius: '50%',
    background: '#FFFFFF',
  },
  menu: {
    marginTop: '50px',
    borderRadius: 0,
  },
  list: {
    padding: 0,
    background: '#00797C',
  },
  item: {
    '&:hover': {
      fontWeight: '500 !important',
      background: 'transparent',
    },
    '&.even': {
      background: 'rgba(255, 255, 255, 0.4)',
    },
  },
});

const MenuButton = ({ style, disabled, options, onSelect }) => {
  const [anchorEl, setAnchorEl] = useState(null);
  const classes = useStyles();

  const handelButtonClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  const handleItemClick = (value) => {
    onSelect && onSelect(value);
    handleMenuClose();
  };

  return (
    <>
      <Button
        style={style}
        className={`${classes.button}${anchorEl ? ' enabled' : ''}`}
        disabled={disabled}
        variant="contained"
        onClick={handelButtonClick}
        data-testid="campaignDetailMenuButton"
      >
        <div className={classes.dots}>
          <div className={classes.dot} />
          <div className={classes.dot} />
          <div className={classes.dot} />
        </div>
      </Button>
      <Menu
        classes={{
          paper: classes.menu,
          list: classes.list,
        }}
        keepMounted
        open={!!anchorEl}
        anchorEl={anchorEl}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
        transformOrigin={{ vertical: 'top', horizontal: 'right' }}
        onClose={handleMenuClose}
        data-testid="campaignDetailMenu"
      >
        {options.map((option, index) => (
          <MenuItem
            key={option.value}
            data-testid={`campaignDetailMenu-${option.value}`}
            className={`${classes.item}${index % 2 ? ' even' : ''}`}
            onClick={() => handleItemClick(option.value)}
          >
            {option.label}
          </MenuItem>
        ))}
      </Menu>
    </>
  );
};

MenuButton.propTypes = {
  style: PropTypes.object,
  disabled: PropTypes.bool,
  options: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string.isRequired,
      value: PropTypes.any,
    })
  ).isRequired,
  onSelect: PropTypes.func,
};

export default MenuButton;
