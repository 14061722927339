import React, { useEffect, useState } from 'react';
import { TableRow, TableCell, IconButton } from '@material-ui/core';
import { CustomSwitch, CustomTooltip } from '../../../../shared_elements';
import { ApiKeyActionDialog, AddNewApiKeyDialog } from './index';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import { toastFlashMessage } from '../../../../utils';
import { globalPutService } from '../../../../utils/globalApiServices';
import { convertTimeWithTimezone } from '../../../../utils/time';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faTrash,
  faArrowRotateRight,
  faDna,
  faPen,
  faCopy,
  faEye,
} from '@fortawesome/pro-regular-svg-icons';

const SCENE = {
  Edit: 'edit',
  Clone: 'clone',
  Rotate: 'rotate',
  Delete: 'delete',
};

export default function ApiKeyListingRow(props) {
  const { accountObj } = props;
  const [account, setAccount] = useState(accountObj ? accountObj : {});
  const [scene, setScene] = useState(null);

  const updateStatus = (status) => {
    const formdata = {
      enabled: status,
    };
    globalPutService(`csp/api/account/${account.id}`, formdata).then(
      (response) => {
        if (response.status >= 200 && response.status < 300) {
          toastFlashMessage(`API Key updated successfully`, 'success');
          setAccount(response.data);
        }
      }
    );
  };

  useEffect(() => {
    setAccount(accountObj);
  }, [accountObj]);

  return (
    <>
      <TableRow data-testid="apiKeyListingRow">
        <TableCell item xs={4}>
          {account.displayName ? account.displayName : '--'}
        </TableCell>
        <TableCell>
          <div className="api-key-wrapper">
            <span data-testid="apiKeyListingRowApiKey">
              <CustomTooltip
                arrow
                title={
                  <div className="tooltip-content">
                    <p>
                      API KEY <br />
                      {account.apiKey}
                    </p>
                  </div>
                }
                placement="top"
              >
                <div className="img-wrapper">
                  <FontAwesomeIcon icon={faEye} size="lg" />
                </div>
              </CustomTooltip>
            </span>
            <span data-testid="apiKeyListingRowApiKeyCopyToClipboard">
              <CopyToClipboard
                text={account.apiKey}
                onCopy={() =>
                  toastFlashMessage(
                    'API Key has been copied to your clipboard',
                    'success'
                  )
                }
              >
                <CustomTooltip
                  arrow
                  placement="top"
                  title={<div className="tooltip-content">Copy</div>}
                >
                  <div className="img-wrapper">
                    <FontAwesomeIcon icon={faCopy} size="lg" />
                  </div>
                </CustomTooltip>
              </CopyToClipboard>
            </span>
          </div>
        </TableCell>
        <TableCell item xs={1}>
          <div className="secret-wrapper">
            <span data-testid="apiKeyListingRowSecret">
              <CustomTooltip
                arrow
                title={
                  <div className="tooltip-content">
                    <p>
                      SECRET <br />
                      {account.secret}
                    </p>
                  </div>
                }
                placement="top"
              >
                <div className="img-wrapper">
                  <FontAwesomeIcon icon={faEye} size="lg" />
                </div>
              </CustomTooltip>
            </span>
            <span data-testid="apiKeyListingRowSecretCopyButton">
              <CopyToClipboard
                text={account.secret}
                onCopy={() =>
                  toastFlashMessage(
                    'Secret has been copied to your clipboard',
                    'success'
                  )
                }
              >
                <CustomTooltip
                  arrow
                  placement="top"
                  title={<div className="tooltip-content">Copy</div>}
                >
                  <div className="img-wrapper">
                    <FontAwesomeIcon icon={faCopy} size="lg" />
                  </div>
                </CustomTooltip>
              </CopyToClipboard>
            </span>
          </div>
        </TableCell>
        <TableCell data-testid="apiKeyListingRowCreatedDate">
          {convertTimeWithTimezone(account.createDate)}
        </TableCell>
        <TableCell data-testid="apiKeyListingRowEnableSwitch">
          <CustomSwitch
            val={account.enabled ? true : false}
            handleChange={() => updateStatus(!account.enabled)}
          />
        </TableCell>
        <TableCell>
          <div className="actions-wrapper">
            <span data-testid="apiKeyListingRowEdit">
              <CustomTooltip
                arrow
                title={<div className="tooltip-content">Edit</div>}
                placement="top"
              >
                <div
                  className="img-wrapper"
                  onClick={() => setScene(SCENE.Edit)}
                >
                  <FontAwesomeIcon icon={faPen} size="lg" />
                </div>
              </CustomTooltip>
            </span>
            <span data-testid="apiKeyListingRowClone">
              <CustomTooltip
                arrow
                title={<div className="tooltip-content">Clone</div>}
                placement="top"
              >
                <div
                  className="img-wrapper clone"
                  onClick={() => setScene(SCENE.Clone)}
                >
                  <FontAwesomeIcon icon={faDna} size="lg" />
                </div>
              </CustomTooltip>
            </span>
            <span data-testid="apiKeyListingRowRotate">
              <CustomTooltip
                arrow
                title={<div className="tooltip-content">Rotate</div>}
                placement="top"
              >
                <div
                  className="img-wrapper"
                  onClick={() => setScene(SCENE.Rotate)}
                >
                  <FontAwesomeIcon icon={faArrowRotateRight} size="lg" />
                </div>
              </CustomTooltip>
            </span>
            <span data-testid="apiKeyListingRowDelete">
              <CustomTooltip
                arrow
                title={<div className="tooltip-content">Delete</div>}
                placement="top"
              >
                <div
                  className="img-wrapper delete"
                  onClick={() => setScene(SCENE.Delete)}
                >
                  <FontAwesomeIcon icon={faTrash} size="lg" />
                </div>
              </CustomTooltip>
            </span>
          </div>
        </TableCell>
      </TableRow>
      {!!scene && [SCENE.Clone, SCENE.Rotate, SCENE.Delete].includes(scene) ? (
        <ApiKeyActionDialog
          type={scene}
          handleClose={() => {
            setScene(null);
          }}
          account={account}
          fetchApiAccounts={props.fetchApiAccounts}
        />
      ) : null}
      <AddNewApiKeyDialog
        open={scene === SCENE.Edit}
        type={'edit'}
        handleClose={() => setScene(null)}
        account={account}
        setAccount={setAccount}
      />
    </>
  );
}
