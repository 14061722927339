import { FC } from 'react';
import { Button } from 'portal-commons';
import { Dialog, makeStyles } from '@material-ui/core';

interface Props {
  open: boolean;
  appealType: string;
  entityType?: string;
  identityStatus?: string;
  onClose?: () => void;
}

const useStyles = makeStyles({
  root: {},
  container: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    width: '525px',
    padding: '28px 20px 36px 20px',
    marginLeft: '200px',
    background: '#FFFFFF',
  },
  title: {
    color: '#071822',
    fontFamily: 'Roboto',
    fontWeight: 500,
    fontSize: '18px',
    lineHeight: '21px',
  },
  message: {
    marginTop: '26px',
    color: '#49535D',
    fontFamily: 'Roboto',
    fontWeight: 400,
    fontSize: '16px',
    lineHeight: '19px',
    textAlign: 'center',
  },
  actions: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
    marginTop: '28px',
  },
});

const AppealRequestWarningModal: FC<Props> = ({
  open,
  appealType,
  entityType,
  identityStatus,
  onClose,
}) => {
  const classes = useStyles();

  return (
    <Dialog
      classes={{ paper: classes.container }}
      open={open}
      data-testid="appealRequestWarningModal"
    >
      <div className={classes.title}>{appealType} Appeal</div>
      <div className={classes.message}>
        {entityType} Entity Type cannot submit {appealType} Appeals with an
        Identity Status: {identityStatus}.
      </div>
      <div className={classes.actions}>
        <Button onClick={onClose} data-testid="closeButton">
          Close
        </Button>
      </div>
    </Dialog>
  );
};

export default AppealRequestWarningModal;
