import React, { useState, useEffect, useMemo } from 'react';
import { Link, withRouter } from 'react-router-dom';
import { globalGetService } from '../../utils/globalApiServices';
import { SideNavigation as BaseSideNavigation } from 'portal-commons';
import {
  faHome,
  faBullhorn,
  faTags,
  faCalendarDay,
  faGearCode,
  faArrowsRepeat,
  faBookBookmark,
} from '@fortawesome/pro-regular-svg-icons';

export function SideNavigation(props) {
  const { location } = props;
  const [lastLogin, setLastLogin] = useState('');
  const inActiveCSPAccount = location.pathname === '/user/csp';
  const inRegistrationFlow =
    location.pathname === '/user/csp/create' ||
    location.pathname === '/user/csp/status';

  useEffect(() => {
    if (localStorage.getItem('user') && !lastLogin) {
      const lastLoginTime = JSON.parse(localStorage.getItem('user')).lastLogin;
      setLastLogin(lastLoginTime);
    }
    if (!localStorage.getItem('user') && !lastLogin) {
      globalGetService('user').then((response) => {
        localStorage.setItem('user', JSON.stringify(response.data));
        setLastLogin(response.data ? response.data.lastLogin : undefined);
      });
    }
  }, []);

  const navLink = useMemo(() => {
    const link = [
      {
        title: 'Dashboard',
        icon: faHome,
        pathname: '/dashboard',
      },
    ];

    if (props.role !== 'ROLE_FINANCE') {
      link.push(
        ...[
          {
            title: 'My Campaigns',
            icon: faBullhorn,
            pathname: '/campaigns',
          },
          {
            title: 'CNP Campaigns',
            icon: faBullhorn,
            pathname: '/cnp-campaigns',
          },
          {
            title: 'Brands',
            icon: faTags,
            pathname: '/brands',
          },
          {
            title: 'Resellers',
            icon: faArrowsRepeat,
            pathname: '/resellers',
          },
          {
            title: 'Events',
            icon: faCalendarDay,
            pathname: '/events',
          },
        ]
      );
    }

    link.push({
      title: 'Knowledge Base',
      icon: faBookBookmark,
      pathname: '/knowledge-base',
    });

    if (props.role === 'ROLE_MANAGER' || props.role === 'ROLE_USER') {
      link.push({
        title: 'Integrations',
        icon: faGearCode,
        pathname: '/integrations',
      });
    }
    return link;
  }, [props.role]);

  return (
    <BaseSideNavigation
      portal="csp"
      items={inRegistrationFlow ? navLink : inActiveCSPAccount ? [] : navLink}
      location={location}
      lastLoginTime={lastLogin}
      linkComponent={Link}
      className={`side-navigation ${inRegistrationFlow ? 'registration' : ''}`}
    />
  );
}

export default withRouter(SideNavigation);
