import { BoxV2 as Box } from 'portal-commons';

const MaskedContainer = ({ children, masked = false, sx, ...props }) => (
  <Box
    sx={{
      width: '100%',
      ...(masked
        ? {
            position: 'relative',
            '&::before': {
              content: '""',
              position: 'absolute',
              top: 0,
              left: 0,
              width: '100%',
              height: '100%',
              backgroundColor: '#fff',
              opacity: 0.4,
              zIndex: 99,
            },
          }
        : undefined),
      ...sx,
    }}
    data-testid="maskedContainer"
    {...props}
  >
    {children}
  </Box>
);

export default MaskedContainer;
