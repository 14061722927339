import React, { useState } from 'react';
import {
  Accordion,
  AccordionSummary,
  AccordionDetails,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAngleDown } from '@fortawesome/pro-regular-svg-icons';

const useStyles = makeStyles({
  icon: {
    marginRight: 0,
  },
});

export default function ExpansionList(props) {
  const [expanded, toggleExpansion] = useState(false);
  const classes = useStyles();

  return (
    <Accordion
      expanded={expanded}
      onChange={() => toggleExpansion(!expanded)}
      {...props}
    >
      <AccordionSummary
        id="panel1d-header"
        aria-controls="panel1d-content"
        classes={{ expandIcon: classes.icon }}
        expandIcon={<FontAwesomeIcon icon={faAngleDown} size="2xs" />}
      >
        {expanded
          ? props.docflag
            ? props.title
            : props.children
          : props.title}
      </AccordionSummary>
      {props.docflag && expanded ? (
        <AccordionDetails>{props.children}</AccordionDetails>
      ) : null}
    </Accordion>
  );
}
