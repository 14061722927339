import React from 'react';
import { Grid } from '@material-ui/core';
import { S3_ASSETS_PATH } from '../../../../constants';
import { withRouter } from 'react-router-dom';
import { convertTimeWithTimezone } from '../../../../utils/time';

import { Box } from 'portal-commons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTrash, faPen } from '@fortawesome/pro-regular-svg-icons';

function UsersTable(props) {
  return (
    <ul className="list-unstyled users-table" data-testid="userTable">
      <li className="heading-row">
        <Grid container spacing={1}>
          <Grid item xs={2}>
            NAME
          </Grid>
          <Grid item xs={3}>
            E-MAIL
          </Grid>
          <Grid item xs={3}>
            USER ID
          </Grid>
          <Grid item xs={2}>
            ROLE
          </Grid>
          <Grid item xs={2}>
            CREATED ON
          </Grid>
        </Grid>
      </li>
      {props.users.map((user, index) => (
        <li className="contentRow" key={index} data-testid="userTableRow">
          <Grid container spacing={1}>
            <Grid item xs={2}>
              <p className="paragraph" data-testid="userTableRowFullName">
                <span>{user.profile.fullName}</span>
              </p>
            </Grid>
            <Grid item xs={3}>
              <p className="paragraph" data-testid="userTableRowEmail">
                {user.profile.email}
              </p>
            </Grid>
            <Grid item xs={3}>
              <p className="paragraph" data-testid="userTableRowUserId">
                {user.id}
              </p>
            </Grid>
            <Grid item xs={2}>
              <p className="paragraph" data-testid="userTableRowRole">
                {user.profile &&
                user.profile.csp_app_role &&
                user.profile.csp_app_role.length
                  ? user.profile.csp_app_role[0]
                  : '--'}
              </p>
            </Grid>
            <Grid item xs={2}>
              <div className="date-col" data-testid="userTableRowEditButton">
                <Box flexDirection="row" alignItems="center">
                  <Box margin={{ right: 'xs' }}>
                    <FontAwesomeIcon
                      style={{ cursor: 'pointer' }}
                      icon={faPen}
                      size="lg"
                      onClick={() =>
                        props.history.push({
                          pathname: `/users/${user.id}/edit`,
                          state: {
                            userInfo: {
                              name: user.profile.fullName,
                              email: user.profile.email,
                              role:
                                user.profile.csp_app_role &&
                                user.profile.csp_app_role.length
                                  ? user.profile.csp_app_role[0]
                                  : '',
                            },
                          },
                        })
                      }
                    />
                  </Box>
                  <FontAwesomeIcon
                    style={{ cursor: 'pointer' }}
                    icon={faTrash}
                    size="lg"
                    onClick={() => props.handleModalClick(true, user.id)}
                  />
                </Box>
              </div>
              <p className="paragraph" data-testid="userTableRowDate">
                {convertTimeWithTimezone(user.created, undefined, 'MM/DD/YYYY')}
              </p>
            </Grid>
          </Grid>
        </li>
      ))}
      {props.users && props.users.length < props.maxUsers
        ? new Array(props.maxUsers).fill(0).map((item, index) =>
            index >= props.users.length ? (
              <li className="emptyRow contentRow">
                <Grid container spacing={1}>
                  <Grid item xs={12}>
                    <div
                      data-testid="userTableRowAddUserButton"
                      className="no-user"
                      onClick={() => props.history.push('/user/add')}
                    >
                      <p className="paragraph">User available</p>
                      <img
                        src={`${S3_ASSETS_PATH}/images/add-new-user-icon.svg`}
                      />
                    </div>
                  </Grid>
                </Grid>
              </li>
            ) : null
          )
        : null}
    </ul>
  );
}
export default withRouter(UsersTable);
