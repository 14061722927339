import React, { useState, useEffect } from 'react';
import { Dialog, DialogContent } from '@material-ui/core';
import IntegrationReactSelect from '../../../../ui_elements/IntegrationReactSelect';
import { nominateCnpApi } from '../apiServices';
import { toastFlashMessage } from '../../../../utils';

export default function ElectCNPDialog(props) {
  const { cnpOptions, open, campaignUid, usecase } = props;
  const [error, setError] = useState({});
  const [nominee, setNominee] = useState('');
  const [loader, setLoader] = useState(false);

  const handleSubmit = (e) => {
    e.preventDefault();
    setLoader(true);
    const validateNewInput = {
      nominee: nominee ? '' : 'Please elect Connectivity Partner',
    };
    if (
      Object.keys(validateNewInput).every((key) => validateNewInput[key] == '')
    ) {
      nominateCnpApi(campaignUid, nominee).then((response) => {
        setLoader(false);
        if (response.status >= 200 && response.status < 300) {
          toastFlashMessage(
            `Connectivity partner elected successfully`,
            'success'
          );
          props.handleClose(true);
        }
      });
    } else {
      setLoader(false);
      setError(validateNewInput);
    }
  };
  useEffect(() => {
    setError({});
    setNominee('');
  }, [open]);

  return (
    <Dialog
      open={open}
      // onClose={props.handleClose}
      aria-labelledby="responsive-dialog-title"
      className="elect-cnp-dialog"
      data-testid="electCnpDialog"
    >
      <DialogContent className="content-wrapper">
        <div className="title text-center">
          <h3 className="heading1">Elect Connectivity Partner</h3>
        </div>
        <p className="paragraph">
          Please identify the partner you will be forwarding the messaging
          campaign to.{' '}
          <strong>
            By making a selection you are automatically accepting the campaign
            that has been shared with you.
          </strong>{' '}
          Failing to elect a CNP may hinder the campaign from reaching the MNO.
          <br />
          <br />
          For Sole Proprietor campaigns, if your CNP is not showing in the list,
          it means they are not enabled to receive Sole Proprietor campaigns.
          Please reach out to your CNP for more details.
        </p>
        <p className="note">* Indicates a Required Field</p>
        <form onSubmit={handleSubmit}>
          <div className="form-group-field">
            <IntegrationReactSelect
              options={cnpOptions}
              label="Elect Connectivity Partner"
              error={error.nominee ? true : false}
              value={cnpOptions.find((option) => option.value === nominee)}
              handleChange={(selectedOption, keyName) => {
                setNominee(selectedOption.value);
                setError({ nominee: '' });
              }}
              required={true}
              keyName="nominee"
            />
            {error.nominee ? (
              <h6 className="error-msg">{error.nominee}</h6>
            ) : null}
          </div>
          <ul className="list-inline footer">
            <li>
              <a
                className={`secondary-btn ${loader ? 'disabled' : ''}`}
                onClick={() => props.handleClose(false)}
                data-testid="electCnpDialogCancelButton"
              >
                Cancel
              </a>
            </li>
            <li className="form-group-field">
              <input
                data-testid="electCnpDialogSubmitButton"
                type="submit"
                disabled={loader ? true : false}
                value="Proceed"
              />
            </li>
          </ul>
        </form>
      </DialogContent>
    </Dialog>
  );
}
