import React from 'react';
import PropTypes from 'prop-types';
import {
  Box,
  Container,
  IconButton,
  List,
  ListItem,
  ListItemIcon,
  ListItemSecondaryAction,
  ListItemText,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faEye,
  faXmark,
  faArrowDownToLine,
} from '@fortawesome/pro-regular-svg-icons';

const useStyles = makeStyles((theme) => ({
  container: {
    display: 'flex',
    flexDirection: 'column',
    height: 275,
    background: '#FFFFFF',
    border: '1px solid #C0CCD4',
    boxSizing: 'border-box',
  },
  header: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    height: 40,
    fontSize: 14,
    fontWeight: 500,
    color: '#0091B3',
    background: 'rgba(0, 145, 179, 0.06)',
  },
  items: {
    flex: 1,
    overflow: 'auto',
    '& > *:nth-child(odd)': {},
    '& > *:nth-child(even)': {
      background: 'rgba(192, 204, 212, 0.15)',
    },
  },
  item: {
    height: 40,
    padding: '0 0 0 20px',
  },
  itemText: {
    fontSize: 12,
    fontWeight: 500,
    color: '#68737A',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
  },
  itemIcon: {
    minWidth: 32,
  },
  itemButton: {
    width: 24,
    height: 24,
    '&:hover': {
      background: 'rgba(0, 145, 179, 0.1)',
    },
  },
  itemSvg: {
    width: 18,
    height: 18,
  },
}));

const SampleMultimediaList = ({
  editable,
  attachments,
  onSelect,
  onDelete,
  onDownload,
}) => {
  const classes = useStyles();

  return (
    <Box
      className={classes.container}
      data-testid="campaignDetailSampleMultimediaFiles"
    >
      <Container classes={{ root: classes.header }}>
        Sample Multimedia Files
      </Container>
      <List classes={{ root: classes.items }} disablePadding>
        {attachments.map((attachment, index) => (
          <ListItem
            data-testid={`campaignDetailSampleMultimediaFiles-${index}`}
            key={attachment.uuid}
            classes={{ root: classes.item }}
            disableGutters
          >
            <ListItemText
              classes={{ root: classes.itemText }}
              primary={attachment.fileName}
            />
            <ListItemIcon classes={{ root: classes.itemIcon }}>
              <IconButton
                data-testid={`campaignDetailSampleMultimediaFilesDownloadButton`}
                classes={{ root: classes.itemButton }}
                aria-label="view"
                onClick={() => onDownload && onDownload(index)}
              >
                <FontAwesomeIcon
                  icon={faArrowDownToLine}
                  style={{ color: '#68737A' }}
                  size="xs"
                />
              </IconButton>
            </ListItemIcon>
            <ListItemIcon classes={{ root: classes.itemIcon }}>
              <IconButton
                data-testid={`campaignDetailSampleMultimediaFilesSelectButton`}
                classes={{ root: classes.itemButton }}
                aria-label="view"
                onClick={() => onSelect && onSelect(index)}
              >
                <FontAwesomeIcon
                  icon={faEye}
                  style={{ color: '#68737A' }}
                  size="xs"
                />
              </IconButton>
            </ListItemIcon>
            {editable && !attachment.readonly && (
              <ListItemIcon classes={{ root: classes.itemIcon }}>
                <IconButton
                  data-testid={`campaignDetailSampleMultimediaFilesDeleteButton`}
                  classes={{ root: classes.itemButton }}
                  aria-label="delete"
                  onClick={() => onDelete && onDelete(index)}
                >
                  <FontAwesomeIcon
                    icon={faXmark}
                    style={{ color: '#0091B3' }}
                    size="xs"
                  />
                </IconButton>
              </ListItemIcon>
            )}
          </ListItem>
        ))}
      </List>
    </Box>
  );
};

SampleMultimediaList.propTypes = {
  editable: PropTypes.bool,
  attachments: PropTypes.arrayOf(
    PropTypes.shape({
      uuid: PropTypes.string.isRequired,
      fileName: PropTypes.string.isRequired,
      mimeType: PropTypes.string.isRequired,
      readonly: PropTypes.bool,
    })
  ).isRequired,
  onSelect: PropTypes.func,
  onDownload: PropTypes.func,
  onDelete: PropTypes.func,
};

export default SampleMultimediaList;
