import React from 'react';
import { Container, Grid } from '@material-ui/core';
import { S3_ASSETS_PATH } from './constants';
import { Link } from 'react-router-dom';

const NoAccess = (props) => {
  return (
    <section className="no-access-section" data-testid="noAccess">
      <Container className="no-access-container">
        <Grid container justify="center">
          <Grid item xs={12} className="text-center">
            <h3 className="heading1">Access Denied</h3>
            <p className="heading2">
              Sorry, but you don't have permission to access this page..!
            </p>
            <Link className="primary-btn" data-testid="homeButton" to="/">
              Back to Home
            </Link>
          </Grid>
        </Grid>
      </Container>
    </section>
  );
};
export default NoAccess;
