import React, { FC } from 'react';
import { TableCell, TableRow, useMediaQuery } from '@material-ui/core';
import { useHistory, useLocation } from 'react-router-dom';
import ExpansionList from '../../../../ui_elements/ExpansionList';
import { hasBrand } from '../../Brands/apiServices';
import { convertTimeWithTimezone } from '../../../../utils/time';
import { Event } from '../types';

interface Props {
  event: Event;
}
const EventsListingRow: FC<Props> = ({ event }) => {
  const location = useLocation();
  const history = useHistory();
  const matches = useMediaQuery('(max-width:1280px)');
  const matches2 = useMediaQuery('(max-width:1500px)');

  return (
    <TableRow data-testid="eventListingRow">
      <TableCell
        align="left"
        style={{ minWidth: 160 }}
        data-testid="eventListingRowEventType"
      >
        <div className="event-type-wrapper">{event.eventType}</div>
      </TableCell>
      <TableCell align="left" data-testid="eventListingRowCategoryType">
        {event.categoryType}
      </TableCell>
      <TableCell
        data-testid="eventListingRowCampaignUid"
        align="left"
        style={{ minWidth: 90 }}
        onClick={() => {
          if (event.campaignUid) {
            history.push({
              pathname: `/campaigns/${event.campaignUid}`,
              state: {
                goBackPage: `${location.pathname}${
                  location.search ? location.search : ''
                }`,
              },
            });
          }
        }}
        className={`${event.campaignUid ? 'link' : ''}`}
      >
        {event.campaignUid}
      </TableCell>
      <TableCell
        align="left"
        data-testid="eventListingRowBrandUid"
        style={{ minWidth: 90 }}
        onClick={async () => {
          if (event.brandUid) {
            const brandFound = await hasBrand(event.brandUid);
            if (brandFound) {
              history.push({
                pathname: `/brands/${event.brandUid}`,
                state: {
                  goBackPage: `${location.pathname}${
                    location.search ? location.search : ''
                  }`,
                },
              });
            }
          }
        }}
        className={`${event.brandUid ? 'link' : ''}`}
      >
        {event.brandUid}
      </TableCell>
      <TableCell
        align="left"
        style={{ minWidth: 140 }}
        data-testid="eventListingCreatedDate"
      >
        {convertTimeWithTimezone(
          event.createDate,
          undefined,
          'MM/DD/YYYY HH:mm z'
        )}
      </TableCell>
      <TableCell
        style={{
          width: 500,
          overflow: 'hidden',
          paddingTop: 0,
          paddingBottom: 0,
        }}
        align="left"
        data-testid="eventListingRowDescription"
      >
        {/* { event.description.length > (matches ? 50 : matches2 ? 65: 70) ? <ExpansionList title={matches ? `${event.description.slice(0, 35)}...`: matches2 ? `${event.description.slice(0, 52)}...` : `${event.description.slice(0, 52)}...`}>{`${event.description}`}</ExpansionList> : event.description }  */}
        {event.description.length > (matches ? 50 : matches2 ? 78 : 82) ? (
          <ExpansionList
            title={
              matches
                ? `${event.description.slice(0, 40)}...`
                : matches2
                ? `${event.description.slice(0, 62)}...`
                : `${event.description.slice(0, 65)}...`
            }
          >{`${event.description}`}</ExpansionList>
        ) : (
          event.description
        )}
      </TableCell>
    </TableRow>
  );
};

export default EventsListingRow;
