import React, { useEffect, useState } from 'react';
import { Grid, makeStyles } from '@material-ui/core';
import queryString from 'query-string';
import { useHistory, useLocation } from 'react-router-dom';
import {
  Box,
  Button,
  FilterConfig,
  FilterType,
  Table,
  TableFilter,
} from 'portal-commons';
import { globalGetService } from '../../../../../utils/globalApiServices';
import CampaignListingRow from '../CampaignListingRow';
import { CampaignDetail } from '../../../Campaigns/types';
import { Pagination, SelectItem } from '../../../../../utils/types';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBullhorn, faFilter } from '@fortawesome/pro-regular-svg-icons';
import { faPlus } from '@fortawesome/pro-solid-svg-icons';

interface UseCase {
  id: string;
  uid?: string;
  displayName: string;
}

const defaultFilterConfigs: Record<string, FilterConfig> = {
  usecase: {
    type: FilterType.Dropdown,
    options: [],
    label: 'Use-Case',
    placeholder: 'select use-case',
    width: 230,
  },
  description: {
    type: FilterType.Text,
    label: 'Campaign Description',
    placeholder: 'campaign description',
    width: 230,
  },
  resellerName: {
    type: FilterType.Text,
    label: 'Reseller Name',
    placeholder: 'Enter reseller name',
    suggestions: [],
    width: 230,
  },
};
const defaultFilter = {
  sortField: 'createDate',
  ascendingOrder: false,
  recordsPerPage: 5,
};
const headRows = [
  { id: 'uid', label: 'CAMPAIGN ID', sortable: true },
  { id: 'usecase', label: 'USE CASE', sortable: true },
  { id: 'createDate', label: 'REGISTERED ON', sortable: true },
  { id: 'description', label: 'CAMPAIGN DESCRIPTION', sortable: false },
];
const defaultPaginationRecord: Pagination<CampaignDetail> = {
  totalRecords: 0,
  records: [],
  page: 1,
  recordsPerPage: 5,
  metaData: null,
};

interface Props {
  brandUid: string;
}

const useStyles = makeStyles({
  buttons: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    gap: '12px',
    marginBottom: '12px',
  },
});

const BrandRegisteredCampaigns: React.FC<Props> = ({ brandUid }) => {
  const location = useLocation();
  const history = useHistory();
  const classes = useStyles();
  const [tableLoader, setTableLoader] = useState(true);
  const [prevSearch, setPrevSearch] = useState(location.search);
  const [campaignInfo, setCampaignInfo] = useState<Pagination<CampaignDetail>>(
    defaultPaginationRecord
  );
  const [filtersShown, setFiltersShown] = useState(false);
  const [filterConfigs, setFilterConfigs] = useState(defaultFilterConfigs);
  const [candidateFilters, setCandidateFilters] = useState<
    Record<string, string>
  >({});
  const [appliedFilters, setAppliedFilters] = useState<Record<string, string>>(
    {}
  );
  const [additionalFilters, setAdditionalFilters] = useState(defaultFilter);

  const parseSearchParams = () => {
    const queries = queryString.parse(location.search, {
      decode: true,
    });
    const previousQueries = queryString.parse(prevSearch, {
      decode: true,
    });
    const queryEntries = Object.entries(queries);
    if (queryEntries.length > 0) {
      const newCandidateFilters: Record<string, any> = { ...candidateFilters };
      Object.keys(newCandidateFilters).forEach((key) => {
        if (queries[key] === undefined && previousQueries[key] !== undefined) {
          delete newCandidateFilters[key];
        }
      });
      const newAppliedFilters: Record<string, any> = { ...appliedFilters };
      Object.keys(filterConfigs).forEach((key) => {
        if (queries[key]) {
          if (!prevSearch) {
            newCandidateFilters[key] = queries[key];
          }
          newAppliedFilters[key] = queries[key];
        } else {
          delete newAppliedFilters[key];
        }
      });
      const newAdditionalFilters = {
        ...additionalFilters,
      };
      queryEntries.forEach(([key, value]) => {
        if (!filterConfigs[key]) {
          if (key === 'sortField') {
            newAdditionalFilters.sortField = value as string;
          } else if (key === 'ascendingOrder') {
            newAdditionalFilters.ascendingOrder = JSON.parse(value as string);
          }
        }
      });
      setCandidateFilters(newCandidateFilters);
      setAppliedFilters(newAppliedFilters);
      setAdditionalFilters(newAdditionalFilters);
      return {
        ...newAppliedFilters,
        ...newAdditionalFilters,
        page: queries.page ? queries.page : 1,
      };
    } else {
      setCandidateFilters({});
      setAppliedFilters({});
      setAdditionalFilters(defaultFilter);
      return {
        ...defaultFilter,
      };
    }
  };

  const getAllUseCases = async () => {
    const response = await globalGetService('enums/usecaseTypes');
    if (response.status >= 200 && response.status < 300) {
      const options: SelectItem[] = response.data.map((item: UseCase) => {
        return {
          label: item.displayName,
          value: item.uid ? item.uid : item.id,
        };
      });
      setFilterConfigs({
        ...filterConfigs,
        usecase: {
          ...filterConfigs.usecase,
          options,
        },
      });
    }
  };

  const queryCspCampaigns = async (query = {}) => {
    setTableLoader(true);
    const response = await globalGetService('csp/campaigns', {
      ...query,
      status: 'ACTIVE',
      brandUid,
    });
    setTableLoader(false);
    if (response.status >= 200 && response.status < 300) {
      const tmpCampaigns = response.data;
      setCampaignInfo(tmpCampaigns);
      if (
        tmpCampaigns.records.length === 0 &&
        tmpCampaigns.totalRecords > 0 &&
        tmpCampaigns.page > 1
      ) {
        const lastPageNo = Math.ceil(tmpCampaigns.totalRecords / 5);
        history.push({
          search: `?${queryString.stringify(
            { ...query, page: lastPageNo },
            { encode: true }
          )}`,
        });
      }
    }
  };

  const fetchResellerSuggestions = async (query = {}) => {
    const response = await globalGetService('csp/reseller/suggestion', {
      ...query,
      limit: 20,
    });
    if (response && response.status >= 200 && response.status < 300) {
      return response.data;
    }
    return [];
  };

  const handleChangePage = (newPage: number) => {
    history.push({
      search: `?${queryString.stringify(
        { ...appliedFilters, ...additionalFilters, page: newPage },
        { encode: true }
      )}`,
    });
  };

  const createSortHandler = (sortField: string) => {
    setAdditionalFilters({
      ...additionalFilters,
      ascendingOrder: !additionalFilters.ascendingOrder,
      sortField,
    });

    if (campaignInfo.totalRecords) {
      history.push({
        search: `?${queryString.stringify(
          {
            ...appliedFilters,
            ...additionalFilters,
            page: campaignInfo.page,
            sortField,
            ascendingOrder: !additionalFilters.ascendingOrder,
          },
          { encode: true }
        )}`,
      });
    }
  };

  const handleTableFilterToggle = () => {
    setFiltersShown(!filtersShown);
  };

  const handleCandidateFiltersChange = async (values: Record<string, any>) => {
    setCandidateFilters(values);

    if (values.resellerName !== candidateFilters.resellerName) {
      if (values.resellerName && values.resellerName.length > 1) {
        const suggestions: { companyName: string }[] =
          await fetchResellerSuggestions({
            companyNamePrefix: values.resellerName,
          });
        setFilterConfigs({
          ...filterConfigs,
          resellerName: {
            ...filterConfigs.resellerName,
            suggestions: suggestions.map((s) => s.companyName),
          },
        });
      } else {
        setFilterConfigs({
          ...filterConfigs,
          resellerName: {
            ...filterConfigs.resellerName,
            suggestions: [],
          },
        });
      }
    }
  };

  const handleAppliedFiltersChange = (values: Record<string, any>) => {
    setAppliedFilters(values);

    history.push({
      search: `?${queryString.stringify(
        { ...values, ...additionalFilters },
        { encode: true }
      )}`,
    });
  };

  useEffect(() => {
    const query = parseSearchParams();
    queryCspCampaigns(query);
    getAllUseCases();
  }, []);

  useEffect(() => {
    if (location.search !== prevSearch) {
      const query = parseSearchParams();
      queryCspCampaigns(query);
      setPrevSearch(location.search);
    }
  }, [location.search]);

  return (
    <Grid item xs={12} data-testid="brandRegisteredCampaigns">
      <div className="campaigns-listing details">
        <div className="details-heading campaigns-listing">
          <div className="title">
            <Box flexDirection="row" alignItems="center">
              <Box margin={{ right: 'xs' }}>
                <FontAwesomeIcon icon={faBullhorn} size="xl" />
              </Box>
              <h3 className="heading1">{`${campaignInfo.totalRecords} Registered Campaigns`}</h3>
            </Box>
          </div>
        </div>
        <div className={classes.buttons}>
          <Button
            variant="standard"
            color="secondary"
            onClick={handleTableFilterToggle}
            data-testid="tableAddFilterButton"
          >
            <FontAwesomeIcon icon={faFilter} />
            <span>{filtersShown ? 'Hide' : 'Show'} Filters</span>
          </Button>
        </div>
        {filtersShown && (
          <Grid container style={{ marginBottom: '12px' }}>
            <TableFilter
              configs={filterConfigs}
              candidateValues={candidateFilters}
              appliedValues={appliedFilters}
              onCandidateValuesChange={handleCandidateFiltersChange}
              onAppliedValuesChange={handleAppliedFiltersChange}
              data-testid="brandRegisteredCampaigns"
            />
          </Grid>
        )}
        <Table
          loading={tableLoader}
          emptyState="no campaigns to view"
          records={{ total: campaignInfo.totalRecords }}
          handleChangePage={handleChangePage}
          createSortHandler={createSortHandler}
          filter={additionalFilters}
          headRows={headRows}
          tableData={campaignInfo.records.map((record) => {
            return <CampaignListingRow campaign={record} key={record.uid} />;
          })}
          pagination={{
            page: campaignInfo.page,
            rowsPerPage: campaignInfo.recordsPerPage,
            totalRecords: campaignInfo.totalRecords,
          }}
          testId="brandRegisteredCampaignListingTable"
        />
      </div>
    </Grid>
  );
};

export default BrandRegisteredCampaigns;
