import { ChangeEvent, FocusEvent, useState } from 'react';
import { Button, MultimediaList, AttachmentPreview } from 'portal-commons';
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  makeStyles,
  TextField,
} from '@material-ui/core';
import { isValidInput } from '../../../../../utils';
import AppealCategory from '../AppealCategory';
import SampleMultimediaUploader from '../SampleMultimediaUploader';
import {
  deleteAttachment,
  downloadAttachmentToDesktop,
  uploadAttachment,
} from '../../apiServices';
import {
  AppealCategoryData,
  AppealRequestData,
  AttachmentInfo,
  AttachmentInfoExtras,
  BrandDetail,
} from '../../types';

export const MAX_EXPLANATION_LENGTH = 1024;
// backend limit, larger than this value results in submission failed
const ATTACHMENT_LIMIT = 10;

const useStyles = makeStyles({
  container: {
    width: '700px',
    maxWidth: 'unset',
    padding: '35px 30px 25px 30px',
    marginLeft: '200px',
    boxSizing: 'border-box',
  },
  title: {
    padding: '0',
    '& > *': {
      textAlign: 'center',
      fontWeight: 500,
      fontSize: '20px',
      lineHeight: '23px',
      color: '#071822',
    },
  },
  content: {
    display: 'flex',
    flexDirection: 'column',
    padding: '0',
    marginTop: '30px',
  },
  message: {
    marginBottom: '16px',
    fontFamily: 'Roboto',
    fontWeight: 400,
    fontSize: '16px',
    lineHeight: '19px',
    color: '#49535D',
  },
  subtitleContainer: {
    display: 'flex',
    flexDirection: 'column',
    gap: '4px',
    fontFamily: 'Roboto',
  },
  subtitle: {
    fontWeight: 500,
    fontSize: '18px',
    lineHeight: '21px',
    color: '#49535D',
  },
  note: {
    fontWeight: 400,
    fontSize: '12px',
    lineHeight: '14px',
    color: '#081F2D',
    '&.error': {
      fontWeight: 600,
      color: '#D41C54',
    },
  },
  categories: {
    display: 'flex',
    flexDirection: 'column',
    gap: '18px',
    marginTop: '12px',
  },
  explanationContainer: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-end',
    gap: '2px',
    marginTop: '20px',
    fontFamily: 'Roboto',
    fontWeight: 400,
    fontSize: '10px',
    lineHeight: '12px',
    color: '#49535D',
  },
  explanation: {
    background: 'rgba(192, 204, 212, 0.15)',
    '& input': {
      marginLeft: '5px',
      fontFamily: 'Roboto',
      fontWeight: 400,
      fontSize: '14px',
      lineHeight: '16px',
      color: '#49535D',
    },
    '& > .MuiInputLabel-root': {
      marginTop: '-5px',
      marginLeft: '5px',
    },
    '& > .MuiInputLabel-shrink': {
      marginTop: '3px',
      color: '#0091B3',
    },
    '& > .MuiInputLabel-shrink.Mui-error': {
      color: '#f44336',
    },
    '& > .MuiInputBase-root.Mui-focused::after': {
      borderBottom: '2px solid #0091B3',
      transform: 'scaleX(1)',
    },
  },
  explanationNoteContainer: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    width: '100%',
  },
  multimediaContainer: {
    display: 'flex',
    flexDirection: 'column',
    gap: '13px',
    marginTop: '20px',
  },
  actions: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
    gap: '18px',
    padding: '0',
    marginTop: '50px',
  },
  error: {
    color: '#f44336',
  },
});

interface Props {
  open: boolean;
  brand: BrandDetail;
  title: string;
  message: string;
  categories: AppealCategoryData[];
  disabledCategoryIds?: string[];
  onClose: () => void;
  onSubmit: (_data: AppealRequestData) => void;
}

const AppealRequestModal: React.FC<Props> = ({
  open,
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  brand,
  title,
  message,
  categories,
  disabledCategoryIds,
  onClose,
  onSubmit,
}) => {
  const [checkedCategories, setCheckedCategories] = useState<string[]>([]);
  const [showCheckedCategoriesError, setShowCheckedCategoriesError] =
    useState(false);
  const [explanation, setExplanation] = useState('');
  const [explanationError, setExplanationError] = useState('');
  const [loading, setLoading] = useState(false);
  const [attachments, setAttachments] = useState<AttachmentInfoExtras[]>([]);
  const [attachmentPreviewIndex, setAttachmentPreviewIndex] = useState(-1);
  const classes = useStyles();

  const validateCheckedCategories = () => {
    if (checkedCategories.length < 1) {
      setShowCheckedCategoriesError(true);
      return false;
    }
    setShowCheckedCategoriesError(false);
    return true;
  };

  const validateExplanation = () => {
    if (explanation.length > MAX_EXPLANATION_LENGTH) {
      setExplanationError(`Maximum ${MAX_EXPLANATION_LENGTH} chars`);
      return false;
    } else if (!isValidInput(explanation)) {
      setExplanationError('Invalid input');
      return false;
    }
    setExplanationError('');
    return true;
  };

  const resetStates = () => {
    setCheckedCategories([]);
    setShowCheckedCategoriesError(false);
    setExplanation('');
    setExplanationError('');
    setLoading(false);
    setAttachments([]);
    setAttachmentPreviewIndex(-1);
  };

  const handleToggle = (id: string) => {
    const index = checkedCategories.indexOf(id);
    if (index === -1) {
      setCheckedCategories([...checkedCategories, id]);
    } else {
      const newChecked = [...checkedCategories];
      newChecked.splice(index, 1);
      setCheckedCategories(newChecked);
    }
    setShowCheckedCategoriesError(false);
  };

  const handleExplanationChange = (event: ChangeEvent<HTMLInputElement>) => {
    event.preventDefault();
    event.stopPropagation();
    setExplanation(event.target.value);
    setExplanationError('');
  };

  const handleExplanationBlur = (event: FocusEvent<HTMLInputElement>) => {
    event.preventDefault();
    event.stopPropagation();
    validateExplanation();
  };

  const handleUpload = async (file: File) => {
    setLoading(true);
    try {
      const attachment: AttachmentInfo = await uploadAttachment(file);
      setAttachments([
        ...attachments,
        {
          ...attachment,
          file,
        },
      ]);
    } catch (e) {
      // ignore
    }
    setLoading(false);
  };

  const handleDownload = async (index: number) => {
    try {
      await downloadAttachmentToDesktop(
        attachments[index].uuid,
        attachments[index].fileName
      );
    } catch (e) {
      // ignore
    }
  };

  const handlePreview = async (index: number) => {
    setAttachmentPreviewIndex(index);
  };

  const handleDelete = async (index: number) => {
    const newAttachments = [...attachments];
    const removedAttachments = newAttachments.splice(index, 1);
    setAttachments(newAttachments);
    try {
      await deleteAttachment(removedAttachments[0].uuid);
    } catch (e) {
      // ignore
    }
  };

  const handleClose = () => {
    onClose && onClose();
    resetStates();
  };

  const handleSubmit = () => {
    const valid = validateCheckedCategories() && validateExplanation();
    if (valid) {
      onSubmit &&
        onSubmit({
          categories: checkedCategories,
          explanation,
          attachments: attachments.map((attachment) => attachment.uuid),
        });
      resetStates();
    }
  };

  return (
    <Dialog
      open={open}
      classes={{
        paper: classes.container,
      }}
      data-testid="appealRequestModal"
    >
      {title && (
        <DialogTitle
          data-testid="appealRequestModalTitle"
          classes={{
            root: classes.title,
          }}
        >
          {title}
        </DialogTitle>
      )}

      <DialogContent
        data-testid="appealRequestModalContent"
        classes={{
          root: classes.content,
        }}
      >
        {message && <div className={classes.message}>{message}</div>}
        {categories && categories.length > 0 && (
          <>
            <div className={classes.subtitleContainer}>
              <div className={classes.subtitle}>Appeal Categories</div>
              <div
                className={`${classes.note} ${
                  showCheckedCategoriesError ? 'error' : ''
                }`}
                data-testid="appealRequestModalErrorMessage"
              >
                You are required to select at least one category, but may select
                more
              </div>
            </div>
            <div className={classes.categories}>
              {categories.map((category) => (
                <AppealCategory
                  key={category.appealCategoryId}
                  disabled={
                    !!disabledCategoryIds &&
                    disabledCategoryIds.includes(category.appealCategoryId)
                  }
                  checked={checkedCategories.includes(
                    category.appealCategoryId
                  )}
                  title={category.displayName}
                  message={category.description}
                  onValueChange={() => handleToggle(category.appealCategoryId)}
                />
              ))}
            </div>
          </>
        )}

        <div className={classes.explanationContainer}>
          <TextField
            classes={{ root: classes.explanation }}
            fullWidth
            error={!!explanationError}
            label="Explanation"
            value={explanation}
            onChange={handleExplanationChange}
            onBlur={handleExplanationBlur}
            inputProps={{
              'data-testid': 'appealRequestModalExplanationInput',
            }}
          />
          <div
            className={classes.explanationNoteContainer}
            data-testid="appealRequestModalExplanationErrorContainer"
          >
            <span className={classes.error} style={{ flex: 1 }}>
              {explanationError}
            </span>
            <span
              className={
                explanation.length > MAX_EXPLANATION_LENGTH ? classes.error : ''
              }
            >
              {explanation.length}/{MAX_EXPLANATION_LENGTH}
            </span>
          </div>
        </div>

        <div className={classes.multimediaContainer}>
          <SampleMultimediaUploader
            loading={loading}
            onChange={handleUpload}
            disabled={attachments.length >= ATTACHMENT_LIMIT}
            max={ATTACHMENT_LIMIT}
            data-testid="appealRequestModalFileUploader"
          />
          <MultimediaList
            title="Sample Multimedia Files"
            emptyMessage="No files have been attached"
            editable
            attachments={attachments}
            onDownload={handleDownload}
            onSelect={handlePreview}
            onDelete={handleDelete}
            loading={
              attachmentPreviewIndex > -1 &&
              !attachments[attachmentPreviewIndex].file
            }
            data-testid="appealRequestSampleMultiMediaList"
            style={{ minHeight: 160 }}
          />
        </div>
      </DialogContent>

      <DialogActions
        classes={{
          root: classes.actions,
        }}
        data-testid="appealRequestModalDialogActions"
      >
        <Button
          variant="outline"
          onClick={handleClose}
          data-testid="appealRequestModalDialogCancelButton"
        >
          Cancel
        </Button>
        <Button
          disabled={showCheckedCategoriesError || !!explanationError}
          onClick={handleSubmit}
          data-testid="appealRequestModalDialogSubmitButton"
        >
          Submit Appeal
        </Button>
      </DialogActions>

      {attachmentPreviewIndex > -1 &&
        attachments[attachmentPreviewIndex].file && (
          <AttachmentPreview
            onClose={() => setAttachmentPreviewIndex(-1)}
            file={attachments[attachmentPreviewIndex].file}
            mimeType={attachments[attachmentPreviewIndex].mimeType}
            data-testid="appealRequestModalBackdrop"
          />
        )}
    </Dialog>
  );
};

export default AppealRequestModal;
