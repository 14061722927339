import PropTypes from 'prop-types';
import React from 'react';
import parse from 'html-react-parser';
import { Dialog, DialogContent, makeStyles } from '@material-ui/core';

import { S3_ASSETS_PATH } from '../../constants';

const useStyles = makeStyles({
  container: {
    maxWidth: '525px',
  },
});

const SuccessDialogue = (props) => {
  const classes = useStyles();

  return (
    <Dialog
      open={props.open}
      aria-labelledby="responsive-dialog-title"
      data-testid="successModal"
      maxWidth="xs"
      fullWidth={true}
      id="successModal"
      className={`success-modal ${props.className}`}
      classes={{ paper: classes.container }}
    >
      <DialogContent className={`success-content-blk text-center`}>
        <img
          src={
            props.icon
              ? props.icon
              : `${S3_ASSETS_PATH}/images/payment-success.svg`
          }
          alt=""
        />
        <p className="heading2">{props.title}</p>
        {props.content ? (
          <p className="paragraph">{parse(props.content)}</p>
        ) : null}
        <a
          onClick={props.handleOk}
          className="primary-btn"
          data-testid="successModalCloseButton"
        >
          Close
        </a>
      </DialogContent>
    </Dialog>
  );
};

SuccessDialogue.propTypes = {
  open: PropTypes.bool.isRequired,
};
export default SuccessDialogue;
