import PropTypes from 'prop-types';
import { CampaignAttributes } from 'portal-commons';

const CampaignAttributeNew = (props) => {
  const {
    additionalSetup,
    additionalSetupActive,
    setAdditionalSetupActive,
    id,
    disabled,
    error,
    ...restProps
  } = props;
  const isViewMode = props.type === 'view';
  const value = isViewMode ? props.value : props.campaignInfo[id];
  const errorMessage = typeof error === 'object' ? error[id] ?? '' : error;

  const disabledOption = (() => {
    if (disabled) return 'all';
    if (props.singleVal) return 'no';
    if (isViewMode) {
      if (typeof value === 'boolean') return value ? 'no' : 'yes';
      return 'all';
    }
    return undefined;
  })();

  const handleCheckBoxChange = (value) => {
    props.handleChange(value, props.id, props.label);
  };

  return (
    <CampaignAttributes.Attribute
      id={id}
      label={props.label}
      tooltip={props.tooltip}
      notes={props.notes}
      value={value}
      disabledOption={disabledOption}
      disabledTooltip={disabled || disabledOption === 'all'}
      additionalSetup={additionalSetup}
      onChange={isViewMode ? undefined : handleCheckBoxChange}
      open={additionalSetupActive}
      onVisibilityChange={setAdditionalSetupActive}
      error={errorMessage}
      {...restProps}
    />
  );
};

CampaignAttributeNew.propTypes = {
  additionalSetup: PropTypes.node,
  additionalSetupActive: PropTypes.bool,
  setAdditionalSetupActive: PropTypes.func,
  campaignInfo: PropTypes.object,
  type: PropTypes.string,
  disabled: PropTypes.bool,
};

export default CampaignAttributeNew;
