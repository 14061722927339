import {
  globalPostService,
  globalGetService,
  globalPutService,
  globalDeleteService,
} from '../../../utils/globalApiServices';
import apiInstance from '../../../utils/apiInterceptor';
import { toastFlashMessage } from '../../../utils';
import { getTimezone } from '../../../utils/time';
import queryString from 'query-string';

export function getBrandsList() {
  globalGetService('csp/brands', { recordsPerPage: 1000 }).then((response) => {
    if (response.status >= 200 && response.status < 300) {
      this.setState({
        loader: false,
        brands: response.data.records,
      });
    }
  });
}
export function getUseCaseTypes(brandId) {
  return globalGetService(
    `csp/campaign-requests/builder/brand/${brandId}/usecase`
  );
}
export function getVerticalTypes(brandId, campaignClass, usecaseType) {
  globalGetService(
    `csp/campaign-requests/builder/brand/${brandId}/campaignClass/${campaignClass}/usecase/${usecaseType}/vertical`
  ).then((response) => {
    this.setState((prevState) => ({
      ...prevState,
      campaignInfo: {
        ...prevState.campaignInfo,
        vertical: '',
        description: '',
        embeddedLink: '',
      },
      verticalTypes: response.data,
      checked: false,
      matchingRules: '',
      sampleMessage: {},
    }));
  });
}
export function getMatchingRules(
  brandId,
  campaignClass,
  usecaseType,
  verticalType
) {
  globalGetService(
    `csp/campaign-requests/builder/brand/${brandId}/campaignClass/${campaignClass}/usecase/${usecaseType}/vertical/${verticalType}/rule`
  ).then((response) => {
    // console.log('matchingRules response', response)
    const matchingRules = response.data[0] ? response.data[0] : '';
    this.setState((prevState) => ({
      ...prevState,
      matchingRules,
      campaignInfo: {
        ...prevState.campaignInfo,
        description: '',
        embeddedLink: '',
      },
      checked: false,
      sampleMessage: {},
      annualPrice: matchingRules ? matchingRules.annualPrice : '',
    }));
  });
}
export function getCampaignsList(query = {}) {
  this.setState({ tableLoader: true });
  globalGetService('csp/campaigns', { ...query }).then((response) => {
    this.setState({ tableLoader: false });
    if (response.status >= 200 && response.status < 300) {
      const campaignInfo = {
        ...response.data,
        totalRecords: Math.min(response.data.totalRecords, 10000), // Cap total records since ElasticSearch returns up to 10000 records by default
        displayTotal: response.data.totalRecords, // Keep original count for display in campaign listing page
      };
      this.setState((prevState) => ({
        ...prevState,
        campaignInfo,
        loader: false,
        noCampaignsFound:
          response.data.totalRecords == 0 &&
          Object.keys(this.state.appliedFilters).length == 0
            ? true
            : false,
      }));
      if (
        campaignInfo.records.length == 0 &&
        campaignInfo.totalRecords > 0 &&
        campaignInfo.page > 1
      ) {
        const lastPageNo = Math.ceil(campaignInfo.totalRecords / 10);
        this.setState({
          loader: true,
        });
        this.props.history.push({
          search: `?${queryString.stringify(
            { ...query, page: lastPageNo },
            { encode: true }
          )}`,
        });
        // this.getCampaignsListApi({...query, page: lastPageNo})
      }
    }
  });
}

export async function checkIfCampaignIsASharedCampaign(
  campaignUid,
  params = {}
) {
  // check if a campaign is a CNP campaign by calling csp/campaigns/sharedWithMe.
  // If it has record return true then it means that it is a CNP campaign
  const response = await globalGetService('csp/campaigns/sharedWithMe', {
    campaignUid,
    ...params,
  });
  if (response.status >= 200 && response.status < 300) {
    return response.data?.totalRecords > 0;
  }
  return false;
}

export function getCampaignRequestDetails(campaignRequestId) {
  return globalGetService(`csp/campaign-requests/${campaignRequestId}`);
}
export function getCampaignDetailsApi(campaignUid, params) {
  return globalGetService(`csp/campaigns/${campaignUid}`, params).then(
    (response) => {
      if (response.status >= 200 && response.status < 300) {
        const campaign = response.data;
        this.setState({
          campaign,
          loader: false,
          campaignUid,
          optinKeywords: campaign.optinKeywords
            ? campaign.optinKeywords.split(',')
            : [],
          optinMessage: campaign.optinMessage ? campaign.optinMessage : '',
          optoutKeywords: campaign.optoutKeywords
            ? campaign.optoutKeywords.split(',')
            : [],
          optoutMessage: campaign.optoutMessage ? campaign.optoutMessage : '',
          helpKeywords: campaign.helpKeywords
            ? campaign.helpKeywords.split(',')
            : [],
          helpMessage: campaign.helpMessage ? campaign.helpMessage : '',
        });
        if (campaign.resellerUid) {
          this.getResellerDetailApi(campaign.resellerUid);
        } else {
          this.setState({ resellerInfo: '' });
        }
        return campaign;
      }
    }
  );
}
export function getResellerDetailApi(resellerUid) {
  return globalGetService(`csp/reseller/${resellerUid}`).then((response) => {
    if (response.status >= 200 && response.status < 300) {
      const resellerInfo = response.data;
      this.setState({ resellerInfo });
    }
  });
}

export function getCampaignPrimaryDcaApi(campaignUid) {
  globalGetService(`csp/campaigns/${campaignUid}/primaryDca`).then(
    (response) => {
      if (response.status >= 200 && response.status < 300) {
        const dcaInfo = response.data;
        this.setState({ dcaInfo });
      }
    }
  );
}

export function getCampaignOfferingsbyBrand(brandId) {
  return globalGetService(`csp/campaign-requests/builder/brand/${brandId}`);
}
export function getListOfCampaignUsecases() {
  globalGetService(`enums/usecaseTypes`).then((response) => {
    if (response.status >= 200 && response.status < 300) {
      this.setState({
        usecases: response.data,
      });
    }
  });
}
export function registerSubscription(campaignRequestId) {
  return globalPostService('csp/payment/subscription', campaignRequestId);
}
export function findAllUsecaseTypes() {
  return globalGetService('enums/usecaseTypes');
}
export function findAllVerticalTypes(flag) {
  if (flag == 'filter') {
    return globalGetService('enums/verticalTypes');
  } else {
    globalGetService('enums/verticalTypes').then((response) => {
      if (response.status >= 200 && response.status < 300) {
        this.setState((prevState) => ({
          ...prevState,
          verticalTypes: response.data,
        }));
      }
    });
  }
}
export function getListOfBrandUsecases(brandUid) {
  globalGetService(`csp/campaign/builder/brand/${brandUid}`).then(
    (response) => {
      if (response.status >= 200 && response.status < 300) {
        const brandUsecases = response.data;
        this.setState((prevState) => ({
          ...prevState,
          brandUsecases,
        }));
        const { usecase } = this.state;
        if (usecase && usecase.id) {
          const selectedUsecase = brandUsecases.find(
            (item) => item.usecase == usecase.id
          );
          let qualify = false;
          if (selectedUsecase && selectedUsecase.mnoMetadata) {
            qualify = Object.keys(selectedUsecase.mnoMetadata).some(
              (key) => selectedUsecase.mnoMetadata[key].qualify === true
            );
          }
          if (!qualify) {
            this.handleChange('', 'usecase');
          }
        }
      }
    }
  );
}
export function findAllBrands() {
  return globalGetService('csp/brands', { recordsPerPage: 1000 });
}
export function buildCampaignRequest(brandUid, usecase) {
  globalGetService(
    `csp/campaign/builder/brand/${brandUid}/usecase/${usecase}`
  ).then((response) => {
    this.setState({ loadFlag: false });
    if (response.status >= 200 && response.status < 300) {
      const mnoInfo = response.data;
      if (mnoInfo.mnoMetadata) {
        const flag = Object.keys(mnoInfo.mnoMetadata).find((key, index) => {
          return mnoInfo.mnoMetadata[key].qualify === true;
        });
        this.setState((prevState) => ({
          ...prevState,
          mnoInfo,
          // activeMno : flag ? mnoInfo.mnoMetadata[flag] : {},
          mnoStatusFlag: flag ? true : false,
          step: 2,
          openDisclaimer: true,
          campaignInfo: {
            ...prevState.campaignInfo,
            mnoIds: Object.keys(mnoInfo.mnoMetadata)
              .map((key) => mnoInfo.mnoMetadata[key].qualify && key)
              .filter((val) => val !== false),
          },
        }));
      }
    }
  });
}
export function getCampaignOperationStatusApi(campaignUid) {
  globalGetService(`csp/campaigns/${campaignUid}/operationStatus`).then(
    (response) => {
      if (response.status >= 200 && response.status < 300) {
        this.setState((prevState) => ({
          ...prevState,
          mnoStatusInfo: response.data,
        }));
      }
    }
  );
}
export function getCampaignOperationsApi(campaignUid, queries = {}) {
  return globalGetService(
    `csp/campaigns/${campaignUid}/operations`,
    queries
  ).then((response) => {
    if (response.status >= 200 && response.status < 300) {
      const mnoStatusInfo = response.data.records;
      mnoStatusInfo.forEach((item) => {
        item.rulesEngineResultsJson = JSON.parse(item.rulesEngineResultsJson);
        item.osrAttributesJson = JSON.parse(item.osrAttributesJson);
      });
      this.setState((prevState) => ({
        ...prevState,
        mnoStatusInfo,
      }));
    }
  });
}
export function getAllDcaApi() {
  globalGetService(`csp/dca`, { recordsPerPage: 1000 }).then((response) => {
    if (response.status >= 200 && response.status < 300) {
      const dca = response.data.records;
      this.setState({
        dca,
      });
    }
  });
}
export function fetchAllResellers() {
  return globalGetService(`csp/reseller`, { recordsPerPage: 1000 });
}
export function getAllResellers() {
  fetchAllResellers().then((response) => {
    if (response.status >= 200 && response.status < 300) {
      const resellers = response.data.records;
      this.setState({
        resellers,
      });
    }
  });
}

export function updateCampaignApi(id, data) {
  this.setState({ editLoader: true });
  return globalPutService(`csp/campaigns/${id}`, data).then((response) => {
    this.setState({ editLoader: false });
    if (response.status >= 200 && response.status < 300) {
      toastFlashMessage('Campaign updated successfully', 'success');
      const campaign = response.data;
      campaign.brandName = this.state.campaign.brandName
        ? this.state.campaign.brandName
        : '';
      this.setState({
        campaign,
        openEditModal: false,
      });
      if (campaign.resellerUid) {
        this.getResellerDetailApi(campaign.resellerUid);
      } else {
        this.setState({ resellerInfo: '' });
      }
    }
  });
}

export function deactivateCampaignApi(campaignUid) {
  // status to "EXPIRED"
  this.setState({ editLoader: true });
  return globalPutService(`csp/campaigns/${campaignUid}/deactivate`).then(
    (response) => {
      this.setState({ editLoader: false });
      if (response.status >= 200 && response.status < 300) {
        toastFlashMessage('Campaign deactivated successfully', 'success');
        const campaign = response.data;
        campaign.brandName = this.state.campaign.brandName
          ? this.state.campaign.brandName
          : '';
        this.setState({
          campaign,
          deactivateFlag: false,
        });
      }
    }
  );
}

function preProcessSharingStatusFilter(query) {
  /**
   * Notice: On CNP Campaigns Page(NOT History), the Filter "Sharing Status"
   * (use 'outSharingStatus' as parameter in query)
   * - PENDING: change to 'sharingStatus: PENDING' and delete 'outSharingStatus'
   * - REJECTED: keep the same, 'outSharingStatus: REJECTED'
   */
  if (
    query.hasOwnProperty('outSharingStatus') &&
    query.outSharingStatus === 'PENDING'
  ) {
    if (query.hasOwnProperty('sharingStatus')) {
      query.sharingStatus =
        query.sharingStatus.length === 0
          ? 'PENDING'
          : query.sharingStatus.indexOf('PENDING') === -1
          ? `${query.sharingStatus},PENDING`
          : query.sharingStatus;
    }
    delete query.outSharingStatus;
  }
  return query;
}

export function getPartnerCampaignsListApi(query = {}) {
  this.setState({ tableLoader: true });
  query = preProcessSharingStatusFilter(query);
  globalGetService(`csp/campaigns/sharedWithMe`, query).then((response) => {
    this.setState({ tableLoader: false });
    if (response.status >= 200 && response.status < 300) {
      this.setState({
        partnerCampaignsInfo: response.data,
        loader: false,
      });
      if (
        response.data.records.length === 0 &&
        response.data.totalRecords > 0 &&
        response.data.page > 1
      ) {
        const lastPageNo = Math.ceil(response.data.totalRecords / 10);
        this.setState({
          loader: true,
        });
        this.props.history.push({
          search: `?${queryString.stringify(
            { ...query, page: lastPageNo },
            { encode: true }
          )}`,
        });
      }
    }
  });
}

export function getCnpListApi(query = {}) {
  return globalGetService(`csp/cnp`, {
    ...query,
  }).then((response) => {
    if (response.status >= 200 && response.status < 300) {
      this.setState({
        cnpOptions: response.data.map((item) => {
          return { label: item.displayName, value: item.uid };
        }),
      });
    }
  });
}

export function getSPCnpListApi(queries = {}) {
  return globalGetService(`csp/cnp`, {
    soleProprietorEnabled: true,
    ...queries,
  }).then((response) => {
    if (response.status >= 200 && response.status < 300) {
      this.setState({
        SPCnpOptions: response.data.map((item) => {
          return { label: item.displayName, value: item.uid };
        }),
      });
    }
  });
}

export function nominateCnpApi(campaignUid, upstreamCnpUid) {
  return globalPutService(
    `csp/campaigns/sharedByMe/${campaignUid}/with/${upstreamCnpUid}`
  );
}

export function rejectCnpNominationApi(campaignUid, explanation) {
  return globalDeleteService(`csp/campaigns/sharedWithMe/${campaignUid}`, {
    explanation,
  });
}

export function listCampaignElectedDcaApi(campaignUid, params) {
  return globalGetService(`csp/campaigns/${campaignUid}/mnoIds`, params).then(
    (response) => {
      if (response.status >= 200 && response.status < 300) {
        return response.data;
      }
    }
  );
}

export function getNominationStatusApi(campaignUid, params) {
  return globalGetService(
    `csp/campaigns/sharedByMe/${campaignUid}`,
    params
  ).then((response) => {
    if (response.status >= 200 && response.status < 300) {
      const nominationInfo = response.data ? response.data : {};
      return {
        nominationInfo,
        openAlert: nominationInfo.status
          ? ['DECLINED'].includes(nominationInfo.status)
            ? true
            : false
          : true,
      };
    }
  });
}

export const uploadAttachmentApi = async (file) => {
  const data = new FormData();
  data.append('file', file);
  const response = await globalPostService('csp/attachments', data);
  if (response.status >= 200 && response.status < 300) {
    return response.data;
  }
};

export const uploadAttachmentForCampaignApi = async (
  campaignUid,
  folder,
  file
) => {
  const data = new FormData();
  data.append('file', file);
  const response = await globalPostService(
    `csp/attachments/campaign/${campaignUid}/folder/${folder}`,
    data
  );
  if (response.status >= 200 && response.status < 300) {
    return response.data;
  }
};

export const deleteAttachmentApi = async (attachmentUuid) => {
  const response = await globalDeleteService(
    `csp/attachments/delete?attachmentUuid=${encodeURIComponent(
      attachmentUuid
    )}`
  );
  if (response.status >= 200 && response.status < 300) {
  }
};

export const getCampaignAttachmentsApi = async (campaignUid, folder) => {
  const response = await globalGetService(
    `csp/attachments/campaign/${campaignUid}?folder=${encodeURIComponent(
      folder
    )}`
  );
  if (response.status >= 200 && response.status < 300) {
    return response.data;
  }
  return [];
};

export const downloadAttachmentApi = async (attachmentUuid) => {
  const response = await globalGetService(
    `csp/attachments/download?attachmentUuid=${encodeURIComponent(
      attachmentUuid
    )}`,
    {},
    'arraybuffer'
  );
  if (response.status >= 200 && response.status < 300) {
    return response.data;
  }
};

export const downloadAttachmentToDesktop = async (attachmentUuid, fileName) => {
  const response = await globalGetService(
    `csp/attachments/download?attachmentUuid=${encodeURIComponent(
      attachmentUuid
    )}`,
    {},
    'blob'
  );
  const url = window.URL.createObjectURL(new Blob([response.data]));
  const link = document.createElement('a');
  link.href = url;
  link.setAttribute('download', fileName);
  document.body.appendChild(link);
  link.click();

  // Clean up and remove the link
  link.parentNode.removeChild(link);
};

export const downloadCampaignsAsCsv = async (query = {}) => {
  const response = await globalGetService(
    'csp/campaigns/csv',
    {
      ...query,
      timezone: getTimezone(),
    },
    'arraybuffer'
  );
  if (response.status >= 200 && response.status < 300) {
    return response.data;
  }
};

export const downloadCnpCampaignsAsCsv = async (query = {}) => {
  query = preProcessSharingStatusFilter(query);
  const response = await globalGetService(
    'csp/campaigns/sharedWithMe/csv',
    {
      ...query,
    },
    'arraybuffer'
  );
  if (response.status >= 200 && response.status < 300) {
    return response.data;
  }
};

export const findAllCnps = async (query = {}) => {
  const response = await globalGetService('csp/cnp', {
    ...query,
  });
  if (response.status >= 200 && response.status < 300) {
    return response.data;
  }
};

export const submitCampaignRequest = async (brandUid, usecase, data) => {
  const { upstreamCnpUid, ...parameters } = data;
  const response = await globalPostService(
    `csp/campaign/builder/brand/${brandUid}/usecase/${usecase}`,
    parameters
  );
  if (response.status >= 200 && response.status < 300) {
    try {
      await globalPutService(
        `csp/campaigns/sharedByMe/${response.data.campaignUid}/with/${upstreamCnpUid}`,
        parameters
      );
    } catch (e) {}
    return response.data;
  }
};

export const getProfile = async () => {
  const response = await globalGetService('csp/profile');
  if (response.status >= 200 && response.status < 300) {
    return response.data;
  }
  throw new Error('API error');
};

export const getCampaignTermsAndConditions = async () => {
  const response = await globalGetService(
    'csp/campaign/builder/termsAndConditions'
  );
  if (response.status >= 200 && response.status < 300) {
    return response.data.subTerms;
  }
  throw new Error('API error');
};

export const getEventHistory = async (query = {}) => {
  const response = await globalGetService('csp/event', {
    ...query,
    sortField: 'createDate',
    ascendingOrder: false,
  });
  if (response && response.status >= 200 && response.status < 300) {
    const eventInfo = response.data;
    eventInfo.recordsPerPage = eventInfo.recordsPerPage
      ? eventInfo.recordsPerPage
      : 10;
    return eventInfo;
  }
};

export const nudgeUpstreamCnp = async (campaignUid, intent, description) => {
  const response = await globalPostService(
    `csp/campaigns/${campaignUid}/nudge`,
    {
      intent,
      description,
    }
  );
  if (response.status >= 200 && response.status < 300) {
    return response.data;
  }
  throw new Error('Unexpected API error');
};

export const getBuilderInfo = async (brandUid, usecase) => {
  const response = await globalGetService(
    `csp/campaign/builder/brand/${brandUid}/usecase/${usecase}`
  );
  if (response.status >= 200 && response.status < 300) {
    return response.data;
  }
  throw new Error('Unexpected API error');
};

export const resubmitCampaign = async (campaignUid, mnoIds) => {
  const response = await globalPutService(
    `csp/campaigns/${campaignUid}/resubmit?${mnoIds
      .map((mnoId) => `mnoIds=${mnoId}`)
      .join('&')}`
  );
  if (response.status >= 200 && response.status < 300) {
    return response.data;
  }
  throw new Error('Unexpected API error');
};

export const getMnoList = async () => {
  const response = await globalGetService('enums/mno');
  if (response.status >= 200 && response.status < 300) {
    return response.data;
  }
  throw new Error('Unexpected API error');
};

export const updateCampaign = async (campaignUid, data) => {
  const response = await globalPutService(`csp/campaigns/${campaignUid}`, data);
  if (response.status >= 200 && response.status < 300) {
    return response.data;
  }
  throw new Error('Unexpected API error');
};

export const queryCampaignPhoneNumbers = async (campaignUid, query = {}) => {
  const response = await apiInstance.get(
    `csp/campaigns/${campaignUid}/phoneNumbers`,
    {
      params: query,
      validateStatus: null,
    }
  );
  if (response.status >= 200 && response.status < 300) {
    return response.data;
  }
  return null;
};

export const getCampaignPhoneNumbersOnly = async (campaignUid, query = {}) => {
  const response = await globalGetService(
    `csp/campaigns/${campaignUid}/phoneNumbersOnly`,
    query
  );
  if (response.status >= 200 && response.status < 300) {
    return response.data;
  }
  return null;
};

export const downloadCampaignPhoneNumbers = async (campaignUid, query = {}) => {
  const response = await globalGetService(
    `csp/campaigns/${campaignUid}/phoneNumbers/csv`,
    { ...query, timezone: getTimezone() },
    'arraybuffer'
  );
  if (response && response.status >= 200 && response.status < 300) {
    return response.data;
  }
  return null;
};

export const getCampaignRoles = async (campaignUid) => {
  try {
    const response = await globalGetService(
      `csp/campaigns/${campaignUid}/roles`
    );
    if (response.ok) return response.data;
    return null;
  } catch {
    return null;
  }
};

export const getMigrationCNP = async (campaignUid, params, configs = {}) => {
  const { silent = false } = configs;
  try {
    const response = await apiInstance.get(
      `csp/campaigns/${campaignUid}/migrateCnp`,
      {
        params,
        validateStatus: silent ? null : undefined,
      }
    );

    if (response.ok) {
      return response.data;
    }
    return null;
  } catch {
    return null;
  }
};
