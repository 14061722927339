import { Profile, UserCreatePayload } from './types';
import {
  globalDeleteService,
  globalGetService,
  globalPutService,
} from '../../../utils/globalApiServices';

export const getProfile = async (data = {}) => {
  const response = await globalGetService('csp/profile', data);
  if (response.ok) {
    const data = response.data;
    return {
      ...data,
      emails: data.email?.split(',') || [],
      escalateEmails: data.escalateEmail?.split(',') || [],
      financeEmails: data.financeEmail?.split(',') || [],
      techEmails: data.techEmail?.split(',') || [],
    } as Profile;
  }
};

export const updateEmailPreferences = async (data = {}) => {
  const response = await globalPutService('csp/profile/email', data);
  if (response.ok) {
    return response.data;
  }
};

export const createUser = async (data = {}) => {
  const response = await globalPutService('csp/manage/users', data);
  if (response.ok) {
    return data;
  }
};

export const updateUser = async (id: string, data = {}) => {
  const response = await globalPutService(`csp/manage/users/${id}`, data);
  if (response.ok) {
    return data;
  }
};

export const deleteUser = async (id: string) => {
  const response = await globalDeleteService(
    `csp/manage/users/${id}/remove`,
    {}
  );
  if (response.ok) {
    return id;
  }
};

export const getUsers = async (data = {}) => {
  const response = await globalGetService('csp/manage/users', data);
  if (response.ok) {
    return response.data;
  }
};

function reorderRoles(roles: string[]): string[] {
  const predefinedOrder: string[] = [
    'ROLE_REVIEWER',
    'ROLE_USER',
    'ROLE_MANAGER',
  ];
  const uniqueRoles: string[] = [...new Set(roles)]; // Ensure unique roles

  const orderedRoles: string[] = [
    ...predefinedOrder,
    ...uniqueRoles.filter((role) => !predefinedOrder.includes(role)),
  ];

  return orderedRoles.filter((role) => uniqueRoles.includes(role));
}

export const getRoles = async (data = {}) => {
  const response = await globalGetService('csp/manage/users/roles', data);
  if (response.ok) {
    return reorderRoles(response.data) as string[];
  }
};

export const getBrandCount = async (data = {}) => {
  const response = await globalGetService(
    'csp/statistics/brand?excludeSoleProprietor=true',
    data
  );
  if (response.ok) {
    return response.data.total as number;
  }
};

export const updateMfaEnabled = async (enabled: boolean) => {
  let response;
  if (enabled) {
    response = await globalPutService('csp/manage/users/mfa');
  } else {
    response = await globalDeleteService('csp/manage/users/mfa');
  }
  if (response.ok) {
    return true;
  }
};
