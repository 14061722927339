import React from 'react';

export default function CustomSwitch(props) {
  const { val, handleChange, disabled, testId } = props;
  return (
    <div
      data-testid={testId}
      className={`custom-switch-wrapper ${val === true ? 'yes' : 'no'} ${
        disabled ? 'disabled' : ''
      }`}
      onClick={handleChange}
    >
      {val === true ? (
        <>
          Yes <span></span>
        </>
      ) : (
        <>
          <span></span>No
        </>
      )}
    </div>
  );
}
