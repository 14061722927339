import React, { Component } from 'react';
import { Link, withRouter } from 'react-router-dom';
import queryString from 'query-string';
import { TextField, Tooltip, InputAdornment } from '@material-ui/core';
import { Visibility, VisibilityOff } from '@material-ui/icons';
import { resetPasswordApi, getUserFromTokenApi } from '../apiServices';
import { fieldValidation } from '../../../utils/validator';
import { toastFlashMessage } from '../../../utils';
import regexExpressions from '../../../constants/regexExpressions';
import { S3_ASSETS_PATH } from '../../../constants';
import { withStyles } from '@material-ui/core/styles';

import { Button, Box } from 'portal-commons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEye, faEyeSlash } from '@fortawesome/pro-regular-svg-icons';

const CustomTooltip = withStyles((theme) => ({
  tooltip: {
    background: '#fff',
    color: '#0091B3',
    fontSize: 12,
    // borderRadius: '8px',
    fontFamily: 'Roboto',
    fontWeight: 400,
    zIndex: 9999999,
    padding: '0px',
  },
  arrow: {
    color: 'rgba(0, 145, 179, 0.2)',
  },
}))(Tooltip);

export default withRouter(
  class ResetPasswordForm extends Component {
    constructor(props) {
      super(props);
      this.state = {
        userInfo: {
          newPassword: '',
          confirmPassword: '',
        },
        error: {},
        errorCode: {
          newPassword: {
            0: '',
            1: 'Please enter New Password',
            4: 'Invalid Combination',
          },
          confirmPassword: {
            0: '',
            1: 'Please enter Confirm Password',
            4: 'Invalid Combination',
            5: "Those passwords didn't match, Try again.",
          },
          newPasswordObj: {
            requiredFlag: true,
            regexPattern: regexExpressions.password,
          },
          confirmPasswordObj: {
            matchValues: true,
            requiredFlag: true,
            regexPattern: regexExpressions.password,
          },
        },
        passwordValidation: {
          minCharacter: false,
          oneNumber: false,
          oneUpperCase: false,
          oneLowerCase: false,
          oneSymbol: false,
        },
        loader: false,
        showPassword: false,
        validationText: {
          minCharacter: 'At least 12 characters',
          oneNumber: 'A number',
          oneUpperCase: 'An upper case letter (A-Z)',
          oneLowerCase: 'A lower case letter (a-z)',
          oneSymbol: 'A symbol',
        },
      };
    }

    componentDidMount() {
      if (queryString.parse(this.props.location.search).token) {
        getUserFromTokenApi({
          token: queryString.parse(this.props.location.search).token,
        }).then((response) => {});
      }
    }

    handleChange = (value, key) => {
      this.setState((prevState) => ({
        ...prevState,
        userInfo: {
          ...prevState.userInfo,
          [key]: value,
        },
        error: {
          ...prevState.error,
          [key]: '',
        },
      }));
      if (key == 'newPassword') {
        this.setState({
          passwordValidation: {
            ...this.state.passwordValidation,
            minCharacter: this.hasLength(value),
            oneNumber: this.hasNumber(value),
            oneUpperCase: this.hasUpperCase(value),
            oneLowerCase: this.hasLowerCase(value),
            oneSymbol: this.hasSymbol(value),
          },
        });
      }
    };

    handleError = (value, key) => {
      const { errorCode } = this.state;
      this.setState((prevState) => ({
        ...prevState,
        error: {
          ...prevState.error,
          [key]:
            errorCode[key][
              fieldValidation({
                ...errorCode[`${key}Obj`],
                fieldval: value,
                fieldvalConfirm:
                  key == 'confirmPassword'
                    ? prevState.userInfo.newPassword
                    : '',
              })
            ],
        },
      }));
    };

    hasNumber = (str) => {
      return /\d/.test(str);
    };

    hasLength = (str) => {
      if (str.length >= 12) {
        return true;
      } else {
        return false;
      }
    };

    hasLowerCase = (str) => {
      return /[a-z]/.test(str);
    };

    hasUpperCase = (str) => {
      return /[A-Z]/.test(str);
    };

    hasSymbol = (str) => {
      return /[`!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?~]/.test(str);
    };

    resetPassword = (e) => {
      e.preventDefault();
      const { errorCode, userInfo } = this.state;
      this.setState({
        loader: true,
      });
      const validateNewInput = {
        newPassword:
          errorCode.newPassword[
            fieldValidation({
              ...errorCode.newPasswordObj,
              fieldval: userInfo.newPassword,
            })
          ],
        confirmPassword:
          errorCode.confirmPassword[
            fieldValidation({
              ...errorCode.confirmPasswordObj,
              fieldval: userInfo.confirmPassword,
              fieldvalConfirm: userInfo.newPassword,
            })
          ],
      };
      if (
        Object.keys(validateNewInput).every((k) => {
          return validateNewInput[k] === '';
        })
      ) {
        resetPasswordApi({
          newPassword: userInfo.newPassword,
          token: queryString.parse(this.props.location.search).token,
        }).then((response) => {
          this.setState({
            loader: false,
          });
          if (response.status >= 200 && response.status < 300) {
            toastFlashMessage(
              `${
                this.props.location.pathname.includes('reset-password')
                  ? 'Password has been reset successfully'
                  : 'Password has been set successfully'
              }`,
              'success'
            );
            this.props.history.push('/login');
          }
        });
      } else {
        this.setState({
          error: validateNewInput,
          loader: false,
        });
      }
    };

    togglePasswordMask = () => {
      this.setState((prevState) => ({
        ...prevState,
        showPassword: !prevState.showPassword,
      }));
    };

    render() {
      const { userInfo, error, loader, validationText, passwordValidation } =
        this.state;
      return (
        <div
          className="auth-user-form reset-password"
          data-testid="resetPasswordForm"
        >
          <form onSubmit={this.resetPassword}>
            <div className="form-title">
              <h3 className="heading1">
                <img
                  src={`${S3_ASSETS_PATH}/images/${
                    this.props.location.pathname.includes('reset-password')
                      ? 'reset-pswd'
                      : 'set-pswd'
                  }.svg`}
                />
                {this.props.location.pathname.includes('reset-password')
                  ? 'Reset your password'
                  : 'Set your password'}
              </h3>
              <p className="paragraph">
                {this.props.location.pathname.includes('reset-password')
                  ? 'You can now reset your password to login to your account with your registered email address'
                  : 'You can now set your password to login to your account with your registered email address'}
              </p>
            </div>
            <ul className="list-unstyled">
              <li>
                <div className="form-group-field passwordGroup">
                  {/* <ToolTip title="Your password must be at least 8 characters long, containing one uppercase letter, one lowercase letter, and one number." open={openTooltip} onFocus={true} passwordFlag={true}/> */}
                  <CustomTooltip
                    title={
                      <div className="customTooltipWrapper">
                        <img
                          src={`${S3_ASSETS_PATH}/images/password_suggestion_bell.svg`}
                          className="bellIcon"
                        />
                        <p className="secondaryText boldText">
                          Password must include:
                        </p>
                        <ul className="list-unstyled">
                          {[
                            'minCharacter',
                            'oneNumber',
                            'oneUpperCase',
                            'oneLowerCase',
                            'oneSymbol',
                          ].map((item, index) => (
                            <li className="secondaryText flex-centered">
                              {passwordValidation[item] ? (
                                <>
                                  <img
                                    src={`${S3_ASSETS_PATH}/images/radio_checked_password.svg`}
                                  />
                                  <span>{validationText[item]}</span>
                                </>
                              ) : (
                                <>
                                  {userInfo.newPassword.trim().length !== 0 && (
                                    <>
                                      <img
                                        src={` ${S3_ASSETS_PATH}/images/radio_unchecked_password_error.svg`}
                                      />
                                      <span className="red">
                                        {validationText[item]}
                                      </span>
                                    </>
                                  )}
                                  {userInfo.newPassword.trim().length === 0 && (
                                    <>
                                      <img
                                        src={` ${S3_ASSETS_PATH}/images/radio_unchecked_password.svg`}
                                      />
                                      <span>{validationText[item]}</span>
                                    </>
                                  )}
                                </>
                              )}
                            </li>
                          ))}
                        </ul>
                        <p
                          className="secondaryText boldText"
                          style={{ marginTop: 8 }}
                        >
                          Password cannot include:
                        </p>
                        <ul className="list-styled secondary list-suggestion">
                          <li className="secondaryText">First or Last name</li>
                          <li className="secondaryText">
                            Parts of your username
                          </li>
                          <li className="secondaryText">
                            One of the last five passwords
                          </li>
                        </ul>
                      </div>
                    }
                    arrow={true}
                    placement="right"
                    disableHoverListener
                  >
                    <TextField
                      error={error.newPassword ? true : false}
                      value={userInfo.newPassword}
                      id="newPassword"
                      data-testid="resetPasswordFormNewPasswordInput"
                      type={this.state.showPassword ? 'text' : 'password'}
                      label="New Password"
                      fullWidth={true}
                      required={true}
                      InputProps={{
                        endAdornment: (
                          <InputAdornment
                            position="end"
                            style={{ cursor: 'pointer' }}
                          >
                            {this.state.showPassword ? (
                              <FontAwesomeIcon
                                icon={faEye}
                                style={{ fontSize: '15px', color: '#00AC9D' }}
                                onClick={this.togglePasswordMask}
                              />
                            ) : (
                              <FontAwesomeIcon
                                icon={faEyeSlash}
                                style={{ fontSize: '15px', color: '#00AC9D' }}
                                onClick={this.togglePasswordMask}
                              />
                            )}
                          </InputAdornment>
                        ),
                        'aria-label': 'password',
                      }}
                      onChange={(event) =>
                        this.handleChange(event.target.value, 'newPassword')
                      }
                      onBlur={(event) =>
                        this.handleError(event.target.value, 'newPassword')
                      }
                    />
                  </CustomTooltip>
                  {error.newPassword ? (
                    <h6 className="error-msg">{error.newPassword}</h6>
                  ) : null}
                </div>
              </li>
              <li>
                <div className="form-group-field">
                  <TextField
                    error={error.confirmPassword ? true : false}
                    id="confirmPassword"
                    data-testid="resetPasswordFormConfirmPasswordInput"
                    value={userInfo.confirmPassword}
                    type={'password'}
                    label="Confirm Password"
                    fullWidth={true}
                    required={true}
                    inputProps={{ 'aria-label': 'confirmPassword' }}
                    onChange={(event) =>
                      this.handleChange(event.target.value, 'confirmPassword')
                    }
                    onBlur={(event) =>
                      this.handleError(event.target.value, 'confirmPassword')
                    }
                  />
                  {error.confirmPassword ? (
                    <h6 className="error-msg">{error.confirmPassword}</h6>
                  ) : null}
                </div>
              </li>
              <li>
                <div
                  className="form-group-field"
                  style={{ textAlign: 'center' }}
                >
                  <input
                    type="Submit"
                    data-testid="resetPasswordFormSubmitButton"
                    disabled={
                      loader || Object.keys(error).find((k) => error[k] != '')
                        ? true
                        : false
                    }
                    value={'Submit'}
                  />
                </div>
              </li>
            </ul>
            <p className="text-center supportText account-link-option">
              Back to{' '}
              <Link to="/login" data-testid="resetPasswordFormLoginLink">
                Login
              </Link>
            </p>
          </form>
        </div>
      );
    }
  }
);
