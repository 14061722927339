import React from 'react';
import Grid from '@material-ui/core/Grid';

export default function CampaignSampleMessage(props) {
  const { index, message } = props;
  return (
    <Grid
      container
      className="sample-message-item"
      data-testid={`sampleMessageItem-${index}`}
    >
      <Grid item xs={12}>
        <div className="message-count">
          <h3>{index}</h3>
        </div>
        <p className="paragraph">{message}</p>
      </Grid>
    </Grid>
  );
}
