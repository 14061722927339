import React from 'react';
import PropTypes from 'prop-types';
import {
  Button,
  Dialog,
  DialogContent,
  Grid,
  makeStyles,
} from '@material-ui/core';

const useStyles = makeStyles({
  container: {},
  content: {
    width: 430,
    height: 207,
    paddingTop: '60px !important',
    textAlign: 'center',
    boxSizing: 'border-box',
  },
});

const DeleteSampleMultimediaModal = ({
  open,
  fileName,
  onCancel,
  onSubmit,
}) => {
  const classes = useStyles();

  return (
    <Dialog
      classes={{ container: classes.container }}
      open={open}
      onClose={onCancel}
      data-testid="deleteSampleMultimediaModal"
    >
      <DialogContent classes={{ root: classes.content }}>
        <Grid container>
          <Grid className="title" item xs={12}>
            <p
              className="paragraph"
              style={{
                lineHeight: '20px',
                wordBreak: 'break-all',
              }}
            >
              Are you sure you want to <b>delete</b>
              <br />
              <b>{fileName}</b>
            </p>
          </Grid>
          <Grid
            style={{ marginTop: 11 }}
            container
            direction="row"
            justifyContent="center"
            xs={12}
          >
            <a
              className="secondary-btn"
              onClick={onCancel}
              data-testid="deleteSampleMultimediaModalCancelButton"
            >
              Cancel
            </a>
            <a
              className="primary-btn"
              style={{ marginLeft: 20 }}
              onClick={onSubmit}
              data-testid="deleteSampleMultimediaModalDeleteButton"
            >
              Delete
            </a>
          </Grid>
        </Grid>
      </DialogContent>
    </Dialog>
  );
};

DeleteSampleMultimediaModal.propTypes = {
  open: PropTypes.bool,
  fileName: PropTypes.string,
  onCancel: PropTypes.func,
  onSubmit: PropTypes.func,
};

export default DeleteSampleMultimediaModal;
