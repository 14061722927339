import { Grid, makeStyles } from '@material-ui/core';

import { BRAND_RELATIONSHIP_SCORE } from '../../../../../constants';
import { BrandDetail } from '../../types';

const useStyles = makeStyles({
  value: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    gap: '5px',
    fontFamily: 'Roboto',
    fontWeight: 700,
    fontSize: '14px',
    lineHeight: '16px',
  },
  icon: {
    width: '22px',
    height: '22px',
    objectFit: 'cover',
  },
});

interface Props {
  brand: BrandDetail;
}

const BrandRelationshipDetails: React.FC<Props> = ({ brand }) => {
  const classes = useStyles();

  return (
    <Grid container data-testid="brandRelationshipDetails">
      <Grid item xs={6}>
        <Grid className="detail-item" container spacing={3}>
          <Grid item xs={5}>
            <h3 className="paragraph">Brand Relationship:</h3>
          </Grid>
          <Grid item xs={7}>
            {brand && brand.brandRelationshipScore > 0 && (
              <div
                className={classes.value}
                data-testid="brandRelationshipDetailsRelationship"
              >
                <img
                  className={classes.icon}
                  src={
                    BRAND_RELATIONSHIP_SCORE.find(
                      (score) =>
                        score.value === Number(brand.brandRelationshipScore)
                    )?.img
                  }
                />
                <span>
                  {
                    BRAND_RELATIONSHIP_SCORE.find(
                      (score) =>
                        score.value === Number(brand.brandRelationshipScore)
                    )?.label
                  }
                </span>
              </div>
            )}
          </Grid>
        </Grid>
      </Grid>
      <Grid item xs={6}></Grid>
    </Grid>
  );
};

export default BrandRelationshipDetails;
