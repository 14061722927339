import { BoxV2 as Box, Button, Flex, TextInput } from 'portal-commons';
import { FunctionComponent, useEffect } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { Dialog, makeStyles } from '@material-ui/core';
import { faBullhorn } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { CampaignDetail, CampaignDetailsUpdatePayload } from '../types';
import {
  createMaxLengthValidator,
  createMinLengthValidator,
  isRequired,
  isValidInput,
} from '../validators';

interface CampaignDetailsEditModalProps {
  open: boolean;
  disabled: boolean;
  campaign: CampaignDetail;
  onClose: () => void;
  onSubmit: (data: CampaignDetailsUpdatePayload) => void;
  greyOutFields?: Array<string>;
}

const useStyles = makeStyles({
  container: {
    width: '620px',
    padding: '24px 32px 8px',
  },
});

const CampaignDetailsEditModal: FunctionComponent<
  CampaignDetailsEditModalProps
> = ({ open, disabled, campaign, onClose, onSubmit, greyOutFields = [] }) => {
  const classes = useStyles();
  const { handleSubmit, control, reset } =
    useForm<CampaignDetailsUpdatePayload>({
      defaultValues: {
        description: campaign.description,
        messageFlow: campaign.messageFlow,
        privacyPolicyLink: campaign.privacyPolicyLink || '',
        termsAndConditionsLink: campaign.termsAndConditionsLink || '',
      },
    });

  useEffect(() => {
    if (open) {
      reset({
        description: campaign.description,
        messageFlow: campaign.messageFlow,
        privacyPolicyLink: campaign.privacyPolicyLink || '',
        termsAndConditionsLink: campaign.termsAndConditionsLink || '',
      });
    }
  }, [open]);

  return (
    <Dialog open={open} classes={{ paper: classes.container }}>
      <form noValidate onSubmit={handleSubmit(onSubmit)}>
        <Flex
          sx={{
            flexDirection: 'column',
            rowGap: '8px',
            color: '#333E41',
            fontFamily: 'Roboto',
          }}
        >
          <Flex
            sx={{
              flexDirection: 'row',
              justifyContent: 'center',
              columnGap: '12px',
              padding: '12px 0',
              fontWeight: 600,
              fontSize: '24px',
              lineHeight: '28px',
            }}
          >
            <FontAwesomeIcon icon={faBullhorn} />
            <Box>Campaign Details</Box>
          </Flex>
          <Box
            sx={{
              fontWeight: 400,
              fontSize: '10px',
              lineHeight: '12px',
            }}
          >
            * Indicates a Required Field
          </Box>
          <Flex sx={{ flexDirection: 'column', rowGap: '16px' }}>
            <Controller
              name="description"
              control={control}
              rules={{
                validate: {
                  isRequired,
                  isValidInput,
                  withValidMinLength: createMinLengthValidator(40),
                  withValidMaxLength: createMaxLengthValidator(4096),
                },
              }}
              render={({
                field: { value, onChange, onBlur },
                fieldState: { error = {} },
              }) => (
                <TextInput
                  required
                  multiline
                  disabled={disabled || greyOutFields.includes('description')}
                  error={!!error.message}
                  label="Campaign Description"
                  note={error.message}
                  maxLength={4096}
                  maxRows={5}
                  value={value}
                  onChange={onChange}
                  onBlur={onBlur}
                  data-testid="campaignDescriptionInput"
                />
              )}
            />
            <Controller
              name="messageFlow"
              control={control}
              rules={{
                validate: {
                  isRequired,
                  isValidInput,
                  withValidMinLength: createMinLengthValidator(40),
                  withValidMaxLength: createMaxLengthValidator(4096),
                },
              }}
              render={({
                field: { value, onChange, onBlur },
                fieldState: { error = {} },
              }) => (
                <TextInput
                  required
                  multiline
                  disabled={disabled || greyOutFields.includes('messageFlow')}
                  error={!!error.message}
                  label="Call-to-Action / Message Flow"
                  note={error.message}
                  maxLength={4096}
                  maxRows={5}
                  value={value}
                  onChange={onChange}
                  onBlur={onBlur}
                  data-testid="campaignCallToActionInput"
                />
              )}
            />
            <Controller
              name="privacyPolicyLink"
              control={control}
              rules={{
                validate: {
                  isValidInput,
                  withValidLength: createMaxLengthValidator(255),
                },
              }}
              render={({
                field: { value, onChange, onBlur },
                fieldState: { error = {} },
              }) => (
                <TextInput
                  disabled={
                    disabled || greyOutFields.includes('privacyPolicyLink')
                  }
                  error={!!error.message}
                  label="Privacy Policy Link"
                  note={error.message}
                  maxLength={255}
                  value={value}
                  onChange={onChange}
                  onBlur={onBlur}
                  data-testid="privacyPolicyLinkInput"
                />
              )}
            />
            <Controller
              name="termsAndConditionsLink"
              control={control}
              rules={{
                validate: {
                  isValidInput,
                  withValidLength: createMaxLengthValidator(255),
                },
              }}
              render={({
                field: { value, onChange, onBlur },
                fieldState: { error = {} },
              }) => (
                <TextInput
                  disabled={
                    disabled || greyOutFields.includes('termsAndConditionsLink')
                  }
                  error={!!error.message}
                  label="Terms and Conditions Link"
                  note={error.message}
                  maxLength={255}
                  value={value}
                  onChange={onChange}
                  onBlur={onBlur}
                  data-testid="termsAndConditionsLinkInput"
                />
              )}
            />
          </Flex>
          <Flex
            sx={{
              flexDirection: 'row',
              justifyContent: 'center',
              columnGap: '20px',
              padding: '24px 0 40px',
            }}
          >
            <Button variant="outline" onClick={onClose}>
              Cancel
            </Button>
            <Button type="submit" disabled={disabled}>
              Save
            </Button>
          </Flex>
        </Flex>
      </form>
    </Dialog>
  );
};

export default CampaignDetailsEditModal;
