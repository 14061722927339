import { BoxV2 as Box, Flex } from 'portal-commons';
import { FunctionComponent } from 'react';
import { IconDefinition } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

interface SubsectionProps {
  icon: IconDefinition;
  title: string;
  description: string;
  testId?: string;
}

const Subsection: FunctionComponent<SubsectionProps> = ({
  icon,
  title,
  description,
  testId,
  children,
}) => {
  return (
    <Flex sx={{ flexDirection: 'column' }} data-testid={testId}>
      <Flex
        sx={{
          flexDirection: 'row',
          alignItems: 'center',
          columnGap: '8px',
          marginBottom: '8px',
          color: '#4D5659',
          fontFamily: 'Roboto',
          fontWeight: 600,
          fontSize: '16px',
          lineHeight: '19px',
        }}
      >
        <FontAwesomeIcon icon={icon} />
        <Box sx={{ flex: 1 }}>{title}</Box>
      </Flex>
      <Box
        sx={{
          marginBottom: '8px',
          color: '#4D5659',
          fontFamily: 'Roboto',
          fontWeight: 400,
          fontSize: '14px',
          lineHeight: '16px',
        }}
      >
        {description}
      </Box>
      {children}
    </Flex>
  );
};

export default Subsection;
