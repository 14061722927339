import sanitizeHtml from 'sanitize-html';
import { mnoNameOrder } from '../constants';
import { StyledToast } from 'portal-commons';

export const toastFlashMessage = (message, type) => {
  if (message) {
    let formattedMessage = '';
    const messageType = typeof message;
    if (messageType === 'string') {
      formattedMessage = message.trim();
    } else if (messageType === 'number') {
      formattedMessage = message.toString();
    } else if (messageType === 'object') {
      formattedMessage = JSON.stringify(message);
    }
    if (Array.isArray(message) && !message.length) {
      formattedMessage = '';
    } else if (Array.isArray(message) && message.length) {
      formattedMessage = [...new Set(message)];
    }
    if (formattedMessage) {
      if (Array.isArray(formattedMessage)) {
        formattedMessage.forEach((content) => {
          StyledToast({
            content,
            type,
            'data-testid': `${type}Toast`,
          });
        });
      } else {
        StyledToast({
          content: formattedMessage,
          type,
          'data-testid': `${type}Toast`,
        });
      }
    }
  }
};

export const customSanitizeHtml = (html) => {
  return sanitizeHtml(html, {
    allowedTags: [],
  });
};

export const isValidInput = (input) => {
  const regex = /(<[^>]+)( on\w+="[^"]*")|javascript:|(<\s*)(\/*)script/gim;
  return !regex.test(input);
};

export const verifyUserStatus = () => {
  // debugger
  const oktaToken = localStorage.getItem('okta-token-storage')
    ? JSON.parse(localStorage.getItem('okta-token-storage'))
    : {};
  const userInfo = localStorage.getItem('userInfo')
    ? JSON.parse(localStorage.getItem('userInfo'))
    : {};

  const userTokenAppRole = oktaToken.accessToken
    ? oktaToken.accessToken.claims
      ? oktaToken.accessToken.claims.groups &&
        oktaToken.accessToken.claims.groups.length
        ? oktaToken.accessToken.claims.groups[0]
        : ''
      : ''
    : '';
  const userTokenCspUid = oktaToken.accessToken
    ? oktaToken.accessToken.claims
      ? oktaToken.accessToken.claims.cspUid
      : ''
    : '';
  const userActualAppRole =
    userInfo.groups && userInfo.groups.length ? userInfo.groups[0] : '';
  const userActualCspUid = userInfo && userInfo.cspUid ? userInfo.cspUid : '';
  const cspStatus = localStorage.getItem('cspStatus');

  if (
    cspStatus == 'active' &&
    (userActualCspUid != userTokenCspUid ||
      userActualAppRole != userTokenAppRole) &&
    userActualAppRole != '' &&
    userActualCspUid != ''
  ) {
    // conflict between idToken and userInfo with cspStatus active
    return true;
  } else {
    return false;
  }
};

export const sortMnoList = (mnoInfoList, nestedKey) => {
  if (mnoInfoList && mnoInfoList.length) {
    mnoInfoList.sort((a, b) => {
      let mnoA = a;
      let mnoB = b;
      if (Array.isArray(nestedKey)) {
        for (let i = 0; i < nestedKey.length; i++) {
          const key = nestedKey[i];
          mnoA = mnoA && mnoA.hasOwnProperty(key) ? mnoA[key] : '';
          mnoB = mnoB && mnoB.hasOwnProperty(key) ? mnoB[key] : '';
        }
      } else {
        mnoA = mnoA && mnoA.hasOwnProperty(nestedKey) ? mnoA[nestedKey] : '';
        mnoB = mnoB && mnoB.hasOwnProperty(nestedKey) ? mnoB[nestedKey] : '';
      }
      const indexA = mnoNameOrder.indexOf(mnoA);
      const indexB = mnoNameOrder.indexOf(mnoB);

      if (indexA === -1 && indexB === -1) {
        return 0; // Both are not in the custom order, maintain their relative order.
      } else if (indexA === -1) {
        return 1; // a is not in the custom order, b is, so b comes first.
      } else if (indexB === -1) {
        return -1; // b is not in the custom order, a is, so a comes first.
      } else {
        return indexA - indexB; // Compare their custom order indices.
      }
    });
  }
  return mnoInfoList;
};

export const sortMnoNestedObjectToList = (mnoNestedObject) => {
  const sortedMnoList = [];
  Object.keys(mnoNestedObject).forEach((key) => {
    const mnoName = mnoNestedObject[key].mno;
    const index = mnoNameOrder.indexOf(mnoName);
    sortedMnoList[index] = {
      ...mnoNestedObject[key],
      mnoMetadataKey: key,
    };
  });
  return sortedMnoList;
};
