import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import Grid from '@material-ui/core/Grid';
import { LoginForm } from '../components';
import { Header } from '../../../shared_elements';
import '../../../assets/styles/user-authentication-module.scss';
import { toastFlashMessage } from '../../../utils';

class Login extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  componentDidMount() {
    document.title = 'The Campaign Registry - Welcome to the Campaign Registry';
    if (sessionStorage.getItem('logoutSuccess')) {
      toastFlashMessage('Logged out successfully', 'success');
      sessionStorage.removeItem('logoutSuccess');
    } else if (sessionStorage.getItem('relogin')) {
      toastFlashMessage('Please login again', 'success');
      sessionStorage.removeItem('relogin');
    }
  }

  render() {
    return (
      <section className="user-authentication-section">
        <Header
          title="WELCOME TO THE CAMPAIGN REGISTRY"
          testId="loginHeader"
        ></Header>
        <Grid
          container
          spacing={0}
          alignItems="center"
          justify="center"
          className="user-form-wrapper"
          style={{ height: window.innerHeight - 60 }}
        >
          <LoginForm baseUrl={this.props.baseUrl} />
        </Grid>
      </section>
    );
  }
}
export default withRouter(Login);
