import React, { FunctionComponent } from 'react';
import styled from 'styled-components';

interface CampaignDescriptionProps {
  description: string;
  messageFlow: string;
  privacyPolicyLink?: string;
  termsAndConditionsLink?: string;
}

const Container = styled.div`
  display: grid;
  grid-template-columns: 226px 2fr 226px 1fr;
  row-gap: 12px;
  font-size: 14px;
  line-height: 16px;
`;

const Group = styled.div`
  display: contents;
`;

const Label = styled.div`
  font-weight: 400;
`;

const Value = styled.div`
  font-weight: 500;
`;

const CampaignDescription: FunctionComponent<CampaignDescriptionProps> = ({
  description,
  messageFlow,
  privacyPolicyLink,
  termsAndConditionsLink,
}) => {
  return (
    <Container>
      <Group data-testid="campaignDescription">
        <Label>Campaign Description:</Label>
        <Value>{description}</Value>
      </Group>

      <Group data-testid="campaignPrivacyPolicyLink">
        <Label>Privacy Policy Link:</Label>
        <Value>{privacyPolicyLink || 'N/A'}</Value>
      </Group>

      <Group data-testid="campaignCallToAction">
        <Label>Call-to-Action / Message Flow:</Label>
        <Value>{messageFlow}</Value>
      </Group>

      <Group data-testid="termsAndConditionsLink">
        <Label>Terms and Conditions Link:</Label>
        <Value>{termsAndConditionsLink || 'N/A'}</Value>
      </Group>
    </Container>
  );
};

export default CampaignDescription;
