import { FC, ComponentProps } from 'react';
import { BoxV2 as Box } from 'portal-commons';

const BodyText: FC<ComponentProps<typeof Box>> = ({
  children,
  sx,
  ...rest
}) => (
  <Box
    as="p"
    sx={{ color: 't.grey500', fontSize: '14px', fontWeight: 'normal', ...sx }}
    mb="s"
    {...rest}
  >
    {children}
  </Box>
);

export default BodyText;
