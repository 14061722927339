import { FC } from 'react';
import { BoxV2 as Box } from 'portal-commons';

interface IRadioButton {
  label: string;
  value: string;
  checked?: boolean;
  onChange: (val: string) => void;
}

export const RadioButton: FC<IRadioButton> = ({
  label,
  value,
  checked,
  onChange,
}) => {
  return (
    <Box>
      <Box
        as="label"
        sx={{
          display: 'flex',
          alignItems: 'center',
          cursor: 'pointer',
          userSelect: 'none',
          fontSize: 'H400',
          fontWeight: 'semiBold',
          fontStyle: 'normal',
          lineHeight: 'normal',
          color: 't.black70',
        }}
      >
        <input
          type="radio"
          value={value}
          checked={checked}
          onChange={(e: any) => onChange(e.currentTarget.value)}
          style={{ display: 'none' }}
        />
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
            mr: '12px',
          }}
        >
          {checked ? (
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="15"
              height="16"
              viewBox="0 0 15 16"
              fill="none"
            >
              <rect
                x="0.75"
                y="0.823242"
                width="13.5"
                height="13.5"
                rx="2.25"
                fill="white"
                stroke="#00698F"
                stroke-width="1.5"
              />
              <path
                d="M11.8515 5.23132L6.16757 10.9152L3.58398 8.33162"
                stroke="#00698F"
                stroke-width="2"
                stroke-linecap="square"
                stroke-linejoin="round"
              />
            </svg>
          ) : (
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="15"
              height="16"
              viewBox="0 0 15 16"
              fill="none"
            >
              <rect
                x="0.75"
                y="0.823242"
                width="13.5"
                height="13.5"
                rx="2.25"
                fill="white"
                stroke="#949495"
                stroke-width="1.5"
              />
            </svg>
          )}
        </Box>
        {label}
      </Box>
    </Box>
  );
};

export default RadioButton;
