import React, { Component } from 'react';
import { Link, withRouter } from 'react-router-dom';
import TextField from '@material-ui/core/TextField';
import { FREE_EMAILS_DOMAINS, S3_ASSETS_PATH } from '../../../constants';
import { fieldValidation } from '../../../utils/validator';
import { toastFlashMessage } from '../../../utils';
import regexExpressions from '../../../constants/regexExpressions';
import { createNewUserApi } from '../apiServices';
import CheckBox from '../../../ui_elements/CheckBox';
// import { TermsAndConditions } from '../../../shared_elements'
import 'react-phone-number-input/style.css';

export default withRouter(
  class RegisterForm extends Component {
    constructor(props) {
      super(props);
      this.state = {
        userInfo: {
          firstName: '',
          lastName: '',
          email: '',
        },
        error: {},
        errorCode: {
          firstName: {
            0: '',
            1: 'Please enter First Name',
            3: 'Maximum 100 chars are allowed',
            4: 'Invalid First Name',
          },
          firstNameObj: {
            requiredFlag: true,
            maxLength: 100,
            regexPattern: regexExpressions.name,
          },
          lastName: {
            0: '',
            1: 'Please enter Last Name',
            3: 'Maximum 100 are allowed',
            4: 'Invalid Last Name',
          },
          lastNameObj: {
            requiredFlag: true,
            maxLength: 100,
            regexPattern: regexExpressions.name,
          },
          email: {
            0: '',
            1: 'Please enter Email Address',
            4: 'Invalid Email Address',
          },
          emailObj: {
            requiredFlag: true,
            regexPattern: regexExpressions.email,
          },
        },
        loader: false,
        checked: false,
      };
    }

    handleChange = (value, key) => {
      if (key == 'email') {
        value = value.toLowerCase();
      }
      this.setState((prevState) => ({
        ...prevState,
        userInfo: {
          ...prevState.userInfo,
          [key]: value,
        },
        error: {
          ...prevState.error,
          [key]: '',
        },
      }));
    };

    handleError = (value, key) => {
      const { errorCode } = this.state;
      this.setState((prevState) => ({
        ...prevState,
        error: {
          ...prevState.error,
          [key]:
            errorCode[key][
              fieldValidation({ ...errorCode[`${key}Obj`], fieldval: value })
            ],
        },
      }));
    };

    handleCheckbox = (e) => {
      this.setState((prevState) => ({
        ...prevState,
        checked: !prevState.checked,
      }));
    };

    register = (e) => {
      e.preventDefault();
      const { errorCode, userInfo } = this.state;
      let validateNewInput = {
        firstName:
          errorCode.firstName[
            fieldValidation({
              ...errorCode.firstNameObj,
              fieldval: userInfo.firstName,
            })
          ],
        lastName:
          errorCode.lastName[
            fieldValidation({
              ...errorCode.lastNameObj,
              fieldval: userInfo.lastName,
            })
          ],
        email:
          errorCode.email[
            fieldValidation({
              ...errorCode.emailObj,
              fieldval: userInfo.email,
            })
          ],
      };
      this.setState({
        loader: true,
      });
      if (
        fieldValidation({
          ...errorCode.emailObj,
          fieldval: userInfo.email,
        }) === 0
      ) {
        if (FREE_EMAILS_DOMAINS.includes(userInfo.email.replace(/.*@/, ''))) {
          validateNewInput = {
            ...validateNewInput,
            email: 'Please enter only business email',
          };
        }
      }
      if (
        Object.keys(validateNewInput).every((k) => {
          return validateNewInput[k] === '';
        })
      ) {
        const formData = { ...userInfo };
        // console.log('register formData', formData)
        createNewUserApi(formData).then((response) => {
          this.setState({
            loader: false,
          });
          if (response.status >= 200 && response.status < 300) {
            toastFlashMessage('Account created successfully', 'success');
            this.props.history.push('/user/create/successfully');
          }
        });
      } else {
        this.setState({
          error: validateNewInput,
          loader: false,
        });
      }
    };

    render() {
      const { userInfo, error, loader, checked } = this.state;
      return (
        <div className="auth-user-form register" data-testid="registerForm">
          <form onSubmit={this.register}>
            <div className="form-title login">
              <h3 className="heading1">
                <img src={`${S3_ASSETS_PATH}/images/register.svg`} alt="" />
                Register
              </h3>
            </div>
            <ul className="list-unstyled">
              <li>
                <div className="form-group-field">
                  <TextField
                    error={error.firstName ? true : false}
                    value={userInfo.firstName}
                    data-testid="registerFormFirstNameInput"
                    id="firstName"
                    required
                    fullWidth={true}
                    label="First Name"
                    inputProps={{ 'aria-label': 'firstName' }}
                    onChange={(event) =>
                      this.handleChange(event.target.value, 'firstName')
                    }
                    onBlur={(event) =>
                      this.handleError(event.target.value, 'firstName')
                    }
                  />
                  {error.firstName ? (
                    <h6 className="error-msg">{error.firstName}</h6>
                  ) : null}
                </div>
              </li>
              <li>
                <div className="form-group-field">
                  <TextField
                    error={error.lastName ? true : false}
                    value={userInfo.lastName}
                    id="lastName"
                    data-testid="registerFormLastNameInput"
                    required
                    fullWidth={true}
                    label="Last Name"
                    inputProps={{ 'aria-label': 'lastName' }}
                    onChange={(event) =>
                      this.handleChange(event.target.value, 'lastName')
                    }
                    onBlur={(event) =>
                      this.handleError(event.target.value, 'lastName')
                    }
                  />
                  {error.lastName ? (
                    <h6 className="error-msg">{error.lastName}</h6>
                  ) : null}
                </div>
              </li>
              <li>
                <div className="form-group-field">
                  {/* <label className="for-label">Email Address</label> */}
                  <TextField
                    error={error.email ? true : false}
                    value={userInfo.email}
                    id="email"
                    data-testid="registerFormEmailInput"
                    label="Email Address"
                    required
                    fullWidth={true}
                    inputProps={{ 'aria-label': 'email' }}
                    onChange={(event) =>
                      this.handleChange(event.target.value, 'email')
                    }
                    onBlur={(event) =>
                      this.handleError(event.target.value, 'email')
                    }
                  />
                  {error.email ? (
                    <h6 className="error-msg">{error.email}</h6>
                  ) : null}
                </div>
              </li>
              <li>
                <div className="check-box-span text-center">
                  <label>
                    <CheckBox
                      testId="privacyPolicyCheckBox"
                      checked={checked}
                      handleCheckbox={this.handleCheckbox}
                    />
                    <span className="">
                      I have reviewed and agree to the{' '}
                      <a
                        data-testid="registerFormPrivacyPolicyLink"
                        href="https://www.campaignregistry.com/privacy-policy/"
                        target="_blank"
                      >
                        Privacy Policy
                      </a>
                    </span>
                  </label>
                </div>
              </li>
              <li style={{ paddingTop: 15 }}>
                <div className="form-group-field text-center">
                  <input
                    type="submit"
                    data-testid="registerFormSubmitButton"
                    disabled={
                      loader ||
                      Object.keys(error).find((k) => error[k] != '') ||
                      !checked
                        ? true
                        : false
                    }
                    value={loader ? 'Submit' : 'Submit'}
                  />
                </div>
              </li>
            </ul>
            <p className="text-center supportText account-link-option">
              Already have an account?
              <Link to="/login" data-testid="registerFormLoginLink">
                Login
              </Link>
            </p>
          </form>
        </div>
      );
    }
  }
);
