import Login from './containers/Login';
import ForgotPassword from './containers/ForgotPassword';
import Register from './containers/Register';
import ResetPassword from './containers/ResetPassword';
import UserAuthThanks from './containers/UserAuthThanks';
import { LoggedUserRed } from '../../hocs';

export const authRoutes = [
  {
    path: '/login',
    component: LoggedUserRed(Login),
    key: 'login',
  },
  {
    path: '/register',
    component: LoggedUserRed(Register),
    key: 'register',
  },
  {
    path: '/forgot-password',
    component: LoggedUserRed(ForgotPassword),
    key: 'forgot',
  },
  {
    path: '/expired-password',
    component: LoggedUserRed(ForgotPassword),
    key: 'expired',
  },
  {
    path: '/reset-password',
    component: LoggedUserRed(ResetPassword),
    key: 'reset-password',
  },
  {
    path: '/set-password',
    component: LoggedUserRed(ResetPassword),
    key: 'set-password',
  },
  {
    path: '/user/:type/successfully',
    component: LoggedUserRed(UserAuthThanks),
    key: 'successfully',
  },
];
