import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import {
  Button,
  Dialog,
  DialogContent,
  makeStyles,
  TextField,
} from '@material-ui/core';

import { isValidInput } from '../../../../utils';
import MnoTermsComparisonTable from './MnoTermsComparisonTable';

const useStyles = makeStyles({
  backdrop: {
    backdropFilter: 'blur(1px)',
  },
  container: {
    width: '840px',
    maxWidth: '840px',
    marginLeft: '232px',
  },
  root: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    padding: '35px 20px 40px 20px !important',
  },
  title: {
    color: '#071822',
    fontFamily: 'Roboto',
    fontWeight: 700,
    fontSize: '20px',
    lineHeight: '23px',
  },
  description: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    marginTop: '17px',
    '& > *': {
      fontFamily: 'Roboto',
      fontWeight: 400,
      fontSize: '14px',
      lineHeight: '16px',
      color: '#49535D',
    },
  },
  buttons: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    gap: '14px',
    marginTop: '37px',
  },
  close: {
    height: '40px',
    padding: '0 20px',
    borderRadius: '100px',
    color: '#D41C54',
    background: '#FFFFFF',
    border: '1px solid #D41C54',
    boxShadow: 'none !important',
    textTransform: 'none',
    fontFamily: 'Roboto',
    fontWeight: 500,
    fontSize: '14px',
    lineHeight: '16px',
    '&:hover': {
      color: '#D41C54',
      background: '#FFFFFF',
    },
  },
  submit: {
    height: '40px',
    padding: '0 20px',
    borderRadius: '100px',
    color: '#FFFFFF',
    background: '#D41C54',
    boxShadow: 'none !important',
    textTransform: 'none',
    fontFamily: 'Roboto',
    fontWeight: 500,
    fontSize: '14px',
    lineHeight: '16px',
    '&:hover': {
      color: '#FFFFFF',
      background: '#D41C54',
    },
    '&:disabled': {
      color: '#FFFFFF',
      background: '#D41C54',
      opacity: 0.4,
    },
  },
});

const CampaignResubmissionModal = ({
  open,
  mnoStatus,
  newMnoTerms,
  onClose,
  onSubmit,
}) => {
  const classes = useStyles();
  const [selectedMnos, setSelectedMnos] = useState([]);

  useEffect(() => {
    if (open) {
      const newSelectedMnos = Object.entries(newMnoTerms)
        .filter(([key, terms]) => terms.qualify)
        .map(([key, terms]) => key);
      setSelectedMnos(newSelectedMnos);
    }
  }, [open]);

  return (
    <Dialog
      classes={{
        root: classes.backdrop,
        paper: classes.container,
      }}
      disableBackdropClick
      fullWidth
      open={open}
      onClose={onClose}
      maxWidth="xs"
      data-testid="campaignResubmissionModal"
    >
      <DialogContent
        classes={{
          root: classes.root,
        }}
      >
        <div className={classes.title}>Campaign Resubmission</div>
        <p className={classes.description}>
          <span>
            Below is a preview of the current and new terms that would be
            adopted if you choose to resubmit the campaign against the
          </span>
          <span>
            newest vetting score. Current terms are shown on the left and new
            terms are shown on the right.
          </span>
          <br />
          <span>
            Select the MNO’s you wish to resubmit with. MNOs that currently do
            not qualify are greyed out.
          </span>
        </p>
        <MnoTermsComparisonTable
          style={{ width: '100%', marginTop: '22px' }}
          mnoStatus={mnoStatus}
          newMnoTerms={newMnoTerms}
          selectedMnos={selectedMnos}
          onSelectedMnosChange={setSelectedMnos}
          data-testid="campaignResubmissionModalMnoTermsComparisonTable"
        />
        <div className={classes.buttons}>
          <Button
            variant="contained"
            className={classes.close}
            onClick={onClose}
            data-testid="campaignResubmissionModalDeclineButton"
          >
            Decline
          </Button>
          <Button
            variant="contained"
            className={classes.submit}
            disabled={selectedMnos.length < 1}
            onClick={() => onSubmit && onSubmit(selectedMnos)}
            data-testid="campaignResubmissionModalSubmitButton"
          >
            Submit with New Terms
          </Button>
        </div>
      </DialogContent>
    </Dialog>
  );
};

CampaignResubmissionModal.propTypes = {
  open: PropTypes.bool,
  mnoStatus: PropTypes.arrayOf(
    PropTypes.shape({
      networkId: PropTypes.number.isRequired,
      rulesEngineResultsJson: PropTypes.shape({
        mno: PropTypes.string.isRequired,
        tpm: PropTypes.number,
        tpmScope: PropTypes.string,
        mmsTpm: PropTypes.number,
        msgClass: PropTypes.string,
        brandTier: PropTypes.string,
        brandDailyCap: PropTypes.number,
      }).isRequired,
    })
  ).isRequired,
  newMnoTerms: PropTypes.objectOf(
    PropTypes.shape({
      mno: PropTypes.string.isRequired,
      qualify: PropTypes.bool.isRequired,
      tpm: PropTypes.number,
      tpmScope: PropTypes.string,
      mmsTpm: PropTypes.number,
      msgClass: PropTypes.string,
      brandTier: PropTypes.string,
      brandDailyCap: PropTypes.number,
    })
  ),
  onClose: PropTypes.func,
  onSubmit: PropTypes.func,
};

export default CampaignResubmissionModal;
