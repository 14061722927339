import { HeaderHoc, ErrorBoundaryHoc } from '../../hocs';
import InitiateCspAccount from './CSP-Form/containers/InitiateCspAccount';
import CspVerificationForm from './CSP-Form/containers/CspVerificationForm';
// import OneTimePayment from './CSP-Form/containers/OneTimePayment'
import CspVerificationStatus from './CSP-Form/containers/CspVerificationStatus';
import BrandRegisterForm from './Brands/containers/BrandRegisterForm';
import BrandDetails from './Brands/containers/BrandDetails';
import BrandList from './Brands/containers/BrandList';
import KnowledgeBaseNew from './Knowledge Base/containers/KnowledgeBase';
import Users from './Users/containers/Users';
import NewUserForm from './Users/containers/NewUserForm';
import EditUserForm from './Users/containers/EditUserForm';
import CampaignsListing from './Campaigns/containers/CampaignsListing';
import CampaignRegisterForm from './Campaigns/containers/CampaignRegisterForm';
import CampaignRegisterSuccess from './Campaigns/containers/CampaignRegisterSuccess';
import CampaignDetail from './Campaigns/containers/CampaignDetail';
import DashboardNew from './Dashboard/containers/DashboardNew';
import EventsList from './Events/containers/EventsList';
import Resellers from './Resellers/containers/Resellers';
import PartnerCampaigns from './Campaigns/containers/PartnerCampaigns';
import Integrations from './Integrations/containers/Integrations';
import PartnerCampaignDetail from './Campaigns/containers/PartnerCampaignDetail';
import ProfileManagement from './Profile/containers/ProfileManagement';

export const cspRoutes = [
  {
    path: '/user/csp',
    component: HeaderHoc(ErrorBoundaryHoc(InitiateCspAccount), {
      title: 'WELCOME TO THE CAMPAIGN REGISTRY',
      id: 'CREATE_CSP',
    }),
    key: 'csp-account',
  },
  {
    path: '/user/csp/create',
    component: HeaderHoc(ErrorBoundaryHoc(CspVerificationForm), {
      title: 'CSP VERIFICATION',
      id: 'CREATE_CSP',
    }),
    key: 'csp-form',
  },
  // {
  //     path: '/user/csp/payment',
  //     component: HeaderHoc(ErrorBoundaryHoc(OneTimePayment), {title: 'CSP PAYMENT', id: 'CREATE_CSP'}),
  //     key: 'csp-account'
  // },
  {
    path: '/user/csp/status',
    component: HeaderHoc(ErrorBoundaryHoc(CspVerificationStatus), {
      title: 'APPROVAL STATUS',
      id: 'CREATE_CSP',
    }),
    key: 'csp-status',
  },
  {
    path: '/dashboard',
    component: HeaderHoc(ErrorBoundaryHoc(DashboardNew), {
      title: 'DASHBOARD',
      id: 'DASHBOARD',
    }),
    key: 'dashboard',
  },
  {
    path: '/profile',
    component: HeaderHoc(ErrorBoundaryHoc(ProfileManagement), {
      title: 'CSP Profile',
      id: 'CSP_PROFILE',
    }),
    key: 'profile',
  },
  {
    path: '/brand/create',
    component: HeaderHoc(ErrorBoundaryHoc(BrandRegisterForm), {
      title: 'REGISTER BRAND',
      id: 'CREATE_BRANDS',
    }),
    key: 'brand-create',
  },
  {
    path: '/brands/:id',
    component: HeaderHoc(ErrorBoundaryHoc(BrandDetails), {
      title: 'Brands',
      id: 'VIEW_BRANDS',
    }),
    key: 'brand-details',
  },
  {
    path: '/brands',
    component: HeaderHoc(ErrorBoundaryHoc(BrandList), {
      title: 'Brands',
      id: 'VIEW_BRANDS',
    }),
    key: 'brands-list',
  },
  {
    path: '/campaign/create/successfully',
    component: HeaderHoc(ErrorBoundaryHoc(CampaignRegisterSuccess), {
      title: 'REGISTER A CAMPAIGN',
      id: 'CREATE_CAMPAIGNS',
    }),
    key: 'campaign-create-success',
  },
  {
    path: '/campaigns',
    component: HeaderHoc(ErrorBoundaryHoc(CampaignsListing), {
      title: 'My Campaigns',
      id: 'VIEW_CAMPAIGNS',
    }),
    key: 'campaigns-list',
  },
  {
    path: '/cnp-campaigns',
    component: HeaderHoc(ErrorBoundaryHoc(PartnerCampaigns), {
      title: 'CNP Campaigns',
      id: 'VIEW_PARTNER_CAMPAIGNS',
    }),
    key: 'cnp-campaigns-list',
  },
  {
    path: '/cnp-campaigns/history',
    component: HeaderHoc(ErrorBoundaryHoc(PartnerCampaigns), {
      title: 'HISTORY OF CAMPAIGNS BY PARTNERS',
      id: 'VIEW_PARTNER_CAMPAIGNS',
    }),
    key: 'cnp-campaigns-history-list',
  },
  {
    path: '/cnp-campaigns/:id',
    component: HeaderHoc(ErrorBoundaryHoc(PartnerCampaignDetail), {
      title: 'CAMPAIGNS',
      id: 'VIEW_CAMPAIGNS',
    }),
    key: 'cnp-campaign-details',
  },
  {
    path: '/campaigns/:id',
    component: HeaderHoc(ErrorBoundaryHoc(CampaignDetail), {
      title: 'CAMPAIGNS',
      id: 'VIEW_CAMPAIGNS',
    }),
    key: 'campaign-details',
  },
  {
    path: '/campaign/create',
    component: HeaderHoc(ErrorBoundaryHoc(CampaignRegisterForm), {
      title: 'REGISTER A CAMPAIGN',
      id: 'CREATE_CAMPAIGNS',
    }),
    key: 'campaign-create-class',
  },
  {
    path: '/events',
    component: HeaderHoc(ErrorBoundaryHoc(EventsList), {
      title: 'Events',
      id: 'VIEW_EVENTS',
    }),
    key: 'events-list',
  },
  {
    path: '/knowledge-base',
    component: HeaderHoc(ErrorBoundaryHoc(KnowledgeBaseNew), {
      title: 'KNOWLEDGE BASE',
      id: 'KNOWLEDGE_BASE',
    }),
    key: 'knowledge-base-new',
  },
  {
    path: '/resellers',
    component: HeaderHoc(ErrorBoundaryHoc(Resellers), {
      title: 'Resellers',
      id: 'VIEW_RESELLERS',
    }),
    key: 'edit-user',
  },
  {
    path: '/integrations',
    component: HeaderHoc(ErrorBoundaryHoc(Integrations), {
      title: 'INTEGRATIONS',
      id: 'INTEGRATIONS',
    }),
    key: 'integrations',
  },
];
