import { makeStyles } from '@material-ui/core';
import { S3_ASSETS_PATH } from '../../../../../constants';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faHome,
  faBullhorn,
  faTag,
  faCalendarDay,
  faGearCode,
  faNotebook,
  faCloudCheck,
  faMessageCaptions,
  faArrowsTurnRight,
  faBookBookmark,
} from '@fortawesome/pro-regular-svg-icons';

const useStyles = makeStyles({
  container: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'flex-start',
    gap: '9px',
    width: '100%',
    cursor: 'pointer',
    '&.disabled': {
      cursor: 'default',
    },
  },
  hidden: {
    display: 'none',
  },
  checkbox: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    width: '16px',
    height: '16px',
    background: '#FFFFFF',
    border: '2px solid #C0CCD4',
    borderRadius: '4px',
    boxSizing: 'border-box',
    '&.disabled': {
      background: '#C0CCD4',
    },
  },
  contentContainer: {
    flex: '1',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    gap: '4px',
    fontFamily: 'Roboto',
    fontWeight: 400,
    fontSize: '16px',
    lineHeight: '17px',
    color: '#49535D',
    '&.disabled': {
      opacity: 0.5,
    },
  },
  title: {
    width: '100%',
  },
  message: {
    width: '100%',
    fontSize: '14px',
    lineHeight: '16px',
  },
});

interface Props {
  title: string;
  message: string;
  disabled?: boolean;
  checked: boolean;
  onValueChange: (_id: string | boolean) => void;
}
const AppealCategory: React.FC<Props> = ({
  title,
  message,
  disabled,
  checked,
  onValueChange,
}) => {
  const classes = useStyles();

  const handleClick = () => {
    if (!disabled) {
      onValueChange && onValueChange(!checked);
    }
  };

  return (
    <div
      className={`${classes.container} ${disabled ? 'disabled' : ''}`}
      onClick={handleClick}
      data-testid="categoryContainer"
    >
      <input
        className={classes.hidden}
        type="checkbox"
        checked={checked}
        readOnly
        data-testid="categoryContainerInput"
      />
      <div className={`${classes.checkbox} ${disabled ? 'disabled' : ''}`}>
        {checked && <img src={`${S3_ASSETS_PATH}/images/icon-checked.svg`} />}
      </div>
      <div
        className={`${classes.contentContainer} ${disabled ? 'disabled' : ''}`}
        data-testid="categoryContent"
      >
        <div className={classes.title}>{title}</div>
        <div className={classes.message}>{message}</div>
      </div>
    </div>
  );
};

export default AppealCategory;
