import React, { useState } from 'react';
import { Dialog, Grid } from '@material-ui/core';
import { deleteResellerApi } from '../apiServices';
import { toastFlashMessage } from '../../../../utils';

export default function DeleteResellerModal(props) {
  const [loader, setLoader] = useState(false);
  const deleteReseller = (e) => {
    e.preventDefault();
    setLoader(true);
    deleteResellerApi(props.deleteId).then((response) => {
      setLoader(false);
      if (response.status >= 200 && response.status < 300) {
        toastFlashMessage('Reseller deleted successfully', 'success');
        props.handleModalClick(false, null, true);
      }
    });
  };
  return (
    <Dialog
      open={true}
      onClose={() => props.handleModalClick(false)}
      className="delete-reseller-modal"
      data-testid="deleteResellerModal"
    >
      <Grid container justify="center" className="delete-reseller-content">
        <Grid item xs={12}>
          <div className="dialog-wrapper">
            <p className="heading2 light">
              Do you want to{' '}
              <span>
                {' '}
                permanently <br />
                delete
              </span>
              the reseller?
            </p>
          </div>
          <ul className="list-unstyled">
            <li>
              <p>
                <a
                  className="secondary-btn"
                  data-testid="deleteResellerModalCancelButton"
                  onClick={() => props.handleModalClick(false)}
                >
                  Cancel
                </a>
              </p>
            </li>
            <li>
              <p>
                <a
                  className={loader ? 'primary-btn disabled' : 'primary-btn'}
                  onClick={deleteReseller}
                  data-testid="deleteResellerModalDeleteButton"
                >
                  {loader ? 'Processing..' : 'Delete'}
                </a>
              </p>
            </li>
          </ul>
        </Grid>
      </Grid>
    </Dialog>
  );
}
