import React, { useState, useEffect } from 'react';
import TextField from '@material-ui/core/TextField';
import Autocomplete from '@material-ui/lab/Autocomplete';

export default function IntegrationReactSelect(props) {
  // debugger
  const [value, setValue] = useState(props.value ? props.value : null);

  useEffect(() => setValue(props.value ? props.value : null), [props.value]);

  function handleChangeOption(selectedOption) {
    props.handleChange(selectedOption, props.keyName);
  }

  const handleGetOptionSelected = (option, testValue) => {
    return option.value === testValue.value;
  };

  return (
    <Autocomplete
      // {...defaultProps}
      options={props.options}
      getOptionLabel={(option) =>
        typeof option === 'string' ? option : option.label
      }
      placeholder={props.label}
      id="disable-clearable"
      data-testid={props.testid}
      value={value}
      disableClearable
      disabled={props.disabled}
      defaultValue={value}
      onChange={(...props) => handleChangeOption(props[1])}
      getOptionSelected={handleGetOptionSelected}
      renderInput={(params) => {
        const { inputProps } = params;
        inputProps.autoComplete = 'off';
        return (
          <TextField
            {...params}
            InputLabelProps={{
              ...params.InputLabelProps,
              ...(props.InputLabelProps ? props.InputLabelProps : {}),
            }}
            error={props.error}
            disabled={props.disabled}
            autoComplete="off"
            label={props.label}
            margin="normal"
            fullWidth
            required={props.required}
          />
        );
      }}
    />
  );
}
