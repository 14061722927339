import React from 'react';
import { Container, Grid } from '@material-ui/core';
import { S3_ASSETS_PATH } from './constants';
import { Link } from 'react-router-dom';

const PageNotFound = (props) => {
  return (
    <section className="page-not-found-section" data-testid="pageNotFound">
      <Grid
        container
        justify="center"
        alignItems="center"
        className="page-not-found-container"
      >
        <div className="page-not-found-wrapper">
          <img src={`${S3_ASSETS_PATH}/images/404.svg`} alt="404-page-bg" />
          <div className="content-blk text-center">
            <h3 className="paragraph">OOPS</h3>
            <p className="paragraph">
              we can’t seem to find the page you’re looking for{' '}
            </p>
            <Link className="primary-btn" to="/" data-testid="homeButton">
              Back to Home
            </Link>
          </div>
        </div>
      </Grid>
    </section>
  );
};
export default PageNotFound;
