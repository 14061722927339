import React, { FC } from 'react';
import { TableCell, TableRow } from '@material-ui/core';

interface UseCaseTableRowProps {
  data: { name: string; count: number };
}

const UseCaseTableRow: FC<UseCaseTableRowProps> = ({ data }) => {
  return (
    <TableRow className="contentRow" data-testid="dashboardTableCampaignRow">
      <TableCell data-testid="dashboardTableCampaignRowName">
        {data.name}
      </TableCell>
      <TableCell data-testid="dashboardTableCampaignRowCount">
        {data.count}
      </TableCell>
    </TableRow>
  );
};

export default UseCaseTableRow;
