import { FC } from 'react';
import { Dialog, makeStyles } from '@material-ui/core';
import { Button } from 'portal-commons';

interface Props {
  open: boolean;
  onClose?: () => void;
}

const useStyles = makeStyles({
  root: {},
  container: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    width: '525px',
    padding: '28px 20px 36px 20px',
    background: '#FFFFFF',
  },
  title: {
    color: '#071822',
    fontFamily: 'Roboto',
    fontWeight: 500,
    fontSize: '18px',
    lineHeight: '21px',
  },
  message: {
    marginTop: '26px',
    color: '#49535D',
    fontFamily: 'Roboto',
    fontWeight: 400,
    fontSize: '16px',
    lineHeight: '19px',
    textAlign: 'center',
  },
  actions: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
    marginTop: '28px',
  },
});

const BrandResubmissionPromptModal: FC<Props> = ({ open, onClose }) => {
  const classes = useStyles();

  return (
    <Dialog
      classes={{ paper: classes.container }}
      open={open}
      data-testid="brandResubmissionPromptModal"
    >
      <div className={classes.title}>Identity Status Appeal</div>
      <div className={classes.message}>
        Once a Brand’s Legal Company Name, Tax Number/ID/EIN, Tax
        <br />
        Number/ID/EIN Issuing Country, or Entity Type have been changed
        <br />
        you must Resubmit the Brand before Appealing. This is to ensure
        <br />
        our verification provider has the most up to date Brand information.
        <br />
        <br />
        Once the Brand is resubmitted you will be able to Appeal if all other
        <br />
        Appeal requirements are met. Appeal requirements can be found in
        <br />
        the Knowledge Base Brand Registration tab.
      </div>
      <div className={classes.actions}>
        <Button onClick={onClose} data-testid="closeButton">
          Close
        </Button>
      </div>
    </Dialog>
  );
};

export default BrandResubmissionPromptModal;
