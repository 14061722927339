import { Ref, forwardRef, useState, useImperativeHandle } from 'react';
import { useFormContext, Controller, useFieldArray } from 'react-hook-form';
import {
  faIndustryWindows,
  faDollar,
  faMessageDots,
  faGearCode,
} from '@fortawesome/pro-regular-svg-icons';
import flags from 'react-phone-number-input/flags';
import PhoneInput from 'react-phone-number-input';
import { BoxV2 as Box } from 'portal-commons';

import { fieldValidator } from '../../helper/cspFieldValidator';
import Section from './Section';
import BodyText from './BodyText';
import TwoColumnGrid from './TwoColumnGrid';
import AddEmailBtn from './AddEmailBtn';
import RemovableTextInput from './RemovableTextInput';
import { REQUIRED_ERR_MSG } from './constants';

interface IContactDetails {
  csp: Record<string, any>;
  backwards?: boolean;
  countryIsoCode: Record<string, string>;
  handleChange: (val: any, key: string) => void;
}

interface RefProps {
  next: () => boolean;
  prev: () => boolean;
}

const MAX_STEP = 1;
const MIN_STEP = 0;
const MAX_EMAIL_COUNT = 5;

const ContactDetails = forwardRef(
  (
    { csp = {}, backwards, countryIsoCode, handleChange }: IContactDetails,
    ref: Ref<RefProps>
  ) => {
    const { control } = useFormContext();
    const [step, setStep] = useState<number>(backwards ? MAX_STEP : MIN_STEP);
    const emailArray = useFieldArray({
      control,
      name: 'email',
    });
    const financeEmailArray = useFieldArray({
      control,
      name: 'financeEmail',
    });
    const techEmailArray = useFieldArray({
      control,
      name: 'techEmail',
    });
    const escalateEmailArray = useFieldArray({
      control,
      name: 'escalateEmail',
    });

    const next = (currentStep: number): boolean => {
      if (currentStep < MAX_STEP) {
        setStep((prev) => prev + 1);
        return true;
      }
      return false;
    };

    const prev = (currentStep: number): boolean => {
      if (currentStep > MIN_STEP) {
        setStep((prev) => prev - 1);
        return true;
      }
      return false;
    };

    useImperativeHandle(
      ref,
      () => ({
        next: () => next(step),
        prev: () => prev(step),
      }),
      [step]
    );

    const handleAddEmail = (field: string) => {
      let fieldArray;
      switch (field) {
        case 'email':
          fieldArray = emailArray;
          break;
        case 'financeEmail':
          fieldArray = financeEmailArray;
          break;
        case 'techEmail':
          fieldArray = techEmailArray;
          break;
        case 'escalateEmail':
          fieldArray = escalateEmailArray;
          break;
      }
      fieldArray?.append({ email: '' });
    };

    const renderAddEmailBtn = (field: string) => {
      if (csp[field].length < MAX_EMAIL_COUNT) {
        return (
          <AddEmailBtn sx={{ mt: 's' }} onClick={() => handleAddEmail(field)} />
        );
      }
    };

    if (step === 0) {
      return (
        <div data-testid="cspContactDetail">
          <Section
            icon={faIndustryWindows}
            title="Commercial Contact Details"
            sx={{ mb: 'xl' }}
          >
            <BodyText>Used for contract and business communications.</BodyText>
            <TwoColumnGrid>
              <Controller
                control={control}
                defaultValue=""
                name="phone"
                rules={{
                  validate: (v) =>
                    fieldValidator.phone(v, {
                      countryIsoCode: countryIsoCode.phone,
                    }),
                }}
                render={({ field, fieldState: { error = {} } }) => (
                  <div className="registration">
                    <PhoneInput
                      data-testid="cspContactDetailPhoneInput"
                      flags={flags}
                      country="US"
                      countryOptions={['US', 'IT', 'IN', '|']}
                      limitMaxLength
                      placeholder={'Phone Number *'}
                      onCountryChange={(countryIsoCode) =>
                        handleChange(countryIsoCode, 'countryIsoCode phone')
                      }
                      inputClassName={error.message ? 'error' : ''}
                      {...field}
                      onChange={(newNumber) => {
                        // newNumber is undefined when the digits after country codes are all deleted (+1 234 5678, and deletes 2345678)
                        // and field.onChange(undefined) would restore value to initial state when editing existing CSP
                        field.onChange(newNumber ?? countryIsoCode.phone);
                      }}
                    />
                    {!!error.message && (
                      <Box
                        className="error-msg"
                        sx={{
                          color: '#B70505',
                          fontSize: '10px',
                          lineHeight: '12px',
                          mt: '2px',
                        }}
                      >
                        {error.message}
                      </Box>
                    )}
                  </div>
                )}
              />
            </TwoColumnGrid>
            <Box
              sx={{
                content: '""',
                width: '100%',
                borderBottom: '1px solid',
                borderColor: 't.black20',
                margin: '20px 0',
              }}
            />
            <TwoColumnGrid>
              {emailArray.fields.map((field, index) => (
                <>
                  <div key={field.id}>
                    <Controller
                      control={control}
                      defaultValue=""
                      name={`email.${index}`}
                      rules={{
                        required: REQUIRED_ERR_MSG,
                        maxLength: 100,
                        validate: (v) => fieldValidator.email(v.email),
                      }}
                      render={({ field, fieldState: { error = {} } }) => (
                        <>
                          <RemovableTextInput
                            data-testid="cspContactDetailEmailInput"
                            error={!!error.message}
                            id="email"
                            required
                            label="Email Address"
                            note={error.message}
                            maxLength={100}
                            {...field}
                            onChange={(val) => field.onChange({ email: val })}
                            value={field.value.email}
                            removable={csp.email.length > 1}
                            onRemoveClick={() => emailArray.remove(index)}
                          />
                        </>
                      )}
                    />
                  </div>
                </>
              ))}
            </TwoColumnGrid>
            {renderAddEmailBtn('email')}
          </Section>
          <Section icon={faDollar} title="Financial Contact Details">
            <BodyText>Used for invoicing and finance communications.</BodyText>
            <TwoColumnGrid>
              {financeEmailArray.fields.map((field, index) => (
                <div key={field.id}>
                  <Controller
                    control={control}
                    defaultValue=""
                    name={`financeEmail.${index}`}
                    rules={{
                      required: REQUIRED_ERR_MSG,
                      maxLength: 100,
                      validate: (v) => fieldValidator.financeEmail(v.email),
                    }}
                    render={({ field, fieldState: { error = {} } }) => {
                      return (
                        <>
                          <RemovableTextInput
                            data-testid="cspFinanceEmailInput"
                            required
                            maxLength={100}
                            error={!!error.message}
                            note={error.message}
                            label="Email Address"
                            removable={csp.financeEmail.length > 1}
                            onRemoveClick={() =>
                              financeEmailArray.remove(index)
                            }
                            {...field}
                            onChange={(val) => field.onChange({ email: val })}
                            value={field.value.email}
                          />
                        </>
                      );
                    }}
                  />
                </div>
              ))}
            </TwoColumnGrid>
            {renderAddEmailBtn('financeEmail')}
          </Section>
        </div>
      );
    }

    if (step === 1) {
      return (
        <div data-testid="cspVerificationFormAddtionalEmails">
          <Section
            icon={faMessageDots}
            title="System Notification Contact Details"
            sx={{ mb: 'xl' }}
          >
            <BodyText>Used for system event notification.</BodyText>
            <TwoColumnGrid>
              {escalateEmailArray.fields.map((field, index) => (
                <div key={field.id}>
                  <Controller
                    control={control}
                    defaultValue=""
                    name={`escalateEmail.${index}`}
                    rules={{
                      required: REQUIRED_ERR_MSG,
                      maxLength: 100,
                      validate: {
                        base: (v) => fieldValidator.escalateEmail(v.email),
                      },
                    }}
                    render={({ field, fieldState: { error = {} } }) => (
                      <>
                        <RemovableTextInput
                          data-testid="cspEscalateEmailInput"
                          required
                          error={!!error.message}
                          note={error.message}
                          label="Email Address"
                          maxLength={100}
                          removable={csp.escalateEmail.length > 1}
                          onRemoveClick={() => escalateEmailArray.remove(index)}
                          {...field}
                          onChange={(val) => field.onChange({ email: val })}
                          value={field.value.email}
                        />
                      </>
                    )}
                  />
                </div>
              ))}
            </TwoColumnGrid>
            {renderAddEmailBtn('escalateEmail')}
          </Section>
          <Section icon={faGearCode} title="Technical Contact Details">
            <BodyText>
              Complete this using your support team email, it will be used if
              other emails given do not respond.
            </BodyText>
            <TwoColumnGrid>
              {techEmailArray.fields.map((field, index) => (
                <div key={field.id}>
                  <Controller
                    control={control}
                    defaultValue=""
                    name={`techEmail.${index}`}
                    rules={{
                      required: REQUIRED_ERR_MSG,
                      maxLength: 100,
                      validate: (v) =>
                        fieldValidator.techEmail(v.email) || true,
                    }}
                    render={({ field, fieldState: { error = {} } }) => (
                      <>
                        <RemovableTextInput
                          data-testid="cspTechEmailInput"
                          required
                          error={!!error.message}
                          note={error.message}
                          label="Email Address"
                          removable={csp.techEmail.length > 1}
                          onRemoveClick={() => techEmailArray.remove(index)}
                          maxLength={100}
                          {...field}
                          onChange={(val) => field.onChange({ email: val })}
                          value={field.value.email}
                        />
                      </>
                    )}
                  />
                </div>
              ))}
            </TwoColumnGrid>
            {renderAddEmailBtn('techEmail')}
          </Section>
        </div>
      );
    }

    return null;
  }
);

ContactDetails.displayName = 'ContactDetails';

export default ContactDetails;
