import React from 'react';
import { Drawer, Grid } from '@material-ui/core';
import { connect } from 'react-redux';
import { S3_ASSETS_PATH } from '../../constants';
import parse from 'html-react-parser';

function HelpSection(props) {
  const [open, setOpen] = React.useState(false);
  const [state, setState] = React.useState({
    right: false,
  });
  function selectData(flag) {
    const data = props.helpBar.find((item) => item.path == flag);
    if (data) {
      return data.text;
    } else {
      return '';
    }
  }
  const toggleDrawer = (anchor, open) => (event) => {
    if (
      event.type === 'keydown' &&
      (event.key === 'Tab' || event.key === 'Shift')
    ) {
      return;
    }
    setOpen(open);
    setState({ ...state, [anchor]: open });
  };
  return (
    <div className="help-section" data-testid="helpSection">
      <Drawer
        open={state.right}
        onClose={toggleDrawer('right', false)}
        className="help-section-drawer"
        anchor="right"
        PaperProps={{
          style: {
            maxHeight: 550,
            maxWidth: 550,
            minWidth: 300,
            overflow: 'visible',
          },
        }}
      >
        <Grid container className="help-section-wrapper">
          <h3 className="heading1 title">Need Help?</h3>
          <div className="content-blk">{parse(selectData(props.path))}</div>
          <div className="footer">
            <h4 className="paragraph">
              Having trouble? let us help you{' '}
              <span>support@campaignregistry.com</span>
            </h4>
          </div>
        </Grid>
        <img
          data-testid="helpSectionFalseToggle"
          src={`${S3_ASSETS_PATH}/images/help.svg`}
          onClick={toggleDrawer('right', false)}
        />
      </Drawer>
      {!open && (
        <img
          data-testid="helpSectionTrueToggle"
          src={`${S3_ASSETS_PATH}/images/help.svg`}
          onClick={toggleDrawer('right', true)}
          style={props.imgStyle}
        />
      )}
    </div>
  );
}
const mapStateToProps = (state) => ({
  helpBar: state.shareReducer.helpBar,
});

export default connect(mapStateToProps)(HelpSection);
