import React, { useEffect, useState } from 'react';
import MomentUtils from '@date-io/moment';
import { Dialog, Grid, TextField, makeStyles } from '@material-ui/core';
import { DatePicker, MuiPickersUtilsProvider } from '@material-ui/pickers';

import {
  AEGIS_COMMITTEE_STATE_OPTIONS,
  AEGIS_COMMITTEE_TYPE_CODE_OPTIONS,
  AEGIS_LOCALE_OPTIONS,
  AEGIS_PIN_PREFERENCE_OPTIONS,
} from '../../constants';
import IntegrationReactSelect from '../../../../../ui_elements/IntegrationReactSelect';
import ToolTip from '../../../../../ui_elements/ToolTip';
import moment from 'moment';
import { BrandDetail } from '../../types';

const datePattern = /^\d{4}-\d{2}-\d{2}$/;

const useStyles = makeStyles({
  container: {
    width: 931,
    maxWidth: 'unset',
  },
  header: {
    marginBottom: 32,
    fontSize: 18,
    fontWeight: 500,
    lineHeight: '21px',
    textAlign: 'center',
    color: '#49535D',
  },
  content: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    '& > *': {
      padding: '27px 0',
    },
    '& > *:not(:first-child)': {
      borderTop: '1px solid #C0CCD4',
    },
  },
  pricing: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    height: 42,
    padding: '0 13px',
    marginTop: 14,
    color: '#FFFFFF',
    background: '#0091B3',
  },
});

interface Props {
  brand: BrandDetail;
  onClose: () => void;
  onSubmit: (_data: { [key: string]: number | boolean | string }) => void;
}

const PoliticalVettingModal: React.FC<Props> = ({
  brand,
  onClose,
  onSubmit,
}) => {
  const classes = useStyles();
  const [requestorFirstName, setRequestorFirstName] = useState(
    brand.entityType === 'SOLE_PROPRIETOR' && brand.firstName
      ? brand.firstName
      : ''
  );
  const [requestorLastName, setRequestorLastName] = useState(
    brand.entityType === 'SOLE_PROPRIETOR' && brand.lastName
      ? brand.lastName
      : ''
  );
  const [locale, setLocale] = useState('');
  const [fecCommitteeTypeCode, setFecCommitteeTypeCode] = useState('');
  const [committeeId, setCommitteeId] = useState('');
  const [candidateType, setCandidateType] = useState('');
  const [stateLocalCommitteeType, setStateLocalCommitteeType] = useState('');
  const [localCommitteeState, setLocalCommitteeState] = useState('');
  const [localCommitteeMunicipality, setLocalCommitteeMunicipality] =
    useState('');
  const [tribalLocation, setTribalLocation] = useState('');
  const [filingUrl, setFilingUrl] = useState('');
  const [filingRecordUrlInstruction, setFilingRecordUrlInstruction] =
    useState('');
  const [filingEmail, setFilingEmail] = useState('');
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [minElectionDate, setMinElectionDate] = useState(
    moment(Date.now()).add(1, 'day').toDate()
  );
  const [electionDate, setElectionDate] = useState(
    moment(Date.now()).add(1, 'day').format('yyyy-MM-DD')
  );
  const [pinPreference, setPinPreference] = useState('');
  const [loading, setLoading] = useState(false);

  const isValid = () => {
    return (
      !!requestorFirstName &&
      !!requestorLastName &&
      !!locale &&
      (locale !== 'Federal' || !!fecCommitteeTypeCode) &&
      !!committeeId &&
      !!candidateType &&
      ((locale !== 'State' && locale !== 'Local' && locale !== 'Tribal') ||
        !!stateLocalCommitteeType) &&
      !!localCommitteeState &&
      !!localCommitteeMunicipality &&
      !!tribalLocation &&
      !!filingUrl &&
      datePattern.test(electionDate) &&
      !!pinPreference &&
      (pinPreference !== 'Email' || !!filingEmail)
    );
  };

  const handleCancel = () => {
    onClose && onClose();
  };

  const handleSubmit = () => {
    if (!loading) {
      setLoading(true);
      onSubmit &&
        onSubmit({
          requestorFirstName,
          requestorLastName,
          locale,
          fecCommitteeTypeCode,
          committeeId,
          candidateType,
          stateLocalCommitteeType,
          localCommitteeState,
          localCommitteeMunicipality,
          tribalLocation,
          filingUrl,
          filingRecordUrlInstruction,
          filingEmail,
          electionDate,
          pinPreference,
        });
    }
  };

  useEffect(() => {
    if (locale !== 'Tribal') {
      setTribalLocation('US');
    }
  }, [locale]);

  return (
    <Dialog
      className="brand-vetting-modal"
      classes={{
        paper: classes.container,
      }}
      open
      onClose={(_e, reason) => {
        !(reason === 'backdropClick') && onClose();
      }}
      data-testid="politicalVettingModal"
    >
      <div className="modal-wrapper" style={{ width: 931 }}>
        <div className={classes.header}>
          Political Vetting Application - Aegis Mobile
        </div>
        <div className={`modal-content ${classes.content}`}>
          {brand.entityType === 'NON_PROFIT' && (
            <>
              <Grid
                className="vetting-applied-wrapper modal-info"
                container
                spacing={4}
              >
                <Grid item xs={6}>
                  <div className="form-group-field">
                    <TextField
                      InputLabelProps={{
                        shrink: true,
                      }}
                      required
                      disabled
                      fullWidth
                      label="Candidate Name"
                      value={brand.displayName}
                      inputProps={{
                        'data-testid': 'politicalVettingModalDisplayNameInput',
                      }}
                    />
                  </div>
                </Grid>
              </Grid>
              <Grid
                className="vetting-applied-wrapper modal-info"
                container
                spacing={4}
              >
                <Grid item xs={6}>
                  <div className="form-group-field">
                    <TextField
                      InputLabelProps={{
                        shrink: true,
                      }}
                      required
                      disabled
                      fullWidth
                      label="Committee Name"
                      value={brand.companyName ?? ''}
                      inputProps={{
                        'data-testid': 'politicalVettingModalCompanyNameInput',
                      }}
                    />
                  </div>
                </Grid>
                <Grid item xs={6}>
                  <div className="form-group-field">
                    <TextField
                      InputLabelProps={{
                        shrink: true,
                      }}
                      required
                      disabled
                      fullWidth
                      label="Committee/Campaign EIN"
                      value={brand.ein ?? ''}
                      inputProps={{
                        'data-testid': 'politicalVettingModalEinInput',
                      }}
                    />
                  </div>
                </Grid>
                <Grid item xs={6}>
                  <div className="form-group-field">
                    <TextField
                      InputLabelProps={{
                        shrink: true,
                      }}
                      required
                      disabled
                      fullWidth
                      label="Committee/Campaign Website"
                      value={brand.website ?? ''}
                      inputProps={{
                        'data-testid': 'politicalVettingModalWebsiteInput',
                      }}
                    />
                  </div>
                </Grid>
              </Grid>
            </>
          )}
          {brand.entityType === 'SOLE_PROPRIETOR' && (
            <Grid
              className="vetting-applied-wrapper modal-info"
              container
              spacing={4}
            >
              <Grid item xs={6}>
                <div className="form-group-field">
                  <TextField
                    InputLabelProps={{
                      shrink: true,
                    }}
                    required
                    disabled
                    fullWidth
                    label="Candidate Name"
                    value={brand.displayName}
                    inputProps={{
                      'data-testid': 'politicalVettingModalDisplayNameInput',
                    }}
                  />
                </div>
              </Grid>
              <Grid item xs={6}>
                <div className="form-group-field">
                  <TextField
                    InputLabelProps={{
                      shrink: true,
                    }}
                    required
                    disabled
                    fullWidth
                    label="Committee/Campaign Website"
                    value={brand.website ?? ''}
                    inputProps={{
                      'data-testid': 'politicalVettingModalWebsiteInput',
                    }}
                  />
                </div>
              </Grid>
            </Grid>
          )}
          <Grid
            className="vetting-applied-wrapper modal-info"
            container
            spacing={4}
          >
            <Grid item xs={6}>
              <div className="form-group-field">
                <TextField
                  InputLabelProps={{
                    shrink: true,
                  }}
                  required
                  disabled
                  fullWidth
                  label="Filing Address Line 1"
                  value={brand.street}
                  inputProps={{
                    'data-testid': 'politicalVettingModalStreetInput',
                  }}
                />
              </div>
            </Grid>
            <Grid item xs={6}>
              <div className="form-group-field">
                <TextField
                  InputLabelProps={{
                    shrink: true,
                  }}
                  required
                  disabled
                  fullWidth
                  label="Filing City"
                  value={brand.city}
                  inputProps={{
                    'data-testid': 'politicalVettingModalCityInput',
                  }}
                />
              </div>
            </Grid>
            <Grid item xs={6}>
              <div className="form-group-field">
                <TextField
                  InputLabelProps={{
                    shrink: true,
                  }}
                  required
                  disabled
                  fullWidth
                  label="Filing State"
                  value={brand.state}
                  inputProps={{
                    'data-testid': 'politicalVettingModalStateInput',
                  }}
                />
              </div>
            </Grid>
            <Grid item xs={6}>
              <div className="form-group-field">
                <TextField
                  InputLabelProps={{
                    shrink: true,
                  }}
                  required
                  disabled
                  fullWidth
                  label="Filing Postal Code"
                  value={brand.postalCode}
                  inputProps={{
                    'data-testid': 'politicalVettingModalPostalCodeInput',
                  }}
                />
              </div>
            </Grid>
          </Grid>
          <Grid
            className="vetting-applied-wrapper modal-info"
            container
            spacing={4}
          >
            <Grid item xs={6}>
              <div className="form-group-field">
                <TextField
                  InputLabelProps={{
                    shrink: true,
                  }}
                  inputProps={{
                    maxLength: 100,
                    'data-testid': 'politicalVettingModalFirstNameInput',
                  }}
                  required
                  fullWidth
                  label="Requestor First Name"
                  value={requestorFirstName}
                  onChange={(e) => setRequestorFirstName(e.target.value)}
                />
                <ToolTip
                  title="First name of the person from the committee who initiated the verification request. Exactly as filed with election authority."
                  placement="right-end"
                />
              </div>
            </Grid>
            <Grid item xs={6}>
              <div className="form-group-field">
                <TextField
                  InputLabelProps={{
                    shrink: true,
                  }}
                  inputProps={{
                    maxLength: 100,
                    'data-testid': 'politicalVettingModalLastNameInput',
                  }}
                  required
                  fullWidth
                  label="Requestor Last Name"
                  value={requestorLastName}
                  onChange={(e) => setRequestorLastName(e.target.value)}
                />
                <ToolTip
                  title="Last name of the person from the committee who initiated the verification request. Exactly as filed with election authority."
                  placement="right-end"
                />
              </div>
            </Grid>
            <Grid item xs={6}>
              <div className="form-group-field">
                <TextField
                  InputLabelProps={{
                    shrink: true,
                  }}
                  required
                  disabled
                  fullWidth
                  label="Requestor Phone"
                  value={brand.phone}
                  inputProps={{
                    'data-testid': 'politicalVettingModalPhoneInput',
                  }}
                />
              </div>
            </Grid>
            <Grid item xs={6}>
              <div className="form-group-field">
                <TextField
                  InputLabelProps={{
                    shrink: true,
                  }}
                  required
                  disabled
                  fullWidth
                  label="Requestor Email Address"
                  value={brand.email}
                  inputProps={{
                    'data-testid': 'politicalVettingModalEmailInput',
                  }}
                />
              </div>
            </Grid>
          </Grid>
          <Grid
            className="vetting-applied-wrapper modal-info"
            container
            spacing={4}
          >
            <Grid item xs={6}>
              <div className="form-group-field">
                <IntegrationReactSelect
                  InputLabelProps={{
                    shrink: true,
                  }}
                  required
                  label="Locale"
                  options={AEGIS_LOCALE_OPTIONS}
                  value={AEGIS_LOCALE_OPTIONS.find(
                    (option) => option.value === locale
                  )}
                  handleChange={(option: { label: string; value: string }) =>
                    setLocale(option.value)
                  }
                  testid="politicalVettingModalLocaleSelect"
                />
              </div>
            </Grid>
            <Grid item xs={6}>
              <div className="form-group-field">
                <IntegrationReactSelect
                  InputLabelProps={{
                    shrink: true,
                  }}
                  required={locale === 'Federal'}
                  label="FEC Committee Type Code"
                  options={AEGIS_COMMITTEE_TYPE_CODE_OPTIONS}
                  value={AEGIS_COMMITTEE_TYPE_CODE_OPTIONS.find(
                    (option) => option.value === fecCommitteeTypeCode
                  )}
                  handleChange={(option: { label: string; value: string }) =>
                    setFecCommitteeTypeCode(option.value)
                  }
                  testid="politicalVettingModalFecCommitteeTypeCodeSelect"
                />
              </div>
            </Grid>
            <Grid item xs={6}>
              <div className="form-group-field">
                <TextField
                  InputLabelProps={{
                    shrink: true,
                  }}
                  inputProps={{
                    maxLength: 32,
                    'data-testid': 'politicalVettingModalCommitteeIdInput',
                  }}
                  required
                  fullWidth
                  label="Committee ID"
                  value={committeeId}
                  onChange={(e) => setCommitteeId(e.target.value)}
                />
                <ToolTip
                  title={
                    'Free text field. Required for Federal and any other locale that issues a committee/campaign ID - if not issued, enter "00000".'
                  }
                  placement="right-end"
                />
              </div>
            </Grid>
            <Grid item xs={6}>
              <div className="form-group-field">
                <TextField
                  InputLabelProps={{
                    shrink: true,
                  }}
                  inputProps={{
                    maxLength: 64,
                    'data-testid': 'politicalVettingModalCandidateTypeInput',
                  }}
                  required
                  fullWidth
                  label="Candidate Type"
                  value={candidateType}
                  onChange={(e) => setCandidateType(e.target.value)}
                />
                <ToolTip
                  title={
                    'Free text field. Required for campaigns. If committee is not candidate-specific, enter "PAC".'
                  }
                  placement="right-end"
                />
              </div>
            </Grid>
            <Grid item xs={6}>
              <div className="form-group-field">
                <TextField
                  InputLabelProps={{
                    shrink: true,
                  }}
                  inputProps={{
                    maxLength: 64,
                    'data-testid':
                      'politicalVettingModalStateLocalCommitteeTypeInput',
                  }}
                  required={
                    locale === 'State' ||
                    locale === 'Local' ||
                    locale === 'Tribal'
                  }
                  fullWidth
                  label="State Local Committee Type"
                  value={stateLocalCommitteeType}
                  onChange={(e) => setStateLocalCommitteeType(e.target.value)}
                />
                <ToolTip
                  title={
                    'Free text field. Required for State, Local, and Tribal.'
                  }
                  placement="right-end"
                />
              </div>
            </Grid>
            <Grid item xs={6}>
              <div className="form-group-field">
                <IntegrationReactSelect
                  InputLabelProps={{
                    shrink: true,
                  }}
                  required
                  label="Local Committee State"
                  options={AEGIS_COMMITTEE_STATE_OPTIONS}
                  value={AEGIS_COMMITTEE_STATE_OPTIONS.find(
                    (option) => option.value === localCommitteeState
                  )}
                  handleChange={(option: { label: string; value: string }) =>
                    setLocalCommitteeState(option.value)
                  }
                  testid="politicalVettingModalLocalCommitteeStateSelect"
                />
                <ToolTip
                  title={
                    'If Federal Congress, enter the state to be represented; if President, enter "US".'
                  }
                  placement="right-end"
                />
              </div>
            </Grid>
            <Grid item xs={6}>
              <div className="form-group-field">
                <TextField
                  InputLabelProps={{
                    shrink: true,
                  }}
                  inputProps={{
                    maxLength: 64,
                    'data-testid':
                      'politicalVettingModalLocalCommitteeMunicipalityInput',
                  }}
                  required
                  fullWidth
                  label="Local Committee Municipality"
                  value={localCommitteeMunicipality}
                  onChange={(e) =>
                    setLocalCommitteeMunicipality(e.target.value)
                  }
                />
                <ToolTip
                  title={
                    'Free text field. If Federal Congress, enter district to be represented; if President, enter "US".'
                  }
                  placement="right-end"
                />
              </div>
            </Grid>
            <Grid item xs={6}>
              <div className="form-group-field">
                <TextField
                  InputLabelProps={{
                    shrink: true,
                  }}
                  inputProps={{
                    maxLength: 64,
                    'data-testid': 'politicalVettingModalTribalLocationInput',
                  }}
                  required
                  disabled={locale !== 'Tribal'}
                  fullWidth
                  label="Tribal Location"
                  value={tribalLocation}
                  onChange={(e) => setTribalLocation(e.target.value)}
                />
                <ToolTip
                  title={'Free text field. If non-tribal, enter "US".'}
                  placement="right-end"
                />
              </div>
            </Grid>
            <Grid item xs={6}>
              <div className="form-group-field">
                <TextField
                  InputLabelProps={{
                    shrink: true,
                  }}
                  inputProps={{
                    maxLength: 256,
                    'data-testid': 'politicalVettingModalFilingUrlInput',
                  }}
                  required
                  fullWidth
                  label="Filing Url"
                  value={filingUrl}
                  onChange={(e) => setFilingUrl(e.target.value)}
                />
                <ToolTip
                  title="Should be a valid URL format. Election authority website URL where the filing information can be found."
                  placement="right-end"
                />
              </div>
            </Grid>
            <Grid item xs={6}>
              <div className="form-group-field">
                <TextField
                  InputLabelProps={{
                    shrink: true,
                  }}
                  inputProps={{
                    maxLength: 256,
                    'data-testid':
                      'politicalVettingModalFilingRecordUrlInstructionInput',
                  }}
                  fullWidth
                  label="Filing Record Url Instructions"
                  value={filingRecordUrlInstruction}
                  onChange={(e) =>
                    setFilingRecordUrlInstruction(e.target.value)
                  }
                />
                <ToolTip
                  title="Free text field. Additional instructions to aid in locating the filing record."
                  placement="right-end"
                />
              </div>
            </Grid>
            <Grid item xs={6}>
              <div className="form-group-field">
                <MuiPickersUtilsProvider
                  utils={MomentUtils}
                  libInstance={moment}
                >
                  <DatePicker
                    TextFieldComponent={(props) => (
                      <TextField required {...props} />
                    )}
                    margin="normal"
                    autoOk
                    disableToolbar
                    variant="inline"
                    format="yyyy-MM-DD"
                    label="Election Date"
                    minDate={minElectionDate}
                    value={electionDate}
                    onChange={(date) =>
                      setElectionDate(moment(date).format('yyyy-MM-DD'))
                    }
                    inputProps={{
                      'data-testid': 'politicalVettingModalElectionDateInput',
                    }}
                    data-testid="politicalVettingModalDatePickerInput"
                  />
                </MuiPickersUtilsProvider>
                <ToolTip
                  title="Date string in the format yyyy-mm-dd. Must be in the future."
                  placement="right-end"
                />
              </div>
            </Grid>
            <Grid item xs={6}>
              <div className="form-group-field">
                <IntegrationReactSelect
                  InputLabelProps={{
                    shrink: true,
                  }}
                  required
                  label="PIN Preference"
                  options={AEGIS_PIN_PREFERENCE_OPTIONS}
                  value={AEGIS_PIN_PREFERENCE_OPTIONS.find(
                    (option) => option.value === pinPreference
                  )}
                  handleChange={(option: { label: string; value: string }) =>
                    setPinPreference(option.value)
                  }
                  testid="politicalVettingModalPinPreferenceSelect"
                />
              </div>
            </Grid>
            <Grid item xs={6}>
              <div className="form-group-field">
                <TextField
                  InputLabelProps={{
                    shrink: true,
                  }}
                  inputProps={{
                    maxLength: 128,
                    'data-testid': 'politicalVettingModalFillingEmailInput',
                  }}
                  required={pinPreference === 'Email'}
                  fullWidth
                  type="email"
                  label="Filing Email"
                  value={filingEmail}
                  onChange={(e) => setFilingEmail(e.target.value)}
                />
                <ToolTip
                  title="Free text field. Exactly as filed with election authority. If not supplied, PIN cannot be sent via email."
                  placement="right-end"
                />
              </div>
            </Grid>
          </Grid>
        </div>
        <Grid item className="modal-footer">
          <ul className="list-inline text-center">
            <li>
              <a
                className="secondary-btn"
                href="#"
                onClick={handleCancel}
                data-testid="politicalVettingModalCancelButton"
              >
                Cancel
              </a>
            </li>
            <li style={{ marginLeft: 16 }}>
              <div className="form-group-field">
                <input
                  disabled={loading || !isValid()}
                  type="submit"
                  className="primary-btn"
                  value="Submit"
                  onClick={handleSubmit}
                  data-testid="politicalVettingModalSubmitButton"
                />
              </div>
            </li>
          </ul>
        </Grid>
      </div>
    </Dialog>
  );
};

export default PoliticalVettingModal;
