import React, { Component } from 'react';
import Grid from '@material-ui/core/Grid';
import { Header } from '../../../shared_elements';
import { RegisterForm } from '../components';
import '../../../assets/styles/user-authentication-module.scss';

export default class Register extends Component {
  constructor(props) {
    super(props);
  }

  componentDidMount() {
    document.title = 'The Campaign Registry - Welcome to the Campaign Registry';
  }

  render() {
    return (
      <section
        className="user-authentication-section"
        style={{ overflow: 'hidden' }}
      >
        <Header
          title="WELCOME TO THE CAMPAIGN REGISTRY"
          testId="registerHeader"
        ></Header>
        <Grid
          container
          spacing={1}
          alignItems="center"
          justify="center"
          className="user-form-wrapper"
          style={{ height: window.innerHeight - 60 }}
        >
          <RegisterForm baseUrl={this.props.baseUrl} />
        </Grid>
      </section>
    );
  }
}
