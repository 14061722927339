import { FC, ReactNode } from 'react';
import { BoxV2 as Box, Flex } from 'portal-commons';

interface StepProps {
  step: number;
  label: ReactNode;
  active: boolean;
  disabled?: boolean;
}

interface StepperProps {
  steps: Array<{ key: number | string; label: ReactNode }>;
  activeKey: number | string;
  disabled?: boolean;
}

const RED_COLOR_NAME = 't.paleRed700';
const DISABLED_GREY = 't.black10';

const Step: FC<StepProps> = ({ step, label, active, disabled = false }) => {
  const primaryColor = disabled ? DISABLED_GREY : RED_COLOR_NAME;
  return (
    <Flex
      as="span"
      data-testid={`stepperStep-${step}`}
      sx={{
        flexDirection: 'column',
        alignItems: 'center',
        gap: 'xs',
        position: 'relative',
        width: '85px',
        overflow: 'visible',
      }}
    >
      {step > 1 && (
        <Box
          sx={{
            position: 'absolute',
            width: '120px',
            top: '20px',
            left: 'calc(-100% - 24px)',
            right: 'calc(-100% - 24px)',
          }}
        >
          <Box
            as="span"
            sx={{
              display: 'block',
              borderColor: disabled ? DISABLED_GREY : '#666E71',
              borderTopStyle: 'solid',
              borderTopWidth: '1px',
            }}
          />
        </Box>
      )}
      <Flex
        as="span"
        sx={{
          fontSize: '20px',
          color: active ? '#fff' : primaryColor,
          borderRadius: '50%',
          width: '40px',
          height: '40px',
          borderWidth: '2px',
          borderColor: primaryColor,
          borderStyle: 'solid',
          backgroundColor: active ? primaryColor : '#fff',
          alignItems: 'center',
          justifyContent: 'center',
        }}
      >
        {step}
      </Flex>
      <Box
        as="span"
        sx={{
          fontSize: 'H300',
          color: disabled ? DISABLED_GREY : 't.black80',
          fontWeight: active ? 'extraBold' : 'normal',
          whiteSpace: 'nowrap',
        }}
      >
        {label}
      </Box>
    </Flex>
  );
};

const Stepper: FC<StepperProps> = ({ steps, activeKey, disabled = false }) => {
  return (
    <Box
      data-testid="stepperList"
      sx={{
        display: 'inline-flex',
        flexDirection: 'row',
        alignItems: 'flex-start',
        gap: '100px',
      }}
    >
      {steps.map((prop, index) => (
        <Step
          {...prop}
          step={index + 1}
          active={activeKey === prop.key}
          disabled={disabled}
        />
      ))}
    </Box>
  );
};

export default Stepper;
