import { Box } from 'portal-commons';
import { FunctionComponent, useState } from 'react';
import { Grid } from '@material-ui/core';
import { faBullhorn, faPen } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { toastFlashMessage } from '../../../../utils';
import CampaignDescription from './CampaignDescription';
import { CampaignDetail, CampaignDetailsUpdatePayload } from '../types';
import CampaignDetailsEditModal from './CampaignDetailsEditModal';
import { updateCampaign } from '../apis';

interface CampaignDetailsSectionProps {
  disabled?: boolean;
  readonly?: boolean;
  campaign: CampaignDetail;
  onChange?: (data: CampaignDetailsUpdatePayload) => void;
  greyOutFields?: Array<string>;
}

const CampaignDetailsSection: FunctionComponent<
  CampaignDetailsSectionProps
> = ({ disabled, readonly, campaign, onChange, greyOutFields = [] }) => {
  const [modalOpen, setModalOpen] = useState(false);
  const [loading, setLoading] = useState(false);

  const handleSubmit = async (data: CampaignDetailsUpdatePayload) => {
    setLoading(true);
    const response = await updateCampaign(campaign.uid, data);
    if (response) {
      setModalOpen(false);
      onChange?.(data);
      toastFlashMessage('Campaign updated successfully', 'success');
    }
    setLoading(false);
  };

  return (
    <Grid item xs={12}>
      <div
        className="description details"
        data-testid="campaignDetailsCampaignDetails"
      >
        <div className="details-heading">
          <Box flexDirection="row" alignItems="center">
            <Box margin={{ right: 'xs' }}>
              <FontAwesomeIcon icon={faBullhorn} size="xl" />
            </Box>
            <h3 className="heading1">Campaign Details</h3>
          </Box>
          {!readonly && (
            <div
              data-testid="campaignDetailsCampaignDetailsEditButton"
              className={disabled ? 'edit-btn disabled' : 'edit-btn'}
              onClick={() => setModalOpen(true)}
            >
              <Box flexDirection="row" alignItems="center">
                <Box margin={{ right: 'xs' }}>
                  <FontAwesomeIcon icon={faPen} color="#0091B3" />
                </Box>
                <span>Edit</span>
              </Box>
            </div>
          )}
        </div>
        <CampaignDescription
          description={campaign.description}
          messageFlow={campaign.messageFlow}
          privacyPolicyLink={campaign.privacyPolicyLink}
          termsAndConditionsLink={campaign.termsAndConditionsLink}
        />
      </div>
      <CampaignDetailsEditModal
        open={modalOpen}
        disabled={loading}
        campaign={campaign}
        onClose={() => setModalOpen(false)}
        onSubmit={handleSubmit}
        greyOutFields={greyOutFields}
      />
    </Grid>
  );
};

export default CampaignDetailsSection;
