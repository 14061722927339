import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Grid, Container } from '@material-ui/core';

import { Loader } from '../../../../shared_elements';
import {
  getCampaignDetailsApi,
  getCampaignOperationsApi,
  getResellerDetailApi,
  getCnpListApi,
  updateCampaignApi,
  deactivateCampaignApi,
  listCampaignElectedDcaApi,
  getNominationStatusApi,
  getCampaignAttachmentsApi,
  downloadAttachmentApi,
  getProfile,
  getCampaignTermsAndConditions,
  getEventHistory,
  downloadAttachmentToDesktop,
} from '../apiServices';
import {
  CampaignDescription,
  CampaignSampleMessage,
  MnoStatusTableNew,
  CampaignAttributeNew,
  CampaignEditModal,
  ConfirmationDialog,
  ElectCNPAlert,
  ElectCNPDialog,
} from '../components';
import '../../../../assets/styles/campaign-detail-module.scss';
import PartnerCampaignDetailsBasic from '../components/PartnerCampaignDetailsBasic';
import EventsListingRow from '../components/EventListingRow';
import { withStyles } from '@material-ui/core/styles';
import { ATTACHMENT_FOLDER_MMS } from '../constants';
import BrandDetails from '../components/BrandDetails';
import MessageFlowAttributePopup from '../components/MessageFlowAttributePopup';
import { getMigrationAndProvisionalInfo } from '../utils';

import {
  BoxV2 as Box,
  Flex,
  Table,
  MultimediaList,
  AttachmentPreview,
} from 'portal-commons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faShareNodes,
  faSquarePollHorizontal,
  faMessageDots,
  faFileImage,
  faCalendarRange,
  faAngleLeft,
  faTag,
  faBullhorn,
} from '@fortawesome/pro-regular-svg-icons';
import SupportingDocumentSection from '../components/SupportingDocumentSection';
import EmbeddedLinkPopup from '../components/EmbeddedLinkPopup';

const useStyles = (theme) => ({
  modal: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    width: 430,
    height: 226,
    background: '#FFFFFF',
  },
});

const headRows = [
  {
    id: 'eventType',
    label: 'EVENT TYPE',
    sortable: false,
  },
  {
    id: 'categoryType',
    label: 'SOURCE',
    sortable: false,
  },
  {
    id: 'campaignUid',
    label: 'CAMPAIGN ID',
    sortable: false,
  },
  {
    id: 'brandUid',
    label: 'BRAND ID',
    sortable: false,
  },
  {
    id: 'updateDate',
    label: 'DATE & TIME',
    sortable: false,
  },
  {
    id: 'description',
    label: 'DESCRIPTION',
    sortable: false,
  },
];

const HeadingBlock = ({ icon, title, children, ...props }) => (
  <div className="details-heading" {...props}>
    <Flex sx={{ alignItems: 'center' }}>
      <Box sx={{ mr: 'xs' }}>
        <FontAwesomeIcon icon={icon} size="xl" color="#49535D" />
      </Box>
      <h3 className="heading1">{title}</h3>
    </Flex>
    {children}
  </div>
);

class PartnerCampaignDetail extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loader: true,
      campaign: {},
      goBackPage: '/cnp-campaigns?status=ACTIVE',
      resellerInfo: {},
      dcaInfo: {},
      renewalData: '',
      deactivateData: '',
      electedDcas: [],
      cnpOptions: [],
      nominationInfo: {},
      openAlert: false,
      electCnpFlag: false,
      campaignUid: '',
      sampleMultimedia: [],
      sampleMultimediaIndex: -1,
      termsAndConditions: [],
      dpaSigned: false,
      optinKeywords: [],
      optinMessage: '',
      optinPopup: false,
      optoutKeywords: [],
      optoutMessage: '',
      optoutPopup: false,
      helpKeywords: [],
      helpMessage: '',
      helpPopup: false,
      embeddedLinkPopup: false,
      eventInfo: {
        records: [],
        page: 1,
        recordsPerPage: 10,
        totalRecords: 0,
      },
      tableLoader: false,
    };
    this.getCampaignDetailsApi = getCampaignDetailsApi.bind(this);
    this.getCampaignOperationsApi = getCampaignOperationsApi.bind(this);
    this.getResellerDetailApi = getResellerDetailApi.bind(this);
    this.getCnpListApi = getCnpListApi.bind(this);
    this.updateCampaignApi = updateCampaignApi.bind(this);
    this.deactivateCampaignApi = deactivateCampaignApi.bind(this);
  }

  async componentDidMount() {
    this._isMounted = true;
    document.title = 'The Campaign Registry - Campaigns';
    const campaignUid = this.deriveCampaignId(this.props);
    if (this.props.location.state) {
      const { goBackPage } = this.props.location.state;
      this.setState({
        goBackPage,
      });
    }

    try {
      this.updateProvisionalFlag(campaignUid);
      this.initialize(campaignUid);
    } catch (error) {}
  }

  componentDidUpdate(prevProps, prevState) {
    const prevCampaignUid = this.deriveCampaignId(prevProps);
    const campaignUid = this.deriveCampaignId(this.props);

    if (prevCampaignUid !== campaignUid) {
      this.setState({ provisional: null, campaign: null });
      this.updateProvisionalFlag(campaignUid);
      return;
    }

    if (this.state.provisional == null) {
      // when this is null, campaign details is not fetched back yet, page shows loading spinner
      return;
    } else if (prevState.provisional !== this.state.provisional) {
      this.updateProvisionalDependentData(campaignUid, this.state.provisional);
      return;
    }

    if (this.props.cspData.length && this.state.renewalData == '') {
      const renewalData = this.props.cspData.find(
        (item) =>
          item.lookup_key == 'csp-campaign-dialog-confirm#cancel-auto-renewal'
      );
      this.setState({
        renewalData,
      });
    }
    if (this.props.cspData.length && this.state.deactivateData == '') {
      const deactivateData = this.props.cspData.find(
        (item) => item.lookup_key == 'csp-campaign-dialog-confirm#deactivate'
      );
      this.setState({
        deactivateData,
      });
    }
  }

  componentWillUnmount() {
    this._isMounted = false;
  }

  deriveCampaignId = (componentProps) => {
    return componentProps.match.params.id;
  };

  // fetch provisional independent data
  initialize = async (campaignUid) => {
    this.setState({
      tableLoader: true,
    });
    const eventInfo = await getEventHistory({
      campaignUid,
    });
    this.setState({
      eventInfo: eventInfo ? eventInfo : this.state.eventInfo,
      tableLoader: false,
    });

    getCampaignAttachmentsApi(campaignUid, ATTACHMENT_FOLDER_MMS).then(
      (sampleMultimedia) => {
        this.setState({
          sampleMultimedia,
        });
      }
    );
    getCampaignTermsAndConditions().then((termsAndConditions) => {
      this.setState({
        termsAndConditions,
      });
    });
    getProfile().then((profile) => {
      this.setState({
        dpaSigned: profile.dpaSigned,
      });
    });
  };

  updateCampaignDetails = async (campaignUid, provisional) => {
    const campaign = await this.getCampaignDetailsApi(campaignUid, {
      provisional,
    });

    this.getCnpListApi({
      soleProprietorEnabled:
        campaign?.usecase === 'SOLE_PROPRIETOR' ? true : undefined,
      cnpMigrationSupported: !provisional ? undefined : true,
    });
  };

  updateElectedDca = (campaignUid, provisional) => {
    listCampaignElectedDcaApi(campaignUid, { provisional }).then(
      (electedDcas) => {
        this.setState({
          electedDcas,
        });
      }
    );
  };

  updateNominationStatus = (campaignUid, provisional) => {
    getNominationStatusApi(campaignUid, {
      provisional,
    }).then((data) => {
      this.setState({
        nominationInfo: data.nominationInfo,
        openAlert: !!data.openAlert,
      });
    });
  };

  updateProvisionalDependentData = (campaignUid, provisional) => {
    this.updateCampaignDetails(campaignUid, provisional);
    this.updateNominationStatus(campaignUid, provisional);
    this.updateElectedDca(campaignUid, provisional);
    this.getCampaignOperationsApi(campaignUid, { provisional });
  };

  updateProvisionalFlag = async (campaignUid) => {
    const result = await getMigrationAndProvisionalInfo(campaignUid);
    this.setState({
      migrationInfo: result.migrationInfo,
      provisional: result.provisional,
    });
  };

  handleGoBack = () => {
    const { goBackPage } = this.state;
    this.props.history.push(`${goBackPage}`);
  };

  handleEditClick = (type) => {
    this.setState({
      openEditModal: true,
      editType: type,
    });
  };

  handleSubmitEdit = (data) => {
    const { editType, campaignUid } = this.state;
    // console.log('campaign formDataEdit', data)
    this.updateCampaignApi(campaignUid, data);
  };

  handleDeactivateCampaign = () => {
    const { campaignUid } = this.state;
    this.deactivateCampaignApi(campaignUid);
  };

  handleCloseAlert = () => {
    this.setState({
      openAlert: false,
    });
  };

  handleClickCnpAction = (flag, success = false) => {
    this.setState({
      electCnpFlag: success ? false : flag,
    });
    if (success) {
      const { campaignUid } = this.state;
      this.getNominationStatusApi(campaignUid);
      this.listCampaignElectedDcaApi(campaignUid);
    }
  };

  handleMultimediaSelect = async (index) => {
    this.setState({
      sampleMultimediaIndex: index,
    });
    if (!this.state.sampleMultimedia[index].file) {
      const response = await downloadAttachmentApi(
        this.state.sampleMultimedia[index].uuid
      );
      const newSampleMultimedia = [...this.state.sampleMultimedia];
      newSampleMultimedia.splice(index, 1, {
        ...this.state.sampleMultimedia[index],
        file: new File(
          [response],
          this.state.sampleMultimedia[index].fileName,
          {
            type: this.state.sampleMultimedia[index].mimeType,
          }
        ),
      });
      this.setState({
        sampleMultimedia: newSampleMultimedia,
      });
    }
  };

  handleMultimediaDownload = async (index) => {
    await downloadAttachmentToDesktop(
      this.state.sampleMultimedia[index].uuid,
      this.state.sampleMultimedia[index].fileName
    );
  };

  handleOptinPopupChange = (active) => {
    this.setState({
      optinPopup: active,
      optoutPopup: false,
      helpPopup: false,
      embeddedLinkPopup: false,
    });
  };

  handleChangePage = async (page) => {
    try {
      this.setState({
        tableLoader: true,
      });
      const eventInfo = await getEventHistory({
        campaignUid: this.props.match.params.id,
        page,
      });
      this.setState({
        eventInfo,
        tableLoader: false,
      });
    } catch (e) {}
  };

  handleOptoutPopupChange = (active) => {
    this.setState({
      optinPopup: false,
      optoutPopup: active,
      helpPopup: false,
      embeddedLinkPopup: false,
    });
  };

  handleHelpPopupChange = (active) => {
    this.setState({
      optinPopup: false,
      optoutPopup: false,
      helpPopup: active,
      embeddedLinkPopup: false,
    });
  };

  handleEmbeddedLinkPopupChange = (active) => {
    this.setState({
      optinPopup: false,
      optoutPopup: false,
      helpPopup: false,
      embeddedLinkPopup: active,
    });
  };

  render() {
    const {
      campaign,
      loader,
      mnoStatusInfo,
      goBackPage,
      resellerInfo,
      dcaInfo,
      openEditModal,
      editType,
      editLoader,
      deactivateFlag,
      renewalData,
      deactivateData,
      electedDcas,
      cnpOptions,
      nominationInfo,
      openAlert,
      campaignUid,
      electCnpFlag,
      sampleMultimedia,
      sampleMultimediaIndex,
      termsAndConditions,
      optinKeywords,
      optinMessage,
      optinPopup,
      optoutKeywords,
      optoutMessage,
      optoutPopup,
      helpKeywords,
      helpMessage,
      helpPopup,
      embeddedLinkPopup,
      eventInfo,
      tableLoader,
    } = this.state;
    return (
      <section
        className="campaign-detail-section"
        data-testid="partnerCampaignDetail"
      >
        {loader ? (
          <Loader />
        ) : (
          <Container maxWidth={false} className="campaign-detail-container">
            <Grid container className="top-blk">
              <Grid item xs={6} className="back-btn">
                <a
                  onClick={this.handleGoBack}
                  className="paragraph"
                  data-testid="partnerCampaignDetailBackButton"
                >
                  <Flex>
                    <Box sx={{ mr: 'xs' }}>
                      <FontAwesomeIcon icon={faAngleLeft} color="#68737A" />
                    </Box>
                    {goBackPage.startsWith('/cnp-campaigns')
                      ? 'back to all campaigns'
                      : 'back'}
                  </Flex>
                </a>
              </Grid>
            </Grid>
            <Grid container spacing={0}>
              <Grid item xs={12} data-testid="partnerCampaignDetailCampaignId">
                <div className="title">
                  <h3 className="heading1">Campaign ID: {campaign.uid}</h3>
                </div>
              </Grid>
              <Grid item xs={12}>
                <PartnerCampaignDetailsBasic
                  campaign={campaign}
                  handleSubmitEdit={this.handleSubmitEdit}
                  loader={editLoader}
                  renewalData={renewalData}
                />
              </Grid>
            </Grid>
            <Grid container>
              <Grid item xs={12}>
                <div
                  className="mno details"
                  data-testid="partnerCampaignDetailCarrierStatus"
                >
                  <HeadingBlock icon={faShareNodes} title="Carrier Status" />
                  <MnoStatusTableNew
                    mnoStatusInfo={mnoStatusInfo}
                    electedDcas={electedDcas}
                  />
                </div>
              </Grid>
              <Grid item xs={12}>
                <div
                  className="description details"
                  data-testid="partnerCampaignDetailBrandDetails"
                >
                  <HeadingBlock icon={faTag} title="Brand Details" />
                  {campaign.brand && (
                    <BrandDetails
                      brand={campaign.brand}
                      usecase={campaign.usecase}
                      dpaSigned={this.state.dpaSigned}
                    />
                  )}
                </div>
              </Grid>
              <Grid item xs={12}>
                <div className="attributes details">
                  <HeadingBlock
                    className="details-heading"
                    data-testid="partnerCampaignDetailCampaignAndContentAttributes"
                    icon={faSquarePollHorizontal}
                    title="Campaign and Content Attributes"
                  />
                  <Box
                    sx={{
                      display: 'grid',
                      gridTemplateColumns: '1fr 1fr',
                      columnGap: 'l',
                      rowGap: 's',
                    }}
                  >
                    <CampaignAttributeNew
                      type="view"
                      id="subscriberOptin"
                      value={campaign.subscriberOptin}
                      label="Subscriber Opt-in"
                      tooltip="Indicates whether the campaign is collecting and processing consumer opt-ins"
                      additionalSetup={
                        <MessageFlowAttributePopup
                          defaultReadonly
                          editable={false}
                          label="Opt-in"
                          keywordsTooltip="A keyword consumers can text to opt-in."
                          messageTooltip="If consumers can text in a keyword, the response should include the Brand name, confirmation of opt-in enrollment to a recurring message campaign, how to get help, and clear description of how to opt-out."
                          keywords={optinKeywords}
                          message={optinMessage}
                          onClose={() => this.handleOptinPopupChange(false)}
                        />
                      }
                      additionalSetupActive={optinPopup}
                      setAdditionalSetupActive={this.handleOptinPopupChange}
                    />
                    <CampaignAttributeNew
                      type="view"
                      id="embeddedLink"
                      value={campaign.embeddedLink}
                      label="Embedded Link"
                      tooltip="Indicates whether the campaign is using an embedded link of any kind. Note that public URL shorteners (bitly, tinyurl) are not accepted"
                      additionalSetup={
                        <EmbeddedLinkPopup
                          editable={false}
                          defaultReadonly
                          value={campaign.embeddedLinkSample}
                          onClose={() =>
                            this.handleEmbeddedLinkPopupChange(false)
                          }
                        />
                      }
                      additionalSetupActive={embeddedLinkPopup}
                      setAdditionalSetupActive={
                        this.handleEmbeddedLinkPopupChange
                      }
                    />
                    <CampaignAttributeNew
                      type="view"
                      id="subscriberOptout"
                      value={campaign.subscriberOptout}
                      label="Subscriber Opt-Out"
                      tooltip="Indicates whether the campaign is collecting and processing consumer opt-outs"
                      additionalSetup={
                        <MessageFlowAttributePopup
                          defaultReadonly
                          editable={false}
                          label="Opt-out"
                          keywordsTooltip="A keyword consumers can text to opt-out."
                          messageTooltip="The response to the STOP keyword may include the Brand name but should include an acknowledgement of opt-out request and confirmation that no further messages will be sent."
                          keywords={optoutKeywords}
                          message={optoutMessage}
                          onClose={() => this.handleOptoutPopupChange(false)}
                        />
                      }
                      additionalSetupActive={optoutPopup}
                      setAdditionalSetupActive={this.handleOptoutPopupChange}
                    />
                    <CampaignAttributeNew
                      type="view"
                      id="embeddedPhone"
                      value={campaign.embeddedPhone}
                      label="Embedded Phone Number"
                      tooltip="Indicates whether the campaign is using an embedded phone number (except the required HELP information contact phone number)"
                    />
                    <CampaignAttributeNew
                      type="view"
                      id="subscriberHelp"
                      value={campaign.subscriberHelp}
                      label="Subscriber Help"
                      tooltip="Indicates whether the campaign has implemented implemented message reply providing customers on how they can contact the message sender after they reply with the “HELP” keyword."
                      additionalSetup={
                        <MessageFlowAttributePopup
                          defaultReadonly
                          editable={false}
                          label="Help"
                          keywordsTooltip="A keyword consumers use to get help or more information."
                          messageTooltip="The response to HELP keyword may include the Brand name and additional support contact information."
                          keywords={helpKeywords}
                          message={helpMessage}
                          onClose={() => this.handleHelpPopupChange(false)}
                        />
                      }
                      additionalSetupActive={helpPopup}
                      setAdditionalSetupActive={this.handleHelpPopupChange}
                    />
                    <CampaignAttributeNew
                      type="view"
                      id="ageGated"
                      value={campaign.ageGated}
                      label="Age-Gated Content"
                      tooltip="Indicates whether the campaign include any age-gated content as defined by Carrier and CTIA guidelines"
                    />
                  </Box>
                  <Box
                    sx={{
                      display: 'grid',
                      gridTemplateColumns: '1fr 1fr',
                      columnGap: 'l',
                      mt: 's',
                    }}
                  >
                    <Flex sx={{ flexDirection: 'column', gap: 's' }}>
                      <CampaignAttributeNew
                        type="view"
                        id="numberPool"
                        value={campaign.numberPool}
                        label="Number Pooling"
                        tooltip="Indicates whether more than 50 numbers will be used for the campaign."
                      />
                      <CampaignAttributeNew
                        type="view"
                        id="directLending"
                        value={campaign.directLending}
                        label="Direct Lending or Loan Arrangement"
                        tooltip="Indicates whether the campaign include content related to direct lending or other loan arrangements"
                      />
                    </Flex>
                    <CampaignAttributeNew
                      type="view"
                      label="Terms & Conditions"
                      tooltip="Indicates the campaign follows CTIA messaging principles and best practices"
                      notes={termsAndConditions}
                      id="termsAndConditions"
                      value={campaign.termsAndConditions}
                    />
                  </Box>
                </div>
              </Grid>
              <Grid item xs={12}>
                <div
                  className="description details"
                  data-testid="partnerCampaignDetailCampaignDescription"
                >
                  <HeadingBlock
                    icon={faBullhorn}
                    title="Campaign Description"
                  />
                  <CampaignDescription
                    description={campaign.description}
                    messageFlow={campaign.messageFlow}
                    privacyPolicyLink={campaign.privacyPolicyLink}
                    termsAndConditionsLink={campaign.termsAndConditionsLink}
                  />
                </div>
              </Grid>
              <SupportingDocumentSection
                readonly
                campaignUid={this.props.match.params.id}
              />
              <Grid item xs={12}>
                <div
                  className="sample-messages details"
                  data-testid="partnerCampaignDetailSampleMessages"
                >
                  <HeadingBlock icon={faMessageDots} title="Sample Messages" />
                  {Object.keys(campaign).map((k, index) => {
                    if (k.includes('sample')) {
                      return (
                        campaign[k] && (
                          <CampaignSampleMessage
                            key={index}
                            index={k.slice(-1)}
                            message={campaign[k]}
                          />
                        )
                      );
                    }
                  })}
                </div>
              </Grid>
            </Grid>
            <Grid item xs={12}>
              <div
                className="sample-messages details"
                data-testid="partnerCampaignDetailSamplMultimedia"
              >
                <HeadingBlock icon={faFileImage} title="Sample Multimedia" />
                <Grid container spacing={4}>
                  <Grid item xs={6}>
                    <MultimediaList
                      data-testid="campaignDetailSampleMultimediaFiles"
                      title="Sample Multimedia Files"
                      loading={
                        sampleMultimediaIndex > -1 &&
                        !sampleMultimedia[sampleMultimediaIndex].file
                      }
                      attachments={sampleMultimedia}
                      onSelect={this.handleMultimediaSelect}
                      onDownload={this.handleMultimediaDownload}
                      style={{ height: 275 }}
                    />
                  </Grid>
                  {sampleMultimediaIndex > -1 &&
                    sampleMultimedia[sampleMultimediaIndex].file && (
                      <AttachmentPreview
                        onClose={() => {
                          this.setState({ sampleMultimediaIndex: -1 });
                        }}
                        file={sampleMultimedia[sampleMultimediaIndex].file}
                        mimeType={
                          sampleMultimedia[sampleMultimediaIndex].mimeType
                        }
                        data-testid="partnerCampaignDetailSamplMultimediaBackdrop"
                      />
                    )}
                </Grid>
              </div>
              <Grid item xs={12}>
                <div
                  className="campaign-events details"
                  data-testid="partnerCampaignDetailEvent"
                >
                  <HeadingBlock icon={faCalendarRange} title="Events" />
                  <Grid container>
                    <Table
                      headRows={headRows}
                      emptyState="no events to view"
                      className="campaign-detail-events-table"
                      disableHover
                      records={{ total: eventInfo.totalRecords }}
                      loading={tableLoader}
                      tableData={eventInfo.records.map((record, index) => {
                        return <EventsListingRow key={index} event={record} />;
                      })}
                      handleChangePage={this.handleChangePage}
                      pagination={{
                        rowsPerPage: eventInfo.recordsPerPage,
                        page: eventInfo.page,
                        totalRecords: eventInfo.totalRecords,
                      }}
                    />
                  </Grid>
                </div>
              </Grid>
            </Grid>
            <CampaignEditModal
              open={openEditModal}
              handleClose={() => this.setState({ openEditModal: false })}
              type={editType}
              campaignInfo={campaign}
              loader={editLoader}
              handleSubmitEdit={this.handleSubmitEdit}
            />
            <ConfirmationDialog
              open={deactivateFlag}
              type="deactivate"
              handleCancel={() => this.setState({ deactivateFlag: false })}
              handleSuccess={this.handleDeactivateCampaign}
              loader={editLoader}
              text={deactivateData ? deactivateData.text : ''}
            />
            <ElectCNPAlert
              handleClose={this.handleCloseAlert}
              open={openAlert}
            />
          </Container>
        )}
        <ElectCNPDialog
          open={electCnpFlag}
          cnpOptions={cnpOptions}
          handleClose={(flag) =>
            this.handleClickCnpAction(flag, flag ? true : false)
          }
          campaignUid={campaignUid}
          usecase={campaign.usecase}
        />
      </section>
    );
  }
}
const mapStateToProps = (state) => ({
  cspData: state.shareReducer.cspData,
});
export default connect(mapStateToProps)(
  withStyles(useStyles)(PartnerCampaignDetail)
);
