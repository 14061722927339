import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles({
  '@keyframes spin': {
    '0%': {
      opacity: '1',
    },
    '100%': {
      opacity: '0',
    },
  },
  container: {
    position: 'relative',
    display: 'inline-block',
    width: '34px',
    height: '34px',
    '& > *': {
      position: 'absolute',
      width: '6px',
      height: '0',
      border: '1px solid #949495',
      borderRadius: '1px',
      animation: '$spin 0.8s linear infinite',
    },
    '& > *:nth-child(1)': {
      animationDelay: '0s',
      top: '16px',
      left: '24px',
    },
    '& > *:nth-child(2)': {
      animationDelay: '-0.1s',
      top: '9px',
      left: '20px',
      transform: 'rotate(-45deg)',
    },
    '& > *:nth-child(3)': {
      animationDelay: '-0.2s',
      top: '5px',
      left: '13px',
      transform: 'rotate(90deg)',
    },
    '& > *:nth-child(4)': {
      animationDelay: '-0.3s',
      top: '9px',
      left: '6px',
      transform: 'rotate(45deg)',
    },
    '& > *:nth-child(5)': {
      animationDelay: '-0.4s',
      top: '16px',
      left: '2px',
    },
    '& > *:nth-child(6)': {
      animationDelay: '-0.5s',
      top: '23px',
      left: '5px',
      transform: 'rotate(-45deg)',
    },
    '& > *:nth-child(7)': {
      animationDelay: '-0.6s',
      top: '27px',
      left: '13px',
      transform: 'rotate(90deg)',
    },
    '& > *:nth-child(8)': {
      animationDelay: '-0.7s',
      top: '23px',
      left: '21px',
      transform: 'rotate(45deg)',
    },
  },
});

const LoadingSpinner: React.FC = () => {
  const classes = useStyles();
  return (
    <div className={classes.container} data-testid="loadingSpinner">
      <div />
      <div />
      <div />
      <div />
      <div />
      <div />
      <div />
      <div />
    </div>
  );
};

export default LoadingSpinner;
