import React from 'react';
import { withRouter } from 'react-router-dom';
import { TableCell, TableRow, useMediaQuery } from '@material-ui/core';
import ExpansionList from '../../../../ui_elements/ExpansionList';
import { convertTimeWithTimezone } from '../../../../utils/time';

function EventsListingRow(props) {
  const { event } = props;
  const matches = useMediaQuery('(max-width:1280px)');
  const matches2 = useMediaQuery('(max-width:1500px)');

  return (
    <TableRow data-testid="eventListingRow">
      <TableCell
        align="left"
        style={{ minWidth: 200, wordBreak: 'break-word' }}
        data-testid="eventListingRowEventType"
      >
        <div className="event-type-wrapper">
          {event.eventType ? event.eventType : '--'}
        </div>
      </TableCell>
      <TableCell align="left" data-testid="eventListingRowCategoryType">
        {event.categoryType}
      </TableCell>
      <TableCell align="left" data-testid="eventListingRowCampaignUid">
        {event.campaignUid}
      </TableCell>
      <TableCell align="left" data-testid="eventListingRowBrandUid">
        {event.brandUid}
      </TableCell>
      <TableCell
        style={{ minWidth: 120 }}
        data-testid="eventListingRowCreateDate"
      >
        {convertTimeWithTimezone(event.createDate)}
      </TableCell>
      <TableCell
        style={{ width: 400, overflow: 'hidden' }}
        align="left"
        onClick={(e) => e.stopPropagation()}
        data-testid="eventListingRowDescription"
      >
        {event.description.length > (matches ? 60 : matches2 ? 60 : 60) ? (
          <ExpansionList
            title={
              matches
                ? `${event.description.slice(0, 50)}...`
                : matches2
                ? `${event.description.slice(0, 50)}...`
                : `${event.description.slice(0, 50)}...`
            }
          >{`${event.description}`}</ExpansionList>
        ) : (
          event.description
        )}
      </TableCell>
    </TableRow>
  );
}
export default withRouter(EventsListingRow);
