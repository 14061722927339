import React, { FC, MouseEvent } from 'react';
import { TableRow, TableCell, makeStyles } from '@material-ui/core';
import { VettingAppeal } from '../../types';
import { capitalizeFirstLetter, parseDate, isStatusPending } from './helper';
import { Box } from 'portal-commons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faHourglass } from '@fortawesome/pro-regular-svg-icons';

interface TableRowProps {
  data: VettingAppeal;
  onViewDetailsClick: (event: MouseEvent<HTMLAnchorElement>) => any;
}

const useStyles = makeStyles({
  pending: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    gap: '4px',
  },
  link: {
    fontWeight: 700,
    textDecoration: 'underline',
    color: '#00698F',
  },
});

const AppealHistoryTableRow: FC<TableRowProps> = ({
  data,
  onViewDetailsClick,
}) => {
  const classes = useStyles();
  return (
    <TableRow key={data.vettingId} data-testid="appealHistoryRow">
      <TableCell data-testid="appealHistoryStatus">
        {capitalizeFirstLetter(data.status)}
      </TableCell>
      <TableCell data-testid="appealHistoryDate">
        {parseDate(data.createDate)}
      </TableCell>
      <TableCell data-testid="appealHistoryIcon">
        {isStatusPending(data.status) ? (
          <TableCell className={classes.pending}>
            <Box
              flexDirection="row"
              alignItems="center"
              justifyContent="center"
            >
              <Box margin={{ right: 'xxs' }}>
                <FontAwesomeIcon icon={faHourglass} />
              </Box>
              PENDING
            </Box>
          </TableCell>
        ) : (
          parseDate(data.statusUpdateDate)
        )}
      </TableCell>
      <TableCell>
        <a
          className={classes.link}
          href="#"
          onClick={onViewDetailsClick}
          data-testid="viewDetailLink"
        >
          VIEW DETAILS
        </a>
      </TableCell>
    </TableRow>
  );
};

export default AppealHistoryTableRow;
