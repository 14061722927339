import React, { useState, useEffect } from 'react';
import { TextField, Dialog, DialogContent } from '@material-ui/core';
import { toastFlashMessage } from '../../../../utils';
import { rejectCnpNominationApi } from '../apiServices';
import { S3_ASSETS_PATH } from '../../../../constants';
import regexExpressions from '../../../../constants/regexExpressions';
import { campaignErrorCode } from '../../index';
import { fieldValidation } from '../../../../utils/validator';

export default function RejectCampaignDialog(props) {
  const { open, campaignUid, brandId, downstreamCnpName } = props;
  const [loader, setLoader] = useState(false);
  const [explanation, setExplanation] = useState('');
  const [error, setError] = useState({});
  const [errorCodes, setErrorCodes] = useState(campaignErrorCode);

  const handleSubmit = (e) => {
    e.preventDefault();
    setLoader(true);
    const validateNewInput = {
      explanation:
        errorCodes.campaignRejectionDescription[
          fieldValidation({
            ...errorCodes.campaignRejectionDescriptionObj,
            fieldval: explanation,
            regexPattern: regexExpressions.validInput,
          })
        ],
    };
    if (
      Object.keys(validateNewInput).every((k) => {
        return validateNewInput[k] === '';
      })
    ) {
      rejectCnpNominationApi(campaignUid, explanation).then((response) => {
        setLoader(false);
        if (response.status >= 200 && response.status < 300) {
          toastFlashMessage(`Campaign rejected successfully`, 'success');
          props.handleClose(true);
        }
      });
    } else {
      setLoader(false);
      setError(validateNewInput);
    }
  };

  useEffect(() => {
    if (open) {
      setError({});
      setExplanation('');
    }
  }, [open]);

  return (
    <Dialog
      open={open}
      aria-labelledby="responsive-dialog-title"
      className="elect-cnp-dialog reject-campaign"
    >
      <DialogContent className="content-wrapper">
        <div className="title text-center">
          <h3 className="heading1">Decline Campaign</h3>
        </div>
        <form onSubmit={handleSubmit}>
          <p className="note">* Indicates a Required Field</p>
          <div className="form-group-field">
            <TextField
              value={campaignUid}
              id="campaignUid"
              placeholder="Campaign ID"
              label="Campaign ID"
              fullWidth={true}
              inputProps={{ 'aria-label': 'campaignUid' }}
              disabled
            />
          </div>
          <div className="form-group-field">
            <TextField
              value={brandId}
              id="brandId"
              placeholder="Brand ID"
              label="Brand ID"
              fullWidth={true}
              inputProps={{ 'aria-label': 'brandId' }}
              disabled
            />
          </div>
          <div className="form-group-field">
            <TextField
              value={downstreamCnpName}
              id="downstreamCnpName"
              placeholder="Downstream CNP"
              label="Downstream CNP"
              fullWidth={true}
              inputProps={{ 'aria-label': 'downstreamCnpName' }}
              disabled
            />
          </div>
          <div className="form-group-field">
            <TextField
              error={error.explanation ? true : false}
              value={explanation}
              id="explanation"
              placeholder="Explanation*"
              label="Explanation*"
              fullWidth={true}
              inputProps={{ 'aria-label': 'explanation' }}
              onChange={(event) => {
                setError('');
                setExplanation(event.target.value);
              }}
              onBlur={(event) => {
                setError('');
                setExplanation(event.target.value);
              }}
            />
            {error.explanation ? (
              <h6 className="error-msg">{error.explanation}</h6>
            ) : null}
          </div>
          <ul className="list-inline footer" style={{ marginTop: '30px' }}>
            <li>
              <a
                className={`secondary-btn ${loader ? 'disabled' : ''}`}
                onClick={() => props.handleClose()}
              >
                Cancel
              </a>
            </li>
            <li className="form-group-field">
              <input
                type="submit"
                disabled={loader ? true : false}
                value="Decline"
              />
            </li>
          </ul>
        </form>
      </DialogContent>
    </Dialog>
  );
}
