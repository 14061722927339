import React, { useEffect, useState } from 'react';
import { Dialog, DialogContent } from '@material-ui/core';
import { S3_ASSETS_PATH } from '../../constants';
import { connect } from 'react-redux';
import parse from 'html-react-parser';

function GeneralAlertPopup(props) {
  const [genAlert, setGenAlert] = useState('');

  useEffect(() => {
    if (props.cspData.length) {
      const genAlert = props.cspData.find(
        (item) => item.lookup_key == 'general-alert-popup'
      );
      setGenAlert(genAlert);
    }
  }, [props.cspData]);
  return (
    <Dialog
      open={props.open}
      aria-labelledby="responsive-dialog-title"
      maxWidth="xs"
      fullWidth={true}
      id="successModal"
      className={`success-modal general-alert`}
      data-testid="generalAlertPopup"
    >
      <DialogContent className={`success-content-blk text-center`}>
        <img src={`${S3_ASSETS_PATH}/images/general_alert_icon.svg`} />
        <h3 className="paragraph title">
          <span>PLEASE READ</span>
        </h3>
        {/* <p className="paragraph"><span>The Campaign Registry (“TCR”)</span> is operational with all the relevant parties in the messaging ecosystem. This means that any campaigns registered now can be enabled with NetNumber, associating the Campaign ID with your desired phone number(s). <br/><br/>Please note, some carriers are currently in the midst of final testing and integration of their traffic management rules, which will result in 10DLC benefits materializing in stages through the first quarter of 2021.<br/><br/>Due to this, TCR will delay implementing campaign registration fees until February 1st, 2021 (note: one-off fees such as TCR Brand registration, requested external vetting, and any MNO Campaign registration fees will still apply).</p> */}
        <div className="paragraph">
          {parse(genAlert.text ? genAlert.text : 'Loading...')}
        </div>
        <a
          onClick={props.handleClose}
          className="primary-btn"
          data-testid="generalAlertPopupOkButton"
        >
          OK
        </a>
      </DialogContent>
    </Dialog>
  );
}

const mapStateToProps = (state) => ({
  cspData: state.shareReducer.cspData,
});

export default connect(mapStateToProps)(GeneralAlertPopup);
