import React from 'react';
import { TableCell, TableRow } from '@material-ui/core';
import { useHistory, useLocation } from 'react-router-dom';
import { CampaignDetail } from '../../../Campaigns/types';
import { convertTimeWithTimezone } from '../../../../../utils/time';

interface Props {
  campaign: CampaignDetail;
}
const CampaignListingRow: React.FC<Props> = ({ campaign }) => {
  const location = useLocation();
  const history = useHistory();

  return (
    <TableRow
      data-testid="campaignListingRow"
      onClick={() =>
        history.push({
          pathname: `/campaigns/${campaign.uid}`,
          state: {
            goBackPage: `${location.pathname}${
              location.search ? location.search : ''
            }`,
          },
        })
      }
    >
      <TableCell align="left" data-testid="campaignListingRowUid">
        <span>{campaign.uid ? campaign.uid : '--'}</span>
      </TableCell>
      <TableCell align="left" data-testid="campaignListingRowUsecase">
        {campaign.usecase}
      </TableCell>
      <TableCell align="left" data-testid="campaignListingRowDate">
        {convertTimeWithTimezone(campaign.createDate, undefined, 'MM/DD/YYYY')}
      </TableCell>
      <TableCell
        align="left"
        style={{ maxWidth: 500 }}
        data-testid="campaignListingRowDescription"
      >
        {campaign.description && campaign.description.length >= 70
          ? `${campaign.description.slice(0, 69)}...`
          : campaign.description}
      </TableCell>
    </TableRow>
  );
};
export default CampaignListingRow;
