import { DEV_BASE_URL } from '../constants';
import { toastFlashMessage } from '.';
import axios from 'axios';

const axiosInstance = axios.create();
axiosInstance.defaults.baseURL = DEV_BASE_URL;
axiosInstance.interceptors.request.use(
  (config) => {
    const oktaTokenStorage = JSON.parse(
      localStorage.getItem('okta-token-storage')
    );
    if (oktaTokenStorage) {
      config.headers.Authorization = `Bearer ${oktaTokenStorage.accessToken.accessToken}`;
    }
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);
axiosInstance.interceptors.response.use(
  (response) => {
    if (response.status >= 200 && response.status < 300) {
      response.ok = true;
    }
    return response;
  },
  (error) => {
    // debugger
    // console.log('api error.response', error.response)
    if (error.response && error.response.status < 500) {
      if (
        error.response.data &&
        error.response.data.error &&
        Object.keys(error.response.data.error)
      ) {
        const messages = [];
        Object.keys(error.response.data.error).forEach((keyName) => {
          const errorCode = error.response.data.code;
          Object.keys(error.response.data.error[keyName]).forEach((index) => {
            const errorMsg = error.response.data.error[keyName][index];
            if (errorMsg) {
              if (
                errorMsg.includes(`"code":502,"description":"CSP not found"`) ||
                errorMsg.includes('User CSP profile not found') ||
                errorMsg.includes('CSP not found') ||
                errorMsg.includes(
                  `"code":502,"field":"cspId","description":"record not found"`
                ) ||
                (keyName === 'cspUid' &&
                  errorMsg.includes('record not found')) ||
                errorCode === 525 ||
                errorMsg.includes(
                  'Vetting re-submission not allowed with same brand information.'
                ) ||
                errorCode === 535
              ) {
                //
              } else if (
                errorMsg.includes(
                  'HTTP 400, Okta E0000001 (Api validation failed: login - login: An object with this field already exists in the current organization)'
                )
              ) {
                messages.push('Account with this email address already exists');
              } else if (
                errorMsg.includes(
                  'HTTP 400, Okta E0000001 (Api validation failed: password - password: Password requirements were not met. Password requirements: at least 12 characters, a lowercase letter, an uppercase letter, a number, a symbol, no parts of your username, does not include your first name, does not include your last name. Your password cannot be any of your last 5 passwords.)'
                )
              ) {
                messages.push('Password requirements are not met');
              } else if (errorCode === 537) {
                // Custom Error message for Active Campaign Check for EIN Update
                // https://campaignreg.atlassian.net/browse/TCR-6355
                const remapKeyName = {
                  ein: 'EIN',
                  einIssuingCountry: 'EIN Issuing Country',
                  entityType: 'Entity Type',
                  companyName: 'Legal Company Name',
                };

                const formattedMessage = `${
                  remapKeyName[keyName] || keyName
                }, ${errorMsg}`;

                messages.push(formattedMessage);
              } else {
                messages.push(errorMsg);
              }
            }
          });
        });
        toastFlashMessage(messages, 'error');
      } else if (
        !error.response.config.url.includes('profile') &&
        error.response.error &&
        [102, 319].includes(error.response.error[0].code)
      ) {
        //
      }
    } else {
      toastFlashMessage(
        'Unable to process the request due to technical error',
        'error'
      );
    }
    return error.response;
  }
);

export default axiosInstance;
