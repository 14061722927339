import React from 'react';
import { Grid } from '@material-ui/core';

export default function BrandDetailItem(props) {
  return (
    <Grid
      container
      spacing={3}
      className="detail-item"
      data-testid={props.title.replace(/\s/g, '')}
    >
      <Grid item xs={props.xs ? 5 : 4}>
        <h3 className="paragraph">{props.title}:</h3>
      </Grid>
      <Grid item xs={props.xs ? 7 : 8}>
        <h3 className="paragraph">
          <span>{props.value ? props.value : 'N/A'}</span>
        </h3>
      </Grid>
    </Grid>
  );
}
