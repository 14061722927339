import React, { Component } from 'react';
import { Grid, Container } from '@material-ui/core';
import { CspVerificationMessage } from '../components';
import { S3_ASSETS_PATH } from '../../../../constants';
import { Loader } from '../../../../shared_elements';
import { getCspProfileApi } from '../apiServices';
import { connect } from 'react-redux';
import '../../../../assets/styles/csp-status-module.scss';

class CspVerificationStatus extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loader: true,
      statusData: '',
      status: '',
    };
  }

  componentDidMount() {
    document.title = 'The Campaign Registry - Approval Status';
    getCspProfileApi().then((response) => {
      if (response.status >= 200 && response.status <= 300) {
        const status = response.data.status.toLowerCase();
        localStorage.setItem('cspStatus', status);
        if (status == 'active') {
          this.props.history.push('/dashboard');
        } else {
          const cspStatus = ['review', 'queue', 'appeal'].includes(status)
            ? 'pending'
            : `${status}ed`;
          const statusData = this.props.cspData
            ? this.props.cspData.find(
                (item) =>
                  item.lookup_key == `csp-verification-status#${cspStatus}`
              )
            : '';
          this.setState({
            status: cspStatus,
            loader: false,
            statusData,
          });
        }
      }
    });
  }

  componentDidUpdate(prevProps, prevState) {
    const { loader, statusData, status } = this.state;
    if (!loader && !statusData && this.props.cspData.length) {
      const statusData = this.props.cspData.find(
        (item) => item.lookup_key == `csp-verification-status#${status}`
      );
      this.setState({
        statusData,
      });
    }
  }

  render() {
    const { loader, status, statusData } = this.state;
    return (
      <section
        className="csp-verification-status-section"
        data-testid="cspVeriricationStatus"
      >
        {loader || !statusData ? (
          <Loader />
        ) : (
          <Container
            style={{ padding: '0px' }}
            className="csp-verification-status-container"
          >
            <Grid
              container
              spacing={0}
              justify="center"
              alignItems="center"
              className="csp-status-outer-wrapper"
            >
              <CspVerificationMessage
                type={status}
                icon={`${S3_ASSETS_PATH}/images/${status}-status.svg`}
                title={statusData.title}
                content={statusData.text}
              />
            </Grid>
          </Container>
        )}
      </section>
    );
  }
}
const mapStateToProps = (state) => ({
  cspData: state.shareReducer.cspData,
});
export default connect(mapStateToProps)(CspVerificationStatus);
