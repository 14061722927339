import React from 'react';
import CloseIcon from '@material-ui/icons/Close';
import PropTypes from 'prop-types';

export default function FilterRowItem(props) {
  const { filterLabel, filterValue, name } = props;
  return (
    <li className="secondaryText">
      {filterLabel} : <span>"{filterValue}"</span>
      <CloseIcon
        data-testid={`filterRowCloseButton-${filterValue}`}
        className="filter-close"
        onClick={() => {
          props.handleRemoveFilterClick(name);
        }}
      />
    </li>
  );
}
FilterRowItem.propTypes = {
  filterLabel: PropTypes.string.isRequired,
  filterValue: PropTypes.string.isRequired,
  handleRemoveFilterClick: PropTypes.func,
  name: PropTypes.string.isRequired,
};
