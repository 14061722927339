import React from 'react';
import { TableCell, TableRow } from '@material-ui/core';
import { withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';
import { remapTCRStatus } from '../utils';
import { convertTimeWithTimezone } from '../../../../utils/time';

function CampaignListingRow(props) {
  const { data } = props;
  return (
    <TableRow
      className="link"
      onClick={() =>
        props.history.push({
          pathname: `/campaigns/${data.uid}`,
          state: {
            goBackPage: `${props.location.pathname}${
              props.location.search ? props.location.search : ''
            }`,
          },
        })
      }
    >
      <TableCell align="left" style={{ width: 125 }}>
        <span>{data.uid ? data.uid : data.id}</span>
      </TableCell>
      <TableCell align="left" style={{ width: 125 }}>
        {data.brandUid}
      </TableCell>
      <TableCell align="left" style={{ width: 175 }}>
        {data.brandName}
      </TableCell>
      <TableCell align="left" style={{ width: 125 }}>
        {data.usecase}
      </TableCell>
      <TableCell align="left" style={{ width: 150 }}>
        {convertTimeWithTimezone(data.createDate, undefined, 'MM/DD/YYYY')}
      </TableCell>
      <TableCell align="left" style={{ width: 170 }}>
        {data.resellerName ? data.resellerName : 'N/A'}
      </TableCell>
      <TableCell align="left" style={{ width: 125 }}>
        {remapTCRStatus(data.status)}
      </TableCell>
    </TableRow>
  );
}
CampaignListingRow.propTypes = {
  data: PropTypes.object,
};
export default withRouter(CampaignListingRow);
