import React from 'react';
import { Dialog, DialogContent, Grid } from '@material-ui/core';

const CampaignStatusModal = (props) => {
  return (
    <Dialog
      open={props.open}
      onClose={() => props.handleClick(false)}
      aria-labelledby="responsive-dialog-title"
      maxWidth="xs"
      fullWidth={true}
      id="successModal"
      className={`create-campaign-modal`}
      data-testid="campaignStatusModal"
    >
      <DialogContent className={`create-campaign-content text-center`}>
        <Grid container>
          <Grid item xs={12} className="title">
            <p className="paragraph">
              Click <span>I agree to confirm.</span>
              <br />
              You will be invoiced monthly.
            </p>
          </Grid>
          <Grid item xs={12} className="footer">
            <li>
              <p>
                <a
                  data-testid="campaignStatusModalCancelButton"
                  className={
                    props.loadFlag ? 'secondary-btn disabled' : 'secondary-btn'
                  }
                  onClick={() => props.handleClick(false)}
                >
                  Cancel
                </a>
              </p>
            </li>
            <li>
              <p>
                <a
                  data-testid="campaignStatusModalAgreeButton"
                  className={
                    props.loadFlag ? 'primary-btn disabled' : 'primary-btn'
                  }
                  onClick={() => props.handleClick(true)}
                >
                  {props.loadFlag ? 'Processing...' : 'I Agree'}
                </a>
              </p>
            </li>
          </Grid>
        </Grid>
      </DialogContent>
    </Dialog>
  );
};
export default CampaignStatusModal;
