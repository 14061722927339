import React from 'react';
import { Grid } from '@material-ui/core';
import { S3_ASSETS_PATH, mnoNameOrder } from '../../../../constants';
import { sortMnoList } from '../../../../utils';
import CustomTooltip from './CustomTooltip';

import { Box } from 'portal-commons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAward, faThumbsUp } from '@fortawesome/pro-regular-svg-icons';

export default function MnoStatusTableNew(props) {
  const { mnoStatusInfo, electedDcas } = props;

  return (
    <div
      className="mno-overview custom-scrollbar"
      data-testid="mnoStatusTableNew"
    >
      <ul className="mno-listing list-unstyled">
        {mnoStatusInfo
          ? sortMnoList(mnoStatusInfo, ['rulesEngineResultsJson', 'mno']).map(
              (item, index) =>
                item.rulesEngineResultsJson &&
                item.rulesEngineResultsJson.qualify &&
                item.rulesEngineResultsJson.mnoSupport ? (
                  <li key={index}>
                    <Grid container>
                      <Grid
                        item
                        xs={2}
                        className="name"
                        style={{ maxWidth: `11%` }}
                        data-testid="mnoStatusTableNewMno"
                      >
                        {item.rulesEngineResultsJson
                          ? item.rulesEngineResultsJson.mno
                          : '--'}
                      </Grid>
                      <Grid
                        item
                        xs={2}
                        className="text-center"
                        data-testid="mnoStatusTableNewQualify"
                      >
                        <h3 className="paragraph bold">
                          <Box
                            flexDirection="row"
                            justifyContent="center"
                            alignItems="center"
                          >
                            <Box margin={{ right: 'xxs' }}>
                              <FontAwesomeIcon icon={faAward} size="xl" />
                            </Box>
                            <span>QUALIFY</span>
                          </Box>
                        </h3>
                        <p className="paragraph bold">
                          {item.rulesEngineResultsJson ? (
                            item.rulesEngineResultsJson.qualify ? (
                              'Yes'
                            ) : (
                              <img
                                style={{ position: 'relative', bottom: '5px' }}
                                src={`${S3_ASSETS_PATH}/images/not-qualify.svg`}
                              />
                            )
                          ) : (
                            '--'
                          )}
                        </p>
                      </Grid>
                      <Grid
                        item
                        xs={2}
                        data-testid="mnoStatusTableNewMnoReview"
                      >
                        <h3 className="paragraph bold">
                          <Box
                            flexDirection="row"
                            justifyContent="center"
                            alignItems="center"
                          >
                            <Box margin={{ right: 'xxs' }}>
                              <FontAwesomeIcon icon={faThumbsUp} size="xl" />
                            </Box>
                            <span>MNO REVIEW</span>
                          </Box>
                        </h3>
                        <p className="paragraph bold">
                          {item.rulesEngineResultsJson
                            ? item.rulesEngineResultsJson.mnoReview
                              ? 'Yes'
                              : 'No'
                            : 'N/A'}
                        </p>
                      </Grid>
                      {item.rulesEngineResultsJson.mno == 'T-Mobile' ? (
                        <>
                          <Grid
                            item
                            xs={2}
                            style={{ maxWidth: '6%' }}
                            data-testid="mnoStatusTableNewBandTier"
                          >
                            <h3
                              className="paragraph bold"
                              style={{ marginLeft: 5 }}
                            >
                              BRAND
                              <br />
                              TIER
                            </h3>
                            {item.rulesEngineResultsJson.mno == 'T-Mobile' &&
                            item.rulesEngineResultsJson.brandTier ? (
                              <CustomTooltip
                                arrow
                                title={`Please check the Knowledge Base for the ”Daily and Monthly maximum throughput values per Brand” associated with this tier.`}
                                placement="top"
                              >
                                <p
                                  className="paragraph light"
                                  style={{ marginLeft: 5 }}
                                >
                                  {item.rulesEngineResultsJson.brandTier}
                                </p>
                              </CustomTooltip>
                            ) : (
                              <p
                                className="paragraph light"
                                style={{ marginLeft: 5 }}
                              >
                                {item.rulesEngineResultsJson.tpm
                                  ? item.rulesEngineResultsJson.tpm
                                  : 'N/A'}
                              </p>
                            )}
                          </Grid>
                          <Grid
                            item
                            xs={2}
                            style={{ maxWidth: '11%' }}
                            data-testid="mnoStatusTableNewBrandDailyCap"
                          >
                            <h3 className="paragraph bold">
                              BRAND
                              <br />
                              DAILY CAP
                            </h3>
                            {item.rulesEngineResultsJson.mno == 'T-Mobile' ? (
                              <p className="paragraph light">
                                {item.rulesEngineResultsJson.brandDailyCap
                                  ? item.rulesEngineResultsJson.brandDailyCap
                                  : item.rulesEngineResultsJson.brandTier &&
                                    item.rulesEngineResultsJson.brandTier ===
                                      'UNCAPPED'
                                  ? 'No'
                                  : 'N/A'}
                              </p>
                            ) : (
                              <p className="paragraph light">
                                {item.rulesEngineResultsJson.tpmScope
                                  ? item.rulesEngineResultsJson.tpmScope
                                  : 'N/A'}
                              </p>
                            )}
                          </Grid>
                          <Grid
                            item
                            xs={2}
                            style={{ maxWidth: '5%' }}
                            data-testid="mnoStatusTableNewMmsTpm"
                          >
                            <h3 className="paragraph bold">
                              MMS
                              <br />
                              TPM
                            </h3>
                            {item.rulesEngineResultsJson.mno == 'T-Mobile' &&
                            item.rulesEngineResultsJson.brandTier ? (
                              <CustomTooltip
                                arrow
                                title={`Please check the Knowledge Base for the ”Daily and Monthly maximum throughput values per Brand” associated with this tier.`}
                                placement="top"
                              >
                                <p className="paragraph light">
                                  {item.rulesEngineResultsJson.mmsTpm
                                    ? item.rulesEngineResultsJson.mmsTpm
                                    : 'N/A'}
                                </p>
                              </CustomTooltip>
                            ) : (
                              <p className="paragraph light">
                                {item.rulesEngineResultsJson.mmsTpm
                                  ? item.rulesEngineResultsJson.mmsTpm
                                  : 'N/A'}
                              </p>
                            )}
                          </Grid>
                        </>
                      ) : (
                        <>
                          <Grid
                            item
                            xs={2}
                            style={{ maxWidth: '6%' }}
                            data-testid="mnoStatusTableNewTpmScope"
                          >
                            <h3
                              className="paragraph bold"
                              style={{ marginLeft: 5 }}
                            >
                              TPM
                              <br />
                              SCOPE
                            </h3>
                            {
                              <p
                                className="paragraph light"
                                style={{ marginLeft: 5 }}
                              >
                                {item.rulesEngineResultsJson.tpmScope
                                  ? item.rulesEngineResultsJson.tpmScope
                                  : 'N/A'}
                              </p>
                            }
                          </Grid>
                          <Grid
                            item
                            xs={2}
                            style={{ maxWidth: '11%' }}
                            data-testid="mnoStatusTableNewSmsTpm"
                          >
                            <h3 className="paragraph bold">
                              SMS
                              <br />
                              TPM
                            </h3>
                            {item.rulesEngineResultsJson.mno == 'AT&T' ? (
                              <p className="paragraph light">
                                {item.rulesEngineResultsJson.tpm
                                  ? item.rulesEngineResultsJson.tpm
                                  : 'N/A'}
                              </p>
                            ) : (
                              <p className="paragraph light">
                                {item.rulesEngineResultsJson.tpm
                                  ? item.rulesEngineResultsJson.tpm
                                  : 'N/A'}
                              </p>
                            )}
                          </Grid>
                          <Grid
                            item
                            xs={2}
                            style={{ maxWidth: '5%' }}
                            data-testid="mnoStatusTableNewMmsTpm"
                          >
                            <h3 className="paragraph bold">
                              MMS
                              <br />
                              TPM
                            </h3>
                            {
                              <p className="paragraph light">
                                {item.rulesEngineResultsJson.mmsTpm
                                  ? item.rulesEngineResultsJson.mmsTpm
                                  : 'N/A'}
                              </p>
                            }
                          </Grid>
                        </>
                      )}

                      <Grid
                        item
                        xs={2}
                        style={{ maxWidth: '10%' }}
                        data-testid="mnoStatusTableNewMessageClass"
                      >
                        <h3 className="paragraph bold">
                          MESSAGE
                          <br />
                          CLASS
                        </h3>
                        <p className="paragraph light">
                          {item.rulesEngineResultsJson
                            ? item.rulesEngineResultsJson.msgClass
                              ? item.rulesEngineResultsJson.msgClass
                              : 'N/A'
                            : 'N/A'}
                        </p>
                      </Grid>
                      <Grid
                        item
                        xs={2}
                        style={{ maxWidth: '12%' }}
                        data-testid="mnoStatusTableNewStatus"
                      >
                        <h3 className="paragraph bold">STATUS</h3>
                        <p className="paragraph bold status">
                          <img
                            src={`${S3_ASSETS_PATH}/images/${item.status.toLowerCase()}-icon.svg`}
                            alt=""
                          />
                          <span>
                            {item.status.toLowerCase() === 'approved'
                              ? 'REGISTERED'
                              : item.status}
                          </span>
                        </p>
                      </Grid>
                      <Grid
                        item
                        xs={2}
                        style={{ maxWidth: '11%' }}
                        data-testid="mnoStatusTableNewElectedDca"
                      >
                        <h3
                          className="paragraph bold"
                          style={{ padding: '20px 0px 17px' }}
                        >
                          ELECTED DCA
                        </h3>
                        <p className="paragraph light">
                          {electedDcas.includes(item.networkId) ? 'Yes' : 'No'}
                        </p>
                      </Grid>
                    </Grid>
                  </li>
                ) : null
            )
          : null}
      </ul>
    </div>
  );
}
