import React, { useState, useEffect } from 'react';
import { Grid, Container } from '@material-ui/core';
import { Link } from 'react-router-dom';
import { S3_ASSETS_PATH } from '../../../../constants';
import { connect } from 'react-redux';
import '../../../../assets/styles/initiate-csp-module.scss';
import parse from 'html-react-parser';

function InitiateCspAccount(props) {
  const [cspWelcome, setCspWelcome] = useState({
    title: 'Welcome to The Campaign Registry!',
    text: 'In order to register Brands and Campaigns you need to activate your CSP account.Please fill in the required information in the following pages to allow us to validate your application',
  });
  useEffect(() => {
    document.title = 'The Campaign Registry - CSP Verification';
    if (
      localStorage.getItem('cspStatus') &&
      localStorage.getItem('cspStatus') == 'active'
    ) {
      props.history.push('/dashboard');
    }
    if (props.cspData && props.cspData.length) {
      const cspWelcome = props.cspData.find(
        (item) => item.lookup_key == 'csp-welcome'
      );
      setCspWelcome(cspWelcome);
    }
  }, [props.cspData]);
  return (
    <section
      style={{ padding: '0px' }}
      className="initiate-csp-account-section"
      data-testid="initiateCspAccount"
    >
      <Container maxWidth={false} className="initiate-csp-account">
        <Grid
          container
          justify="center"
          alignItems="center"
          className="content-blk"
        >
          <Grid item className="text-center">
            <div className="initiate-csp-wrapper">
              <img src={`${S3_ASSETS_PATH}/images/intiate-csp.svg`} alt="" />
              <h4 className="heading1">{cspWelcome.title}</h4>
              <div className="heading1 text text-center">
                {parse(cspWelcome.text)}
              </div>
            </div>
            <div className="bottom-csp">
              <Link
                to="/user/csp/create"
                className="primary-btn"
                data-testid="initiateCspAccountActivateButton"
              >
                Activate CSP Account
              </Link>
            </div>
          </Grid>
        </Grid>
      </Container>
    </section>
  );
}
const mapStateToProps = (state) => ({
  cspData: state.shareReducer.cspData,
});
export default connect(mapStateToProps)(InitiateCspAccount);
