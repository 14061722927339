import { FunctionComponent } from 'react';
import { BoxV2 as Box, Flex } from 'portal-commons';

import { entityTypeValueToText, stringValueToText } from '../utils';

interface ProfileBannerProps {
  companyName?: string;
  displayName?: string;
  uid?: string;
  entityType?: string;
}

const ProfileBanner: FunctionComponent<ProfileBannerProps> = ({
  companyName,
  displayName,
  uid,
  entityType,
}) => {
  return (
    <Flex
      sx={{
        flexDirection: 'column',
        alignItems: 'center',
        rowGap: '48px',
        paddingTop: '40px',
        paddingBottom: '18px',
        color: '#49535D',
        fontFamily: 'Roboto',
        fontWeight: 400,
      }}
      data-testid="profileBanner"
    >
      <Flex
        sx={{
          flexDirection: 'row',
          alignItems: 'center',
          columnGap: '5px',
          fontSize: '22px',
          lineHeight: '26px',
        }}
      >
        <Box>Legal Company Name:</Box>
        <Box sx={{ fontWeight: 700 }} data-testid="companyName">
          {companyName || '--'}
        </Box>
      </Flex>
      <Flex
        sx={{
          flexDirection: 'row',
          alignItems: 'center',
          columnGap: '69px',
          fontSize: '16px',
          lineHeight: '19px',
        }}
      >
        <Flex
          sx={{
            flexDirection: 'row',
            alignItems: 'center',
            columnGap: '5px',
          }}
        >
          <Box>Brand Name or DBA:</Box>
          <Box style={{ fontWeight: 700 }} data-testid="displayName">
            {stringValueToText(displayName)}
          </Box>
        </Flex>
        <Flex
          sx={{
            flexDirection: 'row',
            alignItems: 'center',
            columnGap: '5px',
          }}
        >
          <Box>CSP ID:</Box>
          <Box style={{ fontWeight: 700 }} data-testid="uid">
            {stringValueToText(uid)}
          </Box>
        </Flex>
        <Flex
          sx={{
            flexDirection: 'row',
            alignItems: 'center',
            columnGap: '5px',
          }}
        >
          <Box>Company Type:</Box>
          <Box style={{ fontWeight: 700 }} data-testid="entityType">
            {entityTypeValueToText(entityType)}
          </Box>
        </Flex>
      </Flex>
    </Flex>
  );
};

export default ProfileBanner;
