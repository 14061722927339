import React from 'react';
import { Grid } from '@material-ui/core';
import { BRAND_RELATIONSHIP_SCORE } from '../../../../../constants';
import { BrandDetail } from '../../types';

interface Props {
  brandInfo: BrandDetail;
  error: Record<string, string>;
  handleChange: <K extends keyof BrandDetail>(
    _value: BrandDetail[K],
    _key: K
  ) => void;
  handleError?: () => void;
}
const BrandTrustScore: React.FC<Props> = ({
  brandInfo,
  error,
  handleChange,
}) => {
  return (
    <React.Fragment>
      <Grid
        container
        justifyContent={'space-between'}
        className="brand-relationship-wrapper"
        data-testid="brandTrustScore"
      >
        {BRAND_RELATIONSHIP_SCORE.map((item) => (
          <Grid
            item
            xs={2}
            key={item.value}
            className={`relationship-item ${
              Number(brandInfo.brandRelationshipScore) === item.value
                ? ' active'
                : brandInfo.entityType === 'SOLE_PROPRIETOR'
                ? 'disabledOption'
                : ''
            }`}
            onClick={() => handleChange(item.value, 'brandRelationshipScore')}
          >
            <img src={item.img} alt="" />
            <div className="top-blk">
              <p className="paragraph title">{item.label}</p>
            </div>
            <div className="content-blk">
              <p className="paragraph">{item.description}</p>
            </div>
          </Grid>
        ))}
      </Grid>
      {error.brandRelationshipScore ? (
        <h6
          className="error-msg-brand-relationship"
          style={{ marginTop: '12px' }}
        >
          {error.brandRelationshipScore}
        </h6>
      ) : null}
    </React.Fragment>
  );
};

export default BrandTrustScore;
