export const AEGIS_LOCALE_OPTIONS = [
  {
    label: 'Federal',
    value: 'Federal',
  },
  {
    label: 'State',
    value: 'State',
  },
  {
    label: 'Local',
    value: 'Local',
  },
  {
    label: 'Tribal',
    value: 'Tribal',
  },
];

export const AEGIS_COMMITTEE_TYPE_CODE_OPTIONS = [
  {
    label: 'C - Communication cost',
    value: 'C',
  },
  {
    label: 'D - Delegate committee',
    value: 'D',
  },
  {
    label: 'E - Electioneering communication\n',
    value: 'E',
  },
  {
    label: 'H - House',
    value: 'H',
  },
  {
    label: 'I - Independent expenditor (person or group)',
    value: 'I',
  },
  {
    label: 'N - PAC - nonqualified',
    value: 'N',
  },
  {
    label: 'O - Independent expenditure-only (Super PACs)',
    value: 'O',
  },
  {
    label: 'P - Presidential',
    value: 'P',
  },
  {
    label: 'Q - PAC - qualified',
    value: 'Q',
  },
  {
    label: 'S - Senate',
    value: 'S',
  },
  {
    label: 'U - Single-candidate independent expenditure',
    value: 'U',
  },
  {
    label: 'V - PAC with non-contribution account - nonqualified',
    value: 'V',
  },
  {
    label: 'W - PAC with non-contribution account - qaualified',
    value: 'W',
  },
  {
    label: 'X - Party - nonqualified',
    value: 'X',
  },
  {
    label: 'Y - Party - qualified',
    value: 'Y',
  },
  {
    label: 'Z - National party nonfederal account',
    value: 'Z',
  },
];

export const AEGIS_COMMITTEE_STATE_OPTIONS = [
  {
    label: 'AL - Alabama',
    value: 'AL',
  },
  {
    label: 'AK - Alaska',
    value: 'AK',
  },
  {
    label: 'AZ - Arizona',
    value: 'AZ',
  },
  {
    label: 'AR - Arkansas',
    value: 'AR',
  },
  {
    label: 'CA - California',
    value: 'CA',
  },
  {
    label: 'CO - Colorado',
    value: 'CO',
  },
  {
    label: 'CT - Connecticut',
    value: 'CT',
  },
  {
    label: 'DE - Delaware',
    value: 'DE',
  },
  {
    label: 'FL - Florida',
    value: 'FL',
  },
  {
    label: 'GA - Georgia',
    value: 'GA',
  },
  {
    label: 'HI - Hawaii',
    value: 'HI',
  },
  {
    label: 'ID - Idaho',
    value: 'ID',
  },
  {
    label: 'IL - Illinois',
    value: 'IL',
  },
  {
    label: 'IN - Indiana',
    value: 'IN',
  },
  {
    label: 'IA - Iowa',
    value: 'IA',
  },
  {
    label: 'KS - Kansas',
    value: 'KS',
  },
  {
    label: 'KY - Kentucky',
    value: 'KY',
  },
  {
    label: 'LA - Louisiana',
    value: 'LA',
  },
  {
    label: 'ME - Maine',
    value: 'ME',
  },
  {
    label: 'MD - Maryland',
    value: 'MD',
  },
  {
    label: 'MA - Massachusetts',
    value: 'MA',
  },
  {
    label: 'MI - Michigan',
    value: 'MI',
  },
  {
    label: 'MN - Minnesota',
    value: 'MN',
  },
  {
    label: 'MS - Mississippi',
    value: 'MS',
  },
  {
    label: 'MO - Missouri',
    value: 'MO',
  },
  {
    label: 'MT - Montana',
    value: 'MT',
  },
  {
    label: 'NE - Nebraska',
    value: 'NE',
  },
  {
    label: 'NV - Nevada',
    value: 'NV',
  },
  {
    label: 'NH - New Hampshire',
    value: 'NH',
  },
  {
    label: 'NJ - New Jersey',
    value: 'NJ',
  },
  {
    label: 'NM - New Mexico',
    value: 'NM',
  },
  {
    label: 'NY - New York',
    value: 'NY',
  },
  {
    label: 'NC - North Carolina',
    value: 'NC',
  },
  {
    label: 'ND - North Dakota',
    value: 'ND',
  },
  {
    label: 'OH - Ohio',
    value: 'OH',
  },
  {
    label: 'OK - Oklahoma',
    value: 'OK',
  },
  {
    label: 'OR - Oregon',
    value: 'OR',
  },
  {
    label: 'PA - Pennsylvania',
    value: 'PA',
  },
  {
    label: 'RI - Rhode Island',
    value: 'RI',
  },
  {
    label: 'SC - South Carolina',
    value: 'SC',
  },
  {
    label: 'SD - South Dakota',
    value: 'SD',
  },
  {
    label: 'TN - Tennessee',
    value: 'TN',
  },
  {
    label: 'TX - Texas',
    value: 'TX',
  },
  {
    label: 'UT - Utah',
    value: 'UT',
  },
  {
    label: 'VT - Vermont',
    value: 'VT',
  },
  {
    label: 'VA - Virginia',
    value: 'VA',
  },
  {
    label: 'WA - Washington',
    value: 'WA',
  },
  {
    label: 'WV - West Virginia',
    value: 'WV',
  },
  {
    label: 'WI - Wisconsin',
    value: 'WI',
  },
  {
    label: 'WY - Wyoming',
    value: 'WY',
  },
  {
    label: 'DC - District of Columbia',
    value: 'DC',
  },
  {
    label: 'AS - American Samoa',
    value: 'AS',
  },
  {
    label: 'GU - Guam',
    value: 'GU',
  },
  {
    label: 'MP - Northern Mariana Islands',
    value: 'MP',
  },
  {
    label: 'PR - Puerto Rico',
    value: 'PR',
  },
  {
    label: 'VI - US Virgin Islands',
    value: 'VI',
  },
  {
    label: 'US - United States',
    value: 'US',
  },
];

export const AEGIS_PIN_PREFERENCE_OPTIONS = [
  {
    label: 'Regular Mail - $64',
    value: 'Regular',
  },
  {
    label: 'Email - $64',
    value: 'Email',
  },
  {
    label: 'Express Mail - $91.95 ($64 + Express delivery fare $27.95)',
    value: 'Express',
  },
];

export const FILE_TYPES_FOR_VETTING_APPEAL = {
  '.jpg': 'image/jpeg',
  '.jpeg': 'image/jpeg',
  '.png': 'image/png',
  '.bmp': 'image/bmp',
  '.raw': 'image/x-panasonic-raw',
  '.tiff': 'image/tiff',
  '.pdf': 'application/pdf',
  '.docx':
    'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
  '.htm': 'text/html',
  '.odt': 'application/vnd.oasis.opendocument.text',
  '.rtf': 'application/rtf',
  '.txt': 'text/plain',
  '.xml': 'application/xml',
};

export const FILE_EXTENSIONS_FOR_VETTING_APPEAL = Object.keys(
  FILE_TYPES_FOR_VETTING_APPEAL
);

export const MIME_TYPES_FOR_VETTING_APPEAL = Object.values(
  FILE_TYPES_FOR_VETTING_APPEAL
);

export const FILE_EXTENSIONS_TOOLTIP_FOR_VETTING_APPEAL =
  FILE_EXTENSIONS_FOR_VETTING_APPEAL.sort().join(', ');
