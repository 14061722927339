import React from 'react';
import { Grid } from '@material-ui/core';
import { S3_ASSETS_PATH } from '../../../../constants';
import CheckBox from '../../../../ui_elements/CheckBox';
import { TermsAndConditions } from '../../../../shared_elements';
import parse from 'html-react-parser';

function CspPaymentInfo(props) {
  const { checked, loader, paymentInfo = {} } = props;
  return (
    <Grid
      container
      className="csp-terms-and-conditions"
      spacing={0}
      data-testid="cspPaymentInfo"
    >
      <Grid item xs={12} lg={12}>
        <div className="payment-summary">
          <div className="title text-center">
            <img src={`${S3_ASSETS_PATH}/images/submit-csp-reg.svg`} alt="" />
            <h3 className="heading1">
              {paymentInfo.title ? paymentInfo.title : ''}
            </h3>
          </div>
          <div className="order-summary">
            <p className="paragraph text-center">* Fees may apply </p>
            <div className="secondaryText">
              {parse(paymentInfo.text ? paymentInfo.text : '')}
            </div>
          </div>
        </div>
      </Grid>
      <Grid item xs={12}>
        <div className="bottom-blk">
          {checked ? (
            <>
              <p className="paragraph text-center">
                This order will be added to your next balance.
              </p>
              <div className="check-box-span text-center">
                <label>
                  <CheckBox
                    checked={checked}
                    handleCheckbox={props.handleCheck}
                  />
                  <span>
                    I agree to the{' '}
                    <a href="javascript:void(0)">Terms & Conditions.</a>
                  </span>
                </label>
              </div>
            </>
          ) : (
            <TermsAndConditions handleClick={props.handleCheck} />
          )}
        </div>
      </Grid>
    </Grid>
  );
}
export default CspPaymentInfo;
