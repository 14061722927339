import React from 'react';
import { useStylesTable } from '../../utils/tableUIStyle';
import { withStyles } from '@material-ui/core/styles';
import {
  Table,
  TableBody,
  TableCell,
  TableRow,
  Paper,
  TableHead,
  TableSortLabel,
  LinearProgress,
} from '@material-ui/core';
import PropTypes from 'prop-types';
import Pagination from '@material-ui/lab/Pagination';
import { S3_ASSETS_PATH } from '../../constants';
import { MAX_EVENT_PAGES } from '../../applications/CspPortal/Events/constants';

const ColorLinearProgress = withStyles({
  colorPrimary: {
    backgroundColor: '#fff',
  },
  barColorPrimary: {
    background: '#0091B3',
  },
})(LinearProgress);

function ListingTableNew(props) {
  const classes = useStylesTable();
  const {
    headRows,
    filter,
    createSortHandler,
    tableData,
    pagination = {},
    overLimitDisclaimer = '',
    testId = '',
  } = props;
  const emptyRows = Object.keys(pagination).length
    ? pagination.rowsPerPage -
      Math.min(pagination.rowsPerPage, pagination.totalRecords)
    : null;

  const showDiscalimer = pagination.page === MAX_EVENT_PAGES ? true : false;

  return (
    <Paper className={classes.paper} data-testid={testId}>
      <div
        className={`${classes.tableWrapper} tableWrapper ${props.className}`}
      >
        <Table
          className={`${classes.table}`}
          aria-labelledby="tableTitle"
          size="medium"
        >
          <TableHead>
            <TableRow>
              {headRows.map((row, index) => (
                <TableCell
                  key={row.id ? row.id : index}
                  align={'left'}
                  padding={'normal'}
                  sortDirection={
                    filter
                      ? filter.sortField === row.id
                        ? filter.ascendingOrder === true ||
                          filter.ascendingOrder === 'true'
                          ? 'asc'
                          : 'desc'
                        : false
                      : null
                  }
                >
                  {row.sortOption ? (
                    <TableSortLabel
                      active={filter.sortField === row.id}
                      direction={
                        filter.ascendingOrder == true ||
                        filter.ascendingOrder == 'true'
                          ? 'asc'
                          : 'desc'
                      }
                      onClick={() => createSortHandler(row.id)}
                      //    IconComponent={<img src={`${S3_ASSETS_PATH}/images/sort-icon.svg`} />}
                    >
                      {' '}
                      {row.label}{' '}
                    </TableSortLabel>
                  ) : (
                    <span> {row.label} </span>
                  )}
                </TableCell>
              ))}
            </TableRow>
            {props.tableLoader ? (
              <TableRow className="empty-row loader">
                <TableCell
                  colSpan={8}
                  style={{ height: 0, padding: 0, borderBottom: 'none' }}
                >
                  <ColorLinearProgress className={classes.margin} />
                </TableCell>
              </TableRow>
            ) : null}
          </TableHead>
          <TableBody>
            {tableData}
            {emptyRows > 0 &&
            !props.tableLoader &&
            props.records.total === 0 ? (
              <TableRow
                style={{ height: 48 * emptyRows }}
                className="empty-row"
              >
                {
                  <TableCell
                    colSpan={8}
                    className="no-records-found"
                    style={
                      props.records.total !== 0 ? { background: '#fff' } : {}
                    }
                  >
                    <img
                      src={`${S3_ASSETS_PATH}/images/no-data-found.svg`}
                      alt=""
                    />
                    <p className="paragraph">
                      No data found,
                      <br /> <span>{props.emptyState}</span>
                    </p>
                  </TableCell>
                }
              </TableRow>
            ) : null}
          </TableBody>
        </Table>
      </div>
      {showDiscalimer && overLimitDisclaimer}
      {Object.keys(pagination).length &&
      pagination.totalRecords &&
      pagination.count > 1 ? (
        <div className="pagination-wrapper">
          <Pagination
            variant="outlined"
            shape="rounded"
            className="pagination"
            count={pagination.count}
            page={pagination.page}
            onChange={props.handleChangePage}
            data-testid="tablePaginationWrapper"
          />
        </div>
      ) : null}
    </Paper>
  );
}
ListingTableNew.propTypes = {
  tableLoader: PropTypes.bool.isRequired,
  emptyState: PropTypes.string.isRequired,
  className: PropTypes.string,
  records: PropTypes.objectOf(PropTypes.any).isRequired,
  handleChangePage: PropTypes.func,
  handleChangeRowsPerPage: PropTypes.func,
  createSortHandler: PropTypes.func,
  filter: PropTypes.object,
  headRows: PropTypes.array.isRequired,
  tableData: PropTypes.array.isRequired,
  pagination: PropTypes.object,
  testId: PropTypes.string,
};
export default ListingTableNew;
