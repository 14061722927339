import React, { FC } from 'react';
import { TableRow, TableCell } from '@material-ui/core';
import ExpansionList from '../../../../ui_elements/ExpansionList';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { convertTimeWithTimezone } from '../../../../utils/time';

interface EventTableRowProps {
  data: { description: string; createDate: number };
}

const EventTableRow: FC<EventTableRowProps> = ({ data }) => {
  const matches = useMediaQuery('(max-width:1280px)');
  const matches2 = useMediaQuery('(max-width:1500px)');
  const { description = '', createDate } = data ?? {};
  return (
    <TableRow>
      <TableCell
        style={{ width: 160 }}
        data-testid="eventListingTableCreateDate"
      >
        {convertTimeWithTimezone(createDate)}
      </TableCell>
      <TableCell
        style={{ maxWidth: 480, overflow: 'hidden' }}
        data-testid="eventListingTableDescription"
      >
        {description.length > (matches ? 65 : matches2 ? 75 : 100) ? (
          <ExpansionList
            title={
              matches
                ? `${description.slice(0, 55)}...`
                : matches2
                ? `${description.slice(0, 65)}...`
                : `${description.slice(0, 72)}...`
            }
          >{`${description}`}</ExpansionList>
        ) : (
          description
        )}
      </TableCell>
    </TableRow>
  );
};

export default EventTableRow;
