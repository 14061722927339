import { FC, ComponentProps } from 'react';
import { BoxV2 as Box } from 'portal-commons';

const AddEmailBtn: FC<ComponentProps<typeof Box>> = ({
  sx,
  onClick,
  ...restProps
}) => {
  return (
    <Box
      role="button"
      tabIndex={0}
      sx={{
        color: '#0091B3',
        fontSize: 'H400',
        fontWeight: 500,
        lineHeight: 'normal',
        border: 'none',
        background: '#fff',
        display: 'inline-block',
        textAlign: 'left',
        width: 'fit-content',
        cursor: 'pointer',
        ...sx,
      }}
      onClick={(e) => onClick?.(e)}
      {...restProps}
    >
      &#43; &nbsp;Add a New Email
    </Box>
  );
};
export default AddEmailBtn;
