// src/App.js

import React, { useEffect } from 'react';
import {
  Route,
  Switch,
  useHistory,
  useLocation,
  Redirect,
} from 'react-router-dom';
import { Security, SecureRoute } from '@okta/okta-react';
import { OktaAuth } from '@okta/okta-auth-js';
import { datadogRum } from '@datadog/browser-rum';
import { authRoutes } from './applications/auth/authRoutes';
import { cspRoutes } from './applications/CspPortal/cspRoutes';
import Protected from './Protected';
import { GetCspVerificationHoc, ErrorBoundaryHoc } from './hocs';
import PageNotFound from './404';
import { OKTA_CLIENT_ID, OKTA_ISSUER_URL } from './constants';
import CustomLoginCallback from './CustomLoginCallback';
import Initializer from './Initializer';

const oktaAuth = new OktaAuth({
  issuer: OKTA_ISSUER_URL,
  clientId: OKTA_CLIENT_ID,
  redirectUri: `${window.location.origin}/implicit/callback`,
  pkce: false,
  postLogoutRedirectUri: `${window.location.origin}/login`,
});

const AppWithRouterAccess = () => {
  const history = useHistory();
  const location = useLocation();

  const onAuthRequired = () => {
    history.push('/login');
  };

  const computeViewName = (routeMatches) => {
    let viewName = '';
    for (let index = 0; index < routeMatches.length; index++) {
      const routeMatch = routeMatches[index];
      const { path } = routeMatch;

      if (!path) {
        return null;
      }

      if (path.startsWith('/')) {
        viewName = path;
      } else {
        viewName += viewName.endsWith('/') ? path : `/${path}`;
      }
    }

    return viewName || '/';
  };

  useEffect(() => {
    const allRoutes = [...authRoutes, ...cspRoutes];
    const routeMatches = allRoutes.filter((route) => {
      const regex = new RegExp(route.path.replace(/:[^\/]+/g, '\\w+'));
      return regex.test(location.pathname);
    });
    const viewName = routeMatches && computeViewName(routeMatches);
    if (viewName) {
      datadogRum.startView({ name: viewName });
    }
  }, [location.pathname]);

  return (
    <Security oktaAuth={oktaAuth} onAuthRequired={onAuthRequired}>
      <Initializer />
      <Switch>
        <Route
          exact
          path="/implicit/callback"
          // component={LoginCallback}
          component={CustomLoginCallback}
          key="callback"
        />
        <Route
          exact
          path="/"
          component={ErrorBoundaryHoc(GetCspVerificationHoc(Protected))}
          key="home"
        />
        <SecureRoute
          exact
          path="/protected"
          component={Protected}
          key="protected"
        />
        {authRoutes.map(({ path, component, key }, index) => (
          <Route exact path={path} component={component} key={key} />
        ))}
        {cspRoutes.map(({ path, component, key }) => (
          <SecureRoute
            exact
            path={path}
            component={component}
            key={key}
            id={key}
          />
        ))}
        {/* <SecureRoute
                          render={({ component }) => component()}
                          exact
                          component={MySecureApp}
                        /> */}
        <Route path="/404" component={PageNotFound} key="404" />
        <Redirect from="*" to="/404" />
      </Switch>
    </Security>
  );
};
// const MySecureApp = (props) => {
//   return (
//      <>
//      {cspRoutes.map(({ path, component, key }) => (
//            <Route
//              exact
//              path={path}
//              component={component}
//              key={key}
//              id={key}
//            />
//          ))}
//      </>
//   )
// }
export default AppWithRouterAccess;
