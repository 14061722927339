import React, { useState, useEffect, useRef, useCallback } from 'react';
import { Document, Page, pdfjs } from 'react-pdf';
import { CircularProgress } from '@material-ui/core';
import { Flex, BoxV2 as Box } from 'portal-commons';

if (!pdfjs.GlobalWorkerOptions.workerSrc) {
  pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.min.js`;
}

interface PDFViewerProps {
  url: string;
  onScrollToBottom?: () => void;
  width?: number;
}

const DOCUMENT_OPTIONS = {
  isEvalSupported: false,
  cMapUrl: 'https://unpkg.com/pdfjs-dist@2.16.105/cmaps/',
  cMapPacked: true,
} as const;

const PDFViewer: React.FC<PDFViewerProps> = ({
  url,
  onScrollToBottom,
  width = 900,
}) => {
  const [numPages, setNumPages] = useState<number | null>(null);
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [documentError, setDocumentError] = useState<boolean>(false);
  const [pageLoaded, setPageLoaded] = useState<number>(0);
  const [pageLoadedError, setPageLoadedError] = useState<boolean>(false);
  const [allPagesLoaded, setAllPagesLoaded] = useState<boolean>(false);
  const containerRef = useRef<HTMLDivElement>(null);
  const docBottomRef = useRef<HTMLDivElement>(null);

  const onDocumentLoadSuccess = useCallback(
    ({ numPages }: { numPages: number }) => {
      setNumPages(numPages);
      setIsLoading(false);
      setPageLoaded(0);
      setPageLoadedError(false);
      setAllPagesLoaded(false);
      setDocumentError(false);
    },
    []
  );

  const onDocumentLoadError = useCallback((error: Error) => {
    console.error('Error while loading PDF:', error);
    setDocumentError(true);
    setIsLoading(false);
  }, []);

  const handlePageLoadSuccess = () => {
    setPageLoaded(pageLoaded + 1);
    if (pageLoaded + 1 === numPages) {
      setAllPagesLoaded(true);
    }
  };

  const handlePageLoadError = () => {
    setPageLoadedError(true);
  };

  // Reset state when URL changes
  useEffect(() => {
    setPageLoaded(0);
    setPageLoadedError(false);
    setAllPagesLoaded(false);
    setDocumentError(false);
    setNumPages(null);
  }, [url]);

  // Intersection Observer setup
  useEffect(() => {
    const docBottom = docBottomRef.current;
    if (!docBottom || isLoading || !onScrollToBottom) return;

    const observer = new IntersectionObserver(
      (entries) => {
        const [entry] = entries;
        if (entry.isIntersecting && allPagesLoaded) {
          onScrollToBottom();
        }
      },
      {
        root: containerRef.current,
        rootMargin: '50px',
        threshold: 1.0,
      }
    );
    observer.observe(docBottom);

    // eslint-disable-next-line consistent-return
    return () => observer.unobserve(docBottom);
  }, [onScrollToBottom, isLoading, allPagesLoaded]);

  const renderLoadingIndicator = () => {
    return (
      <Flex
        sx={{
          mt: 20,
          flexDirection: 'column',
          alignItems: 'center',
          justifyContent: 'center',
          gap: '20px',
        }}
      >
        <CircularProgress size={40} />
        <div>Loading PDF...</div>
      </Flex>
    );
  };

  const renderPage = useCallback(
    (pageNumber: number) => (
      <Page
        key={`page_${pageNumber}`}
        pageNumber={pageNumber}
        renderTextLayer={false}
        renderAnnotationLayer={false}
        width={width}
        onLoadSuccess={() => handlePageLoadSuccess()}
        onLoadError={() => handlePageLoadError()}
        loading={<div>Loading page {pageNumber}...</div>}
        error={
          <Flex sx={{ justifyContent: 'center', p: 2, color: 'red' }}>
            Failed to load page {pageNumber}
          </Flex>
        }
      />
    ),
    [width, handlePageLoadSuccess, handlePageLoadError]
  );

  const renderFailedPagesNotice = () => {
    if (!pageLoadedError) return null;

    return (
      <Flex sx={{ alignItems: 'center', flexDirection: 'column' }}>
        <Box sx={{ color: 'red', mb: 10 }}>PDF page(s) failed to load.</Box>
        <Box
          sx={{ cursor: 'pointer' }}
          onClick={() => {
            window.open(url, '_blank');
            onScrollToBottom?.();
          }}
        >
          View original <u>PDF</u>
        </Box>
      </Flex>
    );
  };

  return (
    <div
      ref={containerRef}
      className="custom-scrollbar"
      style={{
        height: 'calc(100vh - 200px)',
        overflow: 'auto',
        margin: '0 auto',
      }}
    >
      {documentError ? (
        <div style={{ color: 'red', textAlign: 'center' }}>
          Failed to load PDF. Please try again later.
        </div>
      ) : (
        <>
          {renderFailedPagesNotice()}
          <Document
            file={url}
            onLoadSuccess={onDocumentLoadSuccess}
            onLoadError={onDocumentLoadError}
            options={DOCUMENT_OPTIONS}
            loading={renderLoadingIndicator()}
          >
            {!isLoading &&
              numPages &&
              !pageLoadedError &&
              Array.from({ length: numPages }, (_, index) =>
                renderPage(index + 1)
              )}
          </Document>
          <div ref={docBottomRef} style={{ height: '1px', width: '100%' }} />
        </>
      )}
    </div>
  );
};

export default PDFViewer;
